import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  //return true
  const userData = {
    id:1,
    email:'admin@tusse.com',
    password: 'admin@tusse.com',
    username:'tusse',
    fullName: 'tusse',
    avatar: null,
    role: 'admin',
    ability: [
      {
        action: 'manage',
        subject: 'all',
      },
    ],
    extras: [{eCommerceCartItemsCount:0}]
  }
  let accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjEzODU0MTQ5LCJleHAiOjE2MTM4NTQ3NDl9.jhevHNbPubDTs5fky6HEy_ENTIoiElvBirLi23INVRA"
  //console.log(userData)
  //localStorage.setItem("userData",userData)
  
  //return true
  //return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  return userData && accessToken
  //return userData
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}
