import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
    lDovizKuru:1,
    lToplamMiktar: 0.0,
    lMalBedeli:0.0,
    lMalIskontoTutari:0.0,
    lToplamKdv:0.0,
    lNetTutar:0.0,
    bLisans: false,
    bLisansAylik:false,
    bLisansYillik:false,
    bLisansTemizle:false,
    bFirma: false,
    bBilgisayar: false,
    lKargoUcreti: 0.0,
    cartItems: [],
  },
  getters: {
    getTradeResult: state =>{
      return {
        bLisans : state.bLisans,
        bFirma : state.bFirma,
        bBilgisayar : state.bBilgisayar,
        lDovizKuru : state.lDovizKuru,
        lToplamMiktar : state.lToplamMiktar,
        lMalBedeli : state.lMalBedeli,
        lMalIskontoTutari: state.lMalIskontoTutari,
        lToplamKdv : state.lToplamKdv,
        lKargoUcreti: state.lKargoUcreti,
        lNetTutar: state.lNetTutar,
        bLisansYillik: state.bLisansYillik,
        bLisansAylik: state.bLisansAylik,
        bLisansTemizle: state.bLisansTemizle,
        cartItems: state.cartItems
        
      }
    }
  },
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
    toplamhesapla(state, item) {
      //console.log(item)
      let it = item
      let arr = state.cartItems;
      let lDovizKuru = state.lDovizKuru;
      state.lToplamMiktar =0.0,
      state.lMalBedeli=0.0,
      state.lMalIskontoTutari=0.0,
      state.lToplamKdv=0.0,
      state.lNetTutar=0.00,
      state.bLisans = false,
      state.bFirma = false,
      state.bBilgisayar = false,
      state.bLisansAylik = false,
      state.bLisansYillik = false,
      state.bLisansTemizle =false,
      state.lKargoUcreti =0.0,
      axios.get('/apps/ecommerce/cart').then(response => {
      //console.log(response.data.products)
      arr = response.data.products
      state.cartItems = arr
      //console.log(arr)
      arr.forEach(function(itm){
        //console.log(itm);
        state.lToplamMiktar += parseInt(itm.qty)
        var lBrutFiyat = itm.price / ((100+itm.tax)/100)
        if (itm.sDovizCinsi ==='USD') {      
          state.lMalBedeli += parseFloat(lBrutFiyat) * parseInt(itm.qty) * lDovizKuru
          state.lToplamKdv += ((parseFloat(lBrutFiyat) * parseInt(itm.qty)) * lDovizKuru) * parseFloat(itm.tax) / 100
        }else{
          state.lMalBedeli = state.lMalBedeli + (parseFloat(lBrutFiyat) * parseInt(itm.qty))
          state.lToplamKdv += (parseFloat(lBrutFiyat) * parseInt(itm.qty)) * parseFloat(itm.tax) / 100
        }
        if(itm.sKodu =='KarpinYillikLisans'){
          state.bFirma = true,
          state.bLisans = true,
          state.bLisansYillik = true
        }else if(itm.sKodu =='KarpinAylikLisans'){
          state.bFirma = true,
          state.bLisans = true,
          state.bLisansAylik = true
        }else if(itm.sKodu =='LisansTemizleme'){
          state.bFirma = true,
          state.bLisans = true,
          state.bLisansTemizle = true
        }else if(itm.sKodu =='LisansDurdur'){
          state.bFirma = true,
          state.bLisans = true
        }else if(itm.sKodu =='LisansBilgisayar'){
          state.bFirma = true,
          state.bLisans = true,
          state.bBilgisayar = true
        }else if(itm.sKodu =='KarpinKullanici'){
          state.bFirma = true,
          state.bLisans = false
        }else if(itm.sKodu =='KarpinEntegre'){
          state.bFirma = true,
          state.bLisans = false 
        }
        else if(itm.sKodu =='KarpinMobile'){
          state.bFirma = true,
          state.bLisans = false 
        }else if(itm.sKodu =='Turab'){
          state.bFirma = true,
          state.bLisans = false 
        }
        //console.log(state.lMalBedeli)
        //state.lMalBedeli = Number.parseFloat(state.lMalBedeli).toFixed(2)
        
        //state.lMalIskontoTutari = Number.parseFloat(state.lMalIskontoTutari).toFixed(2)
        //state.lToplamKdv = Number.parseFloat(state.lToplamKdv).toFixed(2)
        //state.lKargoUcreti = Number.parseFloat(state.lKargoUcreti).toFixed(2)
        state.lNetTutar= state.lMalBedeli + state.lToplamKdv
        if (state.lNetTutar < 150){state.lKargoUcreti=20.00}else {state.lKargoUcreti=0}
        state.lNetTutar= Number.parseFloat(parseFloat(state.lMalBedeli) + parseFloat(state.lToplamKdv)+ parseFloat(state.lKargoUcreti)).toFixed(2)
      });
    })
    },
  },
  actions: {
    fetchProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/products', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/ecommerce/products/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWishlistProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/wishlist')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/cart')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/wishlist', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/wishlist/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProductInCart(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/cart', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromCart(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/cart/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateItemQuantity(ctx, { productId, qty }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/ecommerce/item/${productId}`, { productId, qty })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
