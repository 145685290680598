/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

//import axios from '../../http/axios/index.js'

export default { 
  // SET_BEARER (state, accessToken) {
  //   axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  // },
  SET_FIRMA(state,item){
    state.nFirmaID = item.nFirmaID,
    state.nHesapID = item.nHesapID,
    state.sKodu = item.sKodu,
    state.sAciklama = item.sAciklama,
    state.sAdres1= item.sAdres1,
    state.sAdres2= item.sAdres2,
    state.sVergiDairesi= item.sVergiDairesi,
    state.sVergiNo= item.sVergiNo,
    state.sSaticiRumuzu= item.sSaticiRumuzu,
    state.sSemt= item.sSemt,
    state.sIl = item.sIl,
    state.sMail= item.sMail,
    state.sYetkili= item.sYetkili,
    state.sTelefon= item.sTelefon,
    state.sPostaKodu = item.sPostaKodu
  }
}
