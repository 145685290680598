/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


//import auth from '@/auth/authService'
//import firebase from 'firebase/app'
//import 'firebase/auth'

export default {
  nFirmaID : -4,
  nHesapID: 0,
  sKodu : 'tusse',
  sAciklama : 'www.tusse.com.tr',
  sAdres1:'Fatih Mah Prof Dr Aziz Sancar Cad No:34/1 Küçükçekmece/İstanbul - TÜRKİYE',
  sAdres2: '',
  sVergiDairesi: 'Küçüçekmece',
  sVergiNo: '49759718224',
  sSaticiRumuzu: '',
  sSemt: 'Küçükçekmece',
  sIl : 'İstanbul',
  sMail: 'info@tusse.com.tr', 
  sYetkili:'Orhan ÖZKARA',
  sTelefon:'+90 212 598 51 23',
  sPostaKodu:'6980048620',
  isUserLoggedIn: () => {
    let isAuthenticated = true

    // get firebase current user
    // const firebaseCurrentUser = firebase.auth().currentUser

    // if (auth.isAuthenticated() || firebaseCurrentUser) isAuthenticated = true
    // else isAuthenticated = false

    return localStorage.getItem('userInfo') && isAuthenticated
  }
}
