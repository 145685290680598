import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
//const userData = JSON.parse(localStorage.getItem('userData'))
const userData = {
    id:1,
    email:'admin@tusse.com',
    password: 'admin@tusse.com',
    username:'tusse',
    fullName: 'tusse',
    avatar: null,
    role: 'admin',
    ability: [
      {
        action: 'manage',
        subject: 'all',
      },
    ],
    extras: [{eCommerceCartItemsCount:0}]
  }

  
const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
