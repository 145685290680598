import mock from '@/@fake-db/mock'
// eslint-disable-next-line object-curly-newline
import { paginateArray, sortCompare, randomDate, getRandomInt } from '@/@fake-db/utils'

const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
const nextWeek = new Date(nextDay.getTime() + 7 * 24 * 60 * 60 * 1000)

/* eslint-disable global-require */
const data = {
  products: [
    {
      merchantId: 215534,
      id: 101366786,
      productCode: "TS-1720",
      name: "Kadın Siyah Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon",
      slug: "Kadın Siyah Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon",
      description: "Kadın Siyah Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon, Bel kısmı lastiklidir, Ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 88252633,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty574/product/media/images/20221022/0/199503831/168117989/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty574/product/media/images/20221022/0/199503831/168117989/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty575/product/media/images/20221022/0/199503831/168117989/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty574/product/media/images/20221022/0/199503831/168117989/3/3_org_zoom.jpg"
      ],
      price: 249.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 249.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 179.99,
        sellingPrice: 249.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.8421052631579,
        totalCount: 38.0
      },
      winnerVariant: "44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 168140865,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 107791366,
          comment: "Süper bir ürün bedeni oldukça iyi kullanışlı teşekkür ediyorum",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-05-27",
          lastModifiedDate: "27 Mayıs 2021"
        },
        {
          id: 130768768,
          comment: "Anneme aldım. Çok beğendi. Yeri çok güzel tiril tiril.",
          rate: 5.0,
          userFullName: "A** Ç**",
          trusted: true,
          commentDateISOtype: "2021-10-04",
          lastModifiedDate: "4 Ekim 2021"
        },
        {
          id: 193934107,
          comment: "Yumuşacık tril tril bir kumasi var.Tavsiye ediyorum .Bedeni tam ölçü.",
          rate: 5.0,
          userFullName: "H** Y**",
          trusted: true,
          commentDateISOtype: "2022-07-21",
          lastModifiedDate: "21 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 101366786,
          imageUrl: "https://cdn.dsmcdn.com/ty574/product/media/images/20221022/0/199503831/168117989/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon"
        },
        {
          id: 321982308,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319132/513837525/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Siyah Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Normal Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Kısa Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283365080,
      productCode: "TS-7190",
      name: "Çiçek Desenli Pamuklu Büyük Beden Gömlek",
      slug: "Çiçek Desenli Pamuklu Büyük Beden Gömlek",
      description: "Çiçek Desenli Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117376,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty864/product/media/images/20230504/17/339750519/450755712/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty864/product/media/images/20230504/17/339750519/450755712/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty865/product/media/images/20230504/17/339750519/450755712/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty864/product/media/images/20230504/17/339750519/450755712/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 363.22,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.92857142857143,
        totalCount: 14.0
      },
      winnerVariant: "42",
      color: "TURUNCU DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450755712,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 310894727,
          comment: "aşırı kaliteli tam beden",
          rate: 5.0,
          userFullName: "B** G**",
          trusted: true,
          commentDateISOtype: "2023-06-04",
          lastModifiedDate: "4 Haziran 2023"
        },
        {
          id: 300702374,
          comment: "Cox guzel ve kaliteli tam bedenimi aldim duruwu gozel tewekkur",
          rate: 5.0,
          userFullName: "vusala q.",
          trusted: true,
          commentDateISOtype: "2023-05-05",
          lastModifiedDate: "5 Mayıs 2023"
        },
        {
          id: 314991927,
          comment: "Beğendim 👍",
          rate: 4.0,
          userFullName: "Z** H**",
          trusted: true,
          commentDateISOtype: "2023-06-17",
          lastModifiedDate: "17 Haziran 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Katlamalı"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 273971238,
      productCode: "TS-P1230",
      name: "Kadın Haki Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      slug: "Kadın Haki Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      description: "Kadın Haki Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse krep kumaş düz kesim büyük beden yazlık lastikli pantolon, ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., ürün hafif ve rahattır., terleme yaratmaz., dökümlü ve salaş bir görünüm sağlar., kolay kolay buruşmaz., gösterişli ve şık bir görünüm sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün numune bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 91060888,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83047828/436721319/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83047828/436721319/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83047828/436721319/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83047828/436721319/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 245.15,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.33333333333333,
        totalCount: 6.0
      },
      winnerVariant: "42",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 436749411,
          stock: 7.0
        }
      ],
      productReviews: [
        {
          id: 193865991,
          comment: "Annem icin almistim tam oldu guzel tavsiye ederim",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-21",
          lastModifiedDate: "21 Temmuz 2022"
        },
        {
          id: 319761553,
          comment: "bayıldım çok güzel tavsiye ederim",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-07-07",
          lastModifiedDate: "7 Temmuz 2023"
        },
        {
          id: 316996169,
          comment: "mukemmel bir urun bayildim",
          rate: 5.0,
          userFullName: "E** A**",
          trusted: true,
          commentDateISOtype: "2023-06-23",
          lastModifiedDate: "23 Haziran 2023"
        }
      ],
      colors: [
        {
          id: 104380932,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/11/125213574/172386117/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971238,
          imageUrl: "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83047828/436721319/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 104340820,
          imageUrl: "https://cdn.dsmcdn.com/ty111/product/media/images/20210508/12/86842500/172316128/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Krep Kumaş Düz Kesim Büyük Beden Yazlık Lastikli Pantolon"
        },
        {
          id: 126238536,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125108144/206986809/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 125934251,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/11/111427360/205638411/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971605,
          imageUrl: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049498/436749971/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Indıgo Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Yüksek Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Boru Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319127,
      productCode: "TS-1688-4",
      name: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900871,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562772/511381188/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562772/511381188/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562772/511381188/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562772/511381188/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.91,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 5.0
      },
      winnerVariant: "50",
      color: "SOMON-GÜLLÜ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 511383403,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 199934366,
          comment: "Anneme aldık çok güzel",
          rate: 5.0,
          userFullName: "S** U** T**",
          trusted: true,
          commentDateISOtype: "2022-08-18",
          lastModifiedDate: "18 Ağustos 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122697354,
      productCode: "TS-1751-2",
      name: "Kadın Taş Işli Dijital Büzgülü Büyük Beden Elbise",
      slug: "Kadın Taş Işli Dijital Büzgülü Büyük Beden Elbise",
      description: "Kadın Taş Işli Dijital Büzgülü Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Kadın Empirme Dokuma Viskon Büyük Beden Elbise, %100 viskon kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 108032191,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/13/107229805/198018788/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/13/107229805/198018788/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/13/107229805/198018788/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty138/product/media/images/20210705/13/107229805/198018788/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 2.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "42",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 198036797,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 123561869,
          comment: "Elbisenin rengi deseni guzel yalnız biraz naylon karışımı var.bedeninizi alın",
          rate: 4.0,
          userFullName: "İ** E**",
          trusted: true,
          commentDateISOtype: "2021-08-21",
          lastModifiedDate: "21 Ağustos 2021"
        },
        {
          id: 155998729,
          comment: "iade ettim",
          rate: 2.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-01-25",
          lastModifiedDate: "25 Ocak 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138327542,
      productCode: "TS-1703-61",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız., Mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120192799,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125057168/236145338/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125057168/236145338/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/16/125057168/236145338/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057168/236145338/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 236155694,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 190096736,
          comment: "Kesimi güzel. Çok rahat yıka giy ütüye gerek yok!",
          rate: 5.0,
          userFullName: "S** C**",
          trusted: true,
          commentDateISOtype: "2022-06-27",
          lastModifiedDate: "27 Haziran 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 309342958,
      productCode: "TS-1697027",
      name: "Kadın Etnik Desenli Viskon Büyük Beden Salaş Pantolon",
      slug: "Kadın Etnik Desenli Viskon Büyük Beden Salaş Pantolon",
      description: "Kadın Etnik Desenli Viskon Büyük Beden Salaş Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli viskon büyük beden salaş pantolon, ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 38 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 257444876,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty446/product/media/images/20220603/20/120793925/493835488/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty446/product/media/images/20220603/20/120793925/493835488/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty444/product/media/images/20220603/20/120793925/493835488/2/2_org_zoom.jpg"
      ],
      price: 249.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 249.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 249.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "38",
      color: "LİLA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 493850081,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 321991025,
          comment: "Kuması dikişi durusu güzel sadece yüksek bel olması biraz gözüme battı ama bu ürünlerde de hep yüksek bel diyecek birşey yok, düşünmeden alınabilir",
          rate: 5.0,
          userFullName: "S** S**",
          trusted: true,
          commentDateISOtype: "2023-07-15",
          lastModifiedDate: "15 Temmuz 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Yüksek Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Geniş Paça"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Etnik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "İnce"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320946778,
      productCode: "TS-27159",
      name: "Eteği Volanlı Sıfır Kol Pamuklu Büyük Beden Elbise",
      slug: "Eteği Volanlı Sıfır Kol Pamuklu Büyük Beden Elbise",
      description: "Eteği Volanlı Sıfır Kol Pamuklu Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kolsuz, midi boy dokuma elbise. Ürün tamamen pamuktur. Hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Mankendeki beden 38-40 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359551,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134203562/512205055/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134203562/512205055/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203562/512205055/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203562/512205055/3/3_org_zoom.jpg"
      ],
      price: 429.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 429.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 429.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "38-40",
      color: "PEMBE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38-40",
          itemNumber: 512205055,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 320943547,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134199941/512200854/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Eteği Volanlı Sıfır Kol Pamuklu Büyük Beden Elbise"
        },
        {
          id: 320946778,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134203562/512205055/1/1_org_zoom.jpg",
          name: "PEMBE",
          description: "Eteği Volanlı Sıfır Kol Pamuklu Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124642916,
      productCode: "TS-E004",
      name: "Kadın Leopar Desenli Beli Lastikli Parçalı Dokumabattal Büyük Beden Anne Etek",
      slug: "Kadın Leopar Desenli Beli Lastikli Parçalı Dokumabattal Büyük Beden Anne Etek",
      description: "Kadın Leopar Desenli Beli Lastikli Parçalı Dokumabattal Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün numune bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 109765629,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty144/product/media/images/20210713/11/109989936/202854318/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty144/product/media/images/20210713/11/109989936/202854318/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty144/product/media/images/20210713/11/109989936/202854318/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty144/product/media/images/20210713/11/109989936/202854318/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty144/product/media/images/20210713/11/109989936/202854318/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "52-54",
      color: "LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "52-54",
          itemNumber: 202874368,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126029373,
      productCode: "C-0417-2",
      name: "Kadın Siyah V Yaka Omuz Güpürlü Viskon Bluz",
      slug: "Kadın Siyah V Yaka Omuz Güpürlü Viskon Bluz",
      description: "Kadın Siyah V Yaka Omuz Güpürlü Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40723986,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty389/product/media/images/20220408/1/85852877/205975346/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty389/product/media/images/20220408/1/85852877/205975346/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty388/product/media/images/20220408/1/85852877/205975346/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty389/product/media/images/20220408/1/85852877/205975346/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 165.59,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205975346,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126029373,
          imageUrl: "https://cdn.dsmcdn.com/ty389/product/media/images/20220408/1/85852877/205975346/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Siyah V Yaka Omuz Güpürlü Viskon Bluz"
        },
        {
          id: 48460692,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10355034/85848601/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah V Yaka Omuz Güpürlü Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320947042,
      productCode: "TS-1688-3",
      name: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265362020,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203831/512205334/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203831/512205334/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134203831/512205334/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134203831/512205334/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "50",
      color: "TURUNCU ZİGZAG",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 512223692,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 266929273,
          comment: "Çok güzel ve kaliteli çok özenli ve hızlı kargo ayrıca küçük bir not yazmışlar kendi elleriyle çok teşekkürler",
          rate: 5.0,
          userFullName: "B** B**",
          trusted: true,
          commentDateISOtype: "2023-02-08",
          lastModifiedDate: "8 Şubat 2023"
        },
        {
          id: 313640693,
          comment: "kumaşı kaliteli elbiseyi çok beğendik ❤ hızlı kargo için de teşekkür ederiz bol kazançlar 🙏",
          rate: 5.0,
          userFullName: "K** A**",
          trusted: true,
          commentDateISOtype: "2023-06-13",
          lastModifiedDate: "13 Haziran 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320949279,
      productCode: "TS-2000-14",
      name: "Çiçek Desenli Pamuklu Dokuma Kolları Ve Eteği Ponponlu Büyük Beden Elbise",
      slug: "Çiçek Desenli Pamuklu Dokuma Kolları Ve Eteği Ponponlu Büyük Beden Elbise",
      description: "Çiçek Desenli Pamuklu Dokuma Kolları Ve Eteği Ponponlu Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli pamuklu dokuma büyük beden elbise, %100 pamuk kumaş. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür. Mankenin üzerindeki beden 42 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265363462,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134206204/512208393/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134206204/512208393/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134206204/512208393/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134206204/512208393/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134206204/512208393/4/4_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 449.99,
        sellingPrice: 449.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.5,
        totalCount: 2.0
      },
      winnerVariant: "44",
      color: "BOTANİK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 512228444,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 192710434,
          comment: "benim aldıgim ürünün kolu ve kol evi 3 beden kücüktü.",
          rate: 4.0,
          userFullName: "B** K**",
          trusted: true,
          commentDateISOtype: "2022-07-14",
          lastModifiedDate: "14 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320344025,
      productCode: "TS-1689",
      name: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise",
      slug: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise",
      description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli kısa kol pamuklu büyük beden elbise, hem rahat, hem de şık bir görüntü sağlar, kumaş %100 pamuklu kumaştır, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz.,ürünümüz viskon kumaştır, ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin üzerindeki 42 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900797,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/9/133589884/511439657/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/9/133589884/511439657/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/9/133589884/511439657/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/9/133589884/511439657/3/3_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "TROPİKAL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 511443267,
          stock: 10.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 320950147,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134207069/512209321/1/1_org_zoom.jpg",
          name: "LACİ-BEYAZ",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        },
        {
          id: 377536296,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/13/205021725/610470074/1/1_org_zoom.jpg",
          name: "SİYAH ÇİÇEKLİ",
          description: "Çiçek Desenli Empirme Kendinden Bağcıklı Dökümlü Pamuklu Büyük Beden Elbise"
        },
        {
          id: 320319045,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562691/511381103/1/1_org_zoom.jpg",
          name: "KIRMIZI-ÇİÇEKLİ",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        },
        {
          id: 320344025,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/9/133589884/511439657/1/1_org_zoom.jpg",
          name: "TROPİKAL",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Bağcıklı"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377534419,
      productCode: "TS-1703-86",
      name: "Kadın Mor Çiçekli Desenli Cepli Dökümlü Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Mor Çiçekli Desenli Cepli Dökümlü Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Mor Çiçekli Desenli Cepli Dökümlü Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885703,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/10/204655023/610467951/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/10/204655023/610467951/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/15/205085505/610467951/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/15/205085530/610467951/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 335.33,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "MOR ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 610975836,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320943967,
      productCode: "TS-1686-8",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359857,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134200378/512201518/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134200378/512201518/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134200378/512201518/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134200378/512201518/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 2.0,
      ratingScore: {
        averageRating: 2.33333333333333,
        totalCount: 3.0
      },
      winnerVariant: "46",
      color: "SAX MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 512217712,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 212630610,
          comment: "güzel sadece yakası biraz kapalı oldu bir de kumaşı digerleri gibi degil naylonumsu",
          rate: 4.0,
          userFullName: "H** C**",
          trusted: true,
          commentDateISOtype: "2022-09-28",
          lastModifiedDate: "28 Eylül 2022"
        },
        {
          id: 206120063,
          comment: "Kumaşı güzel değil",
          rate: 1.0,
          userFullName: "N** T**",
          trusted: true,
          commentDateISOtype: "2022-09-09",
          lastModifiedDate: "9 Eylül 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366498,
      productCode: "TS-1748",
      name: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      slug: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      description: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109169703,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949042/450750754/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949042/450750754/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949042/450750754/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949042/450750754/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 204.94,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.83333333333333,
        totalCount: 6.0
      },
      winnerVariant: "42",
      color: "YEŞİL ÇITIR ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450750754,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 189703151,
          comment: "Merhabalar! Bu gömleğin yeşil (yani resimdeki ) rengi olmadığı için lacivert geldiğine rağmen yine mükemmel bir ürün olduğunu söylemek istiyorum. Mektubda bunları anlattığınız için özellikle teşekkür ederim. Ürünün ölçüsü 46 , anneme aldım. 63 kilo tam oldu, rahat, çok güzel, kumaşı yumuşak, kargo hızlı. Tavsiye ediyorum.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-06-24",
          lastModifiedDate: "24 Haziran 2022"
        },
        {
          id: 306085894,
          comment: "kaliteli ürün",
          rate: 5.0,
          userFullName: "d** ç**",
          trusted: true,
          commentDateISOtype: "2023-05-19",
          lastModifiedDate: "19 Mayıs 2023"
        },
        {
          id: 321406081,
          comment: "ürünğm geldi aynı renk aynı ton resimdeki gibi çom güzel ama dar kesim oldugundan brdeninizin bir beden büyügünü alın ben nprmalde 48benden giyiniyorum 50 beden aldım tam oldu",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-07-13",
          lastModifiedDate: "13 Temmuz 2023"
        }
      ],
      colors: [
        {
          id: 283366498,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949042/450750754/1/1_org_zoom.jpg",
          name: "YEŞİL ÇITIR ÇİÇEKLİ",
          description: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124013010,
          imageUrl: "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724966/200701187/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Krep Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 171619517,
          imageUrl: "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794332/280055461/1/1_org_zoom.jpg",
          name: "BEJ PUANTİYELİ",
          description: "Kadın Puantiyeli Krep Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124012968,
          imageUrl: "https://cdn.dsmcdn.com/ty234/product/media/images/20211110/0/172574731/200721599/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124012991,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888690/200701009/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290606131,
      productCode: "TS-1702165",
      name: "Kadın Batik Desenli Önü Düğmeli Cepli Viskon Kolsuz Büyük Beden Elbise",
      slug: "Kadın Batik Desenli Önü Düğmeli Cepli Viskon Kolsuz Büyük Beden Elbise",
      description: "Kadın Batik Desenli Önü Düğmeli Cepli Viskon Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Büyük Beden Elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin üzerindeki ürün 42 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 245144673,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/20/100795249/464264846/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/20/100795249/464264846/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100795249/464264846/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/20/100795249/464264846/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 2.0,
      ratingScore: {
        averageRating: 2.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "MAVİ BATİK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 464269152,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 205315268,
          comment: "ürünün kumaşı resmen polyester 200 tl etmez. ter icinde kalıyor insan sıcak günlerde. tril tril serin tutar beni diye almıştım giymiş bulundum iade etme şansımı kaybettim. salaşliğı rahat. ama hava günesten kavrulmaya başladığında giyemez oluyor insan. birde hamile gibi gösteriyor insanı onu da sevmedim. attım dolabımın bir köşesine bir daha ne zaman giyerim hiç bilemiyorum. tavsiye etmem kimseye. almayın",
          rate: 2.0,
          userFullName: "S** P**",
          trusted: true,
          commentDateISOtype: "2022-09-07",
          lastModifiedDate: "7 Eylül 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Batik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Sıfır Kol"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138325221,
      productCode: "TS-1702-16",
      name: "Kadın Desenli Viskon Kolsuz Elbise",
      slug: "Kadın Desenli Viskon Kolsuz Elbise",
      description: "Kadın Desenli Viskon Kolsuz Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kadın Çiçek Desenli Kolları Lastikli Dokuma Pamuklu Büyük Beden Elbise., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamad"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120191068,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054873/236140299/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054873/236140299/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125054873/236140299/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/16/125054873/236140299/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 2.0
      },
      winnerVariant: "44",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 236150993,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 265734400,
          comment: "günlük kullanıma uygun ve rahat bir elbise. fakat pamuklu olmasını tercih ederdim.",
          rate: 4.0,
          userFullName: "G** E**",
          trusted: true,
          commentDateISOtype: "2023-02-04",
          lastModifiedDate: "4 Şubat 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 725948661,
      productCode: "TS-P1228",
      name: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      slug: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse krep kumaş düz kesim büyük beden yazlık lastikli pantolon, ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., ürün hafif ve rahattır., terleme yaratmaz., dökümlü ve salaş bir görünüm sağlar., kolay kolay buruşmaz., gösterişli ve şık bir görünüm sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün numune bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 552638955,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty914/product/media/images/20230527/16/365147033/954858888/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty914/product/media/images/20230527/16/365147033/954858888/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/16/365147033/954858888/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/16/365147033/954858888/3/3_org_zoom.jpg"
      ],
      price: 289.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 289.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 289.99,
        sellingPrice: 289.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 954858888,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 323957975,
          comment: "rahat bir pantolon ama dar kalıp 1 beden büyük alinmali",
          rate: 5.0,
          userFullName: "s** u**",
          trusted: true,
          commentDateISOtype: "2023-07-22",
          lastModifiedDate: "22 Temmuz 2023"
        }
      ],
      colors: [
        {
          id: 725948661,
          imageUrl: "https://cdn.dsmcdn.com/ty914/product/media/images/20230527/16/365147033/954858888/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725631449,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364826277/954539071/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725793366,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364992010/954702030/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 726203337,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/18/365413366/955118936/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725794958,
          imageUrl: "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364993691/954703682/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Bol Paça"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuk Karışımlı"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 880,
          key: "Siluet",
          value: "Regular"
        },
        {
          id: 881,
          key: "Ortam",
          value: "Casual/Günlük"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Beli lastikli"
        },
        {
          id: 870,
          key: "Persona",
          value: "Smart"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "İnce"
        },
        {
          id: 710,
          key: "Koleksiyon",
          value: "Büyük Beden"
        },
        {
          id: 893,
          key: "Kemer/Kuşak Durumu",
          value: "Bağcıklı"
        },
        {
          id: 279,
          key: "Yaş",
          value: "Tüm Yaş Grupları"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290597296,
      productCode: "TS-7120",
      name: "Renkli Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Renkli Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Renkli Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek desenli kolları bağcıklı pamuklu büyük beden gömlek., %100 cotton kumaş., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 245135627,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/19/100785081/464247247/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/19/100785081/464247247/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785081/464247247/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785081/464247247/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 4.0
      },
      winnerVariant: "42",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 464247247,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 317304800,
          comment: "Super bir kelme ile",
          rate: 5.0,
          userFullName: "n** y**",
          trusted: true,
          commentDateISOtype: "2023-06-25",
          lastModifiedDate: "25 Haziran 2023"
        },
        {
          id: 195220318,
          comment: "Kalitesi çok güzel, annem severek giyiyor.",
          rate: 5.0,
          userFullName: "F** Y**",
          trusted: true,
          commentDateISOtype: "2022-07-28",
          lastModifiedDate: "28 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        },
        {
          id: 48,
          key: "Boy",
          value: "Kısa"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Truvakar Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Organik"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320943661,
      productCode: "TS-P004",
      name: "Elegant %100 Pamuklu Bohem Desenli Büyük Beden Elbise",
      slug: "Elegant %100 Pamuklu Bohem Desenli Büyük Beden Elbise",
      description: "Elegant %100 Pamuklu Bohem Desenli Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Pamuklu Dokuma Büyük Beden Elbise, %100 pamuk kumaş. kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür. Mankendeki beden 44-46 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359635,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134200073/512200980/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134200073/512200980/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134200073/512200980/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134200073/512200980/3/3_org_zoom.jpg"
      ],
      price: 999.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 999.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 649.99,
        sellingPrice: 999.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "INDIGO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 512217699,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Balon Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321346556,
      productCode: "TS-S121",
      name: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      slug: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684099,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623638/512815749/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623638/512815749/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134623638/512815749/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134623638/512815749/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 512815749,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 266553754,
          comment: "Anneme aldım güzel beğendim",
          rate: 5.0,
          userFullName: "Ü** P**",
          trusted: true,
          commentDateISOtype: "2023-02-06",
          lastModifiedDate: "6 Şubat 2023"
        }
      ],
      colors: [
        {
          id: 321346556,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623638/512815749/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        },
        {
          id: 321349817,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134627000/512820430/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470047,
      productCode: "TS-L240",
      name: "Nervür Detaylı Şifon Büyük Beden Klasik Gömlek",
      slug: "Nervür Detaylı Şifon Büyük Beden Klasik Gömlek",
      description: "Nervür Detaylı Şifon Büyük Beden Klasik Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458614,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896334/515255068/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896334/515255068/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896334/515255068/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896334/515255068/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 285.71,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 3.0
      },
      winnerVariant: "36",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515255068,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 220101587,
          comment: "modeli,kumaşı güzel ama bedeni küçük geldiği için iade etmek zorunda kaldım",
          rate: 4.0,
          userFullName: "t** k**",
          trusted: true,
          commentDateISOtype: "2022-10-21",
          lastModifiedDate: "21 Ekim 2022"
        },
        {
          id: 310100442,
          comment: "kısa",
          rate: 3.0,
          userFullName: "b** ö**",
          trusted: true,
          commentDateISOtype: "2023-06-01",
          lastModifiedDate: "1 Haziran 2023"
        },
        {
          id: 248606531,
          comment: "Çok beğendim kullanışlı kurtarıcı parça",
          rate: 5.0,
          userFullName: "H** Ç**",
          trusted: true,
          commentDateISOtype: "2023-01-03",
          lastModifiedDate: "3 Ocak 2023"
        }
      ],
      colors: [
        {
          id: 322471099,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897612/515256656/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Nervür Detaylı Şifon Büyük Beden Klasik Gömlek"
        },
        {
          id: 322470047,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896334/515255068/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Nervür Detaylı Şifon Büyük Beden Klasik Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 141070397,
      productCode: "TS-E0013",
      name: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 121857394,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/10/127894062/239367898/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/10/127894062/239367898/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210909/10/127894062/239367898/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/10/127894062/239367898/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "46-48",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46-48",
          itemNumber: 239367898,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 317062054,
          comment: "Anneme aldım çok beğendi kumaşı dokusu gayet kaliteli gönül rahatlığıyla alabilirsiniz",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-06-24",
          lastModifiedDate: "24 Haziran 2023"
        },
        {
          id: 322810526,
          comment: "Tam beden alabilirsiniz",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-07-19",
          lastModifiedDate: "19 Temmuz 2023"
        }
      ],
      colors: [
        {
          id: 141070397,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/10/127894062/239367898/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        },
        {
          id: 141070345,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210909/10/127894021/239367828/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        },
        {
          id: 141070414,
          imageUrl: "https://cdn.dsmcdn.com/ty484/product/media/images/20220719/23/145981717/239378076/1/1_org_zoom.jpg",
          name: "TURUNCU",
          description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321982308,
      productCode: "TS-1720",
      name: "Kadın Siyah Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon",
      slug: "Kadın Siyah Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon",
      description: "Kadın Siyah Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon, Bel kısmı lastiklidir, Ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 88252633,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319132/513837525/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319132/513837525/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319132/513837525/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220705/0/135319132/513837525/3/3_org_zoom.jpg"
      ],
      price: 249.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 249.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 249.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 513885213,
          stock: 8.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 101366786,
          imageUrl: "https://cdn.dsmcdn.com/ty574/product/media/images/20221022/0/199503831/168117989/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon"
        },
        {
          id: 321982308,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319132/513837525/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Siyah Düz Renk Viskon Beli Lastikli Büyük Beden Kapri Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Normal Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Kısa Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 333664175,
      productCode: "C-S105",
      name: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      slug: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Oya İşli %100 Pamuk Özel Şile Bezi El İşçiliği Büyük Beden Dokuma Tunik Bluz, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki 40-42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684564,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty492/product/media/images/20220729/20/152087142/531847242/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty492/product/media/images/20220729/20/152087142/531847242/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty494/product/media/images/20220729/20/152087142/531847242/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty493/product/media/images/20220729/20/152087142/531847242/3/3_org_zoom.jpg"
      ],
      price: 429.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 429.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 269.99,
        sellingPrice: 429.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 531847242,
          stock: 4.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321347179,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134624255/512816391/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 333664175,
          imageUrl: "https://cdn.dsmcdn.com/ty492/product/media/images/20220729/20/152087142/531847242/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 280075055,
      productCode: "TS-EL001323",
      name: "Leopar Desenli Viskon Büyük Beden Dökümlü Uzun Anne Elbise",
      slug: "Leopar Desenli Viskon Büyük Beden Dökümlü Uzun Anne Elbise",
      description: "Leopar Desenli Viskon Büyük Beden Dökümlü Uzun Anne Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 236620259,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty394/product/media/images/20220412/21/89497014/446006026/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty394/product/media/images/20220412/21/89497014/446006026/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty393/product/media/images/20220412/21/89497014/446006026/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty393/product/media/images/20220412/21/89497014/446006026/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 3.0
      },
      winnerVariant: "44",
      color: "LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 446018381,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 182415732,
          comment: "Model kumaş çok güzel beden olarak dar bir beden büyük alınmalı yapışıyor",
          rate: 5.0,
          userFullName: "ŞEBNEM A.",
          trusted: true,
          commentDateISOtype: "2022-05-19",
          lastModifiedDate: "19 Mayıs 2022"
        },
        {
          id: 212943025,
          comment: "elbise kaliteli ve çok şık ama bedeni küçük olduğu için iade etmek zorunda kaldım",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-09-29",
          lastModifiedDate: "29 Eylül 2022"
        },
        {
          id: 296002001,
          comment: "Kalıbı çok dar Anne elbisesi böyle olmaz..",
          rate: 2.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-04-25",
          lastModifiedDate: "25 Nisan 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Leopar"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Çok Renkli"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319055,
      productCode: "TS-1686-4",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900807,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562700/511381113/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562700/511381113/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562700/511381113/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562700/511381113/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 344.42,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 2.0
      },
      winnerVariant: "46",
      color: "KIRMIZI-ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 511383216,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 199142029,
          comment: "indirimden aldim, cok guzel",
          rate: 5.0,
          userFullName: "D** M**",
          trusted: true,
          commentDateISOtype: "2022-08-15",
          lastModifiedDate: "15 Ağustos 2022"
        },
        {
          id: 233312785,
          comment: "%100 Polyester çok kaygan kumaşı var, ütü sevmeyenler alabilir. Sıcak sevmeyenler uzak dursun.",
          rate: 4.0,
          userFullName: "H** O**",
          trusted: true,
          commentDateISOtype: "2022-11-25",
          lastModifiedDate: "25 Kasım 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 104535260,
      productCode: "TS-S100-2",
      name: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Puantiye Desenli U.Kol Sandy Kumaş Elbise, Kırışma zor olmaz, Çamaşır makinesinde, düşük derece ayarında yıkanabilir, Sağlam bir kumaş çeşididir, Renkleri çok canlı göstermesinden ötürü, oldukça şık görünmektedir, Ürün, dökümlü ve kullanış açısından çok r"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 91235298,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080447/172568398/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080447/172568398/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080447/172568398/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080447/172568398/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42-44",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 172569929,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 186518010,
          comment: "daha önce de bu markadan elbise almıştım.Cok memnunum hem şık hem de rahat bir kumaş.Hizli kargo için çok tesekkurler",
          rate: 5.0,
          userFullName: "E** Ü**",
          trusted: true,
          commentDateISOtype: "2022-06-09",
          lastModifiedDate: "9 Haziran 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536282,
      productCode: "TYC00603339845",
      name: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      slug: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve Uzun Kol, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304887330,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/8/204616136/610470059/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/8/204616136/610470059/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/15/205085883/610470059/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205085894/610470059/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "46",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 610788872,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 48,
          key: "Boy",
          value: "Maxi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138327638,
      productCode: "TS-1703-60",
      name: "Kadın Çiçek Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Çiçek Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Çiçek Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kadın Taş İşli Dijital Büzgülü Büyük Beden Elbise, Kırışma zor olmaz, Çamaşır makinesinde, düşük derece ayarında yıkanabilir, Sağlam bir kumaş çeşididir, Renkleri çok canlı göstermesinden ötürü, oldukça şık görünmektedir, Ürün, dökümlü ve kullanış a"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120192865,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125057256/236145478/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125057256/236145478/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057256/236145478/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057256/236145478/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 8.0
      },
      winnerVariant: "42",
      color: "KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 236155946,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 187606155,
          comment: "tusse elbiselerin penyesi hem kaliteli hem yumuşacık. Bu kırmızı elbiseyi 50 BEDEN OLARAK SATINALDIM GEÇEN HAFTADA YİNE TUSSE PUANTİYELİ SİYAH BEYAZ OLAN ELBİSENİN 52 BEDENİNİ  SATIN ALMIŞTIM. 52 beden az büyük oldu diye 50 beden gelene kadar iade etmeden bekledim fakat kırmızı elbisenin 50 bedeni siyah beyaz puantiyeli elbisenin 52 bedeninden daha büyük oldu bu işi anlamadım ben. o yüzden kırmızı elbiseyi iade etmeliyim. sonra 48 beden veya 46 beden olarak satın  alacağım.",
          rate: 4.0,
          userFullName: "a** ç**",
          trusted: true,
          commentDateISOtype: "2022-06-15",
          lastModifiedDate: "15 Haziran 2022"
        },
        {
          id: 189094492,
          comment: "Ablama aldım , çok beğendi",
          rate: 5.0,
          userFullName: "F** M** B**",
          trusted: true,
          commentDateISOtype: "2022-06-21",
          lastModifiedDate: "21 Haziran 2022"
        },
        {
          id: 190698327,
          comment: "2ci siparişim de geldi çabuk vepaketi güzeldi kumaşıda harika kalın gibi duruyor ama serin tutuluyor velhasıl çok beğendim bu firma çok güzel inşallah kaliteyi bozmazlar ben elbiselerin kolsuzu olsa yine alırım çok tesekkür",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-06-30",
          lastModifiedDate: "30 Haziran 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320950147,
      productCode: "TS-1689",
      name: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise",
      slug: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise",
      description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Kısa Kol Pamuklu Büyük Beden Elbise, hem rahat, hem de şık bir görüntü sağlar, Kumaş %100 pamuklu kumaştır, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz.,Ürünümüz viskon kumaştır, ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., Mankenin üzerindeki 42 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900797,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134207069/512209321/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134207069/512209321/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134207069/512209321/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134207069/512209321/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134207069/512209321/4/4_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "LACİ-BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 512230299,
          stock: 10.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 320950147,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134207069/512209321/1/1_org_zoom.jpg",
          name: "LACİ-BEYAZ",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        },
        {
          id: 377536296,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/13/205021725/610470074/1/1_org_zoom.jpg",
          name: "SİYAH ÇİÇEKLİ",
          description: "Çiçek Desenli Empirme Kendinden Bağcıklı Dökümlü Pamuklu Büyük Beden Elbise"
        },
        {
          id: 320319045,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562691/511381103/1/1_org_zoom.jpg",
          name: "KIRMIZI-ÇİÇEKLİ",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        },
        {
          id: 320344025,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/9/133589884/511439657/1/1_org_zoom.jpg",
          name: "TROPİKAL",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Bağcıklı"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320943667,
      productCode: "TS-1686-1",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359639,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134200085/512200992/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134200085/512200992/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134200085/512200992/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134200085/512200992/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.66666666666667,
        totalCount: 6.0
      },
      winnerVariant: "46",
      color: "MAVİ DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 512217650,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 202803603,
          comment: "Anneme aldım cok güzel oldu",
          rate: 3.0,
          userFullName: "A** B**",
          trusted: true,
          commentDateISOtype: "2022-08-28",
          lastModifiedDate: "28 Ağustos 2022"
        },
        {
          id: 203520891,
          comment: "Teyzeme aldık mükemmel kumaşı var vücut da yapmışmadı ve aşırı kaliteli",
          rate: 5.0,
          userFullName: "n** a**",
          trusted: true,
          commentDateISOtype: "2022-08-31",
          lastModifiedDate: "31 Ağustos 2022"
        },
        {
          id: 237882461,
          comment: "Biraz naylon hissettiriyor ama güzel ürün",
          rate: 4.0,
          userFullName: "N** S** T**",
          trusted: true,
          commentDateISOtype: "2022-12-06",
          lastModifiedDate: "6 Aralık 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 725631449,
      productCode: "TS-P1228",
      name: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      slug: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse krep kumaş düz kesim büyük beden yazlık lastikli pantolon, ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., ürün hafif ve rahattır., terleme yaratmaz., dökümlü ve salaş bir görünüm sağlar., kolay kolay buruşmaz., gösterişli ve şık bir görünüm sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün numune bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 552638955,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364826277/954539071/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364826277/954539071/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364826277/954539071/2/2_org_zoom.jpg"
      ],
      price: 289.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 289.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 289.99,
        sellingPrice: 289.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 956797322,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 312867460,
          comment: "Güzel annem beğendi",
          rate: 5.0,
          userFullName: "Y** K**",
          trusted: true,
          commentDateISOtype: "2023-06-10",
          lastModifiedDate: "10 Haziran 2023"
        }
      ],
      colors: [
        {
          id: 725948661,
          imageUrl: "https://cdn.dsmcdn.com/ty914/product/media/images/20230527/16/365147033/954858888/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725631449,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364826277/954539071/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725793366,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364992010/954702030/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 726203337,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/18/365413366/955118936/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725794958,
          imageUrl: "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364993691/954703682/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Bol Paça"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuk Karışımlı"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 880,
          key: "Siluet",
          value: "Regular"
        },
        {
          id: 881,
          key: "Ortam",
          value: "Casual/Günlük"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Beli lastikli"
        },
        {
          id: 870,
          key: "Persona",
          value: "Smart"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "İnce"
        },
        {
          id: 710,
          key: "Koleksiyon",
          value: "Büyük Beden"
        },
        {
          id: 893,
          key: "Kemer/Kuşak Durumu",
          value: "Bağcıklı"
        },
        {
          id: 279,
          key: "Yaş",
          value: "Tüm Yaş Grupları"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 273980133,
      productCode: "TS-EL001",
      name: "Etnik Desenli Sandy Büyük Beden Dökümlü Uzun Anne Elbise",
      slug: "Etnik Desenli Sandy Büyük Beden Dökümlü Uzun Anne Elbise",
      description: "Etnik Desenli Sandy Büyük Beden Dökümlü Uzun Anne Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve uzun kollu, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız.,Mankenin üzerindeki 44 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 232884325,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/20/83057476/436739218/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/20/83057476/436739218/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty383/product/media/images/20220404/20/83057476/436739218/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/20/83057476/436739218/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 399.99
      },
      rating: 2.0,
      ratingScore: {
        averageRating: 2.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "LACİ-OTANTİK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 436764559,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 297137560,
          comment: "vücuda yapışıyor, iade ettik.",
          rate: 2.0,
          userFullName: "Emre b.",
          trusted: true,
          commentDateISOtype: "2023-04-27",
          lastModifiedDate: "27 Nisan 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Etnik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138327622,
      productCode: "TS-1755-9",
      name: "Kadın Etnik Desenli Pamuk Dokuma Truvakar Kol Büyük Beden Elbise",
      slug: "Kadın Etnik Desenli Pamuk Dokuma Truvakar Kol Büyük Beden Elbise",
      description: "Kadın Etnik Desenli Pamuk Dokuma Truvakar Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Çift Cepli Düğmeli Krep Büyük Beden Kuşaklı Elbise., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir g"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120192854,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057296/236145457/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057296/236145457/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057296/236145457/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057296/236145457/3/3_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 449.99,
        sellingPrice: 449.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "44-46",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 236155937,
          stock: 9.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320947135,
      productCode: "C-S403",
      name: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      slug: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Oya İşli %100 Pamuk Özel Şile Bezi El İşçiliği Büyük Beden Dokuma Tunik Elbise, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır. Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki 40-42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359583,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203927/512205433/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203927/512205433/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134203927/512205433/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134203927/512205433/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134203927/512205433/4/4_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512205433,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 320943587,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199986/512200894/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise"
        },
        {
          id: 320947135,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203927/512205433/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Mini"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124024011,
      productCode: "TS-7149",
      name: "Ham Keten Omuz Detaylı Gömlek",
      slug: "Ham Keten Omuz Detaylı Gömlek",
      description: "Ham Keten Omuz Detaylı Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan düz renk keten gömlek., ürünün kumaşı ketendir., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109177306,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty338/product/media/images/20220220/20/54190985/200746317/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty338/product/media/images/20220220/20/54190985/200746317/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty338/product/media/images/20220220/20/54190985/200746317/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty336/product/media/images/20220220/20/54190985/200746317/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.83333333333333,
        totalCount: 6.0
      },
      winnerVariant: "42",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 200768616,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 212958955,
          comment: "Cok beyendik. Urun super.",
          rate: 5.0,
          userFullName: "Habib R.",
          trusted: true,
          commentDateISOtype: "2022-09-29",
          lastModifiedDate: "29 Eylül 2022"
        },
        {
          id: 323714680,
          comment: "Ürün çok güzel memnun kaldım",
          rate: 4.0,
          userFullName: "ç** k**",
          trusted: true,
          commentDateISOtype: "2023-07-21",
          lastModifiedDate: "21 Temmuz 2023"
        },
        {
          id: 189289196,
          comment: "çok beyendim",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-04",
          lastModifiedDate: "4 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 124024011,
          imageUrl: "https://cdn.dsmcdn.com/ty338/product/media/images/20220220/20/54190985/200746317/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Ham Keten Omuz Detaylı Gömlek"
        },
        {
          id: 124023973,
          imageUrl: "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/10/108901422/200746109/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Ham Keten Omuz Detaylı Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Keten"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 101420598,
      productCode: "TS-1736",
      name: "Kadın Boomerang Desenli Viskon Büyük Beden Havuç Pantolon",
      slug: "Kadın Boomerang Desenli Viskon Büyük Beden Havuç Pantolon",
      description: "Kadın Boomerang Desenli Viskon Büyük Beden Havuç Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Boomerang Desenli Viskon Büyük Beden Havuç Pantolon, Ürünün ön kısmı büzgülüdür, Bel kısmı lastiklidir, Ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 40 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 88321141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty110/product/media/images/20210428/18/83515180/168215678/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty110/product/media/images/20210428/18/83515180/168215678/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210428/18/83515180/168215678/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty109/product/media/images/20210428/18/83515180/168215678/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty110/product/media/images/20210428/18/83515180/168215678/4/4_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 0.0,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.35714285714286,
        totalCount: 14.0
      },
      winnerVariant: "40",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 168225991,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 118594424,
          comment: "tam bedeninizi alın çok rahat ve güzel",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-07-14",
          lastModifiedDate: "14 Temmuz 2021"
        },
        {
          id: 127244388,
          comment: "Çok güzel pantolon",
          rate: 5.0,
          userFullName: "O** M**",
          trusted: true,
          commentDateISOtype: "2021-09-16",
          lastModifiedDate: "16 Eylül 2021"
        },
        {
          id: 120943295,
          comment: "kaliteli ve rahat eşim begendi",
          rate: 5.0,
          userFullName: "murat T.",
          trusted: true,
          commentDateISOtype: "2021-08-02",
          lastModifiedDate: "2 Ağustos 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Normal Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Kısa Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320947086,
      productCode: "TS-P001",
      name: "Elegant %100 Pamuklu V Yaka Eteği Volanlı Puantiye Desenli Büyük Beden Elbise",
      slug: "Elegant %100 Pamuklu V Yaka Eteği Volanlı Puantiye Desenli Büyük Beden Elbise",
      description: "Elegant %100 Pamuklu V Yaka Eteği Volanlı Puantiye Desenli Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Pamuklu Dokuma Büyük Beden Elbise, %100 pamuk kumaş. kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür. Mankendeki beden 38-40 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359166,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203881/512205382/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203881/512205382/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134203881/512205382/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134203881/512205382/3/3_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "38-40",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38-40",
          itemNumber: 512205382,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 195127536,
          comment: "Kumaşı modeli çok guzel. Severek giyeceğim.",
          rate: 5.0,
          userFullName: "İ** E**",
          trusted: true,
          commentDateISOtype: "2022-07-27",
          lastModifiedDate: "27 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 320947086,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203881/512205382/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Elegant %100 Pamuklu V Yaka Eteği Volanlı Puantiye Desenli Büyük Beden Elbise"
        },
        {
          id: 320942841,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134199131/512200036/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Elegant %100 Pamuklu V Yaka Eteği Volanlı Puantiye Desenli Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140473377,
      productCode: "TS-7997",
      name: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek",
      slug: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek",
      description: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Klasik Pamuklu Battal Büyük Beden Gömlek, Düz renk pamuk-Saten gömlek., %100 cotton kumaş., parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün XL bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41120110,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/20/127272602/238643127/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/20/127272602/238643127/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 4.0
      },
      winnerVariant: "XL",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 238885498,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 164345409,
          comment: "Ürün tek kelime ile muhteşem Aldıkları para helali hoş olsun insallah Teşekkürler TUSSE🍀",
          rate: 5.0,
          userFullName: "B** ö**",
          trusted: true,
          commentDateISOtype: "2022-02-28",
          lastModifiedDate: "28 Şubat 2022"
        },
        {
          id: 188908569,
          comment: "Çok beğendiğim için iki renginden de aldım, %100 pamuklu kumaş oluşu , dikiş muntazamligı ve kalıbı çok güzel.Tesekkurler",
          rate: 5.0,
          userFullName: "M** K**",
          trusted: true,
          commentDateISOtype: "2022-06-21",
          lastModifiedDate: "21 Haziran 2022"
        }
      ],
      colors: [
        {
          id: 140473377,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 124023053,
          imageUrl: "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
          name: "LİLA",
          description: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 140024951,
          imageUrl: "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Sarı Klasik Pamuklu Gömlek"
        },
        {
          id: 140025063,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
          name: "MİNT YEŞİL",
          description: "Klasik Mint Yeşil Pamuklu Gömlek"
        },
        {
          id: 49109092,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Battal Pamuklu Gömlek"
        },
        {
          id: 140440545,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 56510747,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Battal Pamuklu Kadın Gömlek"
        },
        {
          id: 48931613,
          imageUrl: "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Battal Pamuklu Gömlek"
        },
        {
          id: 142140797,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319048,
      productCode: "TS-1688-2",
      name: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900800,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562693/511381106/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562693/511381106/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562693/511381106/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562693/511381106/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.91,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 8.0
      },
      winnerVariant: "50",
      color: "MOR-TROPİKAL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 511383202,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 200629342,
          comment: "eşime aldım çok güzel oldu..",
          rate: 5.0,
          userFullName: "mehmet k. i.",
          trusted: true,
          commentDateISOtype: "2022-08-20",
          lastModifiedDate: "20 Ağustos 2022"
        },
        {
          id: 265029276,
          comment: "Çok güzel ve kaliteli",
          rate: 5.0,
          userFullName: "B** B**",
          trusted: true,
          commentDateISOtype: "2023-02-03",
          lastModifiedDate: "3 Şubat 2023"
        },
        {
          id: 313204163,
          comment: "İade ettim",
          rate: 1.0,
          userFullName: "S** B**",
          trusted: true,
          commentDateISOtype: "2023-06-12",
          lastModifiedDate: "12 Haziran 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 104340820,
      productCode: "TS-P1230",
      name: "Kadın Krep Kumaş Düz Kesim Büyük Beden Yazlık Lastikli Pantolon",
      slug: "Kadın Krep Kumaş Düz Kesim Büyük Beden Yazlık Lastikli Pantolon",
      description: "Kadın Krep Kumaş Düz Kesim Büyük Beden Yazlık Lastikli Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Krep Kumaş Düz Kesim Büyük Beden Yazlık Lastikli Pantolon, Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 48-50 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 91060888,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty111/product/media/images/20210508/12/86842500/172316128/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty111/product/media/images/20210508/12/86842500/172316128/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty111/product/media/images/20210508/12/86842500/172316128/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210508/12/86842500/172316128/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty111/product/media/images/20210508/12/86842500/172316128/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 2.0,
      ratingScore: {
        averageRating: 2.33333333333333,
        totalCount: 3.0
      },
      winnerVariant: "48-50",
      color: "BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48-50",
          itemNumber: 172323701,
          stock: 7.0
        }
      ],
      productReviews: [
        {
          id: 190331191,
          comment: "56nın neresi 56 sorarım. 46 giyene oldu inanılmaz dar",
          rate: 1.0,
          userFullName: "Seda K.",
          trusted: true,
          commentDateISOtype: "2022-06-28",
          lastModifiedDate: "28 Haziran 2022"
        },
        {
          id: 135070924,
          comment: "Bel lastiği dar.",
          rate: 1.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-10-22",
          lastModifiedDate: "22 Ekim 2021"
        }
      ],
      colors: [
        {
          id: 104380932,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/11/125213574/172386117/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971238,
          imageUrl: "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83047828/436721319/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 104340820,
          imageUrl: "https://cdn.dsmcdn.com/ty111/product/media/images/20210508/12/86842500/172316128/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Krep Kumaş Düz Kesim Büyük Beden Yazlık Lastikli Pantolon"
        },
        {
          id: 126238536,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125108144/206986809/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 125934251,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/11/111427360/205638411/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971605,
          imageUrl: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049498/436749971/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Indıgo Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Yüksek Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Boru Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 280072988,
      productCode: "TS-1716",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Önden Düğmeli Pamuklu Dokuma Elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz.,Ürünümüz viskon kumaştır, ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., Mankenin üzerindeki 44 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 236619153,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty395/product/media/images/20220412/21/89494724/446003285/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty395/product/media/images/20220412/21/89494724/446003285/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty395/product/media/images/20220412/21/89494724/446003285/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty394/product/media/images/20220412/21/89494724/446003285/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "SİYAH BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 446016295,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321982963,
      productCode: "C-S501",
      name: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      slug: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Büyük beden üründür. Hem göbeği olan hem de hamile bayanların kullanımına uygundur. Kilo alıp vermelerde rahatlıkla kullanılabilir. ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44-46 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 266049224,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319806/513838225/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319806/513838225/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319806/513838225/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220705/0/135319806/513838225/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319806/513838225/4/4_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 513889836,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321982403,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319240/513837624/1/1_org_zoom.jpg",
          name: "MERCAN",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982276,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319094/513837490/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982440,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319275/513837662/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982466,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319306/513837694/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982963,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319806/513838225/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321974684,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/23/135311582/513827648/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 141070367,
      productCode: "TYC00219595443",
      name: "Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 121857412,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty891/product/media/images/20230520/14/353314697/239367856/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty891/product/media/images/20230520/14/353314697/239367856/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty891/product/media/images/20230520/14/353314697/239367856/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty891/product/media/images/20230520/14/353314697/239367856/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 2.0
      },
      winnerVariant: "52-54",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "52-54",
          itemNumber: 239367856,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 132988102,
          comment: "çok güzel ürün",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-10-15",
          lastModifiedDate: "15 Ekim 2021"
        },
        {
          id: 313656627,
          comment: "Aynı renk gelmedi görseldeki gibi değil",
          rate: 5.0,
          userFullName: "Z** T**",
          trusted: true,
          commentDateISOtype: "2023-06-13",
          lastModifiedDate: "13 Haziran 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377534383,
      productCode: "TS-P0011",
      name: "%100 Pamuklu Çiçek Desenli Empirme Dökümlü Etnik Büyük Beden Dokuma Kuşaklı Elbise",
      slug: "%100 Pamuklu Çiçek Desenli Empirme Dökümlü Etnik Büyük Beden Dokuma Kuşaklı Elbise",
      description: "%100 Pamuklu Çiçek Desenli Empirme Dökümlü Etnik Büyük Beden Dokuma Kuşaklı Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürünün kumaşı %100 Pamuktur, Dökümlüdür. Kol Ağzı Geniştir 30 derecede yıkayınız."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885667,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/11/204876148/610467913/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/11/204876148/610467913/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/11/204876148/610467913/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/11/204876148/610467913/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/11/204876148/610467913/4/4_org_zoom.jpg"
      ],
      price: 879.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 879.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 749.99,
        sellingPrice: 879.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 611048383,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319068,
      productCode: "TS-1688-1",
      name: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900817,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562713/511381126/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562713/511381126/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562713/511381126/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562713/511381126/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 326.76,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 8.0
      },
      winnerVariant: "54",
      color: "SİYAH-DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "54",
          itemNumber: 511383265,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 248016580,
          comment: "anneme almıştım çok beğendi kalıbı rahat ve kullanışlı teşekkür edetiz",
          rate: 5.0,
          userFullName: "D** T**",
          trusted: true,
          commentDateISOtype: "2023-01-01",
          lastModifiedDate: "1 Ocak 2023"
        },
        {
          id: 296828797,
          comment: "Çok kaliteli çok şık",
          rate: 5.0,
          userFullName: "B** B**",
          trusted: true,
          commentDateISOtype: "2023-04-26",
          lastModifiedDate: "26 Nisan 2023"
        },
        {
          id: 200327991,
          comment: "hakikaten çok çok iyi bedeni deseni kumaşı duruşu hepsi çok iyi ı düzey de tavsiye ederim bu ürünü gözünüz kapalı alabilirsiniz",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-08-19",
          lastModifiedDate: "19 Ağustos 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321795620,
      productCode: "TS-A116",
      name: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265942565,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty848/product/media/images/20230425/20/332002186/513650384/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty848/product/media/images/20230425/20/332002186/513650384/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty846/product/media/images/20230425/20/332002186/513650384/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty846/product/media/images/20230425/20/332002186/513650384/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 513650387,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322662535,
      productCode: "TS-S106",
      name: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      slug: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Büyük Beden Krep Gömlek., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 215545580,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/17/136162149/515551553/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/17/136162149/515551553/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/17/136162149/515551553/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/17/136162149/515551553/3/3_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "48",
      color: "LACİ-DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48",
          itemNumber: 515572317,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 290626931,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/5/100830274/464302737/1/1_org_zoom.jpg",
          name: "KARELİ",
          description: "Kare Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 322662535,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/17/136162149/515551553/1/1_org_zoom.jpg",
          name: "LACİ-DESENLİ",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 248211850,
          imageUrl: "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/16/58008014/397145391/1/1_org_zoom.jpg",
          name: "LACİ-LEOPAR",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 679431082,
          imageUrl: "https://cdn.dsmcdn.com/ty802/product/media/images/20230328/18/314383428/898809554/1/1_org_zoom.jpg",
          name: "KAZAYAĞI-KAHVE",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Kumaş"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322782110,
      productCode: "TS-YY701",
      name: "Yazlık Penye Büyük Beden Anne Yelek",
      slug: "Yazlık Penye Büyük Beden Anne Yelek",
      description: "Yazlık Penye Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Yazlık Penye Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 266692330,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136323721/515717565/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136323721/515717565/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136323721/515717565/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/22/136323721/515717565/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 515717565,
          stock: 4.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322782110,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136323721/515717565/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Yazlık Penye Büyük Beden Anne Yelek"
        },
        {
          id: 322779714,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136305582/515714634/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yazlık Penye Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 176474280,
      productCode: "TS-YY656",
      name: "Viskon Cep Detaylı Büyük Beden Fashion Anne Yelek",
      slug: "Viskon Cep Detaylı Büyük Beden Fashion Anne Yelek",
      description: "Viskon Cep Detaylı Büyük Beden Fashion Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 151144321,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243222/287529712/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243222/287529712/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243222/287529712/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243222/287529712/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 4.0
      },
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 287529712,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 150295693,
          comment: "Cok güzel bedeni tam oldu  kaliteli  teşekkür ederim",
          rate: 5.0,
          userFullName: "E** D**",
          trusted: true,
          commentDateISOtype: "2021-12-31",
          lastModifiedDate: "31 Aralık 2021"
        },
        {
          id: 161747590,
          comment: "Çok teşekkürler yelegi almışlar.Bedenide tam olmuş.Emeginize saglık.",
          rate: 5.0,
          userFullName: "M** Y**",
          trusted: true,
          commentDateISOtype: "2022-02-17",
          lastModifiedDate: "17 Şubat 2022"
        },
        {
          id: 175132731,
          comment: "Cok guzel oldu. Bir beden kicik alin☝🏻",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-04-16",
          lastModifiedDate: "16 Nisan 2022"
        }
      ],
      colors: [
        {
          id: 176474141,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243086/287529546/1/1_org_zoom.jpg",
          name: "LACİVERT",
          description: "Viskon Cep Detaylı Büyük Beden Fashion Anne Yelek"
        },
        {
          id: 176474280,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243222/287529712/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Viskon Cep Detaylı Büyük Beden Fashion Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 725793366,
      productCode: "TS-P1228",
      name: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      slug: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse krep kumaş düz kesim büyük beden yazlık lastikli pantolon, ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., ürün hafif ve rahattır., terleme yaratmaz., dökümlü ve salaş bir görünüm sağlar., kolay kolay buruşmaz., gösterişli ve şık bir görünüm sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün numune bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 552638955,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364992010/954702030/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364992010/954702030/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364992010/954702030/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364992010/954702030/3/3_org_zoom.jpg"
      ],
      price: 289.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 289.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 289.99,
        sellingPrice: 289.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 956810063,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 725948661,
          imageUrl: "https://cdn.dsmcdn.com/ty914/product/media/images/20230527/16/365147033/954858888/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725631449,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364826277/954539071/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725793366,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364992010/954702030/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 726203337,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/18/365413366/955118936/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725794958,
          imageUrl: "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364993691/954703682/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuk Karışımlı"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Bol Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        },
        {
          id: 880,
          key: "Siluet",
          value: "Regular"
        },
        {
          id: 881,
          key: "Ortam",
          value: "Casual/Günlük"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Beli lastikli"
        },
        {
          id: 870,
          key: "Persona",
          value: "Smart"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "İnce"
        },
        {
          id: 710,
          key: "Koleksiyon",
          value: "Büyük Beden"
        },
        {
          id: 893,
          key: "Kemer/Kuşak Durumu",
          value: "Bağcıklı"
        },
        {
          id: 279,
          key: "Yaş",
          value: "Tüm Yaş Grupları"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96942248,
      productCode: "TS-1709-2",
      name: "Kadın Desenli Viskon Yarım Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Yarım Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Yarım Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çiçek Desenli Önden Düğmeli Pamuklu Dokuma Büyük Beden Elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassa"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 83968086,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338469/161428972/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338469/161428972/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338469/161428972/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338469/161428972/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 161432507,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Ekose / Kareli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138319968,
      productCode: "TS-1703-63",
      name: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kadın Yaka Nervür Detaylı Eteği Baskılı Viskon Kolsuz Büyük Beden Elbise, Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede te"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120186903,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/15/125049380/236127098/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/15/125049380/236127098/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/15/125049380/236127098/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/15/125049380/236127098/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.91,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.33333333333333,
        totalCount: 9.0
      },
      winnerVariant: "44",
      color: "PEMBE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 236141312,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 187378252,
          comment: "rahat bir elbise,hediye almistim",
          rate: 5.0,
          userFullName: "İ** K**",
          trusted: true,
          commentDateISOtype: "2022-06-14",
          lastModifiedDate: "14 Haziran 2022"
        },
        {
          id: 317818893,
          comment: "annem begendi ama bedeni uymadı tekrar alacağız",
          rate: 5.0,
          userFullName: "Ö** Y**",
          trusted: true,
          commentDateISOtype: "2023-06-27",
          lastModifiedDate: "27 Haziran 2023"
        },
        {
          id: 204699811,
          comment: "çok güzel teşekkür ederim herkese tavsiye ederim",
          rate: 5.0,
          userFullName: "E** B**",
          trusted: true,
          commentDateISOtype: "2022-09-06",
          lastModifiedDate: "6 Eylül 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 679431082,
      productCode: "TS-S106",
      name: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      slug: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Büyük Beden Krep Gömlek., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 215545580,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty802/product/media/images/20230328/18/314383428/898809554/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty802/product/media/images/20230328/18/314383428/898809554/1/1_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "KAZAYAĞI-KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 898828000,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 318040696,
          comment: "efsane ürün, bayıldık",
          rate: 5.0,
          userFullName: "İ** Ü**",
          trusted: true,
          commentDateISOtype: "2023-06-29",
          lastModifiedDate: "29 Haziran 2023"
        }
      ],
      colors: [
        {
          id: 290626931,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/5/100830274/464302737/1/1_org_zoom.jpg",
          name: "KARELİ",
          description: "Kare Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 322662535,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/17/136162149/515551553/1/1_org_zoom.jpg",
          name: "LACİ-DESENLİ",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 248211850,
          imageUrl: "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/16/58008014/397145391/1/1_org_zoom.jpg",
          name: "LACİ-LEOPAR",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 679431082,
          imageUrl: "https://cdn.dsmcdn.com/ty802/product/media/images/20230328/18/314383428/898809554/1/1_org_zoom.jpg",
          name: "KAZAYAĞI-KAHVE",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Kumaş"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321806156,
      productCode: "TS-1755-21396",
      name: "Kadın Etnik Desenli %100 Pamuk Dokuma Cepli Truvakar Kol Büyük Beden Elbise",
      slug: "Kadın Etnik Desenli %100 Pamuk Dokuma Cepli Truvakar Kol Büyük Beden Elbise",
      description: "Kadın Etnik Desenli %100 Pamuk Dokuma Cepli Truvakar Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Pamuklu Dokuma Büyük Beden Elbise, %100 pamuk kumaş. kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür. Mankenin üzerindeki beden 44 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265946719,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135130700/513630393/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135130700/513630393/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/17/135130700/513630393/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/17/135130700/513630393/3/3_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 429.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "OTANTİK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 513651881,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Çok Renkli"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319129,
      productCode: "TS-1686-10",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900873,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562776/511381190/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562776/511381190/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562776/511381190/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562776/511381190/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.33333333333333,
        totalCount: 3.0
      },
      winnerVariant: "46",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 511381190,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 193544932,
          comment: "Baskı güzel ve düzgün ama kumaş biraz sentetik gibi",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-19",
          lastModifiedDate: "19 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320965322,
      productCode: "TS-1687-1",
      name: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265371433,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134222721/512237338/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134222721/512237338/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134222721/512237338/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134222721/512237338/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 2.0,
      ratingScore: {
        averageRating: 2.0,
        totalCount: 2.0
      },
      winnerVariant: "44",
      color: "BOTANİK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 512253784,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 297095462,
          comment: "beğenmedim.kumaşı çok ince ve kalitesiz",
          rate: 1.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-04-27",
          lastModifiedDate: "27 Nisan 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290597321,
      productCode: "TS-7909",
      name: "Kısa Kol Düz Renk Uzun Boy Pamuklu Büyük Beden Gömlek",
      slug: "Kısa Kol Düz Renk Uzun Boy Pamuklu Büyük Beden Gömlek",
      description: "Kısa Kol Düz Renk Uzun Boy Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Kısa Kol Düz Renk Tunik Boy Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558132,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785103/464247275/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785103/464247275/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785103/464247275/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/19/100785103/464247275/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "40",
      color: "KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 464252639,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 322172787,
          comment: "böyük beden",
          rate: 5.0,
          userFullName: "T** H**",
          trusted: true,
          commentDateISOtype: "2023-07-15",
          lastModifiedDate: "15 Temmuz 2023"
        }
      ],
      colors: [
        {
          id: 95363946,
          imageUrl: "https://cdn.dsmcdn.com/ty53/product/media/images/20210331/3/76484016/158956809/0/0_org_zoom.jpg",
          name: "MAVİ",
          description: "Kısa Kol Düz Renk Uzun Boy Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 290597321,
          imageUrl: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785103/464247275/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Kısa Kol Düz Renk Uzun Boy Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 376784239,
      productCode: "TS-M2404",
      name: "Elegant Yaka Detaylı Pamuklu Büyük Beden Battal Gömlek",
      slug: "Elegant Yaka Detaylı Pamuklu Büyük Beden Battal Gömlek",
      description: "Elegant Yaka Detaylı Pamuklu Büyük Beden Battal Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "%100 cotton kumaş., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün numune bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 304263997,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/19/203494071/608853223/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/19/203494071/608853223/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/19/203494071/608853223/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/19/203494071/608853223/3/3_org_zoom.jpg"
      ],
      price: 499.9,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.9,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 499.9
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "50-52",
      color: "INDIGO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50-52",
          itemNumber: 608853223,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 309963688,
          comment: "çok memnun kaldık",
          rate: 5.0,
          userFullName: "B** O**",
          trusted: true,
          commentDateISOtype: "2023-06-01",
          lastModifiedDate: "1 Haziran 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138327469,
      productCode: "TS-1703-71",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kadın Desenli Önü Düğmeli ve Cep Detaylı Viskon Kolsuz Büyük Beden Elbise, Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede t"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120192755,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057096/236144981/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057096/236144981/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125057096/236144981/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125057096/236144981/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 14.0
      },
      winnerVariant: "50",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 236155511,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 254232787,
          comment: "annem icin almistım  cok kullanısli bir elbise",
          rate: 5.0,
          userFullName: "N** G** Y**",
          trusted: true,
          commentDateISOtype: "2023-01-13",
          lastModifiedDate: "13 Ocak 2023"
        },
        {
          id: 251895492,
          comment: "evde giymeli )",
          rate: 2.0,
          userFullName: "M** Z**",
          trusted: true,
          commentDateISOtype: "2023-01-10",
          lastModifiedDate: "10 Ocak 2023"
        },
        {
          id: 245983720,
          comment: "Anneme hediye aldım bayıldı, görseldeki gibi geldi",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-12-26",
          lastModifiedDate: "26 Aralık 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320943587,
      productCode: "C-S403",
      name: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      slug: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Oya İşli %100 Pamuk Özel Şile Bezi El İşçiliği Büyük Beden Dokuma Tunik Elbise, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki 40-42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359583,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199986/512200894/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199986/512200894/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134199986/512200894/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199986/512200894/3/3_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 531847180,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 320943587,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199986/512200894/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise"
        },
        {
          id: 320947135,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203927/512205433/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Mini"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48707389,
      productCode: "TS-8215-2",
      name: "Kadın Ekru Dantel Hırkalı Ikili Takım",
      slug: "Kadın Ekru Dantel Hırkalı Ikili Takım",
      description: "Kadın Ekru Dantel Hırkalı Ikili Takım",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon ikili takım."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40935232,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/11/10590839/86348109/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/11/10590839/86348109/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/11/10590839/86348109/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/11/10590839/86348109/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/11/10590839/86348109/4/4_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 499.99,
        sellingPrice: 499.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 86360605,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 165252302,
          comment: "Ben çok beğendim kumaşı da duruşu da çok güzel",
          rate: 5.0,
          userFullName: "n** ö**",
          trusted: true,
          commentDateISOtype: "2022-03-04",
          lastModifiedDate: "4 Mart 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124023973,
      productCode: "TS-7149",
      name: "Ham Keten Omuz Detaylı Gömlek",
      slug: "Ham Keten Omuz Detaylı Gömlek",
      description: "Ham Keten Omuz Detaylı Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan düz renk keten gömlek., ürünün kumaşı ketendir., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109177306,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/10/108901422/200746109/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/10/108901422/200746109/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/10/108901422/200746109/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/10/108901422/200746109/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "42",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 200746109,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 191815235,
          comment: "anneme aldim,  cok begendi. kaliteli duruyor",
          rate: 5.0,
          userFullName: "l** s**",
          trusted: true,
          commentDateISOtype: "2022-07-06",
          lastModifiedDate: "6 Temmuz 2022"
        },
        {
          id: 291452895,
          comment: "Güzel ama büyük geldi iade",
          rate: 5.0,
          userFullName: "Lamia B.",
          trusted: true,
          commentDateISOtype: "2023-04-12",
          lastModifiedDate: "12 Nisan 2023"
        }
      ],
      colors: [
        {
          id: 124024011,
          imageUrl: "https://cdn.dsmcdn.com/ty338/product/media/images/20220220/20/54190985/200746317/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Ham Keten Omuz Detaylı Gömlek"
        },
        {
          id: 124023973,
          imageUrl: "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/10/108901422/200746109/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Ham Keten Omuz Detaylı Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Keten"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377535073,
      productCode: "TS-1665",
      name: "%100 Ham Keten Kolsuz Düz Renk Torba Cep Detaylı Dökümlü Büyük Beden Elbise",
      slug: "%100 Ham Keten Kolsuz Düz Renk Torba Cep Detaylı Dökümlü Büyük Beden Elbise",
      description: "%100 Ham Keten Kolsuz Düz Renk Torba Cep Detaylı Dökümlü Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Cep Detaylı Elbise, %100 Ham Keten kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304886260,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/11/204876646/610468759/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/11/204876646/610468759/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/11/204876646/610468759/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/11/204876646/610468759/3/3_org_zoom.jpg"
      ],
      price: 429.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 429.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 429.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 611045605,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 698536465,
      productCode: "TS-1688-11",
      name: "Plus Etnik Desenli Viskon Cepli Vücuda Yapışmayan Battal Büyük Beden Günlük Elbise",
      slug: "Plus Etnik Desenli Viskon Cepli Vücuda Yapışmayan Battal Büyük Beden Günlük Elbise",
      description: "Plus Etnik Desenli Viskon Cepli Vücuda Yapışmayan Battal Büyük Beden Günlük Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 538575632,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty858/product/media/images/20230502/14/337164424/923621108/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty858/product/media/images/20230502/14/337164424/923621108/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty858/product/media/images/20230502/14/337164424/923621108/2/2_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "48",
      color: "TURUNCU DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48",
          itemNumber: 924384669,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 311685854,
          comment: "Ürün tam istediğim gibi geldi çok teşekkür ederim",
          rate: 5.0,
          userFullName: "Ş** A** B**",
          trusted: true,
          commentDateISOtype: "2023-06-07",
          lastModifiedDate: "7 Haziran 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Kumaş"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Etnik"
        },
        {
          id: 250,
          key: "Stil",
          value: "Günlük"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        },
        {
          id: 893,
          key: "Kemer/Kuşak Durumu",
          value: "Kemersiz"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "İnce"
        },
        {
          id: 710,
          key: "Koleksiyon",
          value: "Büyük Beden"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536356,
      productCode: "TS-1773",
      name: "Kadın Etnik Desenli Kalın Viskon Truvakar Kol Büyük Beden Elbise",
      slug: "Kadın Etnik Desenli Kalın Viskon Truvakar Kol Büyük Beden Elbise",
      description: "Kadın Etnik Desenli Kalın Viskon Truvakar Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve truvakar kollu, midi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir. Ürünü 30 derecede tersten yıkayınız.,Mankenin üzerindeki 48 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 236618826,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242289319/610470134/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242289319/610470134/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242289319/610470134/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242289319/610470134/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242289319/610470134/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "ETNİK KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 610470395,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Etnik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        },
        {
          id: 48,
          key: "Boy",
          value: "Regular"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536308,
      productCode: "TS-1681",
      name: "Desenli %100 Pamuk Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      slug: "Desenli %100 Pamuk Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      description: "Desenli %100 Pamuk Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve Uzun Kol, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız.."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885680,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/8/204614328/610470085/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/8/204614328/610470085/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205083621/610470085/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 610982586,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377536790,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/18/204343374/610470588/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377608862,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/19/204417643/610547394/1/1_org_zoom.jpg",
          name: "DESENLİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377534395,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/8/204609508/610467926/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536620,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221031/18/204343213/610470406/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536308,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/8/204614328/610470085/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli %100 Pamuk Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536312,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204657000/610470092/1/1_org_zoom.jpg",
          name: "SİYAH ZİKZAG",
          description: "Zikzag Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 48,
          key: "Boy",
          value: "Maxi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49109092,
      productCode: "TS-7997",
      name: "Kadın Mavi Battal Pamuklu Gömlek",
      slug: "Kadın Mavi Battal Pamuklu Gömlek",
      description: "Kadın Mavi Battal Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "%100 Cotton kumaş Mankenin üzerindeki ürün XL bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41120110,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2163713/87089164/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2163713/87089164/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.53846153846154,
        totalCount: 26.0
      },
      winnerVariant: "XL",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 87078580,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 131031486,
          comment: "Çok güzel bir beden küçük alınabilir rengide çok tatlı mavi",
          rate: 5.0,
          userFullName: "Ö** Ş**",
          trusted: true,
          commentDateISOtype: "2021-10-05",
          lastModifiedDate: "5 Ekim 2021"
        },
        {
          id: 93312763,
          comment: "hizli kargo kaliteli urun",
          rate: 5.0,
          userFullName: "G** s**",
          trusted: true,
          commentDateISOtype: "2021-03-27",
          lastModifiedDate: "27 Mart 2021"
        },
        {
          id: 321917128,
          comment: "Kalitesi güzel kumaş biraz kalın",
          rate: 5.0,
          userFullName: "O** s**",
          trusted: true,
          commentDateISOtype: "2023-07-14",
          lastModifiedDate: "14 Temmuz 2023"
        }
      ],
      colors: [
        {
          id: 140473377,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 124023053,
          imageUrl: "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
          name: "LİLA",
          description: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 140024951,
          imageUrl: "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Sarı Klasik Pamuklu Gömlek"
        },
        {
          id: 140025063,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
          name: "MİNT YEŞİL",
          description: "Klasik Mint Yeşil Pamuklu Gömlek"
        },
        {
          id: 49109092,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Battal Pamuklu Gömlek"
        },
        {
          id: 140440545,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 56510747,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Battal Pamuklu Kadın Gömlek"
        },
        {
          id: 48931613,
          imageUrl: "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Battal Pamuklu Gömlek"
        },
        {
          id: 142140797,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138327512,
      productCode: "TS-1703-55",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Pamuklu Dokuma Kolları ve Eteği Ponponlu Büyük Beden Elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten,"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120192783,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty458/product/media/images/20220621/23/128629490/236155954/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty458/product/media/images/20220621/23/128629490/236155954/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty458/product/media/images/20220621/23/128629490/236155954/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty456/product/media/images/20220621/23/128629490/236155954/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.375,
        totalCount: 8.0
      },
      winnerVariant: "42",
      color: "SİYAH PUANTİYELİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 236155954,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 198358843,
          comment: "çok beyendim anneme almışdım çok beyendi",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-08-12",
          lastModifiedDate: "12 Ağustos 2022"
        },
        {
          id: 225033386,
          comment: "bütün yaz üstümden cikmadi çok güzel çok beğendim çok rahat",
          rate: 5.0,
          userFullName: "Şebnem K.",
          trusted: true,
          commentDateISOtype: "2022-11-06",
          lastModifiedDate: "6 Kasım 2022"
        },
        {
          id: 196052320,
          comment: "Sebep bile açiklanmadan ürnüm firma trfdan iğtal edilmiş hiç nazik olmqyan ve trendhol’a yakişmayan bir uslup",
          rate: 1.0,
          userFullName: "N** A** G**",
          trusted: true,
          commentDateISOtype: "2022-08-01",
          lastModifiedDate: "1 Ağustos 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126238536,
      productCode: "TS-P1230",
      name: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      slug: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse krep kumaş düz kesim büyük beden yazlık lastikli pantolon, ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., ürün hafif ve rahattır., terleme yaratmaz., dökümlü ve salaş bir görünüm sağlar., kolay kolay buruşmaz., gösterişli ve şık bir görünüm sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün numune bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 91060888,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125108144/206986809/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125108144/206986809/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125108144/206986809/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125108144/206986809/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.27,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.58333333333333,
        totalCount: 12.0
      },
      winnerVariant: "54",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "54",
          itemNumber: 206986809,
          stock: 6.0
        }
      ],
      productReviews: [
        {
          id: 312911857,
          comment: "Anneme aldık rahat, yazlık bir ürün",
          rate: 5.0,
          userFullName: "T** B** G**",
          trusted: true,
          commentDateISOtype: "2023-06-10",
          lastModifiedDate: "10 Haziran 2023"
        },
        {
          id: 123966884,
          comment: "Harika bir kumaşı var. Çok rahat",
          rate: 5.0,
          userFullName: "D** p**",
          trusted: true,
          commentDateISOtype: "2021-08-23",
          lastModifiedDate: "23 Ağustos 2021"
        },
        {
          id: 130774127,
          comment: "kumaşı güzel yazlık dökümlü tam beden teşekkürler",
          rate: 5.0,
          userFullName: "y** a**",
          trusted: true,
          commentDateISOtype: "2021-10-04",
          lastModifiedDate: "4 Ekim 2021"
        }
      ],
      colors: [
        {
          id: 104380932,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/11/125213574/172386117/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971238,
          imageUrl: "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83047828/436721319/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 104340820,
          imageUrl: "https://cdn.dsmcdn.com/ty111/product/media/images/20210508/12/86842500/172316128/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Krep Kumaş Düz Kesim Büyük Beden Yazlık Lastikli Pantolon"
        },
        {
          id: 126238536,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125108144/206986809/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 125934251,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/11/111427360/205638411/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971605,
          imageUrl: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049498/436749971/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Indıgo Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Yüksek Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Boru Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 104380932,
      productCode: "TS-P1230",
      name: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      slug: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse krep kumaş düz kesim büyük beden yazlık lastikli pantolon, ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., ürün hafif ve rahattır., terleme yaratmaz., dökümlü ve salaş bir görünüm sağlar., kolay kolay buruşmaz., gösterişli ve şık bir görünüm sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 48-50 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 91060888,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/11/125213574/172386117/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/11/125213574/172386117/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/11/125213574/172386117/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/11/125213574/172386117/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/11/125213574/172386117/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/11/125213574/172386117/5/5_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.27,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 10.0
      },
      winnerVariant: "48-50",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48-50",
          itemNumber: 172386117,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 319600020,
          comment: "kayınvalidem için aldım kumaş kalitesi çok iyi kesimi üzerinde duruşu da güzel oldu",
          rate: 5.0,
          userFullName: "F** G**",
          trusted: true,
          commentDateISOtype: "2023-07-07",
          lastModifiedDate: "7 Temmuz 2023"
        },
        {
          id: 134691824,
          comment: "Çok rahat bir pantolon ama dikişleri biraz hızlı eskiyor",
          rate: 4.0,
          userFullName: "S** Ş**",
          trusted: true,
          commentDateISOtype: "2021-10-21",
          lastModifiedDate: "21 Ekim 2021"
        },
        {
          id: 135067422,
          comment: "Bel lastiği dar.",
          rate: 1.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-10-22",
          lastModifiedDate: "22 Ekim 2021"
        }
      ],
      colors: [
        {
          id: 104380932,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/11/125213574/172386117/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971238,
          imageUrl: "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83047828/436721319/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 104340820,
          imageUrl: "https://cdn.dsmcdn.com/ty111/product/media/images/20210508/12/86842500/172316128/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Krep Kumaş Düz Kesim Büyük Beden Yazlık Lastikli Pantolon"
        },
        {
          id: 126238536,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125108144/206986809/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 125934251,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/11/111427360/205638411/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971605,
          imageUrl: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049498/436749971/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Indıgo Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Yüksek Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Boru Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321982440,
      productCode: "C-S501",
      name: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      slug: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Büyük beden üründür. Hem göbeği olan hem de hamile bayanların kullanımına uygundur. Kilo alıp vermelerde rahatlıkla kullanılabilir. ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44-46 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 266049224,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319275/513837662/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319275/513837662/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319275/513837662/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220705/0/135319275/513837662/3/3_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 513890662,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321982403,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319240/513837624/1/1_org_zoom.jpg",
          name: "MERCAN",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982276,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319094/513837490/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982440,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319275/513837662/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982466,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319306/513837694/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982963,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319806/513838225/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321974684,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/23/135311582/513827648/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 177427092,
      productCode: "TS-E0017",
      name: "Cam Kırığı Triko Örme Beli Lastikli Büyük Beden Anne Etek",
      slug: "Cam Kırığı Triko Örme Beli Lastikli Büyük Beden Anne Etek",
      description: "Cam Kırığı Triko Örme Beli Lastikli Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Beli Lastikli Parçalı Dokuma Büyük Beden Etek, Ürünün kumaşı örme trikodur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44-46 bedendir. Beden ölçüleri: en:56 cm – boy: 100 cm’dir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 151982894,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/17/166295334/289166942/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/17/166295334/289166942/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/17/166295334/289166942/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/17/166295334/289166942/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 316.07,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.8,
        totalCount: 5.0
      },
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 289182713,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 254498651,
          comment: "ürün güzeldi fakat büyü oldu iade ettik",
          rate: 5.0,
          userFullName: "d** c**",
          trusted: true,
          commentDateISOtype: "2023-01-14",
          lastModifiedDate: "14 Ocak 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319112,
      productCode: "TS-1686-11",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900858,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562760/511381173/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562760/511381173/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562760/511381173/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562760/511381173/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.25,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "46",
      color: "SİYAH-MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 511383367,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 204768003,
          comment: "Elbise çok güzel kaliteli bedeni büyük geldiği için iade etmek zorunda kaldım .bir beden küçüğünü sipariş edeceğim hemen",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-09-06",
          lastModifiedDate: "6 Eylül 2022"
        },
        {
          id: 193238564,
          comment: "52 beden anneme rahat bir şekilde oldu. Çok beğendik. Ürün özenle paketlenmişti. Teşekkürler.",
          rate: 5.0,
          userFullName: "G** B**",
          trusted: true,
          commentDateISOtype: "2022-07-18",
          lastModifiedDate: "18 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290720744,
      productCode: "TS-1735001",
      name: "Kadın Düz Renk Viskon Büyük Beden Havuç Pantolon",
      slug: "Kadın Düz Renk Viskon Büyük Beden Havuç Pantolon",
      description: "Kadın Düz Renk Viskon Büyük Beden Havuç Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse viskon büyük beden havuç pantolon, ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 42 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 245247477,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/23/100931972/464448834/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/23/100931972/464448834/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/23/100931972/464448834/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220501/23/100931972/464448834/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 4.0
      },
      winnerVariant: "38",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 464448834,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 186789308,
          comment: "Büyük geldi",
          rate: 5.0,
          userFullName: "N** C**",
          trusted: true,
          commentDateISOtype: "2022-06-10",
          lastModifiedDate: "10 Haziran 2022"
        },
        {
          id: 187023111,
          comment: "Ürün güzel fakat penye kumaş gibi evde giymek için daha uygun  sanki",
          rate: 4.0,
          userFullName: "A** G** Ç**",
          trusted: true,
          commentDateISOtype: "2022-06-12",
          lastModifiedDate: "12 Haziran 2022"
        },
        {
          id: 187190757,
          comment: "Çok rahat çok şık kumaşi nefis çok begendim tşkler",
          rate: 4.0,
          userFullName: "Nalan A. G.",
          trusted: true,
          commentDateISOtype: "2022-06-13",
          lastModifiedDate: "13 Haziran 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Yüksek Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Dar Paça"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 634,
          key: "Kumaş/İplik Özellik",
          value: "Viskon"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283364910,
      productCode: "TS-7191",
      name: "Botanik Yaprak Desenli Viskon Büyük Beden Gömlek",
      slug: "Botanik Yaprak Desenli Viskon Büyük Beden Gömlek",
      description: "Botanik Yaprak Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117238,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947106/450748152/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947106/450748152/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947106/450748152/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947106/450748152/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "YEŞİL-PALMİYE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 450755388,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 316776349,
          comment: "Biz beğendik annene aldım tavsiye ederim",
          rate: 5.0,
          userFullName: "E** D**",
          trusted: true,
          commentDateISOtype: "2023-06-23",
          lastModifiedDate: "23 Haziran 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124023053,
      productCode: "TS-7997",
      name: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek",
      slug: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek",
      description: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Lila Klasik Pamuklu Battal Büyük Beden Gömlek, Düz renk pamuk-Saten gömlek., %100 cotton kumaş., parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün XL bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41120110,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty251/product/media/images/20211117/11/181873544/200765181/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty249/product/media/images/20211117/11/181873544/200765181/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "XL",
      color: "LİLA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 200742804,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 123391625,
          comment: "mükemmel bir ürün 5. alışım almaya devam edeceğim çok güvenilir harika ürünler tşk ederim.",
          rate: 5.0,
          userFullName: "SEVİL T.",
          trusted: true,
          commentDateISOtype: "2021-08-20",
          lastModifiedDate: "20 Ağustos 2021"
        }
      ],
      colors: [
        {
          id: 140473377,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 124023053,
          imageUrl: "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
          name: "LİLA",
          description: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 140024951,
          imageUrl: "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Sarı Klasik Pamuklu Gömlek"
        },
        {
          id: 140025063,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
          name: "MİNT YEŞİL",
          description: "Klasik Mint Yeşil Pamuklu Gömlek"
        },
        {
          id: 49109092,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Battal Pamuklu Gömlek"
        },
        {
          id: 140440545,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 56510747,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Battal Pamuklu Kadın Gömlek"
        },
        {
          id: 48931613,
          imageUrl: "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Battal Pamuklu Gömlek"
        },
        {
          id: 142140797,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 125934251,
      productCode: "TS-P1230",
      name: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      slug: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse krep kumaş düz kesim büyük beden yazlık lastikli pantolon, ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., ürün hafif ve rahattır., terleme yaratmaz., dökümlü ve salaş bir görünüm sağlar., kolay kolay buruşmaz., gösterişli ve şık bir görünüm sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün numune bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 91060888,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/11/111427360/205638411/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/11/111427360/205638411/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/11/111427360/205638411/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/11/111427360/205638411/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 296.83,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "50",
      color: "KİREMİT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 205658330,
          stock: 6.0
        }
      ],
      productReviews: [
        {
          id: 204489827,
          comment: "Ürün çok güzel kaliteli rengi fotoğrafa göre bir tık açık ama hoş duruyor rahat bir ürün bir beden büyük alınmalı",
          rate: 5.0,
          userFullName: "D** S**",
          trusted: true,
          commentDateISOtype: "2022-09-05",
          lastModifiedDate: "5 Eylül 2022"
        }
      ],
      colors: [
        {
          id: 104380932,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/11/125213574/172386117/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971238,
          imageUrl: "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83047828/436721319/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 104340820,
          imageUrl: "https://cdn.dsmcdn.com/ty111/product/media/images/20210508/12/86842500/172316128/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Krep Kumaş Düz Kesim Büyük Beden Yazlık Lastikli Pantolon"
        },
        {
          id: 126238536,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125108144/206986809/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 125934251,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/11/111427360/205638411/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971605,
          imageUrl: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049498/436749971/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Indıgo Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Yüksek Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Boru Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 726203337,
      productCode: "TS-P1228",
      name: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      slug: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse krep kumaş düz kesim büyük beden yazlık lastikli pantolon, ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., ürün hafif ve rahattır., terleme yaratmaz., dökümlü ve salaş bir görünüm sağlar., kolay kolay buruşmaz., gösterişli ve şık bir görünüm sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün numune bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 552638955,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/18/365413366/955118936/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/18/365413366/955118936/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty914/product/media/images/20230527/18/365413366/955118936/2/2_org_zoom.jpg"
      ],
      price: 289.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 289.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 289.99,
        sellingPrice: 289.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "KİREMİT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 955118936,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 725948661,
          imageUrl: "https://cdn.dsmcdn.com/ty914/product/media/images/20230527/16/365147033/954858888/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725631449,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364826277/954539071/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725793366,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364992010/954702030/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 726203337,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/18/365413366/955118936/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725794958,
          imageUrl: "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364993691/954703682/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Bol Paça"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuk Karışımlı"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        },
        {
          id: 880,
          key: "Siluet",
          value: "Regular"
        },
        {
          id: 881,
          key: "Ortam",
          value: "Casual/Günlük"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Beli lastikli"
        },
        {
          id: 870,
          key: "Persona",
          value: "Smart"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "İnce"
        },
        {
          id: 710,
          key: "Koleksiyon",
          value: "Büyük Beden"
        },
        {
          id: 893,
          key: "Kemer/Kuşak Durumu",
          value: "Bağcıklı"
        },
        {
          id: 279,
          key: "Yaş",
          value: "Tüm Yaş Grupları"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 171619517,
      productCode: "TS-1748",
      name: "Kadın Puantiyeli Krep Dokuma Geniş Kesim Büyük Beden Gömlek",
      slug: "Kadın Puantiyeli Krep Dokuma Geniş Kesim Büyük Beden Gömlek",
      description: "Kadın Puantiyeli Krep Dokuma Geniş Kesim Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Krep Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109169703,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794332/280055461/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794332/280055461/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794332/280055461/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794332/280055461/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 4.0
      },
      winnerVariant: "42",
      color: "BEJ PUANTİYELİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 280067022,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 322451971,
          comment: "Güzel ürün Siyah üzerine bej puantiyeli Bir beden küçük alınabilir",
          rate: 5.0,
          userFullName: "j** k**",
          trusted: true,
          commentDateISOtype: "2023-07-18",
          lastModifiedDate: "18 Temmuz 2023"
        },
        {
          id: 201523807,
          comment: "Beklediğimden Farklı gömlek fakat beğendim iadeye gereke duymadım",
          rate: 5.0,
          userFullName: "M** P**",
          trusted: true,
          commentDateISOtype: "2022-08-23",
          lastModifiedDate: "23 Ağustos 2022"
        }
      ],
      colors: [
        {
          id: 283366498,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949042/450750754/1/1_org_zoom.jpg",
          name: "YEŞİL ÇITIR ÇİÇEKLİ",
          description: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124013010,
          imageUrl: "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724966/200701187/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Krep Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 171619517,
          imageUrl: "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794332/280055461/1/1_org_zoom.jpg",
          name: "BEJ PUANTİYELİ",
          description: "Kadın Puantiyeli Krep Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124012968,
          imageUrl: "https://cdn.dsmcdn.com/ty234/product/media/images/20211110/0/172574731/200721599/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124012991,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888690/200701009/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124012968,
      productCode: "TS-1748",
      name: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek",
      slug: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek",
      description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, %100 Cotton kumaş., Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109169703,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty234/product/media/images/20211110/0/172574731/200721599/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty234/product/media/images/20211110/0/172574731/200721599/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty235/product/media/images/20211110/0/172574731/200721599/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty234/product/media/images/20211110/0/172574731/200721599/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 200721602,
          stock: 9.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366498,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949042/450750754/1/1_org_zoom.jpg",
          name: "YEŞİL ÇITIR ÇİÇEKLİ",
          description: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124013010,
          imageUrl: "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724966/200701187/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Krep Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 171619517,
          imageUrl: "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794332/280055461/1/1_org_zoom.jpg",
          name: "BEJ PUANTİYELİ",
          description: "Kadın Puantiyeli Krep Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124012968,
          imageUrl: "https://cdn.dsmcdn.com/ty234/product/media/images/20211110/0/172574731/200721599/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124012991,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888690/200701009/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138327734,
      productCode: "TS-1702-19",
      name: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kadın Çiçek Desenli Kolları Lastikli Dokuma Pamuklu Büyük Beden Elbise., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamad"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120192929,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty484/product/media/images/20220720/10/146556021/236156308/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty484/product/media/images/20220720/10/146556021/236156308/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty485/product/media/images/20220720/10/146556021/236156308/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty485/product/media/images/20220720/10/146556021/236156308/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.91,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.23076923076923,
        totalCount: 13.0
      },
      winnerVariant: "46",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 236156147,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 193098208,
          comment: "Kendi numaranizi sipariş ede bilirsiniz.  Fiyatına göre kalitesi orta. Satıcıya tesekkürler.",
          rate: 5.0,
          userFullName: "V** A**",
          trusted: true,
          commentDateISOtype: "2022-07-17",
          lastModifiedDate: "17 Temmuz 2022"
        },
        {
          id: 245983958,
          comment: "Anneme hediye aldım bayıldı, görseldeki gibi geldi",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-12-26",
          lastModifiedDate: "26 Aralık 2022"
        },
        {
          id: 193309123,
          comment: "boyu bana uygun değil iade edeceğim rengi güzel",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-18",
          lastModifiedDate: "18 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138325156,
      productCode: "TS-1703-77",
      name: "Kadın Empirme Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Empirme Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Empirme Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız., Mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120191018,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054828/236140133/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054828/236140133/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125054828/236140133/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/16/125054828/236140133/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.91,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 4.0
      },
      winnerVariant: "44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 236150737,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 237644049,
          comment: "Anneme aldık güzel tam kalıp",
          rate: 5.0,
          userFullName: "N** E**",
          trusted: true,
          commentDateISOtype: "2022-12-05",
          lastModifiedDate: "5 Aralık 2022"
        },
        {
          id: 195231903,
          comment: "Farklı bi ürün gönderildi rengarenk Bi pantolon iade",
          rate: 1.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-28",
          lastModifiedDate: "28 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122667747,
      productCode: "TS-1703-24",
      name: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız., Mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 108004762,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty140/product/media/images/20210705/11/107195435/197964914/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty140/product/media/images/20210705/11/107195435/197964914/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/11/107195435/197964914/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/11/107195435/197964914/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.28571428571429,
        totalCount: 14.0
      },
      winnerVariant: "44",
      color: "LACİ-ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 197979978,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 186131788,
          comment: "rahat bir elbise,hediye almıştım,beğendiğini rahat olduğunu soyledi",
          rate: 5.0,
          userFullName: "İ** K**",
          trusted: true,
          commentDateISOtype: "2022-06-07",
          lastModifiedDate: "7 Haziran 2022"
        },
        {
          id: 185682479,
          comment: "gayet beğendim tam oldu.",
          rate: 5.0,
          userFullName: "çölben k.",
          trusted: true,
          commentDateISOtype: "2022-06-04",
          lastModifiedDate: "4 Haziran 2022"
        },
        {
          id: 191302873,
          comment: "Ablama aldım, beğendi",
          rate: 4.0,
          userFullName: "F** M** B**",
          trusted: true,
          commentDateISOtype: "2022-07-03",
          lastModifiedDate: "3 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140011437,
      productCode: "TS-7164",
      name: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek",
      slug: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek",
      description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse deniz kabuğu desenli kolları bağcıklı pamuklu büyük beden gömlek ., %100 cotton kumaş., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121207830,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/19/126836016/238104874/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/19/126836016/238104874/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/19/126836016/238104874/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126836016/238104874/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "XL",
      color: "MOR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 238104874,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 257773207,
          comment: "Bu markanın gömleklerin  modelleri çok rahat,duruşu güzel,bir çok modelini aldım.",
          rate: 5.0,
          userFullName: "Ö** T**",
          trusted: true,
          commentDateISOtype: "2023-01-19",
          lastModifiedDate: "19 Ocak 2023"
        },
        {
          id: 260882660,
          comment: "incecik çok güzel",
          rate: 5.0,
          userFullName: "Z** E** K**",
          trusted: true,
          commentDateISOtype: "2023-01-26",
          lastModifiedDate: "26 Ocak 2023"
        }
      ],
      colors: [
        {
          id: 140011437,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/19/126836016/238104874/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek"
        },
        {
          id: 140011459,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126838119/238104896/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek"
        },
        {
          id: 140471273,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/19/127261728/238640675/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 140440462,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/18/127249586/238604285/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 141179620,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/18/128000500/239527969/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95363931,
      productCode: "TS-7124",
      name: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      slug: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558041,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183255/158956794/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183255/158956794/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183255/158956794/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183255/158956794/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183255/158956794/4/4_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.14285714285714,
        totalCount: 35.0
      },
      winnerVariant: "XL",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 158963917,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 102928275,
          comment: "Annem için almıştık.Çok Beğendik.Ayrıca çok güzel bir notla gönderilmiş.Teşekkürler.",
          rate: 5.0,
          userFullName: "Hümeyra D.",
          trusted: true,
          commentDateISOtype: "2021-05-08",
          lastModifiedDate: "8 Mayıs 2021"
        },
        {
          id: 203596156,
          comment: "Anneme aldim cok guzel oldu",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-09-01",
          lastModifiedDate: "1 Eylül 2022"
        },
        {
          id: 106133595,
          comment: "annem için aldım harika bir ürün",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-05-21",
          lastModifiedDate: "21 Mayıs 2021"
        }
      ],
      colors: [
        {
          id: 95363931,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183255/158956794/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 95363843,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183281/158956704/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 124013108,
          imageUrl: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183227/200722385/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 95363890,
          imageUrl: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183046/158963902/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Krep"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536620,
      productCode: "TS-1681",
      name: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      slug: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve Uzun Kol, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885680,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty583/product/media/images/20221031/18/204343213/610470406/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221031/18/204343213/610470406/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205084585/610541440/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/15/205084620/610541440/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 610541446,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377536790,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/18/204343374/610470588/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377608862,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/19/204417643/610547394/1/1_org_zoom.jpg",
          name: "DESENLİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377534395,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/8/204609508/610467926/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536620,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221031/18/204343213/610470406/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536312,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204657000/610470092/1/1_org_zoom.jpg",
          name: "SİYAH ZİKZAG",
          description: "Zikzag Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536308,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/8/204614328/610470085/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli %100 Pamuk Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 48,
          key: "Boy",
          value: "Maxi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 273971605,
      productCode: "TS-P1230",
      name: "Kadın Indıgo Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      slug: "Kadın Indıgo Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      description: "Kadın Indıgo Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse krep kumaş düz kesim büyük beden yazlık lastikli pantolon, ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., ürün hafif ve rahattır., terleme yaratmaz., dökümlü ve salaş bir görünüm sağlar., kolay kolay buruşmaz., gösterişli ve şık bir görünüm sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün numune bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 91060888,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049498/436749971/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049498/436749971/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049498/436749971/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125049498/436749971/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125049498/436749971/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 296.83,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "50",
      color: "INDIGO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 436749982,
          stock: 8.0
        }
      ],
      productReviews: [
        {
          id: 317218411,
          comment: "Kumaşı çok güzel. Renk göründüğü gibi aynısı geliyor. Kendi bedeninizi alın. Boyu uzun kısaltma yaptık",
          rate: 5.0,
          userFullName: "F** Ç**",
          trusted: true,
          commentDateISOtype: "2023-06-24",
          lastModifiedDate: "24 Haziran 2023"
        }
      ],
      colors: [
        {
          id: 104380932,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/11/125213574/172386117/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971238,
          imageUrl: "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83047828/436721319/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 104340820,
          imageUrl: "https://cdn.dsmcdn.com/ty111/product/media/images/20210508/12/86842500/172316128/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Krep Kumaş Düz Kesim Büyük Beden Yazlık Lastikli Pantolon"
        },
        {
          id: 126238536,
          imageUrl: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125108144/206986809/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 125934251,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/11/111427360/205638411/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Siyah Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 273971605,
          imageUrl: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049498/436749971/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Indıgo Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Yüksek Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Boru Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319130,
      productCode: "TS-1686-2",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900874,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562777/511381191/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562777/511381191/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562777/511381191/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562777/511381191/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "48",
      color: "SİYAH-ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48",
          itemNumber: 511383411,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 306085831,
          comment: "çok iyi kaliteli",
          rate: 5.0,
          userFullName: "d** ç**",
          trusted: true,
          commentDateISOtype: "2023-05-19",
          lastModifiedDate: "19 Mayıs 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283364874,
      productCode: "TS-1764",
      name: "Şifon Desenli Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      slug: "Şifon Desenli Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      description: "Şifon Desenli Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117211,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049653/450755373/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125049653/450755373/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125049653/450755373/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125049653/450755373/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "MAVİ ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 450755373,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Şifon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Şifon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 56510747,
      productCode: "TS-7997",
      name: "Battal Pamuklu Kadın Gömlek",
      slug: "Battal Pamuklu Kadın Gömlek",
      description: "Battal Pamuklu Kadın Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan düz renk pamuk-Saten gömlek., %100 cotton kumaş., parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41120110,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2167754/100206245/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/19/2167754/100206245/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/19/2167754/100206245/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.88888888888889,
        totalCount: 9.0
      },
      winnerVariant: "XL",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 100216624,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 109451894,
          comment: "Çok çok güsel genç büyük bedenler için",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-06-03",
          lastModifiedDate: "3 Haziran 2021"
        },
        {
          id: 166508898,
          comment: "Ürün çok güzel dokusu ,kumaşı , modeli ancak beden biraz küçük geldi iade edip bir büyük bedenini alacağım gönül rahatlığıyla alınabilecek bir ürün⭐️⭐️⭐️⭐️⭐️",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-03-10",
          lastModifiedDate: "10 Mart 2022"
        },
        {
          id: 146289881,
          comment: "Kaliteli ürün",
          rate: 5.0,
          userFullName: "T** Ü**",
          trusted: true,
          commentDateISOtype: "2021-12-10",
          lastModifiedDate: "10 Aralık 2021"
        }
      ],
      colors: [
        {
          id: 140473377,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 124023053,
          imageUrl: "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
          name: "LİLA",
          description: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 140024951,
          imageUrl: "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Sarı Klasik Pamuklu Gömlek"
        },
        {
          id: 140025063,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
          name: "MİNT YEŞİL",
          description: "Klasik Mint Yeşil Pamuklu Gömlek"
        },
        {
          id: 49109092,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Battal Pamuklu Gömlek"
        },
        {
          id: 140440545,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 56510747,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Battal Pamuklu Kadın Gömlek"
        },
        {
          id: 48931613,
          imageUrl: "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Battal Pamuklu Gömlek"
        },
        {
          id: 142140797,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290605382,
      productCode: "TS-1703-83",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 245144008,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/20/100794281/464263093/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/20/100794281/464263093/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/20/100794281/464263093/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "FUŞYA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 464267377,
          stock: 10.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122667536,
      productCode: "TS-1702-11",
      name: "Kadın Desenli Önü Düğmeli Ve Cep Detaylı Viskon Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Önü Düğmeli Ve Cep Detaylı Viskon Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Önü Düğmeli Ve Cep Detaylı Viskon Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Büyük Beden Elbise, Ürün Örme Triko Elbisedir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 38-40 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 108004566,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty140/product/media/images/20210705/11/107195209/197964605/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty140/product/media/images/20210705/11/107195209/197964605/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/11/107195209/197964605/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/11/107195209/197964605/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 0.0,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 197979760,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 180531606,
          comment: "Anneme aldım çok severek giyiyor",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-05-10",
          lastModifiedDate: "10 Mayıs 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321349832,
      productCode: "C-S106",
      name: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      slug: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Oya İşli %100 Pamuk Özel Şile Bezi El İşçiliği Büyük Beden Dokuma Tunik Bluz, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki 40-42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134627023/512820447/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134627023/512820447/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134627023/512820447/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134627023/512820447/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 269.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512830820,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321346605,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623680/512815797/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349750,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626924/512820359/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349682,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626856/512820284/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349832,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134627023/512820447/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319093,
      productCode: "TS-1688-6",
      name: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900840,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562740/511381154/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562740/511381154/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562740/511381154/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562740/511381154/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562740/511381154/4/4_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 336.39,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.25,
        totalCount: 4.0
      },
      winnerVariant: "50",
      color: "MOR-DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 511383318,
          stock: 8.0
        }
      ],
      productReviews: [
        {
          id: 290930248,
          comment: "çok rahatt ve geniş ananeme aldimm  malida trip tril tam yazlık dökümlü 🖐️",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-04-11",
          lastModifiedDate: "11 Nisan 2023"
        },
        {
          id: 311198085,
          comment: "Çok güzel kaliteli bir elbise annem çok begendi",
          rate: 5.0,
          userFullName: "C** M**",
          trusted: true,
          commentDateISOtype: "2023-06-05",
          lastModifiedDate: "5 Haziran 2023"
        },
        {
          id: 321257105,
          comment: "yanlış geldi. ama ev için sorun değil",
          rate: 2.0,
          userFullName: "d** b**",
          trusted: true,
          commentDateISOtype: "2023-07-12",
          lastModifiedDate: "12 Temmuz 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322469976,
      productCode: "TS-L675",
      name: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      slug: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Saten Büyük Beden Elegant Şık Gömlek, Ürün klasik pantolonlarla çok şık kombinlenebilrmketedir. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 46 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458546,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896261/515254996/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896261/515254996/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896261/515254996/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896261/515254996/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "46",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 515259848,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 295155497,
          comment: "çok iyi ve kaliteli.",
          rate: 5.0,
          userFullName: "E** B**",
          trusted: true,
          commentDateISOtype: "2023-04-21",
          lastModifiedDate: "21 Nisan 2023"
        }
      ],
      colors: [
        {
          id: 322471082,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897591/515256635/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322471019,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897514/515256571/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322469976,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896261/515254996/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366747,
      productCode: "TS-7183",
      name: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek",
      slug: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek",
      description: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117347,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949310/450751164/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949310/450751164/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949310/450751164/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949310/450751164/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "TURKUAZ-ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 450751164,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366747,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949310/450751164/1/1_org_zoom.jpg",
          name: "TURKUAZ-ÇİÇEKLİ",
          description: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek"
        },
        {
          id: 283365043,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947232/450748296/1/1_org_zoom.jpg",
          name: "FUŞYA-ÇİÇEKLİ",
          description: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turkuaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 139941490,
      productCode: "TS-7162",
      name: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121161489,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747918/238019677/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747918/238019677/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/14/126747918/238019677/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/14/126747918/238019677/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "KAHVE DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 238121416,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 140026468,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/18/126808343/238125123/1/1_org_zoom.jpg",
          name: "PUDRA DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Gömlek"
        },
        {
          id: 140428262,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/16/127238643/238589794/1/1_org_zoom.jpg",
          name: "YEŞİL DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 139941375,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747494/238019538/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 139941490,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747918/238019677/1/1_org_zoom.jpg",
          name: "KAHVE DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292143078,
      productCode: "TS-A115",
      name: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246427556,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417249/466880332/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417249/466880332/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102417249/466880332/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417249/466880332/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466890539,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 304387778,
          comment: "Cok beğendim. Lütfen kahve tonlarınıda çalısın🙏🏻tereddüt etmeden alabilirsiniz. Anneler günü notu ile geldi🥰çok naziksiniz sevgili satıcı tesekkurlerr❤️",
          rate: 5.0,
          userFullName: "Emine V. Ü.",
          trusted: true,
          commentDateISOtype: "2023-05-15",
          lastModifiedDate: "15 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 292140570,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102414485/466872971/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292143078,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417249/466880332/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 380558929,
      productCode: "TS-8091",
      name: "Plus Dikişli Triko Klasik Beli Lastikli Kapamasız Diz Yapmayan Battal Büyük Beden Pantolon",
      slug: "Plus Dikişli Triko Klasik Beli Lastikli Kapamasız Diz Yapmayan Battal Büyük Beden Pantolon",
      description: "Plus Dikişli Triko Klasik Beli Lastikli Kapamasız Diz Yapmayan Battal Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Klasik Dikişli Bilekte Cepli Triko Büyük Beden Pantolon, Bel kısmı lastiklidir, Ürünün kumaşı triko örmedir. Diz izi yapmaz. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Mankendeki 50 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 307015560,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty589/product/media/images/20221105/17/208288405/614680921/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty589/product/media/images/20221105/17/208288405/614680921/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty588/product/media/images/20221105/17/208288405/614680921/2/2_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 6.0
      },
      winnerVariant: "44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 614697967,
          stock: 7.0
        }
      ],
      productReviews: [
        {
          id: 281378033,
          comment: "Rahat ve sik",
          rate: 5.0,
          userFullName: "I** D**",
          trusted: true,
          commentDateISOtype: "2023-03-21",
          lastModifiedDate: "21 Mart 2023"
        },
        {
          id: 281929355,
          comment: "Maalesef bedeni olmadı",
          rate: 3.0,
          userFullName: "İ** Z**",
          trusted: true,
          commentDateISOtype: "2023-03-22",
          lastModifiedDate: "22 Mart 2023"
        },
        {
          id: 250347303,
          comment: "begendim ütü izi olarak önünde dikiş var",
          rate: 5.0,
          userFullName: "M** N** Ö**",
          trusted: true,
          commentDateISOtype: "2023-01-07",
          lastModifiedDate: "7 Ocak 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Yüksek Bel"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 151496358,
      productCode: "TS-8084",
      name: "Klasik Dikişli Bilekte Cepli Beli Lastikli Çelik Örme Büyük Beden Pantolon",
      slug: "Klasik Dikişli Bilekte Cepli Beli Lastikli Çelik Örme Büyük Beden Pantolon",
      description: "Klasik Dikişli Bilekte Cepli Beli Lastikli Çelik Örme Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Klasik Dikişli Bilekte Cepli Çelik Örme Büyük Beden Pantolon, Bel kısmı lastiklidir, Ürünün kumaşı çelikli örmedir. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Manken"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 130732309,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty259/product/media/images/20211129/16/1291192/251976042/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211129/16/1291192/251976042/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211129/16/1291192/251976042/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211129/16/1291192/251976042/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211129/16/1291192/251976042/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.42857142857143,
        totalCount: 14.0
      },
      winnerVariant: "44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 251976042,
          stock: 8.0
        }
      ],
      productReviews: [
        {
          id: 181996662,
          comment: "Ürün çok güzel ve kaliteli, ama bedeni büyük geldi. Daha küçüğü de yok. Ğzelerek iade ediyorum.",
          rate: 5.0,
          userFullName: "M** L**",
          trusted: true,
          commentDateISOtype: "2022-05-17",
          lastModifiedDate: "17 Mayıs 2022"
        },
        {
          id: 310894808,
          comment: "çok güzel bir ürün, kaliteli",
          rate: 5.0,
          userFullName: "B** G**",
          trusted: true,
          commentDateISOtype: "2023-06-04",
          lastModifiedDate: "4 Haziran 2023"
        },
        {
          id: 208960580,
          comment: "Kaliteli ve güzel, satıcıya ayrıca teşekkür!",
          rate: 5.0,
          userFullName: "R** H**",
          trusted: true,
          commentDateISOtype: "2022-09-17",
          lastModifiedDate: "17 Eylül 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Normal Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Kısa Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 56510428,
      productCode: "TS-8022",
      name: "Kadın Siyah Klasik Pamuklu Gömlek",
      slug: "Kadın Siyah Klasik Pamuklu Gömlek",
      description: "Kadın Siyah Klasik Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Klasik Pamuklu gömlek., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün L bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 47804734,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty19/product/media/images/20201103/15/21803456/100203916/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty19/product/media/images/20201103/15/21803456/100203916/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty20/product/media/images/20201103/15/21803456/100203916/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty19/product/media/images/20201103/15/21803456/100203916/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty19/product/media/images/20201103/15/21803456/100203916/4/4_org_zoom.jpg"
      ],
      price: 369.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 369.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 369.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.85714285714286,
        totalCount: 7.0
      },
      winnerVariant: "XXL",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XXL",
          itemNumber: 100214069,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 160384274,
          comment: "ayni resimdeki gibii",
          rate: 5.0,
          userFullName: "a** s**",
          trusted: true,
          commentDateISOtype: "2022-02-12",
          lastModifiedDate: "12 Şubat 2022"
        },
        {
          id: 164390009,
          comment: "Notunuz için teşekkürler)) İyi, güzel",
          rate: 5.0,
          userFullName: "A** Q**",
          trusted: true,
          commentDateISOtype: "2022-02-28",
          lastModifiedDate: "28 Şubat 2022"
        }
      ],
      colors: [
        {
          id: 56510868,
          imageUrl: "https://cdn.dsmcdn.com/ty20/product/media/images/20201103/15/21804147/100206490/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Klasik Pamuklu Gömlek"
        },
        {
          id: 56510428,
          imageUrl: "https://cdn.dsmcdn.com/ty19/product/media/images/20201103/15/21803456/100203916/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Klasik Pamuklu Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 102742411,
      productCode: "TS-E1169",
      name: "Kadın Cam Kırığı Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Kadın Cam Kırığı Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Kadın Cam Kırığı Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek, Ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 Beden ölçüleri: en:56 cm – boy: 100 cm’dir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 82383219,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty210/product/media/images/20211027/21/158028440/170062387/2/2_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty210/product/media/images/20211027/21/158028440/170062387/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty210/product/media/images/20211027/21/158028440/170062387/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty211/product/media/images/20211027/21/158028440/170062387/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty210/product/media/images/20211027/21/158028440/170062387/5/5_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.56896551724138,
        totalCount: 58.0
      },
      winnerVariant: "52-54",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "52-54",
          itemNumber: 177632770,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 112292102,
          comment: "Ürün harika, düşünmeden alabilirsiniz. İki beden arasındaysanız küçük bedeni tercih edebilirsiniz. Ayrıca yetkililer çok ilgili. 😍",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-06-16",
          lastModifiedDate: "16 Haziran 2021"
        },
        {
          id: 199820375,
          comment: "anneme aldım çok güzel",
          rate: 5.0,
          userFullName: "m** k**",
          trusted: true,
          commentDateISOtype: "2022-08-18",
          lastModifiedDate: "18 Ağustos 2022"
        },
        {
          id: 223507519,
          comment: "anneme aldim cok begendi cok guzel ve kaliteli",
          rate: 5.0,
          userFullName: "b** b**",
          trusted: true,
          commentDateISOtype: "2022-11-01",
          lastModifiedDate: "1 Kasım 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 52277894,
      productCode: "TS-5415",
      name: "Kadın Siyah Balıksırtı Detaylı Düğmeli Hırka",
      slug: "Kadın Siyah Balıksırtı Detaylı Düğmeli Hırka",
      description: "Kadın Siyah Balıksırtı Detaylı Düğmeli Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 44129257,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty15/product/media/images/20201013/10/15462859/92725733/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty15/product/media/images/20201013/10/15462859/92725733/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty15/product/media/images/20201013/10/15462859/92725733/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty15/product/media/images/20201013/10/15462859/92725733/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty15/product/media/images/20201013/10/15462859/92725733/4/4_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 499.99,
        sellingPrice: 499.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "XXL",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XXL",
          itemNumber: 92725733,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 278266406,
          comment: "fotoğfafta gorundugu gibi. kendi bedeninizi alabilirsiniz çok şık",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-03-14",
          lastModifiedDate: "14 Mart 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283374034,
      productCode: "TS-1763",
      name: "Pamuklu Kilim Desenli Çıtır Çiçekli Dokuma Geniş Kesim Gömlek",
      slug: "Pamuklu Kilim Desenli Çıtır Çiçekli Dokuma Geniş Kesim Gömlek",
      description: "Pamuklu Kilim Desenli Çıtır Çiçekli Dokuma Geniş Kesim Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239118624,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92957642/450763118/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92957642/450763118/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/15/92957642/450763118/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92957642/450763118/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "KİLİM",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 450773372,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 216723651,
          comment: "teşekkürler Tusse!",
          rate: 5.0,
          userFullName: "x** a**",
          trusted: true,
          commentDateISOtype: "2022-10-11",
          lastModifiedDate: "11 Ekim 2022"
        }
      ],
      colors: [
        {
          id: 283367881,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92950618/450753292/1/1_org_zoom.jpg",
          name: "ETNİK",
          description: "Pamuklu Etnik Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 283374034,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92957642/450763118/1/1_org_zoom.jpg",
          name: "KİLİM",
          description: "Pamuklu Kilim Desenli Çıtır Çiçekli Dokuma Geniş Kesim Gömlek"
        },
        {
          id: 283366489,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949036/450750746/1/1_org_zoom.jpg",
          name: "KIRMIZI ÇİÇEKLİ",
          description: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Çok Renkli"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283367881,
      productCode: "TS-1763",
      name: "Pamuklu Etnik Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      slug: "Pamuklu Etnik Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      description: "Pamuklu Etnik Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse pamuklu dokuma geniş kesim büyük beden gömlek, kumaşın ince bir dokusu vardır, yazlık bir üründür, şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239118624,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92950618/450753292/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92950618/450753292/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/15/92950618/450753292/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/15/92950618/450753292/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "ETNİK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 450760399,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283367881,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92950618/450753292/1/1_org_zoom.jpg",
          name: "ETNİK",
          description: "Pamuklu Etnik Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 283374034,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92957642/450763118/1/1_org_zoom.jpg",
          name: "KİLİM",
          description: "Pamuklu Kilim Desenli Çıtır Çiçekli Dokuma Geniş Kesim Gömlek"
        },
        {
          id: 283366489,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949036/450750746/1/1_org_zoom.jpg",
          name: "KIRMIZI ÇİÇEKLİ",
          description: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Çok Renkli"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 704536606,
      productCode: "TS-M254",
      name: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      slug: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      description: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Elegant Pamuklu Desenli Büyük Beden Geniş Kalıp Kalçayı Kapatan Rahat Kesim Gömlek, Ürün Dökümlüdür. Kalçayı Kapatır. Taytlar ve pantolanlarla rahatlıkla kullanılabilir. Kumaş pamukludur. Dijital Baskıdır. Tam kalıptır."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 541830849,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty870/product/media/images/20230508/21/342995086/931161751/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty870/product/media/images/20230508/21/342995086/931161751/1/1_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 931166767,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 704536606,
          imageUrl: "https://cdn.dsmcdn.com/ty870/product/media/images/20230508/21/342995086/931161751/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek"
        },
        {
          id: 704537733,
          imageUrl: "https://cdn.dsmcdn.com/ty870/product/media/images/20230508/21/342996200/931163370/1/1_org_zoom.jpg",
          name: "GRİ",
          description: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Leopar"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Katlamalı"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "İç Göstermeyen"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 376534345,
      productCode: "TS-L641",
      name: "Çiçek Nakışlı Etnik Büyük Beden Gömlek",
      slug: "Çiçek Nakışlı Etnik Büyük Beden Gömlek",
      description: "Çiçek Nakışlı Etnik Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458638,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/0/203216739/608470903/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/0/203216739/608470903/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/0/203216739/608470903/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/0/203216739/608470903/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "ETNİK-KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 608480063,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 376616339,
          imageUrl: "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/10/203307571/608586045/1/1_org_zoom.jpg",
          name: "ETNİK-SARI",
          description: "Çiçek Nakışlı Etnik Büyük Beden Gömlek"
        },
        {
          id: 322470072,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896353/515255093/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Çiçek Nakışlı %100 Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 376534345,
          imageUrl: "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/0/203216739/608470903/1/1_org_zoom.jpg",
          name: "ETNİK-KIRMIZI",
          description: "Çiçek Nakışlı Etnik Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 104559503,
      productCode: "TS-1735-2",
      name: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon",
      slug: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon",
      description: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Viskon Büyük Beden Havuç Pantolon, Ürünün ön kısmı büzgülüdür, Bel kısmı lastiklidir, Ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 91254304,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty112/product/media/images/20210510/9/87112743/172601653/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty112/product/media/images/20210510/9/87112743/172601653/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210510/9/87112743/172601653/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty112/product/media/images/20210510/9/87112743/172601653/3/3_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 172611938,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 117096937,
          comment: "renk farklı tonda olsa daha güzel olurdu.",
          rate: 5.0,
          userFullName: "G** G**",
          trusted: true,
          commentDateISOtype: "2021-07-04",
          lastModifiedDate: "4 Temmuz 2021"
        }
      ],
      colors: [
        {
          id: 126238596,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/17/111770312/206960635/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon"
        },
        {
          id: 104559503,
          imageUrl: "https://cdn.dsmcdn.com/ty112/product/media/images/20210510/9/87112743/172601653/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Normal Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Kısa Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377068537,
      productCode: "TS-YY672",
      name: "Elegant Örgü Triko Anne Büyük Beden Yelek",
      slug: "Elegant Örgü Triko Anne Büyük Beden Yelek",
      description: "Elegant Örgü Triko Anne Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Triko Büyük Beden Anne Yelek, Ürün Örme Triko Anne Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 304457104,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811671/609488339/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811671/609488339/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221030/7/203811671/609488339/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811671/609488339/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 285.04,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 609488339,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 302544645,
          comment: "teşekkürler çoookk memnunum teşekkürler",
          rate: 5.0,
          userFullName: "H** A**",
          trusted: true,
          commentDateISOtype: "2023-05-10",
          lastModifiedDate: "10 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 377068575,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811702/609488377/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Elegant Örgü Triko Anne Büyük Beden Yelek"
        },
        {
          id: 376979591,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221029/19/203718428/609176432/1/1_org_zoom.jpg",
          name: "TAŞ",
          description: "Elegant Örgü Triko Anne Büyük Beden Yelek"
        },
        {
          id: 377068537,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811671/609488339/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Elegant Örgü Triko Anne Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48716395,
      productCode: "TS-1703-11",
      name: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 40943032,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10599908/86367094/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10599908/86367094/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10599908/86367094/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10599908/86367094/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10599908/86367094/4/4_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 4.0
      },
      winnerVariant: "42",
      color: "DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 86377354,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 55911696,
          comment: "BEDENİ tam oluyor ne giyiyorsanız o bedende alın kumaşı da kaliteside çok güzel ben aynı elbisenin İki farklı rengini aldım memnun kaldım",
          rate: 5.0,
          userFullName: "nihal t.",
          trusted: true,
          commentDateISOtype: "2020-09-24",
          lastModifiedDate: "24 Eylül 2020"
        },
        {
          id: 115234447,
          comment: "Ürünün  kalitesine,teslimat hızı ve özenine bayıldım.bu üründen baska 5 farkli rengini daha aldim.bundan sonra tercihim bu firma olacak.",
          rate: 5.0,
          userFullName: "Elmas Ö.",
          trusted: true,
          commentDateISOtype: "2021-06-25",
          lastModifiedDate: "25 Haziran 2021"
        },
        {
          id: 109492421,
          comment: "Annem beğendi.",
          rate: 5.0,
          userFullName: "A** A** P**",
          trusted: true,
          commentDateISOtype: "2021-06-03",
          lastModifiedDate: "3 Haziran 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322779715,
      productCode: "TS-YY722",
      name: "Yazlık Önü Düğmeli Penye Büyük Beden Anne Yelek",
      slug: "Yazlık Önü Düğmeli Penye Büyük Beden Anne Yelek",
      description: "Yazlık Önü Düğmeli Penye Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Yazlık Penye Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 266692332,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136305583/515714635/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136305583/515714635/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/22/136305583/515714635/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136305583/515714635/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 2.0
      },
      winnerVariant: "40-42",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 515723767,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 305810193,
          comment: "Anneme aldım çok kaliteli duruşu da çok güzel",
          rate: 5.0,
          userFullName: "A** K**",
          trusted: true,
          commentDateISOtype: "2023-05-19",
          lastModifiedDate: "19 Mayıs 2023"
        },
        {
          id: 197132885,
          comment: "Kumaşı güzel ve zamanında teslim edildi",
          rate: 4.0,
          userFullName: "E** Ö**",
          trusted: true,
          commentDateISOtype: "2022-08-07",
          lastModifiedDate: "7 Ağustos 2022"
        }
      ],
      colors: [
        {
          id: 322782106,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/22/136323708/515717561/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yazlık Önü Düğmeli Penye Büyük Beden Anne Yelek"
        },
        {
          id: 322779715,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136305583/515714635/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Yazlık Önü Düğmeli Penye Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122456890,
      productCode: "TS-1703-28",
      name: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kolsuz, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin üzer"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 107835230,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928370/197673601/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928370/197673601/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928370/197673601/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928370/197673601/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 179.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.33333333333333,
        totalCount: 9.0
      },
      winnerVariant: "42",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 197673601,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 192310676,
          comment: "Annem için aldım yaz günü günlük kullanıma çok uygun . Severek giydi hiç düşünmeden alabilirsiniz annem çok sevdi tavsiyemizdir",
          rate: 4.0,
          userFullName: "z** t**",
          trusted: true,
          commentDateISOtype: "2022-07-10",
          lastModifiedDate: "10 Temmuz 2022"
        },
        {
          id: 122634111,
          comment: "Tesekkurler cok rahat tırıl tırıl 🧡",
          rate: 5.0,
          userFullName: "sibel u.",
          trusted: true,
          commentDateISOtype: "2021-08-14",
          lastModifiedDate: "14 Ağustos 2021"
        },
        {
          id: 187819552,
          comment: "günlük rahat güzel elbise",
          rate: 5.0,
          userFullName: "f** g**",
          trusted: true,
          commentDateISOtype: "2022-06-16",
          lastModifiedDate: "16 Haziran 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122456923,
      productCode: "TS-1703-50",
      name: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 107835262,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty138/product/media/images/20210703/16/106928409/197673635/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty138/product/media/images/20210703/16/106928409/197673635/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty138/product/media/images/20210703/16/106928409/197673635/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928409/197673635/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 179.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "42",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 197685159,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 122287472,
          comment: "Elbiseyi anneannem için almıştım. Hem elastik kumaş hem de tiril tiril tam yazlık, boyu da ideal. Cepleri de oldukça kullanışlı. Kesinlikle tavsiye ediyorum. kalıbı tam geldi",
          rate: 5.0,
          userFullName: "Ozge K.",
          trusted: true,
          commentDateISOtype: "2021-08-12",
          lastModifiedDate: "12 Ağustos 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 104535202,
      productCode: "TS-1702-6",
      name: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse kadın etnik desenli pamuk dokuma truvakar kol büyük beden elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, ha"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 91235243,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080381/172568339/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080381/172568339/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty112/product/media/images/20210509/23/87080381/172568339/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080381/172568339/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 172568339,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377340980,
      productCode: "TS-1749",
      name: "Leopar Desenli Viskon Büyük Beden Gömlek",
      slug: "Leopar Desenli Viskon Büyük Beden Gömlek",
      description: "Leopar Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117214,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/11/204113931/609978107/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/11/204113931/609978107/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/11/204113931/609978107/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/11/204113931/609978107/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 610020752,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377340980,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/11/204113931/609978107/1/1_org_zoom.jpg",
          name: "LEOPAR",
          description: "Leopar Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 283366547,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949095/450750817/1/1_org_zoom.jpg",
          name: "KAHVE-LEOPAR",
          description: "Leopar Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 283364878,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947068/450748113/1/1_org_zoom.jpg",
          name: "SİYAH-LEOPAR",
          description: "Leopar Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Leopar"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 64771291,
      productCode: "TS-20028009",
      name: "Kadın Siyah Klasik Paça Lastikli Eşofman Takımı",
      slug: "Kadın Siyah Klasik Paça Lastikli Eşofman Takımı",
      description: "Kadın Siyah Klasik Paça Lastikli Eşofman Takımı",
      contentDescriptions: [
        {
          bold: false,
          description: "Klasik Paça Lastikli Eşofman Takımı, Eşofman altının yandan iki fermuarlı ve arkadan bir fermuarsız cebi vardır, Ürün diz yapmaz, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün S bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Takımı",
      productGroupId: 55487353,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Takımı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/2/164306408/112762551/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/2/164306408/112762551/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/2/164306408/112762551/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/2/164306408/112762551/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/2/164306408/112762551/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20832843/112762551/1/1_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.3125,
        totalCount: 16.0
      },
      winnerVariant: "S",
      color: "SİYAH",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "S",
          itemNumber: 112762551,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 126783281,
          comment: "Lacivert renginin Yorumlarını okuyarak cesaret edip aldım iyi ki almışım çok güzel .. paketleme özenliydi ve hızlı elime ulaştı. 168 boy 72 kg L beden aldım (salaş olsun istedim basenlerim var biraz) elimde kocaman görünse de üzerimde güzel oldu tak istediğim gibi .. M alsam tam bedenim olurdı aslında ama basenlerime yapışırdı.. eşofmanın rahat olmasını isterim.. materyali de güzel. Beğendim.",
          rate: 5.0,
          userFullName: "H** N** P**",
          trusted: true,
          commentDateISOtype: "2021-09-13",
          lastModifiedDate: "13 Eylül 2021"
        },
        {
          id: 307828377,
          comment: "Kaliteli, rahat ve tam beden.",
          rate: 5.0,
          userFullName: "N** Ç**",
          trusted: true,
          commentDateISOtype: "2023-05-25",
          lastModifiedDate: "25 Mayıs 2023"
        },
        {
          id: 83755957,
          comment: "Göründüğünden ince ve bedenleri çok büyük, beğenerek almıştm ama beklentimi karşılamadı. İADE",
          rate: 1.0,
          userFullName: "H** K**",
          trusted: true,
          commentDateISOtype: "2021-02-06",
          lastModifiedDate: "6 Şubat 2021"
        }
      ],
      colors: [
        {
          id: 64862319,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20833022/112746691/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Klasik Paça Lastikli Eşofman Takımı"
        },
        {
          id: 64771291,
          imageUrl: "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/2/164306408/112762551/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Klasik Paça Lastikli Eşofman Takımı"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 199,
          key: "Kumaş Özellik",
          value: "Ter Tutmayan"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Lastikli Paça"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 171408678,
      productCode: "TS-8029",
      name: "Klasik Ekose Desenli Battal Pamuklu Oversize Oduncu Gömlek",
      slug: "Klasik Ekose Desenli Battal Pamuklu Oversize Oduncu Gömlek",
      description: "Klasik Ekose Desenli Battal Pamuklu Oversize Oduncu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Ekose Desenli Battal Pamuklu Oduncu ,gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 146523926,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/18/159567413/279761582/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/18/159567413/279761582/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty215/product/media/images/20211029/18/159567413/279761582/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty215/product/media/images/20211029/18/159567413/279761582/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.74,
        totalCount: 100.0
      },
      winnerVariant: "XL",
      color: "KIRMIZI-KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 279815844,
          stock: 6.0
        }
      ],
      productReviews: [
        {
          id: 148376315,
          comment: "gomlek guzel kesimi herseyiyle kaliteli rengi bayildim. tek sorunu gercekten kalin ceket niyetine  bile giyilebilr icine askili giyince kasindiriyo  cunku kaşe gibi yunlu gibi hasas ciltliler icin bu kabus benim gibi  artik ic8ne ince uzun kollu giyicem .beden olarak kendi bedeninizi alin kesimi tam.",
          rate: 4.0,
          userFullName: "sinem ö.",
          trusted: true,
          commentDateISOtype: "2021-12-20",
          lastModifiedDate: "20 Aralık 2021"
        },
        {
          id: 164168625,
          comment: "Kumaşı gerçekten kalın tam kışlık görüntüsü fotoğraflardan daha güzel annem hiç üstünden çıkarmıyor tam olsun isterseniz tam beden ideal biraz oversize uzun isterseniz bir büyük alın",
          rate: 5.0,
          userFullName: "E** A** Ö**",
          trusted: true,
          commentDateISOtype: "2022-02-27",
          lastModifiedDate: "27 Şubat 2022"
        },
        {
          id: 148775355,
          comment: "ÜRÜN ÇOK GÜZEL RENKLER VE KALİTE HARİKA. BİR BEDEN BÜYÜK ALINABİLİR.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-12-23",
          lastModifiedDate: "23 Aralık 2021"
        }
      ],
      colors: [
        {
          id: 171408762,
          imageUrl: "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/18/159567815/279761682/1/1_org_zoom.jpg",
          name: "SİYAH-BEYAZ",
          description: "Klasik Ekose Desenli Battal Pamuklu Oversize Oduncu Gömlek"
        },
        {
          id: 171408678,
          imageUrl: "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/18/159567413/279761582/1/1_org_zoom.jpg",
          name: "KIRMIZI-KAHVE",
          description: "Klasik Ekose Desenli Battal Pamuklu Oversize Oduncu Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Oversize"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Kumaş"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126157000,
      productCode: "C-0108",
      name: "Kadın Şifon Patlı Büyük Beden Viskon Bluz",
      slug: "Kadın Şifon Patlı Büyük Beden Viskon Bluz",
      description: "Kadın Şifon Patlı Büyük Beden Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kolluviskon bluz. Yaka kısmındaki güpür detayı şık bir görüntü sağlar. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır.Şık ve zarif bir görüntü sağlar.Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir.Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110879791,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111680568/206673103/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111680568/206673103/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111680568/206673103/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680568/206673103/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 206673103,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126029364,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534396/205961532/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Şifon Patlı Büyük Beden Viskon Bluz"
        },
        {
          id: 126157000,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111680568/206673103/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Şifon Patlı Büyük Beden Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126028785,
      productCode: "TS-2042",
      name: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz",
      slug: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz",
      description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110875062,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/20/111533792/205956261/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/20/111533792/205956261/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/20/111533792/205956261/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/20/111533792/205956261/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "MOR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 205968316,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126028785,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/20/111533792/205956261/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz"
        },
        {
          id: 126024098,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111527015/205932831/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz"
        },
        {
          id: 126157121,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680694/206673672/1/1_org_zoom.jpg",
          name: "ORANJ",
          description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290711172,
      productCode: "TS-0024",
      name: "Zambak Desenli Örme Triko Yuvarlak Yaka Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      slug: "Zambak Desenli Örme Triko Yuvarlak Yaka Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      description: "Zambak Desenli Örme Triko Yuvarlak Yaka Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Triko Büyük Beden Anne Yelek, Ürün Örme Triko Anne Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün 2XL bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 245238493,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty416/product/media/images/20220501/21/100921518/464433047/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220501/21/100921518/464433047/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/21/100921518/464433047/2/2_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 4.0
      },
      winnerVariant: "XXL",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XXL",
          itemNumber: 464438711,
          stock: 7.0
        }
      ],
      productReviews: [
        {
          id: 289438116,
          comment: "guzel model",
          rate: 5.0,
          userFullName: "N** g** A**",
          trusted: true,
          commentDateISOtype: "2023-04-07",
          lastModifiedDate: "7 Nisan 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Triko"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 710,
          key: "Koleksiyon",
          value: "Büyük Beden"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        },
        {
          id: 900,
          key: "Cep Sayısı",
          value: "2"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290745483,
      productCode: "TS-A255",
      name: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      slug: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 245265116,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/10/100959746/464484846/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/10/100959746/464484846/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/10/100959746/464484846/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/10/100959746/464484846/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 464491168,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 202454554,
          comment: "Cok kaliteli. Anneme aldım çok beğendi. 👍🏻",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-08-27",
          lastModifiedDate: "27 Ağustos 2022"
        }
      ],
      colors: [
        {
          id: 290745531,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/10/100959796/464484934/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290745483,
          imageUrl: "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/10/100959746/464484846/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290776359,
          imageUrl: "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/15/100991581/464532659/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290797524,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/17/101015829/464566695/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 130187131,
      productCode: "S-2708",
      name: "Desenli Fileli Kapişonlu Dijital Büyük Beden Hırka",
      slug: "Desenli Fileli Kapişonlu Dijital Büyük Beden Hırka",
      description: "Desenli Fileli Kapişonlu Dijital Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 114428457,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/12/116190165/222748550/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/12/116190165/222748550/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty154/product/media/images/20210806/12/116190165/222748550/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty154/product/media/images/20210806/12/116190165/222748550/3/3_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "XL",
      color: "GÖKKUŞAĞI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 222748550,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 121861584,
          comment: "Rengi ,kesimi çok güzel..ipliği biraz naylonsu duruyor..deneyip, terletip terletmedigini gerecegiz...",
          rate: 4.0,
          userFullName: "S** İ**",
          trusted: true,
          commentDateISOtype: "2021-08-09",
          lastModifiedDate: "9 Ağustos 2021"
        }
      ],
      colors: [
        {
          id: 130180457,
          imageUrl: "https://cdn.dsmcdn.com/ty155/product/media/images/20210806/12/116183854/222734648/1/1_org_zoom.jpg",
          name: "PEMBE",
          description: "Desenli Fileli Kapişonlu Dijital Büyük Beden Hırka"
        },
        {
          id: 130187131,
          imageUrl: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/12/116190165/222748550/1/1_org_zoom.jpg",
          name: "GÖKKUŞAĞI",
          description: "Desenli Fileli Kapişonlu Dijital Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Kapüşonlu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55782060,
      productCode: "TS-20028008",
      name: "Kadın Siyah Klasik Eşofman Takımı",
      slug: "Kadın Siyah Klasik Eşofman Takımı",
      description: "Kadın Siyah Klasik Eşofman Takımı",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Klasik Eşofman Takımı, Eşofman altının yandan iki fermuarlı ve arkadan bir fermuarsız cebi vardır, Ürün diz yapmaz, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.90 Göğüs: 103, Kilo:85 Bel:90 Normal bedeni Tshirt Bedeni: L bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Takımı",
      productGroupId: 47190104,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Takımı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69132066/99096022/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69132066/99096022/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty359/product/media/images/20220313/15/69132066/99096022/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty358/product/media/images/20220313/15/69132066/99096022/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty362/product/media/images/20220313/15/69132066/99096022/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69132066/99096022/5/5_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty361/product/media/images/20220313/15/69132066/99096022/6/6_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty362/product/media/images/20220313/15/69132066/99096022/7/7_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.59349593495935,
        totalCount: 246.0
      },
      winnerVariant: "S",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "S",
          itemNumber: 99096040,
          stock: 6.0
        }
      ],
      productReviews: [
        {
          id: 122784357,
          comment: "Ürünü çok beğendim. Yeri kalitesi güzel. İyi ki yorumları okuyarak sipariş vermişim. Normal bedenim XL olmasına rağmen kalıbı dar olabilir diye XXL dicektim yorumlardan sonra kendi bedenimi sipariş verdim. O bile bol geldi. Keşke L beden alsaymışım. Kesimi bol bir ürün. Ona göre sipariş verin derim. İyi günler.",
          rate: 4.0,
          userFullName: "ebru e.",
          trusted: true,
          commentDateISOtype: "2021-08-16",
          lastModifiedDate: "16 Ağustos 2021"
        },
        {
          id: 147066362,
          comment: "çok başarılı bir ürün kilom olmasına rağmen çok rahat ettim bedenizden 1 büyüğünü alin",
          rate: 5.0,
          userFullName: "e** G**",
          trusted: true,
          commentDateISOtype: "2021-12-14",
          lastModifiedDate: "14 Aralık 2021"
        },
        {
          id: 171236130,
          comment: "harika bir ürün kızım için istemiştim. Kumaşı çok kaliteli ve üzerinde güzel duruyor. Ara sıra indirim oluyor kaçırmamak lazım.",
          rate: 5.0,
          userFullName: "n** g**",
          trusted: true,
          commentDateISOtype: "2022-03-30",
          lastModifiedDate: "30 Mart 2022"
        }
      ],
      colors: [
        {
          id: 60229104,
          imageUrl: "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20827104/106444650/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Klasik Eşofman Takımı"
        },
        {
          id: 55782060,
          imageUrl: "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69132066/99096022/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Klasik Eşofman Takımı"
        },
        {
          id: 69429877,
          imageUrl: "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826848/120358499/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Antrasit Klasik Eşofman Takımı"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 196,
          key: "Kullanım Alanı",
          value: "Günlük"
        },
        {
          id: 199,
          key: "Kumaş Özellik",
          value: "Ter Tutmayan"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Normal Paça"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470242,
      productCode: "TS-L606",
      name: "Vosvos Desenli Minimal Viskon Büyük Beden Gömlek",
      slug: "Vosvos Desenli Minimal Viskon Büyük Beden Gömlek",
      description: "Vosvos Desenli Minimal Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458801,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896540/515255287/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896540/515255287/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896540/515255287/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896540/515255287/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 515255287,
          stock: 4.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 103734463,
      productCode: "TS-YY608",
      name: "Yazlık Penye Büyük Beden Anne Yelek",
      slug: "Yazlık Penye Büyük Beden Anne Yelek",
      description: "Yazlık Penye Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Yazlık Penye Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 44-46 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 88173598,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty111/product/media/images/20210505/18/86099501/171374793/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty111/product/media/images/20210505/18/86099501/171374793/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty112/product/media/images/20210505/18/86099501/171374793/1/1_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "46-48",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46-48",
          itemNumber: 171374793,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 114925989,
          comment: "Anneme aldım,çok beğendik tşk ederim",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-06-24",
          lastModifiedDate: "24 Haziran 2021"
        },
        {
          id: 162561080,
          comment: "gerçekten guzel👍",
          rate: 5.0,
          userFullName: "h** b**",
          trusted: true,
          commentDateISOtype: "2022-02-20",
          lastModifiedDate: "20 Şubat 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319045,
      productCode: "TS-1689",
      name: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise",
      slug: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise",
      description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Kısa Kol Pamuklu Büyük Beden Elbise, hem rahat, hem de şık bir görüntü sağlar, Kumaş %100 pamuklu kumaştır, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz.,Ürünümüz viskon kumaştır, ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., Mankenin üzerindeki 42 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900797,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562691/511381103/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562691/511381103/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562691/511381103/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562691/511381103/3/3_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 2.0
      },
      winnerVariant: "42",
      color: "KIRMIZI-ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 511381103,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 316258811,
          comment: "Çok güzel, rahat bir elbise",
          rate: 5.0,
          userFullName: "B** D** A**",
          trusted: true,
          commentDateISOtype: "2023-06-21",
          lastModifiedDate: "21 Haziran 2023"
        }
      ],
      colors: [
        {
          id: 320950147,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134207069/512209321/1/1_org_zoom.jpg",
          name: "LACİ-BEYAZ",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        },
        {
          id: 377536296,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/13/205021725/610470074/1/1_org_zoom.jpg",
          name: "SİYAH ÇİÇEKLİ",
          description: "Çiçek Desenli Empirme Kendinden Bağcıklı Dökümlü Pamuklu Büyük Beden Elbise"
        },
        {
          id: 320319045,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562691/511381103/1/1_org_zoom.jpg",
          name: "KIRMIZI-ÇİÇEKLİ",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        },
        {
          id: 320344025,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/9/133589884/511439657/1/1_org_zoom.jpg",
          name: "TROPİKAL",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Bağcıklı"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321982466,
      productCode: "C-S501",
      name: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      slug: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Büyük beden üründür. Hem göbeği olan hem de hamile bayanların kullanımına uygundur. Kilo alıp vermelerde rahatlıkla kullanılabilir. ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44-46 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 266049224,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319306/513837694/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319306/513837694/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319306/513837694/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319306/513837694/3/3_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 513837694,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321982403,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319240/513837624/1/1_org_zoom.jpg",
          name: "MERCAN",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982276,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319094/513837490/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982440,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319275/513837662/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982466,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319306/513837694/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982963,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319806/513838225/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321974684,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/23/135311582/513827648/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 295257386,
      productCode: "TS-A245",
      name: "%100 Ham Keten Natural Rahat Kesim Düğme Detaylı Büyük Beden Bluz",
      slug: "%100 Ham Keten Natural Rahat Kesim Düğme Detaylı Büyük Beden Bluz",
      description: "%100 Ham Keten Natural Rahat Kesim Düğme Detaylı Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürünün kumaşı ketendir., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün L bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246427509,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty422/product/media/images/20220507/23/105522818/471334084/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty422/product/media/images/20220507/23/105522818/471334084/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty422/product/media/images/20220507/23/105522818/471334084/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty420/product/media/images/20220507/23/105522818/471334084/3/3_org_zoom.jpg"
      ],
      price: 429.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 429.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 383.49,
        sellingPrice: 429.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 4.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 471334084,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 195212543,
          comment: "güzel bir gömlek rahat kaliteli",
          rate: 5.0,
          userFullName: "Senem Ö.",
          trusted: true,
          commentDateISOtype: "2022-07-28",
          lastModifiedDate: "28 Temmuz 2022"
        },
        {
          id: 191904826,
          comment: "Ürünü dün sipariş verip bugün sabahtan elime ulaşması süper oldu hızlı teslimat için teşekkür ederim gerçek keten kumaş ve kalıp olarak güzel bir ürün dışarıda aynısı fiyat olarak yüksek buradan almanızı tavsiye ederim",
          rate: 5.0,
          userFullName: "E** P**",
          trusted: true,
          commentDateISOtype: "2022-07-07",
          lastModifiedDate: "7 Temmuz 2022"
        },
        {
          id: 202291884,
          comment: "Parasına deymez  , ucuz bir bluz",
          rate: 1.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-08-26",
          lastModifiedDate: "26 Ağustos 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Keten"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 104538897,
      productCode: "TS-T003",
      name: "Empirme Kolları Lastikli Etekleri Katlı Krep Büyük Beden Elbise",
      slug: "Empirme Kolları Lastikli Etekleri Katlı Krep Büyük Beden Elbise",
      description: "Empirme Kolları Lastikli Etekleri Katlı Krep Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, maxi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız., Mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 91238035,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty112/product/media/images/20210510/2/87088036/172574997/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty112/product/media/images/20210510/2/87088036/172574997/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210510/2/87088036/172574997/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty112/product/media/images/20210510/2/87088036/172574997/2/2_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 499.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "48-50",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48-50",
          itemNumber: 172574997,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 151036201,
          comment: "Kumaş ve dikiş kalitesi çok güzel. Fotoğraftaki gibi geniş ve rahat bir elbise.",
          rate: 5.0,
          userFullName: "E** A**",
          trusted: true,
          commentDateISOtype: "2022-01-05",
          lastModifiedDate: "5 Ocak 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Krep"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Boyundan Bağlamalı"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320942841,
      productCode: "TS-P001",
      name: "Elegant %100 Pamuklu V Yaka Eteği Volanlı Puantiye Desenli Büyük Beden Elbise",
      slug: "Elegant %100 Pamuklu V Yaka Eteği Volanlı Puantiye Desenli Büyük Beden Elbise",
      description: "Elegant %100 Pamuklu V Yaka Eteği Volanlı Puantiye Desenli Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Pamuklu Dokuma Büyük Beden Elbise, %100 pamuk kumaş. kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür. Mankendeki beden 38-40 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359166,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134199131/512200036/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134199131/512200036/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134199131/512200036/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199131/512200036/3/3_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "38-40",
      color: "MOR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38-40",
          itemNumber: 512200036,
          stock: 10.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 320947086,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203881/512205382/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Elegant %100 Pamuklu V Yaka Eteği Volanlı Puantiye Desenli Büyük Beden Elbise"
        },
        {
          id: 320942841,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134199131/512200036/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Elegant %100 Pamuklu V Yaka Eteği Volanlı Puantiye Desenli Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 130180457,
      productCode: "S-2708",
      name: "Desenli Fileli Kapişonlu Dijital Büyük Beden Hırka",
      slug: "Desenli Fileli Kapişonlu Dijital Büyük Beden Hırka",
      description: "Desenli Fileli Kapişonlu Dijital Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 114428457,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty155/product/media/images/20210806/12/116183854/222734648/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty155/product/media/images/20210806/12/116183854/222734648/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty154/product/media/images/20210806/12/116183854/222734648/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty155/product/media/images/20210806/12/116183854/222734648/3/3_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "PEMBE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 222847991,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 130180457,
          imageUrl: "https://cdn.dsmcdn.com/ty155/product/media/images/20210806/12/116183854/222734648/1/1_org_zoom.jpg",
          name: "PEMBE",
          description: "Desenli Fileli Kapişonlu Dijital Büyük Beden Hırka"
        },
        {
          id: 130187131,
          imageUrl: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/12/116190165/222748550/1/1_org_zoom.jpg",
          name: "GÖKKUŞAĞI",
          description: "Desenli Fileli Kapişonlu Dijital Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Kapüşonlu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 85699872,
      productCode: "TYC00107719750",
      name: "Unisex Klasik Eşofman Takımı",
      slug: "Unisex Klasik Eşofman Takımı",
      description: "Unisex Klasik Eşofman Takımı",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Klasik Eşofman Takımı, Eşofman altının yandan iki fermuarlı ve arkadan bir fermuarsız cebi vardır, Ürün diz yapmaz, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.90 Göğüs: 103, Kilo:85 Bel:90 Normal bedeni Tshirt Bedeni: L bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Takımı",
      productGroupId: 73964840,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Takımı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20839288/143494187/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20839288/143494187/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20839288/143494187/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20839288/143494187/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20839288/143494187/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20839288/143494187/5/5_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20839288/143494187/6/6_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.6,
        totalCount: 10.0
      },
      winnerVariant: "S",
      color: "SİYAH",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "S",
          itemNumber: 143494431,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 167982748,
          comment: "ürün kaliteli, beden uymadığı için idea edip uygun bedenini tekrardan sipariş verdim.",
          rate: 5.0,
          userFullName: "N** B**",
          trusted: true,
          commentDateISOtype: "2022-03-17",
          lastModifiedDate: "17 Mart 2022"
        },
        {
          id: 113078974,
          comment: "💯💯💯💯💯",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-06-17",
          lastModifiedDate: "17 Haziran 2021"
        },
        {
          id: 249358795,
          comment: "Severek kullanıyoruz kalıbı çok güzel",
          rate: 5.0,
          userFullName: "A** O**",
          trusted: true,
          commentDateISOtype: "2023-01-05",
          lastModifiedDate: "5 Ocak 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 196,
          key: "Kullanım Alanı",
          value: "Günlük"
        },
        {
          id: 199,
          key: "Kumaş Özellik",
          value: "Ter Tutmayan"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Normal Paça"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 85677933,
      productCode: "TYC00107722144",
      name: "Klasik Eşofman Takımı",
      slug: "Klasik Eşofman Takımı",
      description: "Klasik Eşofman Takımı",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Klasik Eşofman Takımı, Eşofman altının yandan iki fermuarlı ve arkadan bir fermuarsız cebi vardır, Ürün diz yapmaz, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.90 Göğüs: 103, Kilo:85 Bel:90 Normal bedeni Tshirt Bedeni: L bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Takımı",
      productGroupId: 73945539,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Takımı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20826857/143462960/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20826857/143462960/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20826857/143462960/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20826857/143462960/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20826857/143462960/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826857/143462960/5/5_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826857/143462960/6/6_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.92857142857143,
        totalCount: 14.0
      },
      winnerVariant: "S",
      color: "LACİ",
      gender: "Erkek",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "S",
          itemNumber: 143462985,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 102560442,
          comment: "Eşim için aldım.Beğendi.Güzel bir ürün",
          rate: 5.0,
          userFullName: "O** T**",
          trusted: true,
          commentDateISOtype: "2021-05-07",
          lastModifiedDate: "7 Mayıs 2021"
        },
        {
          id: 134855159,
          comment: "Eşim için almıştım ama küçük geldi o yüzden iade ettik. Bedeninizden bir beden büyük alın.",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-10-22",
          lastModifiedDate: "22 Ekim 2021"
        },
        {
          id: 160761144,
          comment: "tam kalıp ve Çok rahat babama aldık severek kullanıyor",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-02-13",
          lastModifiedDate: "13 Şubat 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 196,
          key: "Kullanım Alanı",
          value: "Günlük"
        },
        {
          id: 199,
          key: "Kumaş Özellik",
          value: "Ter Tutmayan"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Normal Paça"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Kapüşonlu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 85643959,
      productCode: "TYC00107722143",
      name: "Erkek Lacivert Kapüşonlu Eşofman Takımı",
      slug: "Erkek Lacivert Kapüşonlu Eşofman Takımı",
      description: "Erkek Lacivert Kapüşonlu Eşofman Takımı",
      contentDescriptions: [
        {
          bold: false,
          description: "Klasik Eşofman Takımı, Eşofman altının yandan iki fermuarlı ve arkadan bir fermuarsız cebi vardır, Ürün diz yapmaz, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.90 Göğüs: 103, Kilo:85 Bel:90 Normal bedeni Tshirt Bedeni: L bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Takımı",
      productGroupId: 73911753,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Takımı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20832389/143384208/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20832389/143384208/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20832389/143384208/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20832389/143384208/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20832389/143384208/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20832389/143384208/5/5_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20832389/143384208/6/6_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "S",
      color: "LACİ",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "S",
          itemNumber: 143476088,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 218218799,
          comment: "muhteşem dokusu yapısı bedeni herseyi 10 numara düşünmeyin alin",
          rate: 5.0,
          userFullName: "Y** A**",
          trusted: true,
          commentDateISOtype: "2022-10-16",
          lastModifiedDate: "16 Ekim 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 196,
          key: "Kullanım Alanı",
          value: "Günlük"
        },
        {
          id: 199,
          key: "Kumaş Özellik",
          value: "Ter Tutmayan"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Lastikli Paça"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Kapüşonlu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 85642993,
      productCode: "TYC00107722476",
      name: "Erkek Siyah Kapüşonlu Eşofman Takımı",
      slug: "Erkek Siyah Kapüşonlu Eşofman Takımı",
      description: "Erkek Siyah Kapüşonlu Eşofman Takımı",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Klasik Eşofman Takımı, Eşofman altının yandan iki fermuarlı ve arkadan bir fermuarsız cebi vardır, Ürün diz yapmaz, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.90 Göğüs: 103, Kilo:85 Bel:90 Normal bedeni Tshirt Bedeni: L bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Takımı",
      productGroupId: 73910896,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Takımı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20839247/143423758/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20839247/143423758/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20839247/143423758/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20839247/143423758/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20839247/143423758/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20839247/143423758/5/5_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20839247/143423758/6/6_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 3.0
      },
      winnerVariant: "S",
      color: "SİYAH",
      gender: "Erkek",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "S",
          itemNumber: 143423524,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 193432460,
          comment: "güzel",
          rate: 2.0,
          userFullName: "R** ö**",
          trusted: true,
          commentDateISOtype: "2022-07-19",
          lastModifiedDate: "19 Temmuz 2022"
        },
        {
          id: 161906938,
          comment: "kaliteli ipeksi dokusu var gerçekten çok beğendim",
          rate: 5.0,
          userFullName: "e** b**",
          trusted: true,
          commentDateISOtype: "2022-02-18",
          lastModifiedDate: "18 Şubat 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 196,
          key: "Kullanım Alanı",
          value: "Günlük"
        },
        {
          id: 199,
          key: "Kumaş Özellik",
          value: "Ter Tutmayan"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Lastikli Paça"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Kapüşonlu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 69429877,
      productCode: "TS-20028008",
      name: "Antrasit Klasik Eşofman Takımı",
      slug: "Antrasit Klasik Eşofman Takımı",
      description: "Antrasit Klasik Eşofman Takımı",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Klasik Eşofman Takımı, Eşofman altının yandan iki fermuarlı ve arkadan bir fermuarsız cebi vardır, Ürün diz yapmaz, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.90 Göğüs: 103, Kilo:85 Bel:90 Normal bedeni Tshirt Bedeni: L bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Takımı",
      productGroupId: 47190104,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Takımı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826848/120358499/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826848/120358499/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826848/120358499/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826848/120358499/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20826848/120358499/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20826848/120358499/5/5_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20826848/120358499/6/6_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826848/120358499/7/7_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826848/120358499/8/8_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.54761904761905,
        totalCount: 42.0
      },
      winnerVariant: "S",
      color: "ANTRASİT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "S",
          itemNumber: 309412807,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 75640393,
          comment: "Ben bu ürünün 3XL bedenini aldım ancak potluk yaptı üstümde beğenmedim yolladım. Yollarken de üzüldüm çünkü kumaşı kalitesi güzel bir eşofmandı. Ürünü gönderdikten sonra firma yetkilisi aradı ve geri dönüt aldı. Çok ilgililer bir beden küçüğünü ve siyahını aldım çok memnunum tavsiye ederim. Ancak 3XL 48 bedene denk geliyor ona göre sipariş verin.",
          rate: 5.0,
          userFullName: "B** E**",
          trusted: true,
          commentDateISOtype: "2020-12-25",
          lastModifiedDate: "25 Aralık 2020"
        },
        {
          id: 126826319,
          comment: "kumaşı kesimi harika fakat beden bol geldi eşime verdim bir beden küçüğünü alın",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-09-13",
          lastModifiedDate: "13 Eylül 2021"
        },
        {
          id: 138928685,
          comment: "172/65 için M beden gayet güzel oldu. Kumaşı kalitesi çok güzel hızlıca kargolandi teşekkür ederim FAKAT gelen ürün rengi bildiğiniz gri. Görseldeki renk gelmiyor bilginize.  dipnot: resimde görüldüğü gibi hafif parlak değil pamuklu bi dokusu var",
          rate: 5.0,
          userFullName: "ceyda ö.",
          trusted: true,
          commentDateISOtype: "2021-12-15",
          lastModifiedDate: "15 Aralık 2021"
        }
      ],
      colors: [
        {
          id: 60229104,
          imageUrl: "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20827104/106444650/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Klasik Eşofman Takımı"
        },
        {
          id: 55782060,
          imageUrl: "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69132066/99096022/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Klasik Eşofman Takımı"
        },
        {
          id: 69429877,
          imageUrl: "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826848/120358499/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Antrasit Klasik Eşofman Takımı"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 196,
          key: "Kullanım Alanı",
          value: "Günlük"
        },
        {
          id: 199,
          key: "Kumaş Özellik",
          value: "Ter Tutmayan"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Normal Paça"
        },
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 64862319,
      productCode: "TS-20028009",
      name: "Kadın Lacivert Klasik Paça Lastikli Eşofman Takımı",
      slug: "Kadın Lacivert Klasik Paça Lastikli Eşofman Takımı",
      description: "Kadın Lacivert Klasik Paça Lastikli Eşofman Takımı",
      contentDescriptions: [
        {
          bold: false,
          description: "Klasik Paça Lastikli Eşofman Takımı, Eşofman altının yandan iki fermuarlı ve arkadan bir fermuarsız cebi vardır, Ürün diz yapmaz, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün S bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Takımı",
      productGroupId: 55487353,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Takımı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20833022/112746691/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20833022/112746691/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20833022/112746691/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20833022/112746691/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20833022/112746691/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20833022/112746691/5/5_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20833022/112746691/6/6_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.66666666666667,
        totalCount: 15.0
      },
      winnerVariant: "S",
      color: "LACİ",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "S",
          itemNumber: 112746584,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 91742126,
          comment: "Tek kelimeyle harika. Bir beden büyük aldım istedigim gibi durdu",
          rate: 5.0,
          userFullName: "G** Ç**",
          trusted: true,
          commentDateISOtype: "2021-03-19",
          lastModifiedDate: "19 Mart 2021"
        },
        {
          id: 91371969,
          comment: "cok begendim rengi ne kadar guzel 170 boyunda 65 kiloyum M beden tam oldu bana cok guzel gercekten tavsiye ederim kesinlikle dolabinizda bulunsun",
          rate: 5.0,
          userFullName: "a** i**",
          trusted: true,
          commentDateISOtype: "2021-03-17",
          lastModifiedDate: "17 Mart 2021"
        },
        {
          id: 133719149,
          comment: "kumaşı çok güzel fakat altının kalıbı biraz geniş bir beden küçüğü alınabilir",
          rate: 4.0,
          userFullName: "e** k**",
          trusted: true,
          commentDateISOtype: "2021-10-18",
          lastModifiedDate: "18 Ekim 2021"
        }
      ],
      colors: [
        {
          id: 64862319,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20833022/112746691/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Klasik Paça Lastikli Eşofman Takımı"
        },
        {
          id: 64771291,
          imageUrl: "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/2/164306408/112762551/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Klasik Paça Lastikli Eşofman Takımı"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 199,
          key: "Kumaş Özellik",
          value: "Ter Tutmayan"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Lastikli Paça"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 60229104,
      productCode: "TS-20028008",
      name: "Kadın Lacivert Klasik Eşofman Takımı",
      slug: "Kadın Lacivert Klasik Eşofman Takımı",
      description: "Kadın Lacivert Klasik Eşofman Takımı",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Klasik Eşofman Takımı, Eşofman altının yandan iki fermuarlı ve arkadan bir fermuarsız cebi vardır, Ürün diz yapmaz, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.90 Göğüs: 103, Kilo:85 Bel:90 Normal bedeni Tshirt Bedeni: L bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Takımı",
      productGroupId: 47190104,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Takımı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20827104/106444650/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20827104/106444650/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20827104/106444650/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20827104/106444650/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20827104/106444650/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20827104/106444650/5/5_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20827104/106444650/6/6_org_zoom.jpg"
      ],
      price: 599.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 599.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 599.99,
        sellingPrice: 599.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.22222222222222,
        totalCount: 27.0
      },
      winnerVariant: "S",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "S",
          itemNumber: 106456037,
          stock: 8.0
        }
      ],
      productReviews: [
        {
          id: 95794876,
          comment: "Ürünü çok beğendim (kilom 64 kg) M beden sıpariş etmeme rağmen üstü normal alt eşofmanda M yazmasına rağmen 2 beden büyük ((( mecbur iade ettim.",
          rate: 3.0,
          userFullName: "K** A**",
          trusted: true,
          commentDateISOtype: "2021-04-09",
          lastModifiedDate: "9 Nisan 2021"
        },
        {
          id: 91350582,
          comment: "ürün güzel kaliteli yanlız m giymeme rağmen s aldım yine büyük geldi ilgili firmalar bu durumu düzeltsinler",
          rate: 4.0,
          userFullName: "M** E**",
          trusted: true,
          commentDateISOtype: "2021-03-17",
          lastModifiedDate: "17 Mart 2021"
        },
        {
          id: 75064815,
          comment: "Çok kaliteli. Tam bedeninizi alabilirsiniz. Teşekkürler",
          rate: 5.0,
          userFullName: "Kamer B.",
          trusted: true,
          commentDateISOtype: "2020-12-23",
          lastModifiedDate: "23 Aralık 2020"
        }
      ],
      colors: [
        {
          id: 60229104,
          imageUrl: "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20827104/106444650/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Klasik Eşofman Takımı"
        },
        {
          id: 55782060,
          imageUrl: "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69132066/99096022/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Klasik Eşofman Takımı"
        },
        {
          id: 69429877,
          imageUrl: "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20826848/120358499/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Antrasit Klasik Eşofman Takımı"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 196,
          key: "Kullanım Alanı",
          value: "Günlük"
        },
        {
          id: 199,
          key: "Kumaş Özellik",
          value: "Ter Tutmayan"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Normal Paça"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322779714,
      productCode: "TS-YY701",
      name: "Yazlık Penye Büyük Beden Anne Yelek",
      slug: "Yazlık Penye Büyük Beden Anne Yelek",
      description: "Yazlık Penye Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Yazlık Penye Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 266692330,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136305582/515714634/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136305582/515714634/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/22/136305582/515714634/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/22/136305582/515714634/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 336.92,
        sellingPrice: 349.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 2.0
      },
      winnerVariant: "40-42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 515723768,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 323521643,
          comment: "Güzel hızlı kargo annem çok beğendi",
          rate: 5.0,
          userFullName: "Ö** U**",
          trusted: true,
          commentDateISOtype: "2023-07-20",
          lastModifiedDate: "20 Temmuz 2023"
        }
      ],
      colors: [
        {
          id: 322782110,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136323721/515717565/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Yazlık Penye Büyük Beden Anne Yelek"
        },
        {
          id: 322779714,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136305582/515714634/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yazlık Penye Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138324953,
      productCode: "TS-1703-75",
      name: "Kadın Çiçek Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Çiçek Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Çiçek Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız., Mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120190851,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054713/236139656/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054713/236139656/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125054713/236139656/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/16/125054713/236139656/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.33333333333333,
        totalCount: 3.0
      },
      winnerVariant: "46",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 236139656,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 188817893,
          comment: "Penye elbise evde giyilebilir.Penyesi ince ve yumuşak.Paketleme ve kargo normal.",
          rate: 4.0,
          userFullName: "N** B**",
          trusted: true,
          commentDateISOtype: "2022-06-20",
          lastModifiedDate: "20 Haziran 2022"
        },
        {
          id: 192937212,
          comment: "iki tane ısmarladım çok kullanışlı ütü  istemiyor.tek kelimeyle süper",
          rate: 5.0,
          userFullName: "ayse ü.",
          trusted: true,
          commentDateISOtype: "2022-07-16",
          lastModifiedDate: "16 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290596022,
      productCode: "TS-1776",
      name: "Krep Kolları Ve Eteği Fırfırlı Büyük Beden Gömlek Bluz",
      slug: "Krep Kolları Ve Eteği Fırfırlı Büyük Beden Gömlek Bluz",
      description: "Krep Kolları Ve Eteği Fırfırlı Büyük Beden Gömlek Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Kolları ve Eteği Fırfırlı Krep Bluz, Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça:"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 245135687,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/18/100783719/464245111/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/18/100783719/464245111/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/18/100783719/464245111/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/18/100783719/464245111/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 464245111,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Klasik"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Krep"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Büzgülü/Fırfırlı Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Krep"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 691152255,
      productCode: "TYC00795534396",
      name: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      slug: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 533915856,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty831/product/media/images/20230418/18/327604013/914455086/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty831/product/media/images/20230418/18/327604013/914455086/1/1_org_zoom.jpg"
      ],
      price: 229.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 229.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 229.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "ZEBRA-KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 914480954,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Günlük"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 48,
          key: "Boy",
          value: "Kısa"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Extra Esnek"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Örgü"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "Orta"
        },
        {
          id: 66,
          key: "Paket İçeriği",
          value: "Tekli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536296,
      productCode: "TS-1689",
      name: "Çiçek Desenli Empirme Kendinden Bağcıklı Dökümlü Pamuklu Büyük Beden Elbise",
      slug: "Çiçek Desenli Empirme Kendinden Bağcıklı Dökümlü Pamuklu Büyük Beden Elbise",
      description: "Çiçek Desenli Empirme Kendinden Bağcıklı Dökümlü Pamuklu Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Kısa Kol Pamuklu Büyük Beden Elbise, hem rahat, hem de şık bir görüntü sağlar, Kumaş %100 pamuklu kumaştır, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz.,Ürünümüz viskon kumaştır, ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., Mankenin üzerindeki 42 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900797,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/13/205021725/610470074/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/13/205021725/610470074/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/13/205021725/610470074/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/13/205021725/610470074/3/3_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "SİYAH ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 611118254,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 320950147,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134207069/512209321/1/1_org_zoom.jpg",
          name: "LACİ-BEYAZ",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        },
        {
          id: 377536296,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/13/205021725/610470074/1/1_org_zoom.jpg",
          name: "SİYAH ÇİÇEKLİ",
          description: "Çiçek Desenli Empirme Kendinden Bağcıklı Dökümlü Pamuklu Büyük Beden Elbise"
        },
        {
          id: 320319045,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562691/511381103/1/1_org_zoom.jpg",
          name: "KIRMIZI-ÇİÇEKLİ",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        },
        {
          id: 320344025,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/9/133589884/511439657/1/1_org_zoom.jpg",
          name: "TROPİKAL",
          description: "Empirme Kendinden Bağcıklı Pamuklu Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Bağcıklı"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471019,
      productCode: "TS-L675",
      name: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      slug: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Saten Büyük Beden Elegant Şık Gömlek, Ürün klasik pantolonlarla çok şık kombinlenebilrmketedir. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 46 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458546,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897514/515256571/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897514/515256571/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897514/515256571/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897514/515256571/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "46",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 515261026,
          stock: 7.0
        }
      ],
      productReviews: [
        {
          id: 317156683,
          comment: "Tam yazlik bir ürün bayıldım",
          rate: 5.0,
          userFullName: "D** B**",
          trusted: true,
          commentDateISOtype: "2023-06-24",
          lastModifiedDate: "24 Haziran 2023"
        }
      ],
      colors: [
        {
          id: 322471082,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897591/515256635/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322471019,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897514/515256571/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322469976,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896261/515254996/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124023821,
      productCode: "TS-7160",
      name: "Renkli Desenli Pamuklu Büyük Beden Gömlek",
      slug: "Renkli Desenli Pamuklu Büyük Beden Gömlek",
      description: "Renkli Desenli Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Renkli desenli kolları bağcıklı pamuklu büyük beden gömlek., %100 cotton kumaş., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109177219,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty312/product/media/images/20220131/17/39743563/200767769/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty312/product/media/images/20220131/17/39743563/200767769/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty314/product/media/images/20220131/17/39743563/200767769/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty314/product/media/images/20220131/17/39743563/200767769/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 219.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 4.0
      },
      winnerVariant: "XL",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 200767765,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 121114222,
          comment: "Urun fotodakının aynısı ıc gosterıyor yınede cok guzel bır urun",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-08-04",
          lastModifiedDate: "4 Ağustos 2021"
        },
        {
          id: 192484752,
          comment: "İnanılmaz memnunum. Yıka ütüle giy. Hiç bozulmadı. Serin tutuyor. Keşke kısa kollu modeli de olsa. Tam beden. Ketenimsi kumaş. Teşekkürler",
          rate: 5.0,
          userFullName: "n** m**",
          trusted: true,
          commentDateISOtype: "2022-07-12",
          lastModifiedDate: "12 Temmuz 2022"
        },
        {
          id: 175534346,
          comment: "Tam anneler günü hediyesi, sadece kumaşı daha saf pamuk olsaydı keşke",
          rate: 4.0,
          userFullName: "m** a** g**",
          trusted: true,
          commentDateISOtype: "2022-04-18",
          lastModifiedDate: "18 Nisan 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 698577932,
      productCode: "TS-Y7489",
      name: "Fermuar Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      slug: "Fermuar Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      description: "Fermuar Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 538604820,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty857/product/media/images/20230501/21/336312740/923662722/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty857/product/media/images/20230501/21/336312740/923662722/1/1_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 449.99,
        sellingPrice: 449.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 925546859,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 309737578,
          comment: "fena değil alınabilir",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-05-31",
          lastModifiedDate: "31 Mayıs 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Ottoman"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320943521,
      productCode: "TS-1755-19597",
      name: "Kadın Etnik Desenli %100 Pamuk Dokuma Cepli Truvakar Kol Büyük Beden Elbise",
      slug: "Kadın Etnik Desenli %100 Pamuk Dokuma Cepli Truvakar Kol Büyük Beden Elbise",
      description: "Kadın Etnik Desenli %100 Pamuk Dokuma Cepli Truvakar Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Pamuklu Dokuma Büyük Beden Elbise, %100 pamuk kumaş. kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür. Mankenin üzerindeki beden 44 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359532,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134199913/512200827/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134199913/512200827/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134199913/512200827/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134199913/512200827/3/3_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "SARI ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 512217188,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321982403,
      productCode: "C-S501",
      name: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      slug: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Büyük beden üründür. Hem göbeği olan hem de hamile bayanların kullanımına uygundur. Kilo alıp vermelerde rahatlıkla kullanılabilir. ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44-46 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 266049224,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319240/513837624/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319240/513837624/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319240/513837624/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319240/513837624/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319240/513837624/4/4_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "MERCAN",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 513874295,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321982403,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319240/513837624/1/1_org_zoom.jpg",
          name: "MERCAN",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982276,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319094/513837490/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982440,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319275/513837662/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982466,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319306/513837694/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982963,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319806/513838225/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321974684,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/23/135311582/513827648/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321982276,
      productCode: "C-S501",
      name: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      slug: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Büyük beden üründür. Hem göbeği olan hem de hamile bayanların kullanımına uygundur. Kilo alıp vermelerde rahatlıkla kullanılabilir. ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44-46 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 266049224,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319094/513837490/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319094/513837490/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220705/0/135319094/513837490/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220705/0/135319094/513837490/3/3_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "TURKUAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 513883929,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321982403,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319240/513837624/1/1_org_zoom.jpg",
          name: "MERCAN",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982276,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319094/513837490/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982440,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319275/513837662/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982466,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319306/513837694/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982963,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319806/513838225/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321974684,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/23/135311582/513827648/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turkuaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321974684,
      productCode: "C-S501",
      name: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      slug: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Büyük beden üründür. Hem göbeği olan hem de hamile bayanların kullanımına uygundur. Kilo alıp vermelerde rahatlıkla kullanılabilir. ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44-46 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 266049224,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/23/135311582/513827648/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/23/135311582/513827648/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/23/135311582/513827648/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/23/135311582/513827648/3/3_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 513827648,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321982403,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319240/513837624/1/1_org_zoom.jpg",
          name: "MERCAN",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982276,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319094/513837490/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982440,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319275/513837662/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982466,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220705/0/135319306/513837694/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321982963,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135319806/513838225/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        },
        {
          id: 321974684,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/23/135311582/513827648/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Plus %100 Pamuk Kendinden Kırışlı Ütü Istemeyen Organik Büyük Beden Hamile Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321347179,
      productCode: "C-S105",
      name: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      slug: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Oya İşli %100 Pamuk Özel Şile Bezi El İşçiliği Büyük Beden Dokuma Tunik Bluz, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki 40-42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684564,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134624255/512816391/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134624255/512816391/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134624255/512816391/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134624255/512816391/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134624255/512816391/4/4_org_zoom.jpg"
      ],
      price: 429.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 429.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 269.99,
        sellingPrice: 429.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512827947,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321347179,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134624255/512816391/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 333664175,
          imageUrl: "https://cdn.dsmcdn.com/ty492/product/media/images/20220729/20/152087142/531847242/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320943547,
      productCode: "TS-27159",
      name: "Eteği Volanlı Sıfır Kol Pamuklu Büyük Beden Elbise",
      slug: "Eteği Volanlı Sıfır Kol Pamuklu Büyük Beden Elbise",
      description: "Eteği Volanlı Sıfır Kol Pamuklu Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kolsuz, midi boy dokuma elbise. Ürün tamamen pamuktur. Hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359551,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134199941/512200854/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134199941/512200854/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134199941/512200854/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199941/512200854/3/3_org_zoom.jpg"
      ],
      price: 429.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 429.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 429.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512217520,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 320943547,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/21/134199941/512200854/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Eteği Volanlı Sıfır Kol Pamuklu Büyük Beden Elbise"
        },
        {
          id: 320946778,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134203562/512205055/1/1_org_zoom.jpg",
          name: "PEMBE",
          description: "Eteği Volanlı Sıfır Kol Pamuklu Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 79915184,
      productCode: "TS-8070",
      name: "Bağcık Detaylı Ikili Krep Takım",
      slug: "Bağcık Detaylı Ikili Krep Takım",
      description: "Bağcık Detaylı Ikili Krep Takım",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Bağcık Detaylı İkili Krep Takım, Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.73 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 68431291,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty64/product/media/images/20210129/0/58143884/135482452/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty64/product/media/images/20210129/0/58143884/135482452/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty64/product/media/images/20210129/0/58143884/135482452/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty64/product/media/images/20210129/0/58143884/135482452/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty62/product/media/images/20210129/0/58143884/135482452/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 135482452,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48647488,
      productCode: "BL-8404",
      name: "Kadın Haki Büyük Beden Dantel ve Taş İşli Yelekli Takım",
      slug: "Kadın Haki Büyük Beden Dantel ve Taş İşli Yelekli Takım",
      description: "Kadın Haki Büyük Beden Dantel ve Taş İşli Yelekli Takım",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon hırka."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün xl bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 40886346,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200912/18/10533922/86257058/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200912/18/10533922/86257058/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200912/18/10533922/86257058/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200912/18/10533922/86257058/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200912/18/10533922/86257058/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "L/XL",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L/XL",
          itemNumber: 86264161,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 215526585,
          comment: "Güzel de  sadece daha köyü rengi resmindeki gibi degil.",
          rate: 4.0,
          userFullName: "M** B**",
          trusted: true,
          commentDateISOtype: "2022-10-07",
          lastModifiedDate: "7 Ekim 2022"
        }
      ],
      colors: [
        {
          id: 130151247,
          imageUrl: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116151068/222662377/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Haki Büyük Beden Dantel Ve Taş Işli Yelekli Takım"
        },
        {
          id: 48647488,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200912/18/10533922/86257058/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Büyük Beden Dantel ve Taş İşli Yelekli Takım"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290596031,
      productCode: "TS-1767",
      name: "Pamuklu Dokuma Battal Geniş Kesim Büyük Beden Gömlek",
      slug: "Pamuklu Dokuma Battal Geniş Kesim Büyük Beden Gömlek",
      description: "Pamuklu Dokuma Battal Geniş Kesim Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 245135696,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125036500/464245120/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125036500/464245120/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125036500/464245120/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125036500/464245120/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 464245120,
          stock: 4.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Klasik"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 48,
          key: "Boy",
          value: "Kısa"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Organik"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283364941,
      productCode: "TS-7187",
      name: "Puantiye Desenli Viskon Büyük Beden Gömlek",
      slug: "Puantiye Desenli Viskon Büyük Beden Gömlek",
      description: "Puantiye Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117257,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947133/450748181/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947133/450748181/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947133/450748181/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "BEYAZ-PUANTİYE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 450755496,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366718,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949281/450751079/1/1_org_zoom.jpg",
          name: "SİYAH-PUANTİYE",
          description: "Puantiye Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 283364941,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947133/450748181/1/1_org_zoom.jpg",
          name: "BEYAZ-PUANTİYE",
          description: "Puantiye Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 322554364,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/10/135994784/515361811/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Puantiye Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Slim/Fitted"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96942192,
      productCode: "TS-1703-35",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çiçek Desenli Önden Düğmeli Pamuklu Dokuma Büyük Beden Elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassa"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 83968036,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/11/125149498/161428915/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/11/125149498/161428915/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/11/125149498/161428915/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/11/125149498/161428915/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/11/125149498/161428915/4/4_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.39130434782609,
        totalCount: 23.0
      },
      winnerVariant: "42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 161432438,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 188008413,
          comment: "Halama hediye aldim uzerine tam oldu kumasi cok guzel",
          rate: 5.0,
          userFullName: "İnci E.",
          trusted: true,
          commentDateISOtype: "2022-06-16",
          lastModifiedDate: "16 Haziran 2022"
        },
        {
          id: 191302850,
          comment: "Ablama aldım , beğendi",
          rate: 5.0,
          userFullName: "F** M** B**",
          trusted: true,
          commentDateISOtype: "2022-07-03",
          lastModifiedDate: "3 Temmuz 2022"
        },
        {
          id: 129955106,
          comment: "Tam bedeninizi alın çok begendim harika",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-09-30",
          lastModifiedDate: "30 Eylül 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49091097,
      productCode: "TS-6999",
      name: "Kadın Sarı Desenli Viskon Gömlek",
      slug: "Kadın Sarı Desenli Viskon Gömlek",
      description: "Kadın Sarı Desenli Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "%100 Viskon Kumaş Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40932309,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200917/12/10958036/87046415/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200917/12/10958036/87046415/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200917/12/10958036/87046415/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200917/12/10958036/87046415/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200917/12/10958036/87046415/4/4_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 87046415,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 150756425,
      productCode: "TS-20028004",
      name: "Düz Kesim Diz Yapmayan Düz Paça Büyük Beden Eşofman Altı",
      slug: "Düz Kesim Diz Yapmayan Düz Paça Büyük Beden Eşofman Altı",
      description: "Düz Kesim Diz Yapmayan Düz Paça Büyük Beden Eşofman Altı",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz Kesim Diz Yapmayan Düz Paça Büyük Beden Eşofman Altı, Tam kalıptır, Kendi bedeninizi almanızı tavsiye ederiz, ürünün yandan iki ve arkadan bir cebi vardır, günlük kullanıma ve spor için kullanıma uygundur, ürün diz yapmaz, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 40 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Altı",
      productGroupId: 130029107,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Altı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty251/product/media/images/20211117/11/181851723/251431362/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty251/product/media/images/20211117/11/181851723/251431362/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty249/product/media/images/20211117/11/181851723/251431362/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty249/product/media/images/20211117/11/181851723/251431362/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 4.0
      },
      winnerVariant: "38",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 251431345,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 255511670,
          comment: "ürün kesinlikle çok kaliteli. eşim normal beden yerine büyük beden alınca iade etmek zorunda kaldım. normal bedende 42 sini sipariş vereceğim.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-01-16",
          lastModifiedDate: "16 Ocak 2023"
        },
        {
          id: 151349526,
          comment: "kalitesi güzel ama boyu kısa geldi bana 1.72 boyum. 42 beden almisti",
          rate: 2.0,
          userFullName: "k** d**",
          trusted: true,
          commentDateISOtype: "2022-01-06",
          lastModifiedDate: "6 Ocak 2022"
        },
        {
          id: 258136311,
          comment: "Bacak kısmı rahat dış kumaşta güzel iç yüzünü çok sevmedim birde beli biraz düşük",
          rate: 4.0,
          userFullName: "B** Y**",
          trusted: true,
          commentDateISOtype: "2023-01-20",
          lastModifiedDate: "20 Ocak 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 196,
          key: "Kullanım Alanı",
          value: "Günlük"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Normal Paça"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122697239,
      productCode: "TS-1751-1",
      name: "Kadın Taş Işli Dijital Büzgülü Büyük Beden Elbise",
      slug: "Kadın Taş Işli Dijital Büzgülü Büyük Beden Elbise",
      description: "Kadın Taş Işli Dijital Büzgülü Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 108032089,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty140/product/media/images/20210705/13/107229686/198018657/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty140/product/media/images/20210705/13/107229686/198018657/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty140/product/media/images/20210705/13/107229686/198018657/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty140/product/media/images/20210705/13/107229686/198018657/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 152.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "42",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 198036710,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 123306649,
          comment: "elbiseyi çok beğendim tavsiye ederim alırken tam bedeninizi alın",
          rate: 5.0,
          userFullName: "a** k**",
          trusted: true,
          commentDateISOtype: "2021-08-19",
          lastModifiedDate: "19 Ağustos 2021"
        },
        {
          id: 121157856,
          comment: "modelini çol beğenerek aşdım aynısı da geldi model çok güzel ama ben kumaş kalitesibi beğenmedim naylon gibi ve biraz iç gösteriyo",
          rate: 4.0,
          userFullName: "H** K**",
          trusted: true,
          commentDateISOtype: "2021-08-04",
          lastModifiedDate: "4 Ağustos 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320946812,
      productCode: "C-S314",
      name: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      slug: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Oya İşli %100 Pamuk Özel Şile Bezi El İşçiliği Büyük Beden Dokuma Tunik Elbise, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki 40-42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359529,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134203598/512205092/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134203598/512205092/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134203598/512205092/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134203598/512205092/3/3_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 515556221,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 320946812,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134203598/512205092/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise"
        },
        {
          id: 320943514,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199906/512200820/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Mini"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Askılı"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Kare Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320943514,
      productCode: "C-S314",
      name: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      slug: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Oya İşli %100 Pamuk Özel Şile Bezi El İşçiliği Büyük Beden Dokuma Tunik Elbise, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki 40-42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359529,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199906/512200820/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199906/512200820/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134199906/512200820/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134199906/512200820/3/3_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512200820,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 320946812,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134203598/512205092/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise"
        },
        {
          id: 320943514,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199906/512200820/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Mini"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Askılı"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Kare Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 79918105,
      productCode: "TS-8090",
      name: "Inci Detaylı Krep Ikili Takım",
      slug: "Inci Detaylı Krep Ikili Takım",
      description: "Inci Detaylı Krep Ikili Takım",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE İnci Detaylı Krep İkili Takım, Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.72 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 68433954,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty63/product/media/images/20210128/23/58141564/135487063/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty63/product/media/images/20210128/23/58141564/135487063/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty64/product/media/images/20210128/23/58141564/135487063/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty63/product/media/images/20210128/23/58141564/135487063/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty64/product/media/images/20210128/23/58141564/135487063/4/4_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 499.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 135502118,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48703984,
      productCode: "C-0433",
      name: "Kadın Siyah Güpür Yelekli Ikili Takım",
      slug: "Kadın Siyah Güpür Yelekli Ikili Takım",
      description: "Kadın Siyah Güpür Yelekli Ikili Takım",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon ikili takım."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40932536,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/11/10587383/86340138/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/11/10587383/86340138/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/11/10587383/86340138/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/11/10587383/86340138/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/11/10587383/86340138/4/4_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 499.99,
        sellingPrice: 499.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 86340138,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48593806,
      productCode: "TS-2709",
      name: "Kadın Haki Fileli Kapişonlu Dijital Yelek",
      slug: "Kadın Haki Fileli Kapişonlu Dijital Yelek",
      description: "Kadın Haki Fileli Kapişonlu Dijital Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün dijital, baskıdır. Şık bir görüntü sağlar. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün XL bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 40838082,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200912/12/10478078/86144649/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200912/12/10478078/86144649/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200912/12/10478078/86144649/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200912/12/10478078/86144649/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200912/12/10478078/86144649/4/4_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 499.99,
        sellingPrice: 499.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 86144649,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 110300352,
          comment: "Çok çok güsel tavsiye ediyorum",
          rate: 5.0,
          userFullName: "Yıldız S.",
          trusted: true,
          commentDateISOtype: "2021-06-06",
          lastModifiedDate: "6 Haziran 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polyester"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Kapüşonlu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48591245,
      productCode: "TS-8322",
      name: "Kadın Mavi Fileli Kapüşonlu Dijital Yelek",
      slug: "Kadın Mavi Fileli Kapüşonlu Dijital Yelek",
      description: "Kadın Mavi Fileli Kapüşonlu Dijital Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün dijital, baskıdır. Şık bir görüntü sağlar. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün xl bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 40835807,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty13/product/media/images/20201003/16/13242873/86142234/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty13/product/media/images/20201003/16/13242873/86142234/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty14/product/media/images/20201003/16/13242873/86142234/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty13/product/media/images/20201003/16/13242873/86142234/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty14/product/media/images/20201003/16/13242873/86142234/4/4_org_zoom.jpg"
      ],
      price: 499.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 499.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 499.99,
        sellingPrice: 499.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 86142234,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polyester"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Kapüşonlu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 342131753,
      productCode: "TYC00536790890",
      name: "Empirme Desenli Düğmeli %100 Pamuk Büyük Beden Tunik",
      slug: "Empirme Desenli Düğmeli %100 Pamuk Büyük Beden Tunik",
      description: "Empirme Desenli Düğmeli %100 Pamuk Büyük Beden Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli pamuklu büyük beden Tunik., %100 cotton kumaş., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 278895394,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty511/product/media/images/20220817/20/161783758/546365633/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty511/product/media/images/20220817/20/161783758/546365633/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty511/product/media/images/20220817/20/161783758/546365633/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 546387847,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Gömlek Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 342130572,
      productCode: "TYC00536790435",
      name: "Empirme Desenli Düğmeli %100 Pamuk Büyük Beden Tunik",
      slug: "Empirme Desenli Düğmeli %100 Pamuk Büyük Beden Tunik",
      description: "Empirme Desenli Düğmeli %100 Pamuk Büyük Beden Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli pamuklu büyük beden Tunik., %100 cotton kumaş., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 278894968,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty512/product/media/images/20220817/20/161782570/546363899/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty512/product/media/images/20220817/20/161782570/546363899/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty510/product/media/images/20220817/20/161782570/546363899/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46-48",
      color: "CAMEL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46-48",
          itemNumber: 546363899,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Gömlek Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471061,
      productCode: "TS-7214",
      name: "%100 Pamuk Düz Renk Kol Bağcık Detaylı Pamuklu Büyük Beden Gömlek",
      slug: "%100 Pamuk Düz Renk Kol Bağcık Detaylı Pamuklu Büyük Beden Gömlek",
      description: "%100 Pamuk Düz Renk Kol Bağcık Detaylı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz renk kolları bağcıklı pamuklu büyük beden gömlek. Cotton kumaş. Kumaşın tok bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 42 bedendir. Modelin ölçüleri: Boy: 1.76, göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458576,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897559/515256614/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897559/515256614/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897559/515256614/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897559/515256614/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "42",
      color: "TAŞ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515256614,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 279699216,
          comment: "harika bir gömlek ya kumasi çok güzel tiril tiril, üzerindeki kalpli not için de tşk ederim:) özenli hazirlanmisti",
          rate: 5.0,
          userFullName: "S** N** Ç**",
          trusted: true,
          commentDateISOtype: "2023-03-17",
          lastModifiedDate: "17 Mart 2023"
        }
      ],
      colors: [
        {
          id: 322471061,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897559/515256614/1/1_org_zoom.jpg",
          name: "TAŞ",
          description: "%100 Pamuk Düz Renk Kol Bağcık Detaylı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 322470008,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896296/515255029/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "%100 Pamuk Düz Renk Kol Bağcık Detaylı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321349750,
      productCode: "C-S106",
      name: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      slug: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Oya İşli %100 Pamuk Özel Şile Bezi El İşçiliği Büyük Beden Dokuma Tunik Bluz, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki 40-42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626924/512820359/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626924/512820359/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626924/512820359/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626924/512820359/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 269.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512830964,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321346605,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623680/512815797/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349750,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626924/512820359/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349682,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626856/512820284/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349832,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134627023/512820447/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321349682,
      productCode: "C-S106",
      name: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      slug: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Oya İşli %100 Pamuk Özel Şile Bezi El İşçiliği Büyük Beden Dokuma Tunik Bluz, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki 40-42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626856/512820284/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626856/512820284/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626856/512820284/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626856/512820284/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 269.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512830810,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321346605,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623680/512815797/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349750,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626924/512820359/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349682,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626856/512820284/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349832,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134627023/512820447/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320943843,
      productCode: "TS-24837",
      name: "Düz Renk Cepli Kuşaklı Krep Büyük Beden Klasik Elbise",
      slug: "Düz Renk Cepli Kuşaklı Krep Büyük Beden Klasik Elbise",
      description: "Düz Renk Cepli Kuşaklı Krep Büyük Beden Klasik Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kol, midi boy dokuma elbise. Hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Mankendeki beden 38-40 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359763,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134200255/512201387/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134200255/512201387/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134200255/512201387/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/21/134200255/512201387/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "38-40",
      color: "BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38-40",
          itemNumber: 512201387,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Aerobin"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Aerobin"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 303853717,
      productCode: "TS-7212",
      name: "Renkli Sulu Boya Çiçek Desenli %100 Cotton Büyük Beden Gömlek",
      slug: "Renkli Sulu Boya Çiçek Desenli %100 Cotton Büyük Beden Gömlek",
      description: "Renkli Sulu Boya Çiçek Desenli %100 Cotton Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 253497283,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty436/product/media/images/20220521/21/114631427/485455921/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty436/product/media/images/20220521/21/114631427/485455921/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty436/product/media/images/20220521/21/114631427/485455921/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty435/product/media/images/20220521/21/114631427/485455921/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "MAVİ ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 485469584,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290597336,
      productCode: "TS-7207",
      name: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek",
      slug: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek",
      description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 245135658,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785117/464247294/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785117/464247294/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/19/100785117/464247294/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/19/100785117/464247294/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 464252645,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 290597283,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785065/464247234/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek"
        },
        {
          id: 290595990,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/18/100783687/464245078/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek"
        },
        {
          id: 290597336,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785117/464247294/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Klasik"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Geometrik"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290595990,
      productCode: "TS-7207",
      name: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek",
      slug: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek",
      description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 245135658,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/18/100783687/464245078/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/18/100783687/464245078/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/18/100783687/464245078/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 464245078,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 290597283,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785065/464247234/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek"
        },
        {
          id: 290595990,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/18/100783687/464245078/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek"
        },
        {
          id: 290597336,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785117/464247294/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Klasik"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Geometrik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366539,
      productCode: "TS-7192",
      name: "Çizgili Pamuklu Keten Büyük Beden Gömlek",
      slug: "Çizgili Pamuklu Keten Büyük Beden Gömlek",
      description: "Çizgili Pamuklu Keten Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117246,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949091/450750804/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949091/450750804/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949091/450750804/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949091/450750804/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "BEJ-ÇİZGİLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 450757980,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366539,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949091/450750804/1/1_org_zoom.jpg",
          name: "BEJ-ÇİZGİLİ",
          description: "Çizgili Pamuklu Keten Büyük Beden Gömlek"
        },
        {
          id: 283364928,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947116/450748164/1/1_org_zoom.jpg",
          name: "INDIGO-ÇİZGİLİ",
          description: "Çizgili Pamuklu Keten Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Katlamalı"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 280072599,
      productCode: "TS-1724",
      name: "Krep Klasik Kısa Kol Büyük Beden Elbise",
      slug: "Krep Klasik Kısa Kol Büyük Beden Elbise",
      description: "Krep Klasik Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve yarım kollu, midi boy penye elbise, hem rahat, hem de şık bir görüntü sağlar, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin üzerindeki 44 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 236618904,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty394/product/media/images/20220412/21/89494333/446002733/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty394/product/media/images/20220412/21/89494333/446002733/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty395/product/media/images/20220412/21/89494333/446002733/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty393/product/media/images/20220412/21/89494333/446002733/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "KİREMİT-SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 446016029,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 280072599,
          imageUrl: "https://cdn.dsmcdn.com/ty394/product/media/images/20220412/21/89494333/446002733/1/1_org_zoom.jpg",
          name: "KİREMİT-SİYAH",
          description: "Krep Klasik Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280712183,
          imageUrl: "https://cdn.dsmcdn.com/ty958/product/media/images/20230627/16/389637815/446987953/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Krep Klasik Kısa Kol Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Krep"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Krep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96942183,
      productCode: "TS-1705-1",
      name: "Kadın Desenli Viskon Yarım Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Yarım Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Yarım Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız., Mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 83968028,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty99/product/media/images/20210406/22/78338373/161428906/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty99/product/media/images/20210406/22/78338373/161428906/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338373/161428906/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty101/product/media/images/20210406/22/78338373/161428906/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338373/161428906/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.75,
        totalCount: 4.0
      },
      winnerVariant: "48",
      color: "MOR-PUANTİYE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48",
          itemNumber: 161432508,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 152676765,
          comment: "Harika bir elbise, kalıbı çok güzel, canlı renkler… bayıldım :)",
          rate: 5.0,
          userFullName: "P** Ö**",
          trusted: true,
          commentDateISOtype: "2022-01-12",
          lastModifiedDate: "12 Ocak 2022"
        },
        {
          id: 318174778,
          comment: "tam beden alinmali",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-07-01",
          lastModifiedDate: "1 Temmuz 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48931613,
      productCode: "TS-7997",
      name: "Kadın Beyaz Battal Pamuklu Gömlek",
      slug: "Kadın Beyaz Battal Pamuklu Gömlek",
      description: "Kadın Beyaz Battal Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41120110,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.80952380952381,
        totalCount: 21.0
      },
      winnerVariant: "XL",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 86753338,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 153761007,
          comment: "salas oldu, otelyede bedenime duzelis eledim. ezilen parcadir. oz bedenizi alin.",
          rate: 4.0,
          userFullName: "R** H**",
          trusted: true,
          commentDateISOtype: "2022-01-16",
          lastModifiedDate: "16 Ocak 2022"
        },
        {
          id: 113599975,
          comment: "kaliteli bir kumaşı var , tam bedeninizi söyleyebilirsiniz",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-06-19",
          lastModifiedDate: "19 Haziran 2021"
        },
        {
          id: 137175353,
          comment: "Ürünün kalitesi, duruşu çox güzel ve şık. gönül rahatlığıyla sipariş vere bilirsiniz. paketleme de özenli idi. çok teşekkür ediyorum. yorumlardan yola çıkarak sipariş etdim ve asla peşman olmadım.",
          rate: 5.0,
          userFullName: "F** M**",
          trusted: true,
          commentDateISOtype: "2021-11-02",
          lastModifiedDate: "2 Kasım 2021"
        }
      ],
      colors: [
        {
          id: 140473377,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 124023053,
          imageUrl: "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
          name: "LİLA",
          description: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 140024951,
          imageUrl: "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Sarı Klasik Pamuklu Gömlek"
        },
        {
          id: 140025063,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
          name: "MİNT YEŞİL",
          description: "Klasik Mint Yeşil Pamuklu Gömlek"
        },
        {
          id: 49109092,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Battal Pamuklu Gömlek"
        },
        {
          id: 140440545,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 56510747,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Battal Pamuklu Kadın Gömlek"
        },
        {
          id: 48931613,
          imageUrl: "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Battal Pamuklu Gömlek"
        },
        {
          id: 142140797,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48713059,
      productCode: "TS-7093",
      name: "Kadın Turuncu Battal Desenli Gömlek",
      slug: "Kadın Turuncu Battal Desenli Gömlek",
      description: "Kadın Turuncu Battal Desenli Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 2XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40940024,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10596534/86359196/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10596534/86359196/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10596534/86359196/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10596534/86359196/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.42857142857143,
        totalCount: 7.0
      },
      winnerVariant: "48-50",
      color: "TURUNCU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48-50",
          itemNumber: 200896968,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 183603914,
          comment: "Bedeninize uygun renkleri çok tatlış",
          rate: 5.0,
          userFullName: "D** D** A**",
          trusted: true,
          commentDateISOtype: "2022-05-25",
          lastModifiedDate: "25 Mayıs 2022"
        },
        {
          id: 157496090,
          comment: "Kumaşı da kalıbı da çok güzel, tam yazlık",
          rate: 5.0,
          userFullName: "n** ö**",
          trusted: true,
          commentDateISOtype: "2022-02-01",
          lastModifiedDate: "1 Şubat 2022"
        },
        {
          id: 180450412,
          comment: "3xl salas diye aldik dar kesi oldugu icin olmadi iade ettik malesef",
          rate: 1.0,
          userFullName: "R** b** N**",
          trusted: true,
          commentDateISOtype: "2022-05-10",
          lastModifiedDate: "10 Mayıs 2022"
        }
      ],
      colors: [
        {
          id: 306284708,
          imageUrl: "https://cdn.dsmcdn.com/ty441/product/media/images/20220528/21/117447264/489532541/1/1_org_zoom.jpg",
          name: "LACİ-ORANJ",
          description: "Plus Mercan Desenli Pamuklu Büyük Beden Pamuklu Gömlek"
        },
        {
          id: 48713059,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10596534/86359196/1/1_org_zoom.jpg",
          name: "TURUNCU",
          description: "Kadın Turuncu Battal Desenli Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 699196225,
      productCode: "TS-1688-12",
      name: "Plus Etnik Desenli Viskon Cepli Vücuda Yapışmayan Battal Büyük Beden Günlük Elbise",
      slug: "Plus Etnik Desenli Viskon Cepli Vücuda Yapışmayan Battal Büyük Beden Günlük Elbise",
      description: "Plus Etnik Desenli Viskon Cepli Vücuda Yapışmayan Battal Büyük Beden Günlük Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 538823911,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty859/product/media/images/20230502/11/337082738/924378771/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty859/product/media/images/20230502/11/337082738/924378771/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty859/product/media/images/20230502/11/337082738/924378771/2/2_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "48",
      color: "ETNİK-KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48",
          itemNumber: 924406568,
          stock: 8.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Kumaş"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Etnik"
        },
        {
          id: 250,
          key: "Stil",
          value: "Günlük"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        },
        {
          id: 893,
          key: "Kemer/Kuşak Durumu",
          value: "Kemersiz"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "İnce"
        },
        {
          id: 710,
          key: "Koleksiyon",
          value: "Büyük Beden"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 273967257,
      productCode: "TS-1735",
      name: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon",
      slug: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon",
      description: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli viskon büyük beden havuç pantolon, ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 42 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 232876232,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83043723/436710967/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83043723/436710967/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83043723/436710967/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty381/product/media/images/20220404/19/83043723/436710967/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/19/83043723/436710967/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 2.0,
      ratingScore: {
        averageRating: 2.0,
        totalCount: 1.0
      },
      winnerVariant: "38",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 436740655,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 179797776,
          comment: "Kargo özenle paketlenmiş, o konuda herhangi bir sorun yok, ama sipariş verdiğim çiçek desen değil, başka bir desen gönderilmiş. Nasıl bir özensizlik oldu bilmiyorum. Nasıl başka bir desen gönderildi acaba satıcı? Kumaşı oldukça güzel ve kaliteli. Başka bir yakınım beğendiği için değişim yapmayacağım. Tekrar alışveriş yapmayı düşündüğüm bir marka, ama şu an gerçekten tekrar alışveriş yapma konusunda tereddüt ediyorum.",
          rate: 2.0,
          userFullName: "B** Ç**",
          trusted: true,
          commentDateISOtype: "2022-05-08",
          lastModifiedDate: "8 Mayıs 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Normal Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Dar Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377534664,
      productCode: "TS-1755-26",
      name: "%100 Pamuklu Dokuma Çiçek Desenli Hatlara Yapışmayan Büyük Beden Dökümlü Klasik Elbise",
      slug: "%100 Pamuklu Dokuma Çiçek Desenli Hatlara Yapışmayan Büyük Beden Dökümlü Klasik Elbise",
      description: "%100 Pamuklu Dokuma Çiçek Desenli Hatlara Yapışmayan Büyük Beden Dökümlü Klasik Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve yarım kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz.,%100 dokuma pamuk, ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız.,"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885908,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/10/204655358/610468271/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/10/204655358/610468271/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205083750/610468271/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205083751/610468271/2/2_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "SİYAH ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 610976990,
          stock: 10.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471108,
      productCode: "TYC00500242272",
      name: "Düz Renk %100 Pamuklu Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Düz Renk %100 Pamuklu Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Düz Renk %100 Pamuklu Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz renk kolları bağcıklı pamuklu büyük beden gömlek. Cotton kumaş. Kumaşın tok bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 42 bedendir. Modelin ölçüleri: Boy: 1.76, göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266459617,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897616/515256667/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897616/515256667/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897616/515256667/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897616/515256667/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "HARDAL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515261078,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471009,
      productCode: "TS-7209",
      name: "Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek",
      slug: "Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek",
      description: "Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458542,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897505/515256561/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897505/515256561/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897505/515256561/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897505/515256561/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "SİYAH-PEMBE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515261017,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322469972,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896255/515254992/1/1_org_zoom.jpg",
          name: "BEYAZ-PEMBE",
          description: "Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek"
        },
        {
          id: 322471009,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897505/515256561/1/1_org_zoom.jpg",
          name: "SİYAH-PEMBE",
          description: "Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319080,
      productCode: "TS-1686-13",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900827,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562724/511381141/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562724/511381141/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562724/511381141/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562724/511381141/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "46",
      color: "SİYAH-ZİKZAK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 511383294,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 205933523,
          comment: "Elbiseyi annem için aldım,çok kalite ve güzeldi ama anneme dar geldi beden oturmadı üzülerek iade ettik.",
          rate: 5.0,
          userFullName: "C** Ç**",
          trusted: true,
          commentDateISOtype: "2022-09-09",
          lastModifiedDate: "9 Eylül 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319063,
      productCode: "TS-1686-3",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900813,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562707/511381121/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562707/511381121/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562707/511381121/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562707/511381121/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 511381121,
          stock: 4.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319056,
      productCode: "TS-1686-9",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900808,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562702/511381114/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562702/511381114/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562702/511381114/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/1/133562702/511381114/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "TURKUAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 511381114,
          stock: 4.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turkuaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366602,
      productCode: "TS-7182",
      name: "Çizgili Pamuklu Keten Büyük Beden Gömlek",
      slug: "Çizgili Pamuklu Keten Büyük Beden Gömlek",
      description: "Çizgili Pamuklu Keten Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117264,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125036541/450750883/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125036541/450750883/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125036541/450750883/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125036541/450750883/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "42",
      color: "INDIGO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450758058,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 314693258,
          comment: "kumasi cok guzel tiril tiril",
          rate: 5.0,
          userFullName: "T** K**",
          trusted: true,
          commentDateISOtype: "2023-06-16",
          lastModifiedDate: "16 Haziran 2023"
        },
        {
          id: 305110163,
          comment: "mükemmel, severek giyiyorum",
          rate: 5.0,
          userFullName: "Banu A.",
          trusted: true,
          commentDateISOtype: "2023-05-17",
          lastModifiedDate: "17 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 283366602,
          imageUrl: "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125036541/450750883/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Çizgili Pamuklu Keten Büyük Beden Gömlek"
        },
        {
          id: 322528137,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/9/135967269/515321624/1/1_org_zoom.jpg",
          name: "GRİ",
          description: "Çizgili %100 Pamuklu Keten Büyük Beden Gömlek Ts-7182"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 141179620,
      productCode: "TS-7164",
      name: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek ., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121207830,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/18/128000500/239527969/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/18/128000500/239527969/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210909/18/128000500/239527969/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/18/128000500/239527969/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 239581029,
          stock: 4.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 140011437,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/19/126836016/238104874/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek"
        },
        {
          id: 140011459,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126838119/238104896/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek"
        },
        {
          id: 140471273,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/19/127261728/238640675/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 140440462,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/18/127249586/238604285/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 141179620,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/18/128000500/239527969/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140011562,
      productCode: "TS-7156",
      name: "Beyaz Düz Renk Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Beyaz Düz Renk Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Beyaz Düz Renk Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz renk kolları bağcıklı pamuklu büyük beden gömlek. Cotton kumaş. Kumaşın tok bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün xl bedendir. Modelin ölçüleri: Boy: 1.76, göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121207882,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126827689/238105029/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126827689/238105029/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/19/126827689/238105029/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/19/126827689/238105029/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "XL",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 238227036,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 134159447,
          comment: "Yakası biraz dar gibi ama harika beğendim. 3xl aldım gayet güzel oldu",
          rate: 5.0,
          userFullName: "S** U** D**",
          trusted: true,
          commentDateISOtype: "2021-10-19",
          lastModifiedDate: "19 Ekim 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124088688,
      productCode: "TS-7159",
      name: "Piti Kareli Ince Yazlık Pamuklu Büyük Beden Gömlek",
      slug: "Piti Kareli Ince Yazlık Pamuklu Büyük Beden Gömlek",
      description: "Piti Kareli Ince Yazlık Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Piti Kareli İnce Yazlık Pamuklu Büyük Beden Gömlek, %100 Cotton kumaş., Kumaşın ince bir dokusu vardır., İç gösterebilir, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109224525,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/15/108973089/200944715/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/15/108973089/200944715/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/15/108973089/200944715/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/15/108973089/200944715/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 343.24,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 200944715,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 310153648,
          comment: "Hediye aldım, aldığım kişi çok beğendi",
          rate: 5.0,
          userFullName: "M** T**",
          trusted: true,
          commentDateISOtype: "2023-06-01",
          lastModifiedDate: "1 Haziran 2023"
        }
      ],
      colors: [
        {
          id: 124088698,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/15/108973010/200944733/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Piti Kareli Ince Yazlık Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 124088688,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/15/108973089/200944715/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Piti Kareli Ince Yazlık Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Ekose / Kareli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124069060,
      productCode: "TS-7129",
      name: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek desenli kolları bağcıklı pamuklu büyük beden gömlek., %100 cotton kumaş., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558114,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/14/108950908/200884319/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/14/108950908/200884319/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/14/108950908/200884319/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/14/108950908/200884319/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "XL",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 200906632,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 253370752,
          comment: "KUMAŞIBİRAZ İNCE LAKİN ÇOK GÜZEL BİR GÖMLEK,KEYİFLE GİYİYORUM",
          rate: 5.0,
          userFullName: "F** B**",
          trusted: true,
          commentDateISOtype: "2023-01-12",
          lastModifiedDate: "12 Ocak 2023"
        }
      ],
      colors: [
        {
          id: 124012945,
          imageUrl: "https://cdn.dsmcdn.com/ty257/product/media/images/20211128/19/648319/200721496/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 124069060,
          imageUrl: "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/14/108950908/200884319/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124013027,
      productCode: "TS-7151",
      name: "Uğur Böceği Işlemeli Pamuklu Büyük Beden Gömlek",
      slug: "Uğur Böceği Işlemeli Pamuklu Büyük Beden Gömlek",
      description: "Uğur Böceği Işlemeli Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Uğur Böceği işlemeli Pamuklu Gömlek., ürünün kumaşı %100 cottondur., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün L bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109169745,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888725/200701307/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888725/200701307/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/9/108888725/200701307/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty142/product/media/images/20210710/9/108888725/200701307/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "42",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 200721817,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 140990183,
          comment: "geniş kalıp normal bedeninizden bir numara küçük alın gömlek harika.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-11-17",
          lastModifiedDate: "17 Kasım 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124012945,
      productCode: "TS-7129",
      name: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558114,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty257/product/media/images/20211128/19/648319/200721496/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty257/product/media/images/20211128/19/648319/200721496/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty255/product/media/images/20211128/19/648319/200721496/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty256/product/media/images/20211128/19/648319/200721496/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 343.24,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.25,
        totalCount: 4.0
      },
      winnerVariant: "XL",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 200721499,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 186910531,
          comment: "Tusse büyük bedenler için çok güzel 3 - 4 tane gömlek aldım. 1.58 boy 93 kilo 46 beden tam oldu tşk",
          rate: 5.0,
          userFullName: "Ö** T**",
          trusted: true,
          commentDateISOtype: "2022-06-11",
          lastModifiedDate: "11 Haziran 2022"
        },
        {
          id: 304576341,
          comment: "mükemmel kalite çok güzel ürün",
          rate: 5.0,
          userFullName: "Ç** T**",
          trusted: true,
          commentDateISOtype: "2023-05-16",
          lastModifiedDate: "16 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 124012945,
          imageUrl: "https://cdn.dsmcdn.com/ty257/product/media/images/20211128/19/648319/200721496/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 124069060,
          imageUrl: "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/14/108950908/200884319/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95454843,
      productCode: "C-0410",
      name: "Kadın Vizon Çiçek Desenli Büyük Beden Gömlek",
      slug: "Kadın Vizon Çiçek Desenli Büyük Beden Gömlek",
      description: "Kadın Vizon Çiçek Desenli Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82609999,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty78/product/media/images/20210331/11/76583776/159075148/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty78/product/media/images/20210331/11/76583776/159075148/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty67/product/media/images/20210331/11/76583776/159075148/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty38/product/media/images/20210331/11/76583776/159075148/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XXL",
      color: "VİZON ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XXL",
          itemNumber: 159105998,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polyviskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48744120,
      productCode: "C-0409",
      name: "Kadın Yeşil Dijital Gömlek",
      slug: "Kadın Yeşil Dijital Gömlek",
      description: "Kadın Yeşil Dijital Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan çiçek desenli dijital gömlek., polyster ve cotton karışımı kumaştır., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40967166,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/16/10625499/86427412/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/16/10625499/86427412/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/16/10625499/86427412/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/16/10625499/86427412/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/16/10625499/86427412/4/4_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 2.0
      },
      winnerVariant: "XL",
      color: "MİNT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 86437932,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 121301147,
          comment: "Rengi dokusu çok güzel",
          rate: 5.0,
          userFullName: "g** y**",
          trusted: true,
          commentDateISOtype: "2021-08-05",
          lastModifiedDate: "5 Ağustos 2021"
        },
        {
          id: 186802101,
          comment: "Tamamile farkli model ve cok kalitesiz",
          rate: 1.0,
          userFullName: "N** Q**",
          trusted: true,
          commentDateISOtype: "2022-06-10",
          lastModifiedDate: "10 Haziran 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polyviskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321349646,
      productCode: "TS-S206",
      name: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      slug: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684127,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626809/512820242/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626809/512820242/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626809/512820242/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626809/512820242/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "48-50",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48-50",
          itemNumber: 512830861,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 273252424,
          comment: "Harika kaliteli ve güzel bir şey",
          rate: 5.0,
          userFullName: "A** G**",
          trusted: true,
          commentDateISOtype: "2023-02-27",
          lastModifiedDate: "27 Şubat 2023"
        }
      ],
      colors: [
        {
          id: 321349646,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626809/512820242/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        },
        {
          id: 321346588,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623668/512815780/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        },
        {
          id: 321349726,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626896/512820335/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321975512,
      productCode: "TS-1692-3",
      name: "Kadın %100 Pamuklu Etnik Desenli Viskon Büyük Beden Palazzo Pantolon",
      slug: "Kadın %100 Pamuklu Etnik Desenli Viskon Büyük Beden Palazzo Pantolon",
      description: "Kadın %100 Pamuklu Etnik Desenli Viskon Büyük Beden Palazzo Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kadın %100 Pamuklu Etnik Desenli Viskon Büyük Beden Palazzo Pantolon, %100 pamuk kumaştır. ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 40 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 266049958,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/23/135312409/513828692/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/23/135312409/513828692/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/23/135312409/513828692/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/23/135312409/513828692/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "LACİ YAPRAK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 513853303,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 698601496,
      productCode: "TS-YY7648",
      name: "Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      slug: "Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      description: "Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 538605943,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336335138/923686339/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336335138/923686339/1/1_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 449.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 923686339,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 698579307,
          imageUrl: "https://cdn.dsmcdn.com/ty856/product/media/images/20230501/21/336314053/923664098/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek"
        },
        {
          id: 698601496,
          imageUrl: "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336335138/923686339/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Ottoman"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 698599988,
      productCode: "TS-YY4001",
      name: "Renkli Düğme Detaylı Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      slug: "Renkli Düğme Detaylı Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      description: "Renkli Düğme Detaylı Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 538605233,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336333844/923684833/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336333844/923684833/1/1_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 449.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 925545827,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 698578508,
          imageUrl: "https://cdn.dsmcdn.com/ty856/product/media/images/20230501/21/336313536/923663301/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Renkli Düğme Detaylı Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek"
        },
        {
          id: 698599988,
          imageUrl: "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336333844/923684833/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Renkli Düğme Detaylı Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Ottoman"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 698584727,
      productCode: "TS-YY7634",
      name: "Eteği Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      slug: "Eteği Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      description: "Eteği Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 538610298,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336319314/923669544/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336319314/923669544/1/1_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 449.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 923669544,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Ottoman"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 698581276,
      productCode: "TS-YY7641",
      name: "Metal Toka Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      slug: "Metal Toka Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      description: "Metal Toka Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 538607459,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty857/product/media/images/20230501/21/336315872/923666081/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty857/product/media/images/20230501/21/336315872/923666081/1/1_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 449.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 925550017,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Ottoman"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 698579307,
      productCode: "TS-YY7648",
      name: "Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      slug: "Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      description: "Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 538605943,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty856/product/media/images/20230501/21/336314053/923664098/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty856/product/media/images/20230501/21/336314053/923664098/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336314053/923664098/2/2_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 449.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 923664098,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 698579307,
          imageUrl: "https://cdn.dsmcdn.com/ty856/product/media/images/20230501/21/336314053/923664098/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek"
        },
        {
          id: 698601496,
          imageUrl: "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336335138/923686339/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Taş Işli Elegant Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Ottoman"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 698578508,
      productCode: "TS-YY4001",
      name: "Renkli Düğme Detaylı Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      slug: "Renkli Düğme Detaylı Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      description: "Renkli Düğme Detaylı Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 538605233,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty856/product/media/images/20230501/21/336313536/923663301/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty856/product/media/images/20230501/21/336313536/923663301/1/1_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 449.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 923663301,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 698578508,
          imageUrl: "https://cdn.dsmcdn.com/ty856/product/media/images/20230501/21/336313536/923663301/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Renkli Düğme Detaylı Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek"
        },
        {
          id: 698599988,
          imageUrl: "https://cdn.dsmcdn.com/ty855/product/media/images/20230501/21/336333844/923684833/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Renkli Düğme Detaylı Çelikli Ottoman Kumaş Şık Uzun Fermuar Kapamalı Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Ottoman"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 380484054,
      productCode: "TS-3603",
      name: "Elegant Çiçek Işli Kuşaklı Klasik Büyük Beden Yırtmaçlı Yelek",
      slug: "Elegant Çiçek Işli Kuşaklı Klasik Büyük Beden Yırtmaçlı Yelek",
      description: "Elegant Çiçek Işli Kuşaklı Klasik Büyük Beden Yırtmaçlı Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Elegant Çiçek İşli Kuşaklı Klasik Büyük Beden Yırtmaçlı Yelek, Klasik üründür, İçine gömlekler rahatlıkla şık yerlerde kullanıma uygundur."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 306951985,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty589/product/media/images/20221105/15/208204143/614580578/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty589/product/media/images/20221105/15/208204143/614580578/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty588/product/media/images/20221105/15/208204143/614580578/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty589/product/media/images/20221105/15/208204143/614580578/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty589/product/media/images/20221105/15/208204143/614580578/4/4_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 449.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 614600224,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 900,
          key: "Cep Sayısı",
          value: "2"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536614,
      productCode: "TS-1756",
      name: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      slug: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Elegant Klasik Elbise, Kolları rahat kesimdir. %100 pamuktur..."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 232887894,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/18/204343204/610470400/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/18/204343204/610470400/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/15/205084385/610562856/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205084396/610562856/2/2_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "PASTEL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 610563000,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377536211,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204659237/610469989/1/1_org_zoom.jpg",
          name: "TURUNCU DESENLİ",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536614,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/18/204343204/610470400/1/1_org_zoom.jpg",
          name: "PASTEL",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536364,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/10/204659570/610470144/1/1_org_zoom.jpg",
          name: "SİYAH DESENLİ",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536201,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204658869/610469979/1/1_org_zoom.jpg",
          name: "YEŞİL TROPİKAL",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536364,
      productCode: "TS-1756",
      name: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      slug: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Elegant Klasik Elbise, Kolları rahat kesimdir. %100 pamuktur."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 232887894,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/10/204659570/610470144/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/10/204659570/610470144/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/15/205083274/610470144/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/15/205083276/610470144/2/2_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "SİYAH DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 610979981,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377536211,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204659237/610469989/1/1_org_zoom.jpg",
          name: "TURUNCU DESENLİ",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536614,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/18/204343204/610470400/1/1_org_zoom.jpg",
          name: "PASTEL",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536364,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/10/204659570/610470144/1/1_org_zoom.jpg",
          name: "SİYAH DESENLİ",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536201,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204658869/610469979/1/1_org_zoom.jpg",
          name: "YEŞİL TROPİKAL",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536211,
      productCode: "TS-1756",
      name: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      slug: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Elegant Klasik Elbise, Kolları rahat kesimdir. %100 pamuktur."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 232887894,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204659237/610469989/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204659237/610469989/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205084616/610981084/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205084641/610981084/2/2_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "TURUNCU DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 610981076,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377536211,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204659237/610469989/1/1_org_zoom.jpg",
          name: "TURUNCU DESENLİ",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536614,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/18/204343204/610470400/1/1_org_zoom.jpg",
          name: "PASTEL",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536364,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/10/204659570/610470144/1/1_org_zoom.jpg",
          name: "SİYAH DESENLİ",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536201,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204658869/610469979/1/1_org_zoom.jpg",
          name: "YEŞİL TROPİKAL",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536201,
      productCode: "TS-1756",
      name: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      slug: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Elegant Klasik Elbise, Kolları rahat kesimdir. %100 pamuktur."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 232887894,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204658869/610469979/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204658869/610469979/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/15/205085874/610979428/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205085868/610979428/2/2_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "YEŞİL TROPİKAL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 610979428,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377536211,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204659237/610469989/1/1_org_zoom.jpg",
          name: "TURUNCU DESENLİ",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536614,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/18/204343204/610470400/1/1_org_zoom.jpg",
          name: "PASTEL",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536364,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/10/204659570/610470144/1/1_org_zoom.jpg",
          name: "SİYAH DESENLİ",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        },
        {
          id: 377536201,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204658869/610469979/1/1_org_zoom.jpg",
          name: "YEŞİL TROPİKAL",
          description: "%100 Pamuklu Uzun Kol Dijital Desenli Cepli Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377534461,
      productCode: "TS-1755-25",
      name: "%100 Pamuklu Dokuma Çiçek Desenli Hatlara Yapışmayan Büyük Beden Dökümlü Klasik Elbise",
      slug: "%100 Pamuklu Dokuma Çiçek Desenli Hatlara Yapışmayan Büyük Beden Dökümlü Klasik Elbise",
      description: "%100 Pamuklu Dokuma Çiçek Desenli Hatlara Yapışmayan Büyük Beden Dökümlü Klasik Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve yarım kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz.,%100 dokuma pamuk, ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız.,"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885742,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204654902/610468000/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204654902/610468000/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/15/205086355/610468000/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/15/205086370/610468000/2/2_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "EKRU DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 610975970,
          stock: 10.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377534384,
      productCode: "TS-1755-24",
      name: "%100 Pamuklu Dokuma Çiçek Desenli Hatlara Yapışmayan Büyük Beden Dökümlü Klasik Elbise",
      slug: "%100 Pamuklu Dokuma Çiçek Desenli Hatlara Yapışmayan Büyük Beden Dökümlü Klasik Elbise",
      description: "%100 Pamuklu Dokuma Çiçek Desenli Hatlara Yapışmayan Büyük Beden Dökümlü Klasik Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve yarım kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz.,%100 dokuma pamuk, ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız.,"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885669,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/11/204876015/610467918/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/11/204876015/610467918/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/11/204876015/610467918/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/11/204876015/610467918/3/3_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "KIRMIZI BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 611045562,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320954993,
      productCode: "TS-EL00300",
      name: "Ip Askılı Renkli Çiçek Desenli %100 Pamuk Büyük Beden Elbise",
      slug: "Ip Askılı Renkli Çiçek Desenli %100 Pamuk Büyük Beden Elbise",
      description: "Ip Askılı Renkli Çiçek Desenli %100 Pamuk Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "İp Askılı Elbise, Ürün %100 Pamuk kumaştır,Hamile Bayanların kilo alıp verme durumları içinde oldukça kullanışlı bir elbisedir. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265366987,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134212156/512222526/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134212156/512222526/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134212156/512222526/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134212156/512222526/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134212156/512222526/4/4_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512222526,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Askılı"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Bağlamalı"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 248211850,
      productCode: "TS-S106",
      name: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      slug: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Büyük Beden Krep Gömlek., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 215545580,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/16/58008014/397145391/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/16/58008014/397145391/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty344/product/media/images/20220224/16/58008014/397145391/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty344/product/media/images/20220224/16/58008014/397145391/3/3_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "LACİ-LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 397158123,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 290626931,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/5/100830274/464302737/1/1_org_zoom.jpg",
          name: "KARELİ",
          description: "Kare Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 322662535,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/17/136162149/515551553/1/1_org_zoom.jpg",
          name: "LACİ-DESENLİ",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 248211850,
          imageUrl: "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/16/58008014/397145391/1/1_org_zoom.jpg",
          name: "LACİ-LEOPAR",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 679431082,
          imageUrl: "https://cdn.dsmcdn.com/ty802/product/media/images/20230328/18/314383428/898809554/1/1_org_zoom.jpg",
          name: "KAZAYAĞI-KAHVE",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Kumaş"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 280918211,
      productCode: "TS-1703-81",
      name: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankendeki beden 44 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 237230883,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty396/product/media/images/20220413/19/90371949/447410313/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty396/product/media/images/20220413/19/90371949/447410313/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/19/90371949/447410313/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty397/product/media/images/20220413/19/90371949/447410313/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/19/90371949/447410313/4/4_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "ÇAĞLA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 447441933,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122667558,
      productCode: "TS-1722-1",
      name: "Kadın Palmiye Desenli Dijital Büzgülü Büyük Beden Elbise Tunik",
      slug: "Kadın Palmiye Desenli Dijital Büzgülü Büyük Beden Elbise Tunik",
      description: "Kadın Palmiye Desenli Dijital Büzgülü Büyük Beden Elbise Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 108004588,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/11/107195234/197964629/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/11/107195234/197964629/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty140/product/media/images/20210705/11/107195234/197964629/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/11/107195234/197964629/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 349.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 2.0
      },
      winnerVariant: "44",
      color: "GÜMÜŞ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 197979743,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 122288523,
          comment: "elbise çok güzel tiril tiril tam yazlık. Çok da şık.",
          rate: 5.0,
          userFullName: "Ozge K.",
          trusted: true,
          commentDateISOtype: "2021-08-12",
          lastModifiedDate: "12 Ağustos 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Kayık Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Gümüş"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290605432,
      productCode: "TS-1703-84",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 245144054,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100794337/464263169/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100794337/464263169/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100794337/464263169/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.4,
        totalCount: 5.0
      },
      winnerVariant: "46",
      color: "EKRU DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 464267528,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 209340160,
          comment: "Hediye olarak almıştım çok memnun olduğuna dair geri dönüş oldu 👍",
          rate: 4.0,
          userFullName: "s** k**",
          trusted: true,
          commentDateISOtype: "2022-09-18",
          lastModifiedDate: "18 Eylül 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 280713296,
      productCode: "TS-1746",
      name: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Empirme Desenli Sandy Kumaş Elbise, Kırışma zor olmaz, Çamaşır makinesinde, düşük derece ayarında yıkanabilir, Sağlam bir kumaş çeşididir, Renkleri çok canlı göstermesinden ötürü, oldukça şık görünmektedir ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., Mankenin üzerindeki 44 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 236618738,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90149636/446989253/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90149636/446989253/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty396/product/media/images/20220413/12/90149636/446989253/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty396/product/media/images/20220413/12/90149636/446989253/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 158.39,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "MİNT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 446989253,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 280713296,
          imageUrl: "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90149636/446989253/1/1_org_zoom.jpg",
          name: "MİNT",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280707228,
          imageUrl: "https://cdn.dsmcdn.com/ty397/product/media/images/20220413/12/90143589/446981850/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280715118,
          imageUrl: "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90151360/446991381/1/1_org_zoom.jpg",
          name: "HAKİ SİYAH",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280703511,
          imageUrl: "https://cdn.dsmcdn.com/ty484/product/media/images/20220720/0/146021726/447043321/1/1_org_zoom.jpg",
          name: "HELEGRAN EKRU",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 280703511,
      productCode: "TS-1746",
      name: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Empirme Desenli Sandy Kumaş Elbise, Kırışma zor olmaz, Çamaşır makinesinde, düşük derece ayarında yıkanabilir, Sağlam bir kumaş çeşididir, Renkleri çok canlı göstermesinden ötürü, oldukça şık görünmektedir ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., Mankenin üzerindeki 44 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 236618738,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty484/product/media/images/20220720/0/146021726/447043321/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty484/product/media/images/20220720/0/146021726/447043321/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty485/product/media/images/20220720/0/146021726/447043321/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty485/product/media/images/20220720/0/146021726/447043321/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "46",
      color: "HELEGRAN EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 447043321,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 217418811,
          comment: "kumaşı çok güzel yengem için aldım çok begendi😇",
          rate: 5.0,
          userFullName: "S** K**",
          trusted: true,
          commentDateISOtype: "2022-10-13",
          lastModifiedDate: "13 Ekim 2022"
        }
      ],
      colors: [
        {
          id: 280713296,
          imageUrl: "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90149636/446989253/1/1_org_zoom.jpg",
          name: "MİNT",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280707228,
          imageUrl: "https://cdn.dsmcdn.com/ty397/product/media/images/20220413/12/90143589/446981850/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280715118,
          imageUrl: "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90151360/446991381/1/1_org_zoom.jpg",
          name: "HAKİ SİYAH",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280703511,
          imageUrl: "https://cdn.dsmcdn.com/ty484/product/media/images/20220720/0/146021726/447043321/1/1_org_zoom.jpg",
          name: "HELEGRAN EKRU",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 125994259,
      productCode: "TS-1721",
      name: "V Yaka Taşlı Batik Büyük Beden Elbise",
      slug: "V Yaka Taşlı Batik Büyük Beden Elbise",
      description: "V Yaka Taşlı Batik Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 110847082,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/16/111494501/205819107/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/16/111494501/205819107/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/16/111494501/205819107/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/16/111494501/205819107/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 179.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 205839881,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48204489,
      productCode: "TS-1703-1",
      name: "Kadın Siyah  Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Siyah  Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Siyah  Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli pamuklu dokuma kolları ve eteği ponponlu büyük beden elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten,"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 40512063,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200908/18/10097618/85301288/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200908/18/10097618/85301288/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200908/18/10097618/85301288/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200908/18/10097618/85301288/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200908/18/10097618/85301288/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200908/18/10097618/85301288/5/5_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "44",
      color: "Siyah",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 85311071,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 121158033,
          comment: "bi beden küçük alınmalı kumaş ve model kalitesi çok güzel ama bana kendi bedenimde sipariş ettiğim ürün  çok büyük geldi iade edeceğim o yüzden",
          rate: 4.0,
          userFullName: "H** K**",
          trusted: true,
          commentDateISOtype: "2021-08-04",
          lastModifiedDate: "4 Ağustos 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366489,
      productCode: "TS-1763",
      name: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      slug: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      description: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239118624,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949036/450750746/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949036/450750746/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949036/450750746/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949036/450750746/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "KIRMIZI ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 450750746,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283367881,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92950618/450753292/1/1_org_zoom.jpg",
          name: "ETNİK",
          description: "Pamuklu Etnik Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 283374034,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/15/92957642/450763118/1/1_org_zoom.jpg",
          name: "KİLİM",
          description: "Pamuklu Kilim Desenli Çıtır Çiçekli Dokuma Geniş Kesim Gömlek"
        },
        {
          id: 283366489,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949036/450750746/1/1_org_zoom.jpg",
          name: "KIRMIZI ÇİÇEKLİ",
          description: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 376534851,
      productCode: "TS-L661",
      name: "Elegant Taş Işli Tüy Desenli Klasik Büyük Beden Gömlek",
      slug: "Elegant Taş Işli Tüy Desenli Klasik Büyük Beden Gömlek",
      description: "Elegant Taş Işli Tüy Desenli Klasik Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 304022539,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/0/203217266/608471716/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/0/203217266/608471716/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/0/203217266/608471716/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/0/203217266/608471716/3/3_org_zoom.jpg"
      ],
      price: 429.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 429.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 429.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "beyaz",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 608480704,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 376534851,
          imageUrl: "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/0/203217266/608471716/1/1_org_zoom.jpg",
          name: "beyaz",
          description: "Elegant Taş Işli Tüy Desenli Klasik Büyük Beden Gömlek"
        },
        {
          id: 376533485,
          imageUrl: "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/0/203215826/608469571/1/1_org_zoom.jpg",
          name: "siyah",
          description: "Elegant Taş Işli Tüy Desenli Klasik Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 376533485,
      productCode: "TS-L661",
      name: "Elegant Taş Işli Tüy Desenli Klasik Büyük Beden Gömlek",
      slug: "Elegant Taş Işli Tüy Desenli Klasik Büyük Beden Gömlek",
      description: "Elegant Taş Işli Tüy Desenli Klasik Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 304022539,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/0/203215826/608469571/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/0/203215826/608469571/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/0/203215826/608469571/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/0/203215826/608469571/3/3_org_zoom.jpg"
      ],
      price: 429.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 429.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 429.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "44",
      color: "siyah",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 608478345,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 279039244,
          comment: "Çok çokk iyi geldi annem beğendii",
          rate: 5.0,
          userFullName: "H** N** Ö**",
          trusted: true,
          commentDateISOtype: "2023-03-16",
          lastModifiedDate: "16 Mart 2023"
        }
      ],
      colors: [
        {
          id: 376534851,
          imageUrl: "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/0/203217266/608471716/1/1_org_zoom.jpg",
          name: "beyaz",
          description: "Elegant Taş Işli Tüy Desenli Klasik Büyük Beden Gömlek"
        },
        {
          id: 376533485,
          imageUrl: "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/0/203215826/608469571/1/1_org_zoom.jpg",
          name: "siyah",
          description: "Elegant Taş Işli Tüy Desenli Klasik Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126238596,
      productCode: "TS-1735-2",
      name: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon",
      slug: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon",
      description: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Viskon Büyük Beden Havuç Pantolon, Ürünün ön kısmı büzgülüdür, Bel kısmı lastiklidir, Ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 91254304,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/17/111770312/206960635/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/17/111770312/206960635/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/17/111770312/206960635/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/17/111770312/206960635/3/3_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 3.0
      },
      winnerVariant: "42",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 206987202,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 187191371,
          comment: "Mükemmel çok rahat şık kumaşi çok kaliteli daha ne olsun , tam bedeninizi alın",
          rate: 5.0,
          userFullName: "Nalan A. G.",
          trusted: true,
          commentDateISOtype: "2022-06-13",
          lastModifiedDate: "13 Haziran 2022"
        },
        {
          id: 197522214,
          comment: "aldim sonra iade ettim,cok bol oldu durushunuda beyenmedim",
          rate: 2.0,
          userFullName: "I** E**",
          trusted: true,
          commentDateISOtype: "2022-08-09",
          lastModifiedDate: "9 Ağustos 2022"
        }
      ],
      colors: [
        {
          id: 126238596,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/17/111770312/206960635/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon"
        },
        {
          id: 104559503,
          imageUrl: "https://cdn.dsmcdn.com/ty112/product/media/images/20210510/9/87112743/172601653/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Çiçek Desenli Viskon Büyük Beden Havuç Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Normal Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Kısa Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292144760,
      productCode: "TS-A193",
      name: "V Yaka Taş Detaylı Çiçek Desenli Büyük Beden Bluz",
      slug: "V Yaka Taş Detaylı Çiçek Desenli Büyük Beden Bluz",
      description: "V Yaka Taş Detaylı Çiçek Desenli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "V yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246428621,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102418822/466885163/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102418822/466885163/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102418822/466885163/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102418822/466885163/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466896908,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292141676,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102415607/466876384/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292143363,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417522/466880801/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144760,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102418822/466885163/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "V Yaka Taş Detaylı Çiçek Desenli Büyük Beden Bluz"
        },
        {
          id: 292143296,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417451/466880655/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292144289,
      productCode: "TS-A190",
      name: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246429992,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418454/466883513/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418454/466883513/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418454/466883513/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418454/466883513/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466894779,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292145211,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102419274/466885937/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Büyük Beden Bluz"
        },
        {
          id: 292143122,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417287/466880401/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292143524,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417651/466882032/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144289,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418454/466883513/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 704537733,
      productCode: "TS-M254",
      name: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      slug: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      description: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Elegant Pamuklu Desenli Büyük Beden Geniş Kalıp Kalçayı Kapatan Rahat Kesim Gömlek, Ürün Dökümlüdür. Kalçayı Kapatır. Taytlar ve pantolanlarla rahatlıkla kullanılabilir. Kumaş pamukludur. Dijital Baskıdır. Tam kalıptır."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 541830849,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty870/product/media/images/20230508/21/342996200/931163370/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty870/product/media/images/20230508/21/342996200/931163370/1/1_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "GRİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 931168576,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 304145073,
          comment: "Kumaşı beğendim geniş kalıp bi beden küçük alınabilir ama biz değiştirmedik salaş kullanıcaz",
          rate: 4.0,
          userFullName: "Ş** H**",
          trusted: true,
          commentDateISOtype: "2023-05-14",
          lastModifiedDate: "14 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 704536606,
          imageUrl: "https://cdn.dsmcdn.com/ty870/product/media/images/20230508/21/342995086/931161751/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek"
        },
        {
          id: 704537733,
          imageUrl: "https://cdn.dsmcdn.com/ty870/product/media/images/20230508/21/342996200/931163370/1/1_org_zoom.jpg",
          name: "GRİ",
          description: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Katlamalı"
        },
        {
          id: 33,
          key: "Desen",
          value: "Leopar"
        },
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "İç Göstermeyen"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470072,
      productCode: "TS-L641",
      name: "Çiçek Nakışlı %100 Pamuklu Büyük Beden Gömlek",
      slug: "Çiçek Nakışlı %100 Pamuklu Büyük Beden Gömlek",
      description: "Çiçek Nakışlı %100 Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458638,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896353/515255093/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896353/515255093/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896353/515255093/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896353/515255093/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 515255093,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 376616339,
          imageUrl: "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/10/203307571/608586045/1/1_org_zoom.jpg",
          name: "ETNİK-SARI",
          description: "Çiçek Nakışlı Etnik Büyük Beden Gömlek"
        },
        {
          id: 322470072,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896353/515255093/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Çiçek Nakışlı %100 Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 376534345,
          imageUrl: "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/0/203216739/608470903/1/1_org_zoom.jpg",
          name: "ETNİK-KIRMIZI",
          description: "Çiçek Nakışlı Etnik Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321802390,
      productCode: "TS-A108",
      name: "Omuz Detaylı Kısa Kol Armani Büyük Beden Şık Bluz",
      slug: "Omuz Detaylı Kısa Kol Armani Büyük Beden Şık Bluz",
      description: "Omuz Detaylı Kısa Kol Armani Büyük Beden Şık Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265945150,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135126745/513625560/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135126745/513625560/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135126745/513625560/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135126745/513625560/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 513625560,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322663825,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/18/136164232/515554721/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Omuz Detaylı Kısa Kol Büyük Beden Şık Bluz"
        },
        {
          id: 321802390,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135126745/513625560/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Detaylı Kısa Kol Armani Büyük Beden Şık Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320955233,
      productCode: "TS-EL00464",
      name: "Ip Askılı Renkli Şifon Iç Göstermeyen Büyük Beden Elbise",
      slug: "Ip Askılı Renkli Şifon Iç Göstermeyen Büyük Beden Elbise",
      description: "Ip Askılı Renkli Şifon Iç Göstermeyen Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "İp Askılı Elbise, Ürün Şifon kumaştır, Hamile Bayanların kilo alıp verme durumları içinde oldukça kullanışlı bir elbisedir. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265367137,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134212398/512223193/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134212398/512223193/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134212398/512223193/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134212398/512223193/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "LACİ-ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512223193,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Şifon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Askılı"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Şifon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Bağlamalı"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290797524,
      productCode: "TS-A255",
      name: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      slug: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 245265116,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/17/101015829/464566695/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/17/101015829/464566695/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/17/101015829/464566695/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220502/17/101015829/464566695/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 464566695,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 290745531,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/10/100959796/464484934/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290745483,
          imageUrl: "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/10/100959746/464484846/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290776359,
          imageUrl: "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/15/100991581/464532659/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290797524,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/17/101015829/464566695/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290776359,
      productCode: "TS-A255",
      name: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      slug: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 245265116,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/15/100991581/464532659/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/15/100991581/464532659/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/15/100991581/464532659/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/15/100991581/464532659/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 464544450,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 290745531,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/10/100959796/464484934/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290745483,
          imageUrl: "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/10/100959746/464484846/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290776359,
          imageUrl: "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/15/100991581/464532659/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290797524,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/17/101015829/464566695/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290745531,
      productCode: "TS-A255",
      name: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      slug: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 245265116,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/10/100959796/464484934/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/10/100959796/464484934/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220502/10/100959796/464484934/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220502/10/100959796/464484934/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SAFRAN",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 464484934,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 290745531,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/10/100959796/464484934/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290745483,
          imageUrl: "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/10/100959746/464484846/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290776359,
          imageUrl: "https://cdn.dsmcdn.com/ty415/product/media/images/20220502/15/100991581/464532659/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        },
        {
          id: 290797524,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220502/17/101015829/464566695/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Pul Işi Detaylı Yarasa Kol Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283364867,
      productCode: "TS-7186",
      name: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      slug: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      description: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117204,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947060/450748101/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947060/450748101/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947060/450748101/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450748101,
          stock: 10.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 280707228,
      productCode: "TS-1746",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Önden Düğmeli Pamuklu Dokuma Elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz.,Ürünümüz viskon kumaştır, ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., Mankenin üzerindeki 44 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 236618738,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty397/product/media/images/20220413/12/90143589/446981850/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty397/product/media/images/20220413/12/90143589/446981850/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90143589/446981850/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty396/product/media/images/20220413/12/90143589/446981850/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "46",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 447050159,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 201776827,
          comment: "1.56 boy 88 kıloyum 48 beden aldım tam oldu. Kumaşı güzel yumuşak. Bir puanı fiyatından kırdım. Max 180tl olur fazlası etmez",
          rate: 4.0,
          userFullName: "B** K** E**",
          trusted: true,
          commentDateISOtype: "2022-08-24",
          lastModifiedDate: "24 Ağustos 2022"
        }
      ],
      colors: [
        {
          id: 280713296,
          imageUrl: "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90149636/446989253/1/1_org_zoom.jpg",
          name: "MİNT",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280707228,
          imageUrl: "https://cdn.dsmcdn.com/ty397/product/media/images/20220413/12/90143589/446981850/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280715118,
          imageUrl: "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90151360/446991381/1/1_org_zoom.jpg",
          name: "HAKİ SİYAH",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280703511,
          imageUrl: "https://cdn.dsmcdn.com/ty484/product/media/images/20220720/0/146021726/447043321/1/1_org_zoom.jpg",
          name: "HELEGRAN EKRU",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140036779,
      productCode: "TS-7172",
      name: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon gömlek. Viskon kumaş. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 40 bedendir. Modelin ölçüleri: Boy: 1.76, göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121220356,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty212/product/media/images/20211027/21/158026132/238301830/2/2_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty212/product/media/images/20211027/21/158026132/238301830/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty211/product/media/images/20211027/21/158026132/238301830/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty210/product/media/images/20211027/21/158026132/238301830/4/4_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 254.66,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "40",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 238301842,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 162115831,
          comment: "Ayni gorundugu gibi, modeli salaw annem 48 giyir ama 46 bol oldu",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-02-18",
          lastModifiedDate: "18 Şubat 2022"
        },
        {
          id: 306910854,
          comment: "Renkleri canlı fotoğrafın aynısı tril tril bir gömlek ama tombiş minyonlara boyu çok uzun. Uzun boylu hatunlar alın kaliteyi çok seveceksiniz.",
          rate: 5.0,
          userFullName: "A** Y**",
          trusted: true,
          commentDateISOtype: "2023-05-22",
          lastModifiedDate: "22 Mayıs 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 139931262,
      productCode: "TS-7161",
      name: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon gömlek., %100 viskon kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121153912,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/12/126731367/238007223/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/12/126731367/238007223/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/12/126731367/238007223/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/12/126731367/238007223/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 2.0,
      ratingScore: {
        averageRating: 2.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "SİYAH DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 238093399,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138327491,
      productCode: "TS-1702-18",
      name: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kadın Çiçek Desenli Kolları Lastikli Dokuma Pamuklu Büyük Beden Elbise., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamad"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120192769,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125068536/236145159/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125068536/236145159/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125068536/236145159/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125068536/236145159/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.71428571428571,
        totalCount: 7.0
      },
      winnerVariant: "42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 236162254,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 205140310,
          comment: "Muhteşem oldu üzerime.. herkese tavsiye ederim çok rahat bir elbise",
          rate: 5.0,
          userFullName: "N** K**",
          trusted: true,
          commentDateISOtype: "2022-09-07",
          lastModifiedDate: "7 Eylül 2022"
        },
        {
          id: 245983820,
          comment: "Anneme hediye aldım bayıldı, görseldeki gibi geldi",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-12-26",
          lastModifiedDate: "26 Aralık 2022"
        },
        {
          id: 203243792,
          comment: "satıcı çok saçma onu uzun arkası kısa elbiseyi iadesini kabul etmedi uğraşmak istemedim sakın almayın",
          rate: 1.0,
          userFullName: "Serap Y.",
          trusted: true,
          commentDateISOtype: "2022-08-30",
          lastModifiedDate: "30 Ağustos 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138325041,
      productCode: "TS-1703-57",
      name: "Kadın Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Pamuklu Dokuma Kolları ve Eteği Ponponlu Büyük Beden Elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten,"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120190928,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054718/236139912/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054718/236139912/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125054718/236139912/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125054718/236139912/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 2.0
      },
      winnerVariant: "46",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 236150474,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122667506,
      productCode: "TS-1702-10",
      name: "Kadın Desenli Önü Düğmeli Ve Cep Detaylı Viskon Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Önü Düğmeli Ve Cep Detaylı Viskon Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Önü Düğmeli Ve Cep Detaylı Viskon Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Büyük Beden Elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin üzerindeki ürün 46 bedendi"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 108004537,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/11/107195172/197964564/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/11/107195172/197964564/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty140/product/media/images/20210705/11/107195172/197964564/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210705/11/107195172/197964564/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 0.0,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.66666666666667,
        totalCount: 6.0
      },
      winnerVariant: "44",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 197979547,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 117678838,
          comment: "kalıbı çok dar normal bedeninizden büyük alın",
          rate: 2.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-07-07",
          lastModifiedDate: "7 Temmuz 2021"
        },
        {
          id: 121123023,
          comment: "Ürün güzel fakat büyük beden tercih edilmeli  iade etmek zorunda kaldık",
          rate: 3.0,
          userFullName: "H** C** A**",
          trusted: true,
          commentDateISOtype: "2021-08-04",
          lastModifiedDate: "4 Ağustos 2021"
        },
        {
          id: 125243378,
          comment: "sepetime 52 beden yazdığım halde 44 bede n geldi .Modeli beğendiğim halde iade edeceğim.",
          rate: 2.0,
          userFullName: "Remziye E.",
          trusted: true,
          commentDateISOtype: "2021-09-01",
          lastModifiedDate: "1 Eylül 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122456986,
      productCode: "TS-1703-21",
      name: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çiçek Desenli Önden Düğmeli Pamuklu Dokuma Büyük Beden Elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassa"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 107835324,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928466/197673807/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928466/197673807/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928466/197673807/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty140/product/media/images/20210703/16/106928466/197673807/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 197685482,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 190892167,
          comment: "ev içi kullanım için güzel",
          rate: 3.0,
          userFullName: "G** D**",
          trusted: true,
          commentDateISOtype: "2022-07-01",
          lastModifiedDate: "1 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122456970,
      productCode: "TS-1703-51",
      name: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Battal Elbise",
      slug: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Battal Elbise",
      description: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Battal Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Pamuklu Dokuma Kolları ve Eteği Ponponlu Büyük Beden Elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten,"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 107835308,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty138/product/media/images/20210703/16/106928453/197673756/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty138/product/media/images/20210703/16/106928453/197673756/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928453/197673756/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty138/product/media/images/20210703/16/106928453/197673756/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 4.0
      },
      winnerVariant: "52",
      color: "KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "52",
          itemNumber: 197685424,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 179857251,
          comment: "Güzel bir elbise teşekkür ederim",
          rate: 5.0,
          userFullName: "S** B**",
          trusted: true,
          commentDateISOtype: "2022-05-07",
          lastModifiedDate: "7 Mayıs 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 102276458,
      productCode: "TS-1703-44",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 89167237,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty108/product/media/images/20210501/19/84461386/169401558/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210501/19/84461386/169401558/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty110/product/media/images/20210501/19/84461386/169401558/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty110/product/media/images/20210501/19/84461386/169401558/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 0.0,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "NAR ÇİÇEĞİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 169406347,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95363869,
      productCode: "TS-7127",
      name: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558064,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty35/product/media/images/20210331/3/76483946/158956731/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty35/product/media/images/20210331/3/76483946/158956731/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty45/product/media/images/20210331/3/76483946/158956731/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty48/product/media/images/20210331/3/76483946/158956731/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 332.74,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "3XL",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "3XL",
          itemNumber: 158963908,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 157352955,
          comment: "cok cok guzel",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-01-31",
          lastModifiedDate: "31 Ocak 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 177427080,
      productCode: "TS-E0018",
      name: "Piti Kare Ekose Desenli Triko Örme Beli Lastikli Büyük Beden Anne Etek",
      slug: "Piti Kare Ekose Desenli Triko Örme Beli Lastikli Büyük Beden Anne Etek",
      description: "Piti Kare Ekose Desenli Triko Örme Beli Lastikli Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Beli Lastikli Parçalı Dokuma Büyük Beden Etek, Ürünün kumaşı örme trikodur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44-46 bedendir. Beden ölçüleri: en:56 cm – boy: 100 cm’dir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 151982882,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242279737/289166926/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242279737/289166926/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty656/product/media/images/20221220/16/242279737/289166926/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242279737/289166926/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 289182601,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 171746056,
          comment: "güzel etek kalınlığı da iyi",
          rate: 4.0,
          userFullName: "n** g**",
          trusted: true,
          commentDateISOtype: "2022-04-01",
          lastModifiedDate: "1 Nisan 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 177427007,
      productCode: "TS-E0019",
      name: "Ekose Desenli Triko Örme Beli Lastikli Büyük Beden Anne Etek",
      slug: "Ekose Desenli Triko Örme Beli Lastikli Büyük Beden Anne Etek",
      description: "Ekose Desenli Triko Örme Beli Lastikli Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Beli Lastikli Parçalı Dokuma Büyük Beden Etek, Ürünün kumaşı örme trikodur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44-46 bedendir. Beden ölçüleri: en:56 cm – boy: 100 cm’dir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 151982823,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty336/product/media/images/20220220/20/54190842/289182460/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty336/product/media/images/20220220/20/54190842/289182460/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty338/product/media/images/20220220/20/54190842/289182460/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty336/product/media/images/20220220/20/54190842/289182460/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.4,
        totalCount: 5.0
      },
      winnerVariant: "48-50",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48-50",
          itemNumber: 289182469,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 152157386,
          comment: "Valide hanıma hediye almıştım. Çok beğendi",
          rate: 5.0,
          userFullName: "O** K** A**",
          trusted: true,
          commentDateISOtype: "2022-01-10",
          lastModifiedDate: "10 Ocak 2022"
        },
        {
          id: 160940955,
          comment: "Çok kaliteli değil, bu fiyata değmez",
          rate: 3.0,
          userFullName: "e** g**",
          trusted: true,
          commentDateISOtype: "2022-02-14",
          lastModifiedDate: "14 Şubat 2022"
        },
        {
          id: 163822000,
          comment: "göründüğü gibi fakat malını biz beğenmedik",
          rate: 3.0,
          userFullName: "D** B**",
          trusted: true,
          commentDateISOtype: "2022-02-25",
          lastModifiedDate: "25 Şubat 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95157911,
      productCode: "TS-E3247",
      name: "Kadın Siyah Puantiye Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      slug: "Kadın Siyah Puantiye Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      description: "Kadın Siyah Puantiye Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse puantiye desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44-46 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 82379724,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty16/product/media/images/20210330/10/76175420/158622481/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty16/product/media/images/20210330/10/76175420/158622481/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty38/product/media/images/20210330/10/76175420/158622481/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty36/product/media/images/20210330/10/76175420/158622481/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty30/product/media/images/20210330/10/76175420/158622481/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 6.0
      },
      winnerVariant: "56-58",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "56-58",
          itemNumber: 158639264,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 190374895,
          comment: "efsane guzel",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-06-28",
          lastModifiedDate: "28 Haziran 2022"
        },
        {
          id: 99804208,
          comment: "Yayımlasınlar diye 5 puan koydum yoksa reddedeceklerdi yorumumu.Görseldekinden farklı bir ürün geldi.yurt dışından sipariş ettiğim için iade edemedim.tavsiye etmiyorum.",
          rate: 5.0,
          userFullName: "T** M**",
          trusted: true,
          commentDateISOtype: "2021-04-28",
          lastModifiedDate: "28 Nisan 2021"
        },
        {
          id: 128132031,
          comment: "Etek resimdeki gibi gelmede cok kalitesiz siradan bisey .insanlari kandirmayin neyse onun resmini koyun! 5 puan vermememin sebebi yorumum basda kalsin herkes gorsun diye 👎",
          rate: 5.0,
          userFullName: "Nigar E.",
          trusted: true,
          commentDateISOtype: "2021-09-22",
          lastModifiedDate: "22 Eylül 2021"
        }
      ],
      colors: [
        {
          id: 95157410,
          imageUrl: "https://cdn.dsmcdn.com/ty46/product/media/images/20210330/10/76174738/158621769/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Puantiye Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        },
        {
          id: 95157911,
          imageUrl: "https://cdn.dsmcdn.com/ty16/product/media/images/20210330/10/76175420/158622481/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Puantiye Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95157410,
      productCode: "TS-E3247",
      name: "Kadın Lacivert Puantiye Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      slug: "Kadın Lacivert Puantiye Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      description: "Kadın Lacivert Puantiye Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse puantiye desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44-46 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 82379724,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty46/product/media/images/20210330/10/76174738/158621769/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty46/product/media/images/20210330/10/76174738/158621769/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty81/product/media/images/20210330/10/76174738/158621769/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty38/product/media/images/20210330/10/76174738/158621769/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty54/product/media/images/20210330/10/76174738/158621769/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.33333333333333,
        totalCount: 3.0
      },
      winnerVariant: "44-46",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 158642122,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 203160078,
          comment: "urun kalmadığı için bambaşka bir urun gönderilmiş fakat açıklama mektubu eklenmiş teşekkür ler sonradan gördüm fakat urun girselini gondrip bilgilendirme yaosabiz daha hos olurdu cunlu hic begendigin bir model değil malesef iade .",
          rate: 5.0,
          userFullName: "S** s**",
          trusted: true,
          commentDateISOtype: "2022-08-30",
          lastModifiedDate: "30 Ağustos 2022"
        },
        {
          id: 113339437,
          comment: "ürünün beli lastikli puantiyeleri makinada yikandigi zaman hepsi sokuluyor dikkat etmek lazim, elde yikayabilirsiniz ote yandan her sey gorundugu gibi ve kaliteli de",
          rate: 5.0,
          userFullName: "c** B**",
          trusted: true,
          commentDateISOtype: "2021-06-18",
          lastModifiedDate: "18 Haziran 2021"
        }
      ],
      colors: [
        {
          id: 95157410,
          imageUrl: "https://cdn.dsmcdn.com/ty46/product/media/images/20210330/10/76174738/158621769/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Puantiye Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        },
        {
          id: 95157911,
          imageUrl: "https://cdn.dsmcdn.com/ty16/product/media/images/20210330/10/76175420/158622481/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Puantiye Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 80296944,
      productCode: "TS-E001",
      name: "Kadın Örme Triko Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      slug: "Kadın Örme Triko Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      description: "Kadın Örme Triko Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.72 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 68847996,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/21/166437576/136043629/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/21/166437576/136043629/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/21/166437576/136043629/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty226/product/media/images/20211105/21/166437576/136043629/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty226/product/media/images/20211105/21/166437576/136043629/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 322.3,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.72727272727273,
        totalCount: 22.0
      },
      winnerVariant: "46",
      color: "DESENLİ1",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 136074918,
          stock: 6.0
        }
      ],
      productReviews: [
        {
          id: 170853186,
          comment: "Çok beğendim ..anneme almıştım kumaş harika dikişi çok güzel ..tek kelime ile mutlu olduk Trendyol alışveriş yaptığım ürünler bugüne karda hiç pişman olmadım çok hızlı ulaşım sağlanıyor ve değişim olunca hiç sorun olmuyor her şey çok rahat teşekkür ederim",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-03-28",
          lastModifiedDate: "28 Mart 2022"
        },
        {
          id: 93763844,
          comment: "Annem için aldım. Kalın, yumuşak, iç göstermeyen ve ütü istemeyen bir kumaşı var, kumaşının dikişini beğendim. Astarı yok ama astar gerektirmiyor bu konuda bi düşünceniz olmasın. Kendi bedeninizi alın. Bedende tereddüt edip satıcıya soru sor bölümünden sormuştum boy- kilo şeklinde ve doğru bir yönlendirme yaptı, oradan da sorabilirsiniz. Kargo çok çabuk elime ulaştı, satıcı da çok ilgili davrandı. Üründe çok fazla yorum olmadığı için detaylı bir yorum yazayım dedim. :)",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-03-30",
          lastModifiedDate: "30 Mart 2021"
        },
        {
          id: 119318796,
          comment: "Anneme aldik rengi, dokusu, deseni cok güzel beğendik. boyu uzun geldi biraz sadece o da kestirilir zaten.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-07-19",
          lastModifiedDate: "19 Temmuz 2021"
        }
      ],
      colors: [
        {
          id: 80296944,
          imageUrl: "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/21/166437576/136043629/1/1_org_zoom.jpg",
          name: "DESENLİ1",
          description: "Kadın Örme Triko Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        },
        {
          id: 80296892,
          imageUrl: "https://cdn.dsmcdn.com/ty224/product/media/images/20211104/23/165839194/176626210/1/1_org_zoom.jpg",
          name: "DESENLİ3",
          description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366727,
      productCode: "TS-7185",
      name: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      slug: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      description: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117348,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949290/450751088/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949290/450751088/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949290/450751088/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949290/450751088/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450758409,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366727,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949290/450751088/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        },
        {
          id: 283365044,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947230/450748297/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49628584,
      productCode: "TS-4990",
      name: "Kadın Lacivert Çiçekli Desenli Viskon Gömlek",
      slug: "Kadın Lacivert Çiçekli Desenli Viskon Gömlek",
      description: "Kadın Lacivert Çiçekli Desenli Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan desenli viskon gömlek., %100 viskon kumaş Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40938540,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/17/11426424/87933141/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/17/11426424/87933141/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/17/11426424/87933141/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/17/11426424/87933141/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/17/11426424/87933141/4/4_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 6.0
      },
      winnerVariant: "L",
      color: "LACİ ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87994629,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 104628205,
          comment: "Harika bir ürün anneler gününde aldım bayıldık tam beden alınmalı",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-05-15",
          lastModifiedDate: "15 Mayıs 2021"
        },
        {
          id: 94295086,
          comment: "Kendi bedeninizi alın. Bedeninize göre salaş güzel duruyor zaten. Rengi çok canlı, fotoğraftaki gibi. Kırışmayan yumuşak kumaş, ama naylon değil. Bu satıcının kumaşları cidden güzel ya geçen de etek aldık o da güzeldi. Fotoğraftakine göre yaka kol manşet daha güzel beğendim tavsiye ederim.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-04-01",
          lastModifiedDate: "1 Nisan 2021"
        },
        {
          id: 111759199,
          comment: "Ürün ilk yıkamada çekti. Bir beden büyük almamıza rağmen şu an küçük geliyor.",
          rate: 1.0,
          userFullName: "R** A**",
          trusted: true,
          commentDateISOtype: "2021-06-13",
          lastModifiedDate: "13 Haziran 2021"
        }
      ],
      colors: [
        {
          id: 49628584,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/17/11426424/87933141/1/1_org_zoom.jpg",
          name: "LACİ ÇİÇEKLİ",
          description: "Kadın Lacivert Çiçekli Desenli Viskon Gömlek"
        },
        {
          id: 48711220,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10594739/86355476/1/1_org_zoom.jpg",
          name: "LACİ KIRMIZI ÇİÇEK",
          description: "Kadın Lacivert Desenli Viskon Gömlek"
        },
        {
          id: 283366560,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949112/450750835/1/1_org_zoom.jpg",
          name: "BEYAZ ÇİÇEKLİ",
          description: "Renkli Çiçek Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48711220,
      productCode: "TS-4990",
      name: "Kadın Lacivert Desenli Viskon Gömlek",
      slug: "Kadın Lacivert Desenli Viskon Gömlek",
      description: "Kadın Lacivert Desenli Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Desenli Viskon gömlek."
        },
        {
          bold: false,
          description: "%100 Viskon kumaş."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40938540,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10594739/86355476/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10594739/86355476/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10594739/86355476/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10594739/86355476/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10594739/86355476/4/4_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.5,
        totalCount: 2.0
      },
      winnerVariant: "L",
      color: "LACİ KIRMIZI ÇİÇEK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 86367375,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 300618737,
          comment: "Kumaşı,duruşu çok çok güzel,çok kaliteli.Annem çok sevdi.Teşekkürler",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-05-05",
          lastModifiedDate: "5 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 49628584,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/17/11426424/87933141/1/1_org_zoom.jpg",
          name: "LACİ ÇİÇEKLİ",
          description: "Kadın Lacivert Çiçekli Desenli Viskon Gömlek"
        },
        {
          id: 48711220,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10594739/86355476/1/1_org_zoom.jpg",
          name: "LACİ KIRMIZI ÇİÇEK",
          description: "Kadın Lacivert Desenli Viskon Gömlek"
        },
        {
          id: 283366560,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949112/450750835/1/1_org_zoom.jpg",
          name: "BEYAZ ÇİÇEKLİ",
          description: "Renkli Çiçek Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377608862,
      productCode: "TS-1681",
      name: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      slug: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885680,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/19/204417643/610547394/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/19/204417643/610547394/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/15/205086120/610547394/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/15/205086133/610547394/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 610547394,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377536790,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/18/204343374/610470588/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377608862,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/19/204417643/610547394/1/1_org_zoom.jpg",
          name: "DESENLİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377534395,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/8/204609508/610467926/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536620,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221031/18/204343213/610470406/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536308,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/8/204614328/610470085/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli %100 Pamuk Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536312,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204657000/610470092/1/1_org_zoom.jpg",
          name: "SİYAH ZİKZAG",
          description: "Zikzag Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 48,
          key: "Boy",
          value: "Maxi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536790,
      productCode: "TS-1681",
      name: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      slug: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve Uzun Kol, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885680,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/18/204343374/610470588/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/18/204343374/610470588/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205086285/610470588/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205086283/610470588/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 610562997,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377536790,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/18/204343374/610470588/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377608862,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/19/204417643/610547394/1/1_org_zoom.jpg",
          name: "DESENLİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377534395,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/8/204609508/610467926/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536620,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221031/18/204343213/610470406/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536308,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/8/204614328/610470085/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli %100 Pamuk Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536312,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204657000/610470092/1/1_org_zoom.jpg",
          name: "SİYAH ZİKZAG",
          description: "Zikzag Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 48,
          key: "Boy",
          value: "Maxi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377536312,
      productCode: "TS-1681",
      name: "Zikzag Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      slug: "Zikzag Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      description: "Zikzag Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve Uzun Kol, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885680,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204657000/610470092/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204657000/610470092/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205084276/610978991/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/15/205084281/610978991/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/15/205084292/610978991/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "SİYAH ZİKZAG",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 610978991,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377536790,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/18/204343374/610470588/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377608862,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/19/204417643/610547394/1/1_org_zoom.jpg",
          name: "DESENLİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377534395,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/8/204609508/610467926/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536620,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221031/18/204343213/610470406/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536308,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/8/204614328/610470085/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli %100 Pamuk Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536312,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204657000/610470092/1/1_org_zoom.jpg",
          name: "SİYAH ZİKZAG",
          description: "Zikzag Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 48,
          key: "Boy",
          value: "Maxi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377534395,
      productCode: "TS-1681",
      name: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      slug: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve Uzun Kol, maxi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız.."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 304885680,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/8/204609508/610467926/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/8/204609508/610467926/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/15/205083477/610467926/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/15/205083481/610467926/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 610467926,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377536790,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/18/204343374/610470588/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377608862,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221031/19/204417643/610547394/1/1_org_zoom.jpg",
          name: "DESENLİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377534395,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221101/8/204609508/610467926/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536620,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221031/18/204343213/610470406/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536308,
          imageUrl: "https://cdn.dsmcdn.com/ty583/product/media/images/20221101/8/204614328/610470085/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli %100 Pamuk Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        },
        {
          id: 377536312,
          imageUrl: "https://cdn.dsmcdn.com/ty582/product/media/images/20221101/10/204657000/610470092/1/1_org_zoom.jpg",
          name: "SİYAH ZİKZAG",
          description: "Zikzag Desenli Sandy Kumaş Dökümlü Yapışma Yapmayan Dijital Desen Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yakasız"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 48,
          key: "Boy",
          value: "Maxi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 342172139,
      productCode: "TS-1684",
      name: "Empirme Desenli Düğmeli %100 Pamuk Büyük Beden Tunik",
      slug: "Empirme Desenli Düğmeli %100 Pamuk Büyük Beden Tunik",
      description: "Empirme Desenli Düğmeli %100 Pamuk Büyük Beden Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli pamuklu büyük beden Tunik., %100 cotton kumaş., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 278895000,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty511/product/media/images/20220817/22/161824495/546411801/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty511/product/media/images/20220817/22/161824495/546411801/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty511/product/media/images/20220817/22/161824495/546411801/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 546411801,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Gömlek Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322554932,
      productCode: "TS-7216",
      name: "Kadın %100 Pamuklu Tavuskuşu Desenli Pamuklu Büyük Beden Gömlek",
      slug: "Kadın %100 Pamuklu Tavuskuşu Desenli Pamuklu Büyük Beden Gömlek",
      description: "Kadın %100 Pamuklu Tavuskuşu Desenli Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuklu gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266530250,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/10/135995406/515362891/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/10/135995406/515362891/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/10/135995406/515362891/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515372565,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470008,
      productCode: "TS-7214",
      name: "%100 Pamuk Düz Renk Kol Bağcık Detaylı Pamuklu Büyük Beden Gömlek",
      slug: "%100 Pamuk Düz Renk Kol Bağcık Detaylı Pamuklu Büyük Beden Gömlek",
      description: "%100 Pamuk Düz Renk Kol Bağcık Detaylı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz renk kolları bağcıklı pamuklu büyük beden gömlek. Cotton kumaş. Kumaşın tok bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 42 bedendir. Modelin ölçüleri: Boy: 1.76, göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458576,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896296/515255029/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896296/515255029/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896296/515255029/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896296/515255029/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 515259916,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471061,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897559/515256614/1/1_org_zoom.jpg",
          name: "TAŞ",
          description: "%100 Pamuk Düz Renk Kol Bağcık Detaylı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 322470008,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896296/515255029/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "%100 Pamuk Düz Renk Kol Bağcık Detaylı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321346605,
      productCode: "C-S106",
      name: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      slug: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Oya İşli %100 Pamuk Özel Şile Bezi El İşçiliği Büyük Beden Dokuma Tunik Bluz, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki 40-42 bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623680/512815797/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623680/512815797/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134623680/512815797/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134623680/512815797/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 269.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512827714,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321346605,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623680/512815797/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349750,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626924/512820359/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349682,
          imageUrl: "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626856/512820284/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        },
        {
          id: 321349832,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134627023/512820447/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Omuz Oya Işli %100 Pamuk Özel Şile Bezi El Işçiliği Büyük Beden Dokuma Tunik Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320962237,
      productCode: "TS-24029",
      name: "Empirme Kolları Dantelli Cep Detaylı Büyük Beden Elbise",
      slug: "Empirme Kolları Dantelli Cep Detaylı Büyük Beden Elbise",
      description: "Empirme Kolları Dantelli Cep Detaylı Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kol, midi boy dokuma elbise. Hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Mankendeki beden 38-40 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265370111,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134219592/512233316/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/22/134219592/512233316/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134219592/512233316/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134219592/512233316/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "38-40",
      color: "BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38-40",
          itemNumber: 512249299,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320942845,
      productCode: "TS-1687-2",
      name: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Plus Kadın Desenli Battal Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265359170,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199132/512200039/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199132/512200039/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199132/512200039/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220702/21/134199132/512200039/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "TURUNCU DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 512215248,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 307701523,
      productCode: "TS-A225",
      name: "Elegant V Yaka Simli Keten Büyük Beden Gömlek",
      slug: "Elegant V Yaka Simli Keten Büyük Beden Gömlek",
      description: "Elegant V Yaka Simli Keten Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "V yaka Keten bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 256503205,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty442/product/media/images/20220531/21/118969306/491300111/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty442/product/media/images/20220531/21/118969306/491300111/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty442/product/media/images/20220531/21/118969306/491300111/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty441/product/media/images/20220531/21/118969306/491300111/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH-KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 491434487,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Klasik"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Keten"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290605396,
      productCode: "TS-1699",
      name: "Kadın Desenli Kolsuz Gizli Cepli Viskon Büyük Beden Elbise",
      slug: "Kadın Desenli Kolsuz Gizli Cepli Viskon Büyük Beden Elbise",
      description: "Kadın Desenli Kolsuz Gizli Cepli Viskon Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Büyük Beden Elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin üzerindeki ürün 42 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 245144021,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100794299/464263109/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100794299/464263109/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100794299/464263109/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100794299/464263109/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 464267435,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 208642059,
          comment: "Annem çok beğendi . Çok güzel , şık bir ürün. İster kumaş istersede model olarak .",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-09-16",
          lastModifiedDate: "16 Eylül 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Sıfır Kol"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290605372,
      productCode: "TS-1772",
      name: "Sandy Truvakar Kol Taş Işli Elegant Büyük Beden Elbise",
      slug: "Sandy Truvakar Kol Taş Işli Elegant Büyük Beden Elbise",
      description: "Sandy Truvakar Kol Taş Işli Elegant Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse Sandy Büyük Beden Elbise, Midi Boy Penye Elbise, Hem rahat, Hem de şık bir görüntü sağlar, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 245143998,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100794279/464263082/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100794279/464263082/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/20/100794279/464263082/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/20/100794279/464263082/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "PASTEL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 464267419,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Truvakar Kol"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290597283,
      productCode: "TS-7207",
      name: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek",
      slug: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek",
      description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 245135658,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785065/464247234/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785065/464247234/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/19/100785065/464247234/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785065/464247234/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 2.0
      },
      winnerVariant: "44",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 464252567,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 182575740,
          comment: "Harikasinzzzzz beklediğimden daha guzelll",
          rate: 5.0,
          userFullName: "S** S**",
          trusted: true,
          commentDateISOtype: "2022-05-20",
          lastModifiedDate: "20 Mayıs 2022"
        },
        {
          id: 299784349,
          comment: "Ürüm kaliteli ama yanlış ürün gönderildi",
          rate: 4.0,
          userFullName: "S** P**",
          trusted: true,
          commentDateISOtype: "2023-05-03",
          lastModifiedDate: "3 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 290597283,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785065/464247234/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek"
        },
        {
          id: 290595990,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/18/100783687/464245078/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek"
        },
        {
          id: 290597336,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785117/464247294/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Plus Renkli Geometrik Desenli Viskon Büyük Beden Viskon Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Geometrik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366483,
      productCode: "TS-7176001",
      name: "Renkli Dijital Desenli Pamuklu Büyük Beden Gömlek",
      slug: "Renkli Dijital Desenli Pamuklu Büyük Beden Gömlek",
      description: "Renkli Dijital Desenli Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239118618,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949031/450750740/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949031/450750740/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949031/450750740/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949031/450750740/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "40",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 450757851,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283364928,
      productCode: "TS-7192",
      name: "Çizgili Pamuklu Keten Büyük Beden Gömlek",
      slug: "Çizgili Pamuklu Keten Büyük Beden Gömlek",
      description: "Çizgili Pamuklu Keten Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117246,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947116/450748164/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947116/450748164/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947116/450748164/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947116/450748164/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.6,
        totalCount: 5.0
      },
      winnerVariant: "44",
      color: "INDIGO-ÇİZGİLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 450755450,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 253370194,
          comment: "GÜZEL BİR ÜRÜN KALIBI BİRAZ DAR",
          rate: 4.0,
          userFullName: "F** B**",
          trusted: true,
          commentDateISOtype: "2023-01-12",
          lastModifiedDate: "12 Ocak 2023"
        },
        {
          id: 193966256,
          comment: "çok güzel tam yazlık herkes tereddütsüz alabilir",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-21",
          lastModifiedDate: "21 Temmuz 2022"
        },
        {
          id: 187188114,
          comment: "Şahane gömlek çok kullanışlı ve çok kaliteli tşkler",
          rate: 4.0,
          userFullName: "Nalan A. G.",
          trusted: true,
          commentDateISOtype: "2022-06-13",
          lastModifiedDate: "13 Haziran 2022"
        }
      ],
      colors: [
        {
          id: 283366539,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949091/450750804/1/1_org_zoom.jpg",
          name: "BEJ-ÇİZGİLİ",
          description: "Çizgili Pamuklu Keten Büyük Beden Gömlek"
        },
        {
          id: 283364928,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947116/450748164/1/1_org_zoom.jpg",
          name: "INDIGO-ÇİZGİLİ",
          description: "Çizgili Pamuklu Keten Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Katlamalı"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 280712183,
      productCode: "TS-1724",
      name: "Krep Klasik Kısa Kol Büyük Beden Elbise",
      slug: "Krep Klasik Kısa Kol Büyük Beden Elbise",
      description: "Krep Klasik Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve yarım kollu, midi boy penye elbise, hem rahat, hem de şık bir görüntü sağlar, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin üzerindeki 44 bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 236618904,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty958/product/media/images/20230627/16/389637815/446987953/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty958/product/media/images/20230627/16/389637815/446987953/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty957/product/media/images/20230627/16/389637815/446987953/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty957/product/media/images/20230627/16/389637815/446987953/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "INDIGO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 446987953,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 280072599,
          imageUrl: "https://cdn.dsmcdn.com/ty394/product/media/images/20220412/21/89494333/446002733/1/1_org_zoom.jpg",
          name: "KİREMİT-SİYAH",
          description: "Krep Klasik Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280712183,
          imageUrl: "https://cdn.dsmcdn.com/ty958/product/media/images/20230627/16/389637815/446987953/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Krep Klasik Kısa Kol Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Krep"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Krep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 248238149,
      productCode: "TS-SR13",
      name: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      slug: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Örme Düğmeli Büyük Beden Hırka, Ürün Örme Triko Hırkadır, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: Boy: 1.72 Göğüs: 91, Bel: 70, Kalça: 102 T-Shirt Bedeni:M bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 128077947,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/17/58036457/397183502/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/17/58036457/397183502/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty344/product/media/images/20220224/17/58036457/397183502/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 397183502,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 248238149,
          imageUrl: "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/17/58036457/397183502/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568646,
          imageUrl: "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072185/248670142/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568690,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/0/135465581/248670186/0/0_org_zoom.jpg",
          name: "BEYAZ",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568792,
          imageUrl: "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/0/135465684/248670289/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Ekose / Kareli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 176996165,
      productCode: "TS-SR08",
      name: "Kol Ve Yaka Simli Örme Büyük Beden Hırka",
      slug: "Kol Ve Yaka Simli Örme Büyük Beden Hırka",
      description: "Kol Ve Yaka Simli Örme Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kol ve Yaka Simli Örme Büyük Beden Hırka, Ürün Örme Triko Hırkadır, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün L bedendir. Modelin Ölçüleri: Boy: 1.72 Göğüs: 91, Bel: 70, Kalça: 102 T-Shirt Bedeni:M bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 75683974,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2179065/288505285/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2179065/288505285/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/20/2179065/288505285/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/20/2179065/288505285/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42-44",
      color: "LACİVERT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 288487803,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 187228602,
          comment: "Guzel her kes beyendı",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-06-13",
          lastModifiedDate: "13 Haziran 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 176474444,
      productCode: "TS-YY7501",
      name: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      slug: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 151144362,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243592/287530231/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243592/287530231/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243592/287530231/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243592/287530231/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "LACİVERT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 287558577,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 176474201,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243145/287529613/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474444,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243592/287530231/1/1_org_zoom.jpg",
          name: "LACİVERT",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474186,
          imageUrl: "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243127/287529597/1/1_org_zoom.jpg",
          name: "CAMEL",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474204,
          imageUrl: "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243147/287529617/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 176474253,
      productCode: "TS-YY7028",
      name: "Fermuarlı Detaylı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      slug: "Fermuarlı Detaylı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      description: "Fermuarlı Detaylı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 151144424,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243193/287529675/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243193/287529675/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243193/287529675/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243193/287529675/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 287529675,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 176474204,
      productCode: "TS-YY7501",
      name: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      slug: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 151144362,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243147/287529617/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243147/287529617/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243147/287529617/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243147/287529617/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 1.0,
      ratingScore: {
        averageRating: 1.0,
        totalCount: 1.0
      },
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 287558519,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 166148442,
          comment: "Çelik örgü değil bildiğiniz polyester Dikişleri çok kötü",
          rate: 1.0,
          userFullName: "E** K**",
          trusted: true,
          commentDateISOtype: "2022-03-08",
          lastModifiedDate: "8 Mart 2022"
        }
      ],
      colors: [
        {
          id: 176474201,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243145/287529613/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474444,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243592/287530231/1/1_org_zoom.jpg",
          name: "LACİVERT",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474186,
          imageUrl: "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243127/287529597/1/1_org_zoom.jpg",
          name: "CAMEL",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474204,
          imageUrl: "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243147/287529617/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 176474201,
      productCode: "TS-YY7501",
      name: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      slug: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 151144362,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243145/287529613/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243145/287529613/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243145/287529613/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243145/287529613/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "MÜRDÜM",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 287558372,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 176474201,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243145/287529613/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474444,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243592/287530231/1/1_org_zoom.jpg",
          name: "LACİVERT",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474186,
          imageUrl: "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243127/287529597/1/1_org_zoom.jpg",
          name: "CAMEL",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474204,
          imageUrl: "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243147/287529617/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 176474186,
      productCode: "TS-YY7501",
      name: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      slug: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çelik Triko Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 151144362,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243127/287529597/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243127/287529597/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243127/287529597/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243127/287529597/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "48-50",
      color: "CAMEL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48-50",
          itemNumber: 287558339,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 166339826,
          comment: "Not için tesekkurler",
          rate: 5.0,
          userFullName: "A** Y**",
          trusted: true,
          commentDateISOtype: "2022-03-09",
          lastModifiedDate: "9 Mart 2022"
        }
      ],
      colors: [
        {
          id: 176474201,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243145/287529613/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474444,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243592/287530231/1/1_org_zoom.jpg",
          name: "LACİVERT",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474186,
          imageUrl: "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243127/287529597/1/1_org_zoom.jpg",
          name: "CAMEL",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        },
        {
          id: 176474204,
          imageUrl: "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243147/287529617/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Cep Detaylı Fermuarlı Çelikli Triko Büyük Beden Uzun Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 171619471,
      productCode: "TS-7180",
      name: "Kadın Renkli Dijital Desenli Pamuklu Büyük Beden Gömlek",
      slug: "Kadın Renkli Dijital Desenli Pamuklu Büyük Beden Gömlek",
      description: "Kadın Renkli Dijital Desenli Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon gömlek. Viskon kumaş. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 40 bedendir. Modelin ölçüleri: Boy: 1.76, göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 146726642,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794285/280055402/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794285/280055402/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794285/280055402/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 4.0
      },
      winnerVariant: "42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 280066930,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 152314508,
          comment: "Aynı göründüğü gibi geldi. Hediye olarak almıştım.",
          rate: 5.0,
          userFullName: "A** Ç**",
          trusted: true,
          commentDateISOtype: "2022-01-11",
          lastModifiedDate: "11 Ocak 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148568792,
      productCode: "TS-SR13",
      name: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      slug: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Örme Düğmeli Büyük Beden Hırka, Ürün Örme Triko Hırkadır, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: Boy: 1.72 Göğüs: 91, Bel: 70, Kalça: 102 T-Shirt Bedeni:M bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 128077947,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/0/135465684/248670289/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/0/135465684/248670289/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/0/135465684/248670289/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/0/135465684/248670289/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.33333333333333,
        totalCount: 3.0
      },
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248690493,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 138821845,
          comment: "aldigimiz urun ile hic alakasi olmayan bir urun geldi  yurt disinda oldugumuz ve bir arkadasimiz teslim aldigi icin urun elimizde kaldi. bunu basaran firmaya tebrik koyuyorum",
          rate: 1.0,
          userFullName: "Nuri S.",
          trusted: true,
          commentDateISOtype: "2021-11-11",
          lastModifiedDate: "11 Kasım 2021"
        }
      ],
      colors: [
        {
          id: 248238149,
          imageUrl: "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/17/58036457/397183502/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568646,
          imageUrl: "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072185/248670142/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568690,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/0/135465581/248670186/0/0_org_zoom.jpg",
          name: "BEYAZ",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568792,
          imageUrl: "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/0/135465684/248670289/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Ekose / Kareli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148568690,
      productCode: "TS-SR13",
      name: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      slug: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Örme Düğmeli Büyük Beden Hırka, Ürün Örme Triko Hırkadır, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: Boy: 1.72 Göğüs: 91, Bel: 70, Kalça: 102 T-Shirt Bedeni:M bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 128077947,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/0/135465581/248670186/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/0/135465581/248670186/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/0/135465581/248670186/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/0/135465581/248670186/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 2.0
      },
      winnerVariant: "42-44",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248690389,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 194280414,
          comment: "Pek beğenmedim, iade...",
          rate: 3.0,
          userFullName: "C** K**",
          trusted: true,
          commentDateISOtype: "2022-07-23",
          lastModifiedDate: "23 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 248238149,
          imageUrl: "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/17/58036457/397183502/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568646,
          imageUrl: "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072185/248670142/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568690,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/0/135465581/248670186/0/0_org_zoom.jpg",
          name: "BEYAZ",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568792,
          imageUrl: "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/0/135465684/248670289/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Ekose / Kareli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148568646,
      productCode: "TS-SR13",
      name: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      slug: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Örme Düğmeli Büyük Beden Hırka, Ürün Örme Triko Hırkadır, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: Boy: 1.72 Göğüs: 91, Bel: 70, Kalça: 102 T-Shirt Bedeni:M bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 128077947,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072185/248670142/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072185/248670142/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072185/248670142/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072185/248670142/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "42-44",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248670142,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 131693770,
          comment: "Annem için almıştım çok beğendi  hakikaten çok güzel. Ayrıca paketleme güzeldi kargo hızlı emeği geçenlere teşekkür ediyorum.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-10-09",
          lastModifiedDate: "9 Ekim 2021"
        },
        {
          id: 176602061,
          comment: "bi tık geniş oldu omuz kısmı iade ettik ama kaliteli güzel bir ürün",
          rate: 5.0,
          userFullName: "m** ç**",
          trusted: true,
          commentDateISOtype: "2022-04-22",
          lastModifiedDate: "22 Nisan 2022"
        }
      ],
      colors: [
        {
          id: 248238149,
          imageUrl: "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/17/58036457/397183502/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568646,
          imageUrl: "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072185/248670142/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568690,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/0/135465581/248670186/0/0_org_zoom.jpg",
          name: "BEYAZ",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 148568792,
          imageUrl: "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/0/135465684/248670289/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Klasik Desenli Örme Düğmeli Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Ekose / Kareli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 142140797,
      productCode: "TS-7997",
      name: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek",
      slug: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek",
      description: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Klasik Pamuklu Battal Büyük Beden Gömlek, Düz renk pamuk-Saten gömlek., %100 cotton kumaş., parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün XL bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41120110,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210911/12/129010893/240682646/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210911/12/129010893/240682646/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.33333333333333,
        totalCount: 6.0
      },
      winnerVariant: "XL",
      color: "KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 240705583,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 234254874,
          comment: "🧿🧿🧿 mükemmel yıka yıka giy kendi bedennizi alın.",
          rate: 5.0,
          userFullName: "S** G**",
          trusted: true,
          commentDateISOtype: "2022-11-27",
          lastModifiedDate: "27 Kasım 2022"
        },
        {
          id: 137851082,
          comment: "daha önce mavisini almıştım çok şık pamuklu ve büyükbeden",
          rate: 5.0,
          userFullName: "E** H** G**",
          trusted: true,
          commentDateISOtype: "2021-11-06",
          lastModifiedDate: "6 Kasım 2021"
        },
        {
          id: 289837671,
          comment: "Çok beğendim kaliteli etek uçları püsküllü",
          rate: 5.0,
          userFullName: "S** K**",
          trusted: true,
          commentDateISOtype: "2023-04-08",
          lastModifiedDate: "8 Nisan 2023"
        }
      ],
      colors: [
        {
          id: 140473377,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 124023053,
          imageUrl: "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
          name: "LİLA",
          description: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 140024951,
          imageUrl: "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Sarı Klasik Pamuklu Gömlek"
        },
        {
          id: 140025063,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
          name: "MİNT YEŞİL",
          description: "Klasik Mint Yeşil Pamuklu Gömlek"
        },
        {
          id: 49109092,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Battal Pamuklu Gömlek"
        },
        {
          id: 140440545,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 56510747,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Battal Pamuklu Kadın Gömlek"
        },
        {
          id: 48931613,
          imageUrl: "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Battal Pamuklu Gömlek"
        },
        {
          id: 142140797,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140440545,
      productCode: "TS-7997",
      name: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek",
      slug: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek",
      description: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Klasik Pamuklu Battal Büyük Beden Gömlek, Düz renk pamuk-Saten gömlek., %100 cotton kumaş., parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün XL bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41120110,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/18/127250187/238604384/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 2.0
      },
      winnerVariant: "XL",
      color: "BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 238716306,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 190218524,
          comment: "Çok geniş kalıbı var küçük alınmalı",
          rate: 3.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-06-27",
          lastModifiedDate: "27 Haziran 2022"
        }
      ],
      colors: [
        {
          id: 140473377,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 124023053,
          imageUrl: "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
          name: "LİLA",
          description: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 140024951,
          imageUrl: "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Sarı Klasik Pamuklu Gömlek"
        },
        {
          id: 140025063,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
          name: "MİNT YEŞİL",
          description: "Klasik Mint Yeşil Pamuklu Gömlek"
        },
        {
          id: 49109092,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Battal Pamuklu Gömlek"
        },
        {
          id: 140440545,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 56510747,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Battal Pamuklu Kadın Gömlek"
        },
        {
          id: 48931613,
          imageUrl: "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Battal Pamuklu Gömlek"
        },
        {
          id: 142140797,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140025063,
      productCode: "TS-7997",
      name: "Klasik Mint Yeşil Pamuklu Gömlek",
      slug: "Klasik Mint Yeşil Pamuklu Gömlek",
      description: "Klasik Mint Yeşil Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse klasik pamuklu battal büyük beden gömlek, düz renk pamuk-Saten gömlek., %100 cotton kumaş., parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41120110,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/20/126851216/238123387/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/20/126851216/238123387/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 7.0
      },
      winnerVariant: "XL",
      color: "MİNT YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 238422458,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 173814866,
          comment: "Tam göründüğü gibi tam bedeninizi alabilirsiniz",
          rate: 5.0,
          userFullName: "N** Ş** R**",
          trusted: true,
          commentDateISOtype: "2022-04-11",
          lastModifiedDate: "11 Nisan 2022"
        },
        {
          id: 146048156,
          comment: "bayıldığım renk tenkü",
          rate: 5.0,
          userFullName: "deniz d.",
          trusted: true,
          commentDateISOtype: "2021-12-09",
          lastModifiedDate: "9 Aralık 2021"
        },
        {
          id: 141555173,
          comment: "elimden gelse her rengini alirim mavi pembe bu da mint 3.gömlegim pamuklu çok şık duruyor tavsiye ederim",
          rate: 5.0,
          userFullName: "Elif H. G.",
          trusted: true,
          commentDateISOtype: "2021-11-20",
          lastModifiedDate: "20 Kasım 2021"
        }
      ],
      colors: [
        {
          id: 140473377,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 124023053,
          imageUrl: "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
          name: "LİLA",
          description: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 140024951,
          imageUrl: "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Sarı Klasik Pamuklu Gömlek"
        },
        {
          id: 140025063,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
          name: "MİNT YEŞİL",
          description: "Klasik Mint Yeşil Pamuklu Gömlek"
        },
        {
          id: 49109092,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Battal Pamuklu Gömlek"
        },
        {
          id: 140440545,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 56510747,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Battal Pamuklu Kadın Gömlek"
        },
        {
          id: 48931613,
          imageUrl: "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Battal Pamuklu Gömlek"
        },
        {
          id: 142140797,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140024951,
      productCode: "TS-7997",
      name: "Sarı Klasik Pamuklu Gömlek",
      slug: "Sarı Klasik Pamuklu Gömlek",
      description: "Sarı Klasik Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse klasik pamuklu battal büyük beden gömlek, düz renk pamuk-Saten gömlek., %100 cotton kumaş., parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41120110,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072177/238252498/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072177/238252498/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "XL",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 238123270,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 132938247,
          comment: "10 yıldızı hak eden Bi gömlek kumaşı harika başka renklerinide alicam mutlaka dolabinizda olması gereken gömlekler kumaşın bayılacaksınız",
          rate: 5.0,
          userFullName: "HATİCE A.",
          trusted: true,
          commentDateISOtype: "2021-10-15",
          lastModifiedDate: "15 Ekim 2021"
        }
      ],
      colors: [
        {
          id: 140473377,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/20/127272602/238643127/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yeşil Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 124023053,
          imageUrl: "https://cdn.dsmcdn.com/ty250/product/media/images/20211117/11/181873544/200765181/1/1_org_zoom.jpg",
          name: "LİLA",
          description: "Lila Klasik Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 140024951,
          imageUrl: "https://cdn.dsmcdn.com/ty307/product/media/images/20220123/21/34072177/238252498/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Sarı Klasik Pamuklu Gömlek"
        },
        {
          id: 140025063,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/20/126851216/238123387/1/1_org_zoom.jpg",
          name: "MİNT YEŞİL",
          description: "Klasik Mint Yeşil Pamuklu Gömlek"
        },
        {
          id: 49109092,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163713/87089164/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Battal Pamuklu Gömlek"
        },
        {
          id: 140440545,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127250187/238604384/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Klasik Bej Pamuklu Battal Büyük Beden Gömlek"
        },
        {
          id: 56510747,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2167754/100206245/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Battal Pamuklu Kadın Gömlek"
        },
        {
          id: 48931613,
          imageUrl: "https://cdn.dsmcdn.com/ty231/product/media/images/20211108/9/170684360/86753338/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Battal Pamuklu Gömlek"
        },
        {
          id: 142140797,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210911/12/129010893/240682646/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Klasik Kırmızı Pamuklu Battal Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 139940642,
      productCode: "TS-7166",
      name: "Kelebek Desenli Pamuk Büyük Beden Gömlek",
      slug: "Kelebek Desenli Pamuk Büyük Beden Gömlek",
      description: "Kelebek Desenli Pamuk Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121160940,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/13/126737512/238018698/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/13/126737512/238018698/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/13/126737512/238018698/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/13/126737512/238018698/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "42",
      color: "DESENLİ BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 238085214,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 236912620,
          comment: "kimse yorum yazmamış tereddütle aldım. Ama gerçekten müthş duruşu görünüşü kalıbı tam bedennizi alabilirsiniz. 46 giyiyordu 46 aldım tam oldu. renk beyaz değil krem fakat kelek deseni ile çok uymuş.",
          rate: 5.0,
          userFullName: "S** G**",
          trusted: true,
          commentDateISOtype: "2022-12-03",
          lastModifiedDate: "3 Aralık 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 130157286,
      productCode: "BL-8405",
      name: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka",
      slug: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka",
      description: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Dantel ve taş işli bayan viskon hırka., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 40937141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/11/116158414/222680088/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/11/116158414/222680088/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/11/116158414/222680088/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty154/product/media/images/20210806/11/116158414/222680088/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "3XL",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "3XL",
          itemNumber: 222680088,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 178272926,
          comment: "tam kalip bedeniniz ne ise onu alin 🤗",
          rate: 5.0,
          userFullName: "beyza ö.",
          trusted: true,
          commentDateISOtype: "2022-04-28",
          lastModifiedDate: "28 Nisan 2022"
        }
      ],
      colors: [
        {
          id: 48709598,
          imageUrl: "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956966/86352408/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Büyük Beden Hırka"
        },
        {
          id: 130156117,
          imageUrl: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116157287/222677424/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka"
        },
        {
          id: 130157286,
          imageUrl: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/11/116158414/222680088/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 130156117,
      productCode: "BL-8405",
      name: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka",
      slug: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka",
      description: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Dantel ve taş işli bayan viskon hırka., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 40937141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116157287/222677424/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116157287/222677424/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty154/product/media/images/20210806/10/116157287/222677424/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116157287/222677424/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 222677424,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 170947340,
          comment: "Guzel oldu. Beyendim",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-03-28",
          lastModifiedDate: "28 Mart 2022"
        }
      ],
      colors: [
        {
          id: 48709598,
          imageUrl: "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956966/86352408/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Büyük Beden Hırka"
        },
        {
          id: 130156117,
          imageUrl: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116157287/222677424/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka"
        },
        {
          id: 130157286,
          imageUrl: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/11/116158414/222680088/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 130151247,
      productCode: "BL-8404",
      name: "Kadın Haki Büyük Beden Dantel Ve Taş Işli Yelekli Takım",
      slug: "Kadın Haki Büyük Beden Dantel Ve Taş Işli Yelekli Takım",
      description: "Kadın Haki Büyük Beden Dantel Ve Taş Işli Yelekli Takım",
      contentDescriptions: [
        {
          bold: false,
          description: "Dantel ve taş işli bayan viskon hırka., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 40886346,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116151068/222662377/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116151068/222662377/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116151068/222662377/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty154/product/media/images/20210806/10/116151068/222662377/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 222723304,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 130151247,
          imageUrl: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116151068/222662377/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Haki Büyük Beden Dantel Ve Taş Işli Yelekli Takım"
        },
        {
          id: 48647488,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200912/18/10533922/86257058/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Büyük Beden Dantel ve Taş İşli Yelekli Takım"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124013083,
      productCode: "TS-7146",
      name: "Mercan .balıklı Desenli Pamuklu Kadın Büyük Beden Gömlek",
      slug: "Mercan .balıklı Desenli Pamuklu Kadın Büyük Beden Gömlek",
      description: "Mercan .balıklı Desenli Pamuklu Kadın Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Desenli Pamuklu gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109169783,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888785/200701566/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888785/200701566/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/9/108888785/200701566/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888785/200701566/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "BEYAZ-MERCAN",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 200722216,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 193535557,
          comment: "Yüksek kaliteli pamuk ve güzel, taze görünümlü bir baskı. Teşekkürler!",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-19",
          lastModifiedDate: "19 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 104535171,
      productCode: "TS-S100-4",
      name: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, maxi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız., Mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 91235216,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty112/product/media/images/20210509/23/87080378/172568309/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty112/product/media/images/20210509/23/87080378/172568309/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty111/product/media/images/20210509/23/87080378/172568309/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080378/172568309/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 399.99
      },
      rating: 2.0,
      ratingScore: {
        averageRating: 2.0,
        totalCount: 1.0
      },
      winnerVariant: "42-44",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 172569828,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 193609384,
          comment: "bu urunu sipariw verdim fakat baska bir urun geldi. yurtdisi sipariw oldugu icin iade etmedim.",
          rate: 2.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-19",
          lastModifiedDate: "19 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 104535154,
      productCode: "TS-S100-3",
      name: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Otantik Kapişonlu Asimetrik Elbise., Ürünün kumaşı melanjlı örmedir ve kumaşın yumuşak bir yapısı vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 91235201,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080344/172568292/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080344/172568292/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty111/product/media/images/20210509/23/87080344/172568292/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty111/product/media/images/20210509/23/87080344/172568292/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 172568292,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 103585547,
      productCode: "TS-7142",
      name: "Kadın Beyaz Desenli Battal Desenli Pamuklu Büyük Beden Gömlek",
      slug: "Kadın Beyaz Desenli Battal Desenli Pamuklu Büyük Beden Gömlek",
      description: "Kadın Beyaz Desenli Battal Desenli Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Desenli Hakim Yaka gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44-46 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 90331326,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty236/product/media/images/20211110/0/172574572/171263459/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty236/product/media/images/20211110/0/172574572/171263459/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty234/product/media/images/20211110/0/172574572/171263459/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty235/product/media/images/20211110/0/172574572/171263459/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty234/product/media/images/20211110/0/172574572/171263459/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.71428571428571,
        totalCount: 14.0
      },
      winnerVariant: "40-42",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 309743745,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 166634783,
          comment: "İyi ki yorumları dikkate alıp büyük beden almışım. 44-46 bedenim nrmalde, sipariş ettiğim 50-52 beden  iyi oldu. Kalıp gerçekten darmış. Üzerindeki deseni baskı sanmıştım, meğer nakış işleme imiş. Umarım siyah ipler ilk yıkamada boya atmaz. Duruşu, boyu posu ve dediğim gibi nakış detayı son derece modern. Fakat kumaşı ince, iç gösteriyor. Resimdeki gibi tok bir kumaş değil. İade etmeyeceğim. Ama dürüstçe söylemem gerekirse, umduğum kadar kaliteli de bulmadım. Küçük notunuz için teşekkürler. İyi çalışmalar dilerim.",
          rate: 3.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-03-11",
          lastModifiedDate: "11 Mart 2022"
        },
        {
          id: 163852920,
          comment: "gomlegi cok begendim. ayrica paketin agzinda sizin icin ozenle hazirladik notuna bayildim. cok teşekkürler. cok naziksiniz Tusse😊",
          rate: 5.0,
          userFullName: "A** Ö** Ö** Ç**",
          trusted: true,
          commentDateISOtype: "2022-02-26",
          lastModifiedDate: "26 Şubat 2022"
        },
        {
          id: 147925148,
          comment: "bedeni büyük iade etmek zorunda kaldım.bedeni tam olsaydı güzeldi ama",
          rate: 5.0,
          userFullName: "serap a.",
          trusted: true,
          commentDateISOtype: "2021-12-18",
          lastModifiedDate: "18 Aralık 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 103585457,
      productCode: "TS-7144",
      name: "Kadın Beyaz Kuş Tüyü Desenli Pamuklu Büyük Beden Gömlek",
      slug: "Kadın Beyaz Kuş Tüyü Desenli Pamuklu Büyük Beden Gömlek",
      description: "Kadın Beyaz Kuş Tüyü Desenli Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Desenli Pamuklu gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 90331254,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty257/product/media/images/20211128/19/648404/171263483/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty257/product/media/images/20211128/19/648404/171263483/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty256/product/media/images/20211128/19/648404/171263483/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty257/product/media/images/20211128/19/648404/171263483/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 2.0
      },
      winnerVariant: "42-44",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 171263522,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 100213208,
      productCode: "BL-8406",
      name: "Kadın Büyük Beden Dantelli Uzun  Hırka",
      slug: "Kadın Büyük Beden Dantelli Uzun  Hırka",
      description: "Kadın Büyük Beden Dantelli Uzun  Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon hırka, Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 40887163,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty107/product/media/images/20210422/19/82162103/166479564/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty107/product/media/images/20210422/19/82162103/166479564/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty106/product/media/images/20210422/19/82162103/166479564/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty106/product/media/images/20210422/19/82162103/166479564/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty105/product/media/images/20210422/19/82162103/166479564/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 166484411,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 100212624,
      productCode: "TS-4148",
      name: "Şal Yaka Büyük Beden Hırka",
      slug: "Şal Yaka Büyük Beden Hırka",
      description: "Şal Yaka Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Şal Yaka Hırka., %80 Viskon %15 Polyster %5 Elastan kumaştır., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 59406281,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty107/product/media/images/20210422/18/82161583/166478063/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty107/product/media/images/20210422/18/82161583/166478063/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty105/product/media/images/20210422/18/82161583/166478063/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty106/product/media/images/20210422/18/82161583/166478063/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 179.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 166483502,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 100149602,
          comment: "Kesim harika. Kumaş biraz fazla kalın. renk 🤩",
          rate: 4.0,
          userFullName: "D** I**",
          trusted: true,
          commentDateISOtype: "2021-04-29",
          lastModifiedDate: "29 Nisan 2021"
        }
      ],
      colors: [
        {
          id: 100212624,
          imageUrl: "https://cdn.dsmcdn.com/ty107/product/media/images/20210422/18/82161583/166478063/0/0_org_zoom.jpg",
          name: "KAHVE",
          description: "Şal Yaka Büyük Beden Hırka"
        },
        {
          id: 69330960,
          imageUrl: "https://cdn.dsmcdn.com/ty37/product/media/images/20201217/4/39151734/120155569/0/0_org_zoom.jpg",
          name: "VİZON",
          description: "Şal Yaka Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96942277,
      productCode: "TS-S100-1",
      name: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Sandy Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli U.Kol Sandy Kumaş Elbise, Kırışma zor olmaz, Çamaşır makinesinde, düşük derece ayarında yıkanabilir, Sağlam bir kumaş çeşididir, Renkleri çok canlı göstermesinden ötürü, oldukça şık görünmektedir, Ürün, dökümlü ve kullanış açısından çok rahat olan"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 83968111,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty101/product/media/images/20210406/22/78338503/161429001/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty101/product/media/images/20210406/22/78338503/161429001/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty99/product/media/images/20210406/22/78338503/161429001/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338503/161429001/2/2_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "KAHVERENGİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 161432434,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 79942864,
      productCode: "TS-0015",
      name: "V Yaka Desenli Klasik Triko Anne Hırka",
      slug: "V Yaka Desenli Klasik Triko Anne Hırka",
      description: "V Yaka Desenli Klasik Triko Anne Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE V Yaka Desenli Klasik Triko Anne Hırka, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün XL bedendir, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün xl bedendir. Modelin ölçüleri: Boy: 1.72 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 68456354,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty62/product/media/images/20210129/3/58165238/135529385/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty62/product/media/images/20210129/3/58165238/135529385/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty63/product/media/images/20210129/3/58165238/135529385/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty64/product/media/images/20210129/3/58165238/135529385/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 135533403,
          stock: 4.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 74654640,
      productCode: "TS-S000",
      name: "Empirme Desenli U.kol Sandy Kumaş Elbise",
      slug: "Empirme Desenli U.kol Sandy Kumaş Elbise",
      description: "Empirme Desenli U.kol Sandy Kumaş Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Empirme Desenli U.Kol Sandy Kumaş Elbise, Kırışma zor olmaz, Çamaşır makinesinde, düşük derece ayarında yıkanabilir, Sağlam bir kumaş çeşididir, Renkleri çok canlı göstermesinden ötürü, oldukça şık görünmektedir, Ürün, dökümlü ve kullanış açısından çok ra"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 63590449,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty52/product/media/images/20210109/18/50409552/128175892/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty52/product/media/images/20210109/18/50409552/128175892/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty52/product/media/images/20210109/18/50409552/128175892/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty52/product/media/images/20210109/18/50409552/128175892/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty52/product/media/images/20210109/18/50409552/128175892/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 2.0
      },
      winnerVariant: "XL",
      color: "DESENLİ5",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 128175892,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 262426957,
          comment: "Gayet güzel",
          rate: 5.0,
          userFullName: "G** D**",
          trusted: true,
          commentDateISOtype: "2023-01-29",
          lastModifiedDate: "29 Ocak 2023"
        },
        {
          id: 301147574,
          comment: "yerini hiç beğenmedim carse sıcakta yakar kumaşı",
          rate: 1.0,
          userFullName: "F** Y**",
          trusted: true,
          commentDateISOtype: "2023-05-07",
          lastModifiedDate: "7 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 74252570,
          imageUrl: "https://cdn.dsmcdn.com/ty48/product/media/images/20210108/11/48564799/127604640/0/0_org_zoom.jpg",
          name: "DESENLİ",
          description: "Puantiye Desenli U.kol Sandy Kumaş Elbise"
        },
        {
          id: 74654640,
          imageUrl: "https://cdn.dsmcdn.com/ty52/product/media/images/20210109/18/50409552/128175892/0/0_org_zoom.jpg",
          name: "DESENLİ5",
          description: "Empirme Desenli U.kol Sandy Kumaş Elbise"
        },
        {
          id: 74654455,
          imageUrl: "https://cdn.dsmcdn.com/ty50/product/media/images/20210109/17/50408570/128175714/0/0_org_zoom.jpg",
          name: "DESENLİ4",
          description: "Empirme Desenli U.kol Sandy Kumaş Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 74654455,
      productCode: "TS-S000",
      name: "Empirme Desenli U.kol Sandy Kumaş Elbise",
      slug: "Empirme Desenli U.kol Sandy Kumaş Elbise",
      description: "Empirme Desenli U.kol Sandy Kumaş Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ekose Desenli Hatlara Yapışmayan Büyük Beden Elbise, Elbisenin özelliği hatlara yapışma yapmaz. Göbek kısmını gizler. Ürünün kumaşı polyviskon kumaştır. Kumaşın, pamuğa oranla esneme yapısı daha yüksektir. Kumaş, uzun süre renkleri koru. Terleme yaratmaz"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 63590449,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty50/product/media/images/20210109/17/50408570/128175714/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty50/product/media/images/20210109/17/50408570/128175714/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty50/product/media/images/20210109/17/50408570/128175714/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty51/product/media/images/20210109/17/50408570/128175714/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty52/product/media/images/20210109/17/50408570/128175714/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "DESENLİ4",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 128186999,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 74252570,
          imageUrl: "https://cdn.dsmcdn.com/ty48/product/media/images/20210108/11/48564799/127604640/0/0_org_zoom.jpg",
          name: "DESENLİ",
          description: "Puantiye Desenli U.kol Sandy Kumaş Elbise"
        },
        {
          id: 74654640,
          imageUrl: "https://cdn.dsmcdn.com/ty52/product/media/images/20210109/18/50409552/128175892/0/0_org_zoom.jpg",
          name: "DESENLİ5",
          description: "Empirme Desenli U.kol Sandy Kumaş Elbise"
        },
        {
          id: 74654455,
          imageUrl: "https://cdn.dsmcdn.com/ty50/product/media/images/20210109/17/50408570/128175714/0/0_org_zoom.jpg",
          name: "DESENLİ4",
          description: "Empirme Desenli U.kol Sandy Kumaş Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 74252570,
      productCode: "TS-S000",
      name: "Puantiye Desenli U.kol Sandy Kumaş Elbise",
      slug: "Puantiye Desenli U.kol Sandy Kumaş Elbise",
      description: "Puantiye Desenli U.kol Sandy Kumaş Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ekose Desenli Hatlara Yapışmayan Büyük Beden Elbise, Elbisenin özelliği hatlara yapışma yapmaz. Göbek kısmını gizler. Ürünün kumaşı polyviskon kumaştır. Kumaşın, pamuğa oranla esneme yapısı daha yüksektir. Kumaş, uzun süre renkleri koru. Terleme yaratmaz"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 63590449,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty48/product/media/images/20210108/11/48564799/127604640/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty48/product/media/images/20210108/11/48564799/127604640/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty48/product/media/images/20210108/11/48564799/127604640/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty48/product/media/images/20210108/11/48564799/127604640/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty47/product/media/images/20210108/11/48564799/127604640/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XXL",
      color: "DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XXL",
          itemNumber: 127682282,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 74252570,
          imageUrl: "https://cdn.dsmcdn.com/ty48/product/media/images/20210108/11/48564799/127604640/0/0_org_zoom.jpg",
          name: "DESENLİ",
          description: "Puantiye Desenli U.kol Sandy Kumaş Elbise"
        },
        {
          id: 74654640,
          imageUrl: "https://cdn.dsmcdn.com/ty52/product/media/images/20210109/18/50409552/128175892/0/0_org_zoom.jpg",
          name: "DESENLİ5",
          description: "Empirme Desenli U.kol Sandy Kumaş Elbise"
        },
        {
          id: 74654455,
          imageUrl: "https://cdn.dsmcdn.com/ty50/product/media/images/20210109/17/50408570/128175714/0/0_org_zoom.jpg",
          name: "DESENLİ4",
          description: "Empirme Desenli U.kol Sandy Kumaş Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 69330960,
      productCode: "TS-4148",
      name: "Şal Yaka Hırka",
      slug: "Şal Yaka Hırka",
      description: "Şal Yaka Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Şal Yaka Hırka., %80 Viskon %15 Polyster %5 Elastan kumaştır., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 59406281,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty37/product/media/images/20201217/4/39151734/120155569/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty37/product/media/images/20201217/4/39151734/120155569/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty36/product/media/images/20201217/4/39151734/120155569/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty36/product/media/images/20201217/4/39151734/120155569/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty36/product/media/images/20201217/4/39151734/120155569/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 179.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 120155569,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 100212624,
          imageUrl: "https://cdn.dsmcdn.com/ty107/product/media/images/20210422/18/82161583/166478063/0/0_org_zoom.jpg",
          name: "KAHVE",
          description: "Şal Yaka Büyük Beden Hırka"
        },
        {
          id: 69330960,
          imageUrl: "https://cdn.dsmcdn.com/ty37/product/media/images/20201217/4/39151734/120155569/0/0_org_zoom.jpg",
          name: "VİZON",
          description: "Şal Yaka Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 66657161,
      productCode: "TS-0009",
      name: "Şönil Fermuarlı Fashion Yelek",
      slug: "Şönil Fermuarlı Fashion Yelek",
      description: "Şönil Fermuarlı Fashion Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Şönil Kumaş Fermuarlı Fashion Yelek, Ürün Örme Şönil Fermuarlı Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün XL bedendir. Mankenin Ölçüleri"
        },
        {
          bold: false,
          description: "Boy: 1,74 Göğüs: 90 Bel: 60 Basen: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 57051151,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty269/product/media/images/20211211/19/9129889/116075148/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty269/product/media/images/20211211/19/9129889/116075148/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty268/product/media/images/20211211/19/9129889/116075148/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty268/product/media/images/20211211/19/9129889/116075148/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty268/product/media/images/20211211/19/9129889/116075148/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "XL",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 116075757,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 106364373,
          comment: "harika bir kıyafet iyiki almışım çok rahat kaliteli bir ürün teşekkürler",
          rate: 5.0,
          userFullName: "i** y**",
          trusted: true,
          commentDateISOtype: "2021-05-21",
          lastModifiedDate: "21 Mayıs 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55772037,
      productCode: "TS20028005",
      name: "Kadın Siyah Düz Paça Yandan Fermuarlı Eşofman Altı",
      slug: "Kadın Siyah Düz Paça Yandan Fermuarlı Eşofman Altı",
      description: "Kadın Siyah Düz Paça Yandan Fermuarlı Eşofman Altı",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz paça yandan fermuarlı eşofman altı, ürünün yandan iki ve arkadan bir cebi vardır, günlük kullanıma ve spor için kullanıma uygundur, ürün diz yapmaz, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün s bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Eşofman Altı",
      productGroupId: 47181088,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Eşofman Altı",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty665/product/media/images/20221226/10/246953921/99071919/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty665/product/media/images/20221226/10/246953921/99071919/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty665/product/media/images/20221226/10/246953921/99071919/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty663/product/media/images/20221226/10/246953921/99071919/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty665/product/media/images/20221226/10/246953921/99071919/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.64285714285714,
        totalCount: 15.0
      },
      winnerVariant: "S",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "S",
          itemNumber: 99071913,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 96784052,
          comment: "Paketlemede çok güzeldi bunun için teşekkür ederim ama ürün kalıp olarak bol bir beden küçük almanızı tavsiye ederim..",
          rate: 2.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-04-14",
          lastModifiedDate: "14 Nisan 2021"
        },
        {
          id: 189787271,
          comment: "Ürün çok kaliteli. Bol kesim. 1.70 boy 78 kilo M beden aldım. Ne çok bol ne de dar oldu.",
          rate: 5.0,
          userFullName: "Z** I**",
          trusted: true,
          commentDateISOtype: "2022-06-25",
          lastModifiedDate: "25 Haziran 2022"
        },
        {
          id: 279330596,
          comment: "Annem için aldim cok begendi. sadece boyu uzun geldi",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-03-16",
          lastModifiedDate: "16 Mart 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Regular"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polycotton"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Normal Paça"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        },
        {
          id: 199,
          key: "Kumaş Özellik",
          value: "Ter Tutmayan"
        },
        {
          id: 223,
          key: "Paça Boyu",
          value: "Uzun"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49608950,
      productCode: "TYC00041695172",
      name: "Kadın Haki Dantel Ve Taş Işli Viskon Hırka",
      slug: "Kadın Haki Dantel Ve Taş Işli Viskon Hırka",
      description: "Kadın Haki Dantel Ve Taş Işli Viskon Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Dantel ve taş işli bayan viskon hırka."
        },
        {
          bold: false,
          description: "Yelekli ve uzun kol bluzlu ikili takımdır."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün xl bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 41745539,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/15/11407679/87885039/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/15/11407679/87885039/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11407679/87885039/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11407679/87885039/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/15/11407679/87885039/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 3.0
      },
      winnerVariant: "XL",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 87900807,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 180899908,
          comment: "Ürün kalite olarak güzel ama sanki xxl l gibi kalıpları çok dar o yüzden iade",
          rate: 3.0,
          userFullName: "T** K**",
          trusted: true,
          commentDateISOtype: "2022-05-12",
          lastModifiedDate: "12 Mayıs 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49567773,
      productCode: "ML-8220",
      name: "Kadın Siyah Çelik Düğmeli Ikili Takım",
      slug: "Kadın Siyah Çelik Düğmeli Ikili Takım",
      description: "Kadın Siyah Çelik Düğmeli Ikili Takım",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705390,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367420/87816087/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367420/87816087/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367420/87816087/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367420/87816087/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367420/87816087/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87826675,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 104681682,
          comment: "Annemize anneler günü hediyesi olarak aldık gerçekten çok güzel bir ürün çok kaliteli. Üzerine iliştirilen not ise çok hoş bir detaydı. Teşekkürler",
          rate: 5.0,
          userFullName: "Şeyda E. Y.",
          trusted: true,
          commentDateISOtype: "2021-05-15",
          lastModifiedDate: "15 Mayıs 2021"
        },
        {
          id: 102796273,
          comment: "Mükemmel bir ürün",
          rate: 5.0,
          userFullName: "M** P**",
          trusted: true,
          commentDateISOtype: "2021-05-08",
          lastModifiedDate: "8 Mayıs 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48709598,
      productCode: "BL-8405",
      name: "Kadın Ekru Büyük Beden Hırka",
      slug: "Kadın Ekru Büyük Beden Hırka",
      description: "Kadın Ekru Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon hırka."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün XL bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 40937141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956966/86352408/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956966/86352408/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956966/86352408/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956966/86352408/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty24/product/media/images/20201119/10/28956966/86352408/4/4_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 399.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL/L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL/L",
          itemNumber: 86365220,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 48709598,
          imageUrl: "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956966/86352408/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Büyük Beden Hırka"
        },
        {
          id: 130156117,
          imageUrl: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/10/116157287/222677424/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka"
        },
        {
          id: 130157286,
          imageUrl: "https://cdn.dsmcdn.com/ty153/product/media/images/20210806/11/116158414/222680088/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Dantel Ve Taş Işli Viskon Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290626931,
      productCode: "TS-S106",
      name: "Kare Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      slug: "Kare Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      description: "Kare Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Büyük Beden Krep Gömlek., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 215545580,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/5/100830274/464302737/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/5/100830274/464302737/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/5/100830274/464302737/2/2_org_zoom.jpg"
      ],
      price: 449.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 449.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 399.99,
        sellingPrice: 449.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 3.0
      },
      winnerVariant: "44",
      color: "KARELİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 464303675,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 319818701,
          comment: "Annem için almıştım. Çok şık. Sadece kumaşı pamuklu olsaydı daha iyi olurdu.",
          rate: 4.0,
          userFullName: "C** T**",
          trusted: true,
          commentDateISOtype: "2023-07-07",
          lastModifiedDate: "7 Temmuz 2023"
        },
        {
          id: 203456534,
          comment: "Annem için aldım vücuda yapışmıyor duruşu harıka kesinlikle alınmalı",
          rate: 4.0,
          userFullName: "s** m**",
          trusted: true,
          commentDateISOtype: "2022-08-31",
          lastModifiedDate: "31 Ağustos 2022"
        },
        {
          id: 187685226,
          comment: "Sizi seviyoruz notunuz gülümsetti..  teşekkür ederiz güzel ürün",
          rate: 4.0,
          userFullName: "A** Ç**",
          trusted: true,
          commentDateISOtype: "2022-06-15",
          lastModifiedDate: "15 Haziran 2022"
        }
      ],
      colors: [
        {
          id: 290626931,
          imageUrl: "https://cdn.dsmcdn.com/ty414/product/media/images/20220501/5/100830274/464302737/1/1_org_zoom.jpg",
          name: "KARELİ",
          description: "Kare Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 322662535,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/17/136162149/515551553/1/1_org_zoom.jpg",
          name: "LACİ-DESENLİ",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 248211850,
          imageUrl: "https://cdn.dsmcdn.com/ty343/product/media/images/20220224/16/58008014/397145391/1/1_org_zoom.jpg",
          name: "LACİ-LEOPAR",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        },
        {
          id: 679431082,
          imageUrl: "https://cdn.dsmcdn.com/ty802/product/media/images/20230328/18/314383428/898809554/1/1_org_zoom.jpg",
          name: "KAZAYAĞI-KAHVE",
          description: "Desenli Krep Klasik Düğmeli Anne Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Kumaş"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321349807,
      productCode: "TYC00496079382",
      name: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz",
      slug: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz",
      description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265687021,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626989/512820418/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626989/512820418/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626989/512820418/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626989/512820418/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512830866,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321349786,
      productCode: "TS-S204",
      name: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz",
      slug: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz",
      description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684290,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626967/512820398/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626967/512820398/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626967/512820398/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626967/512820398/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 512830830,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321349786,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626967/512820398/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz"
        },
        {
          id: 321346807,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623888/512816012/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321349726,
      productCode: "TS-S206",
      name: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      slug: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684127,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626896/512820335/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626896/512820335/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626896/512820335/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134626896/512820335/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512830928,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321349646,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626809/512820242/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        },
        {
          id: 321346588,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623668/512815780/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        },
        {
          id: 321349726,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626896/512820335/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321346588,
      productCode: "TS-S206",
      name: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      slug: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684127,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623668/512815780/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623668/512815780/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134623668/512815780/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134623668/512815780/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46-48",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46-48",
          itemNumber: 512827686,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321349646,
          imageUrl: "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134626809/512820242/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        },
        {
          id: 321346588,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623668/512815780/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        },
        {
          id: 321349726,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626896/512820335/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366547,
      productCode: "TS-1749",
      name: "Leopar Desenli Viskon Büyük Beden Gömlek",
      slug: "Leopar Desenli Viskon Büyük Beden Gömlek",
      description: "Leopar Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117214,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949095/450750817/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949095/450750817/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949095/450750817/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949095/450750817/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "38",
      color: "KAHVE-LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 450757974,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377340980,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/11/204113931/609978107/1/1_org_zoom.jpg",
          name: "LEOPAR",
          description: "Leopar Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 283366547,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949095/450750817/1/1_org_zoom.jpg",
          name: "KAHVE-LEOPAR",
          description: "Leopar Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 283364878,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947068/450748113/1/1_org_zoom.jpg",
          name: "SİYAH-LEOPAR",
          description: "Leopar Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Leopar"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 100205659,
      productCode: "C-0406",
      name: "Kadın Siyah Asimetrik Devore Büyük Beden Hırka",
      slug: "Kadın Siyah Asimetrik Devore Büyük Beden Hırka",
      description: "Kadın Siyah Asimetrik Devore Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve uzun kollu bayan devore hırka., kumaşın asimetrik bir yapısı ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün l bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 41691748,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty105/product/media/images/20210422/17/82147251/166456624/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty105/product/media/images/20210422/17/82147251/166456624/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty105/product/media/images/20210422/17/82147251/166456624/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty107/product/media/images/20210422/17/82147251/166456624/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 0.0,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 166456624,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 49554068,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/9/11354752/87797174/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Asimetrik Devore Hırka"
        },
        {
          id: 100205659,
          imageUrl: "https://cdn.dsmcdn.com/ty105/product/media/images/20210422/17/82147251/166456624/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Asimetrik Devore Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96942219,
      productCode: "TS-1701-1",
      name: "Kadın Puantiye Desenli Viskon Kolsuz Oturtmalı Büyük Beden Elbise",
      slug: "Kadın Puantiye Desenli Viskon Kolsuz Oturtmalı Büyük Beden Elbise",
      description: "Kadın Puantiye Desenli Viskon Kolsuz Oturtmalı Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Düz Renk Klasik Renkli Örme Büyük Beden Elbise, Ürün Örme Triko Anne Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün 2XL bedendir. Mank"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 83968059,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty101/product/media/images/20210406/22/78338411/161428943/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty101/product/media/images/20210406/22/78338411/161428943/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty99/product/media/images/20210406/22/78338411/161428943/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty99/product/media/images/20210406/22/78338411/161428943/2/2_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "PEMBE PUANTİYE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 161432423,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95420536,
      productCode: "G-1102",
      name: "Kadın  Etnik Ponpon Ve Boncuk Detaylı Çizgili Bürümcük  Gömlek",
      slug: "Kadın  Etnik Ponpon Ve Boncuk Detaylı Çizgili Bürümcük  Gömlek",
      description: "Kadın  Etnik Ponpon Ve Boncuk Detaylı Çizgili Bürümcük  Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82609729,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty66/product/media/images/20210331/10/76547321/159029522/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty66/product/media/images/20210331/10/76547321/159029522/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty76/product/media/images/20210331/10/76547321/159029522/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty70/product/media/images/20210331/10/76547321/159029522/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 159054351,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 135531882,
          comment: "Aslinda urun cok guzel ama 3xl giyinmeme ragmen dar durdu.Evet esnek,likrali ama dapdar duruyor giyinince",
          rate: 3.0,
          userFullName: "Z** E**",
          trusted: true,
          commentDateISOtype: "2021-10-25",
          lastModifiedDate: "25 Ekim 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 176474279,
      productCode: "TS-YY1089",
      name: "Şönil Kadife Yaka Ve Cep Detaylı Büyük Beden Fashion Anne Yelek",
      slug: "Şönil Kadife Yaka Ve Cep Detaylı Büyük Beden Fashion Anne Yelek",
      description: "Şönil Kadife Yaka Ve Cep Detaylı Büyük Beden Fashion Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Şönil Kadife Yaka ve Cep Detaylı Büyük Beden Fashion Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 151144451,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243224/287529711/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243224/287529711/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243224/287529711/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243224/287529711/3/3_org_zoom.jpg"
      ],
      price: 389.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 389.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 389.99,
        sellingPrice: 389.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 287558289,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 230130100,
          comment: "mükemmel bir yelek, hanım hanımcık",
          rate: 5.0,
          userFullName: "X** S**",
          trusted: true,
          commentDateISOtype: "2022-11-17",
          lastModifiedDate: "17 Kasım 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 699195803,
      productCode: "TS-1688-13",
      name: "Plus Etnik Desenli Viskon Cepli Vücuda Yapışmayan Battal Büyük Beden Günlük Elbise",
      slug: "Plus Etnik Desenli Viskon Cepli Vücuda Yapışmayan Battal Büyük Beden Günlük Elbise",
      description: "Plus Etnik Desenli Viskon Cepli Vücuda Yapışmayan Battal Büyük Beden Günlük Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 538823755,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty858/product/media/images/20230502/11/337082306/924378269/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty858/product/media/images/20230502/11/337082306/924378269/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty860/product/media/images/20230502/11/337082306/924378269/2/2_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "48",
      color: "ŞAL DESEN",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48",
          itemNumber: 924391740,
          stock: 10.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Kumaş"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Etnik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 250,
          key: "Stil",
          value: "Günlük"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        },
        {
          id: 893,
          key: "Kemer/Kuşak Durumu",
          value: "Kemersiz"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "İnce"
        },
        {
          id: 710,
          key: "Koleksiyon",
          value: "Büyük Beden"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 376595414,
      productCode: "TS-L676",
      name: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      slug: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Saten Büyük Beden Elegant Şık Gömlek, Ürün klasik pantolonlarla çok şık kombinlenebilrmketedir. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458502,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/9/203285117/608550578/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/9/203285117/608550578/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/9/203285117/608550578/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/9/203285117/608550578/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "SAX MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 608570407,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 279105836,
          comment: "ürün saten her zaman kullanılabilecek bir ürün sadece yan oyuntuları biraz fazla bunu göz önünde bulundurarak satın almanizi tavsiye ederim bunun dışında gerçekten çok güzel bir ürün",
          rate: 5.0,
          userFullName: "P** T**",
          trusted: true,
          commentDateISOtype: "2023-03-16",
          lastModifiedDate: "16 Mart 2023"
        }
      ],
      colors: [
        {
          id: 322470974,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897458/515256518/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322470954,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897441/515256486/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322469931,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896216/515254951/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 376595414,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/9/203285117/608550578/1/1_org_zoom.jpg",
          name: "SAX MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471104,
      productCode: "TS-7217",
      name: "Düz Renk %100 Pamuklu Keten Büyük Beden Gömlek",
      slug: "Düz Renk %100 Pamuklu Keten Büyük Beden Gömlek",
      description: "Düz Renk %100 Pamuklu Keten Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz renk kolları bağcıklı pamuklu büyük beden gömlek. Cotton kumaş. Kumaşın tok bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 40 bedendir. Modelin ölçüleri: Boy: 1.76, göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458595,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897613/515256663/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897613/515256663/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897613/515256663/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897613/515256663/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "38",
      color: "BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 515261092,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471104,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897613/515256663/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Düz Renk %100 Pamuklu Keten Büyük Beden Gömlek"
        },
        {
          id: 322470027,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896307/515255048/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Düz Renk %100 Pamuklu Keten Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470974,
      productCode: "TS-L676",
      name: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      slug: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Saten Büyük Beden Elegant Şık Gömlek, Ürün klasik pantolonlarla çok şık kombinlenebilrmketedir. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458502,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897458/515256518/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897458/515256518/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897458/515256518/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897458/515256518/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "42",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515260989,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 309568731,
          comment: "Gomlek  gorseldekiyla ayni.  Fakat bana biraz kol evi  genis gibi geldi.  kumasi rengi  cok guzel.",
          rate: 4.0,
          userFullName: "S** A**",
          trusted: true,
          commentDateISOtype: "2023-05-30",
          lastModifiedDate: "30 Mayıs 2023"
        },
        {
          id: 211339598,
          comment: "güzel tavsiye ederim duruşu çok güzel hemen kargolandı elime ulaştı teşekkürler",
          rate: 5.0,
          userFullName: "s** ç**",
          trusted: true,
          commentDateISOtype: "2022-09-24",
          lastModifiedDate: "24 Eylül 2022"
        },
        {
          id: 193977471,
          comment: "Dikişler ve saten kumaşı cok iyi.Tesekkurler.",
          rate: 5.0,
          userFullName: "N** K**",
          trusted: true,
          commentDateISOtype: "2022-07-21",
          lastModifiedDate: "21 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 322470974,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897458/515256518/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322470954,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897441/515256486/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322469931,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896216/515254951/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 376595414,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/9/203285117/608550578/1/1_org_zoom.jpg",
          name: "SAX MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470954,
      productCode: "TS-L676",
      name: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      slug: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Saten Büyük Beden Elegant Şık Gömlek, Ürün klasik pantolonlarla çok şık kombinlenebilrmketedir. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458502,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897441/515256486/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897441/515256486/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897441/515256486/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897441/515256486/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515260974,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 305598595,
          comment: "Tereddüt ederek almıştım ama çok beğendim teşekkürler⭐️",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-05-18",
          lastModifiedDate: "18 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 322470974,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897458/515256518/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322470954,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897441/515256486/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322469931,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896216/515254951/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 376595414,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/9/203285117/608550578/1/1_org_zoom.jpg",
          name: "SAX MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470027,
      productCode: "TS-7217",
      name: "Düz Renk %100 Pamuklu Keten Büyük Beden Gömlek",
      slug: "Düz Renk %100 Pamuklu Keten Büyük Beden Gömlek",
      description: "Düz Renk %100 Pamuklu Keten Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz renk kolları bağcıklı pamuklu büyük beden gömlek. Cotton kumaş. Kumaşın tok bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 40 bedendir. Modelin ölçüleri: Boy: 1.76, göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458595,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896307/515255048/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896307/515255048/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896307/515255048/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896307/515255048/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "38",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 515259913,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471104,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897613/515256663/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Düz Renk %100 Pamuklu Keten Büyük Beden Gömlek"
        },
        {
          id: 322470027,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896307/515255048/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Düz Renk %100 Pamuklu Keten Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470002,
      productCode: "TS-7210",
      name: "%100 Pamuk renkli Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "%100 Pamuk renkli Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "%100 Pamuk renkli Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458570,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896288/515255023/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896288/515255023/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896288/515255023/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896288/515255023/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "ORANJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515259910,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322469972,
      productCode: "TS-7209",
      name: "Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek",
      slug: "Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek",
      description: "Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458542,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896255/515254992/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896255/515254992/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896255/515254992/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896255/515254992/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "BEYAZ-PEMBE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515254992,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322469972,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896255/515254992/1/1_org_zoom.jpg",
          name: "BEYAZ-PEMBE",
          description: "Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek"
        },
        {
          id: 322471009,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897505/515256561/1/1_org_zoom.jpg",
          name: "SİYAH-PEMBE",
          description: "Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322469963,
      productCode: "TS-7215",
      name: "Hawai Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek",
      slug: "Hawai Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek",
      description: "Hawai Çiçek Desenli %100 Pamuklu Keten Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458532,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896248/515254983/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896248/515254983/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896248/515254983/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896248/515254983/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "INDIGO ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515259830,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322469931,
      productCode: "TS-L676",
      name: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      slug: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Saten Büyük Beden Elegant Şık Gömlek, Ürün klasik pantolonlarla çok şık kombinlenebilrmketedir. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458502,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896216/515254951/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896216/515254951/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896216/515254951/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896216/515254951/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "FUŞYA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515259794,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 296014801,
          comment: "Ürün çok güzel kumaşı efsane fakat beden küçük geldi",
          rate: 5.0,
          userFullName: "Çiğdem Ç.",
          trusted: true,
          commentDateISOtype: "2023-04-25",
          lastModifiedDate: "25 Nisan 2023"
        }
      ],
      colors: [
        {
          id: 322470974,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897458/515256518/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322470954,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897441/515256486/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322469931,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896216/515254951/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 376595414,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/9/203285117/608550578/1/1_org_zoom.jpg",
          name: "SAX MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321802567,
      productCode: "TS-A143",
      name: "V Yaka Güpür Kol Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "V Yaka Güpür Kol Detaylı Desenli Armani Büyük Beden Bluz",
      description: "V Yaka Güpür Kol Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "V yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246428095,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135126933/513625778/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135126933/513625778/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135126933/513625778/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135126933/513625778/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 513647357,
          stock: 10.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321802567,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135126933/513625778/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "V Yaka Güpür Kol Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292141127,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102415050/466875211/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "V Yaka Güpür Kol Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319094,
      productCode: "TS-1686-5",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900841,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242315324/511383314/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242315324/511383314/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242315324/511383314/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242315324/511383314/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.8,
        totalCount: 5.0
      },
      winnerVariant: "46",
      color: "SİYAH-SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 511383312,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 255199674,
          comment: "likralı esnek duruşu güzel kiloları kapatıyor boyu da diz altı 1.70 boyum var",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-01-15",
          lastModifiedDate: "15 Ocak 2023"
        },
        {
          id: 246197734,
          comment: "Ürün kalitesi harika. Anneme belki 10 tane aldım hepsinden de çok memnun kaldık. Satıcı çok ilgili.",
          rate: 5.0,
          userFullName: "Z** K**",
          trusted: true,
          commentDateISOtype: "2022-12-27",
          lastModifiedDate: "27 Aralık 2022"
        },
        {
          id: 197979042,
          comment: "çok güzel bir elbise üzerime tam oldu çok beğendim herkese tavsiye ederim gönül rahatlığıyla alabilirler özellikle benim gibi kilolu olan bayanlar kurtarıcı bir elbise",
          rate: 5.0,
          userFullName: "F** K**",
          trusted: true,
          commentDateISOtype: "2022-08-10",
          lastModifiedDate: "10 Ağustos 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320319053,
      productCode: "TS-1686-6",
      name: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      slug: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      description: "Plus kadın Desenli Sandy Kısa Kol Battal Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün Battal Bedendir. Yuvarlak yaka ve kısa kollu, midi boy elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız. Mankenin üzerindeki beden 50 bedendir. Manken ölçüleri Boy: 1.75 Göğüs: 98 Bel: 84 Basen: 116 cm'dir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 264900805,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562699/511381111/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562699/511381111/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/1/133562699/511381111/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562699/511381111/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/1/133562699/511381111/4/4_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "SİYAH-ORANJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 511381111,
          stock: 4.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292141127,
      productCode: "TS-A143",
      name: "V Yaka Güpür Kol Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "V Yaka Güpür Kol Detaylı Desenli Armani Büyük Beden Bluz",
      description: "V Yaka Güpür Kol Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "V yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246428095,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102415050/466875211/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102415050/466875211/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102415050/466875211/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102415050/466875211/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 2.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466885826,
          stock: 9.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321802567,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135126933/513625778/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "V Yaka Güpür Kol Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292141127,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102415050/466875211/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "V Yaka Güpür Kol Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290597257,
      productCode: "TS-7170",
      name: "Kadın Patchwork Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Patchwork Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Patchwork Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121160650,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785040/464247199/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785040/464247199/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785040/464247199/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/19/100785040/464247199/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "ŞAL DESEN",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 464252562,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 290597257,
          imageUrl: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785040/464247199/1/1_org_zoom.jpg",
          name: "ŞAL DESEN",
          description: "Kadın Patchwork Renkli Dijital Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 139940212,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126750680/238018200/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290596034,
      productCode: "TS-7208",
      name: "Kısa Kol Düz Renk Önü Düğmeli Uzun Boy Pamuklu Büyük Beden Gömlek",
      slug: "Kısa Kol Düz Renk Önü Düğmeli Uzun Boy Pamuklu Büyük Beden Gömlek",
      description: "Kısa Kol Düz Renk Önü Düğmeli Uzun Boy Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Kısa Kol Düz Renk Tunik Boy Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 245135699,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/18/100783731/464245123/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/18/100783731/464245123/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/18/100783731/464245123/2/2_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "MAVİ-ÇİZGİLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 464245123,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Klasik"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Organik"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366579,
      productCode: "TS-7197",
      name: "Renkli Düz Renk Pamuklu Büyük Beden Gömlek",
      slug: "Renkli Düz Renk Pamuklu Büyük Beden Gömlek",
      description: "Renkli Düz Renk Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 36 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117250,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949127/450750854/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949127/450750854/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949127/450750854/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949127/450750854/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 450750854,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366570,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949120/450750845/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Renkli Düz Renk Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 283366579,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949127/450750854/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Renkli Düz Renk Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366572,
      productCode: "TS-7128",
      name: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      slug: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      description: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558085,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949123/450750848/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949123/450750848/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949123/450750848/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949123/450750848/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450750848,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366510,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949058/450750768/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        },
        {
          id: 283366572,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949123/450750848/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        },
        {
          id: 95363891,
          imageUrl: "https://cdn.dsmcdn.com/ty317/product/media/images/20220131/23/39959091/158963905/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366570,
      productCode: "TS-7197",
      name: "Renkli Düz Renk Pamuklu Büyük Beden Gömlek",
      slug: "Renkli Düz Renk Pamuklu Büyük Beden Gömlek",
      description: "Renkli Düz Renk Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 36 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117250,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949120/450750845/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949120/450750845/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949120/450750845/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949120/450750845/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 450757992,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366570,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949120/450750845/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Renkli Düz Renk Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 283366579,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949127/450750854/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Renkli Düz Renk Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366560,
      productCode: "TS-4990",
      name: "Renkli Çiçek Desenli Viskon Büyük Beden Gömlek",
      slug: "Renkli Çiçek Desenli Viskon Büyük Beden Gömlek",
      description: "Renkli Çiçek Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40938540,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949112/450750835/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949112/450750835/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949112/450750835/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949112/450750835/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "BEYAZ ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450750835,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 49628584,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/17/11426424/87933141/1/1_org_zoom.jpg",
          name: "LACİ ÇİÇEKLİ",
          description: "Kadın Lacivert Çiçekli Desenli Viskon Gömlek"
        },
        {
          id: 48711220,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10594739/86355476/1/1_org_zoom.jpg",
          name: "LACİ KIRMIZI ÇİÇEK",
          description: "Kadın Lacivert Desenli Viskon Gömlek"
        },
        {
          id: 283366560,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949112/450750835/1/1_org_zoom.jpg",
          name: "BEYAZ ÇİÇEKLİ",
          description: "Renkli Çiçek Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366510,
      productCode: "TS-7128",
      name: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      slug: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      description: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558085,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949058/450750768/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949058/450750768/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949058/450750768/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949058/450750768/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450757907,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 195220288,
          comment: "Kalitesi çok güzel, annem severek giyiyor.",
          rate: 5.0,
          userFullName: "F** Y**",
          trusted: true,
          commentDateISOtype: "2022-07-28",
          lastModifiedDate: "28 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 283366510,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949058/450750768/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        },
        {
          id: 283366572,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949123/450750848/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        },
        {
          id: 95363891,
          imageUrl: "https://cdn.dsmcdn.com/ty317/product/media/images/20220131/23/39959091/158963905/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366470,
      productCode: "TS-7199",
      name: "Renkli Şal Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      slug: "Renkli Şal Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      description: "Renkli Şal Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117210,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949017/450750712/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949017/450750712/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949017/450750712/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949017/450750712/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "BEYAZ-MOR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450757817,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 257773109,
          comment: "Bu markanın gömleklerin  modelleri çok rahat,duruşu güzel,bir çok modelini aldım.",
          rate: 5.0,
          userFullName: "Ö** T**",
          trusted: true,
          commentDateISOtype: "2023-01-19",
          lastModifiedDate: "19 Ocak 2023"
        }
      ],
      colors: [
        {
          id: 283364873,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947064/450748107/1/1_org_zoom.jpg",
          name: "BEYAZ-MİNT",
          description: "Renkli Şal Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        },
        {
          id: 283366470,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949017/450750712/1/1_org_zoom.jpg",
          name: "BEYAZ-MOR",
          description: "Renkli Şal Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Şal Desen"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283365127,
      productCode: "TS-7184",
      name: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek",
      slug: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek",
      description: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117417,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947312/450748400/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947312/450748400/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947312/450748400/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92947312/450748400/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 193.59,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450755792,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283365078,
      productCode: "TS-7185191",
      name: "Renkli Çiçek Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      slug: "Renkli Çiçek Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      description: "Renkli Çiçek Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117375,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947265/450748342/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947265/450748342/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947265/450748342/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947265/450748342/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "LEYLAK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450748342,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283365044,
      productCode: "TS-7185",
      name: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      slug: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      description: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117348,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947230/450748297/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947230/450748297/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92947230/450748297/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947230/450748297/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450748297,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366727,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949290/450751088/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        },
        {
          id: 283365044,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947230/450748297/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Renkli Puantiye Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283365043,
      productCode: "TS-7183",
      name: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek",
      slug: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek",
      description: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117347,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947232/450748296/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947232/450748296/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947232/450748296/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92947232/450748296/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "FUŞYA-ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450755649,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366747,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949310/450751164/1/1_org_zoom.jpg",
          name: "TURKUAZ-ÇİÇEKLİ",
          description: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek"
        },
        {
          id: 283365043,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947232/450748296/1/1_org_zoom.jpg",
          name: "FUŞYA-ÇİÇEKLİ",
          description: "Çiçek Desenli Pamuklu Truvakar Kol Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283364873,
      productCode: "TS-7199",
      name: "Renkli Şal Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      slug: "Renkli Şal Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      description: "Renkli Şal Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117210,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947064/450748107/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947064/450748107/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947064/450748107/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947064/450748107/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "BEYAZ-MİNT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450755312,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283364873,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947064/450748107/1/1_org_zoom.jpg",
          name: "BEYAZ-MİNT",
          description: "Renkli Şal Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        },
        {
          id: 283366470,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949017/450750712/1/1_org_zoom.jpg",
          name: "BEYAZ-MOR",
          description: "Renkli Şal Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Şal Desen"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 280072901,
      productCode: "TS-1774",
      name: "Kadın Renkli Viskon Uzun Kol Büyük Beden Tunik Elbise",
      slug: "Kadın Renkli Viskon Uzun Kol Büyük Beden Tunik Elbise",
      description: "Kadın Renkli Viskon Uzun Kol Büyük Beden Tunik Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir.,"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 236619110,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty393/product/media/images/20220412/21/89494640/446003156/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty393/product/media/images/20220412/21/89494640/446003156/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty393/product/media/images/20220412/21/89494640/446003156/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty393/product/media/images/20220412/21/89494640/446003156/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 446016241,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 184777433,
          comment: "çok beğendim değişik hoş bir elbise tam mevsimlik paketleme gönderi notunuz herşey güzel teşekkürler",
          rate: 5.0,
          userFullName: "K** S** s**",
          trusted: true,
          commentDateISOtype: "2022-05-31",
          lastModifiedDate: "31 Mayıs 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 33,
          key: "Desen",
          value: "Renkli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Kısa"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 176474141,
      productCode: "TS-YY656",
      name: "Viskon Cep Detaylı Büyük Beden Fashion Anne Yelek",
      slug: "Viskon Cep Detaylı Büyük Beden Fashion Anne Yelek",
      description: "Viskon Cep Detaylı Büyük Beden Fashion Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 151144321,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243086/287529546/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243086/287529546/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211103/22/165243086/287529546/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty223/product/media/images/20211103/22/165243086/287529546/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "LACİVERT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 287529546,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 176474141,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243086/287529546/1/1_org_zoom.jpg",
          name: "LACİVERT",
          description: "Viskon Cep Detaylı Büyük Beden Fashion Anne Yelek"
        },
        {
          id: 176474280,
          imageUrl: "https://cdn.dsmcdn.com/ty222/product/media/images/20211103/22/165243222/287529712/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Viskon Cep Detaylı Büyük Beden Fashion Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 171619424,
      productCode: "TS-7181",
      name: "Kadın Ebruli Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Ebruli Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Ebruli Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon gömlek. Viskon kumaş. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 40 bedendir. Modelin ölçüleri: Boy: 1.76, göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 146726594,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794235/280055341/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794235/280055341/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794235/280055341/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794235/280055341/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "46",
      color: "TURUNCU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 280055341,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 155478291,
          comment: "Annem çok beğenmişti kumaşı çok güzel.",
          rate: 5.0,
          userFullName: "S** B**",
          trusted: true,
          commentDateISOtype: "2022-01-23",
          lastModifiedDate: "23 Ocak 2022"
        }
      ],
      colors: [
        {
          id: 171619405,
          imageUrl: "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794220/280055321/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Ebruli Renkli Dijital Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 171619424,
          imageUrl: "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794235/280055341/1/1_org_zoom.jpg",
          name: "TURUNCU",
          description: "Kadın Ebruli Renkli Dijital Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 171619405,
      productCode: "TS-7181",
      name: "Kadın Ebruli Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Ebruli Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Ebruli Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon gömlek. Viskon kumaş. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 40 bedendir. Modelin ölçüleri: Boy: 1.76, göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 146726594,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794220/280055321/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794220/280055321/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794220/280055321/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794220/280055321/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 4.0
      },
      winnerVariant: "46",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 280066894,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 153518938,
          comment: "Anneme almıştım herkes çok beğendi diğer renginide sipariş verdim",
          rate: 5.0,
          userFullName: "S** B**",
          trusted: true,
          commentDateISOtype: "2022-01-19",
          lastModifiedDate: "19 Ocak 2022"
        },
        {
          id: 168421726,
          comment: "modelde daha şifon tarzi akan bir gömlek gibi duruyor ama kumaş olarak şile bezinden hallice. renk olarak ayni resimdeki gibi. pek beğenemedim",
          rate: 4.0,
          userFullName: "B** K** S**",
          trusted: true,
          commentDateISOtype: "2022-03-18",
          lastModifiedDate: "18 Mart 2022"
        },
        {
          id: 306880317,
          comment: "kalibi cok gemisti iade ettik",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-05-22",
          lastModifiedDate: "22 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 171619405,
          imageUrl: "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794220/280055321/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Ebruli Renkli Dijital Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 171619424,
          imageUrl: "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/22/159794235/280055341/1/1_org_zoom.jpg",
          name: "TURUNCU",
          description: "Kadın Ebruli Renkli Dijital Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148597054,
      productCode: "TS-SR14",
      name: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      slug: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kol ve Yaka Simli Örme Büyük Beden Yelek, Ürün Örme Triko Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 128104302,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494373/248704272/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494373/248704272/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494373/248704272/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/1/135494373/248704272/2/2_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248715526,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 148596950,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494272/248704161/0/0_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148596890,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494203/248704101/0/0_org_zoom.jpg",
          name: "BORDO",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148597054,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494373/248704272/0/0_org_zoom.jpg",
          name: "LACİ",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148596971,
          imageUrl: "https://cdn.dsmcdn.com/ty308/product/media/images/20220123/21/34072157/248715404/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148596971,
      productCode: "TS-SR14",
      name: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      slug: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kol ve Yaka Simli Örme Büyük Beden Yelek, Ürün Örme Triko Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 128104302,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty308/product/media/images/20220123/21/34072157/248715404/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty308/product/media/images/20220123/21/34072157/248715404/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072157/248715404/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty306/product/media/images/20220123/21/34072157/248715404/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "46-48",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46-48",
          itemNumber: 248715404,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 168495598,
          comment: "Anneme aldım çok güzrl oldu",
          rate: 5.0,
          userFullName: "G** G**",
          trusted: true,
          commentDateISOtype: "2022-03-18",
          lastModifiedDate: "18 Mart 2022"
        },
        {
          id: 176505630,
          comment: "Müthiş bir kalite, sıcacık tutuyor.",
          rate: 5.0,
          userFullName: "E** A**",
          trusted: true,
          commentDateISOtype: "2022-04-21",
          lastModifiedDate: "21 Nisan 2022"
        }
      ],
      colors: [
        {
          id: 148596950,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494272/248704161/0/0_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148596890,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494203/248704101/0/0_org_zoom.jpg",
          name: "BORDO",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148597054,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494373/248704272/0/0_org_zoom.jpg",
          name: "LACİ",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148596971,
          imageUrl: "https://cdn.dsmcdn.com/ty308/product/media/images/20220123/21/34072157/248715404/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148596950,
      productCode: "TS-SR14",
      name: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      slug: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kol ve Yaka Simli Örme Büyük Beden Yelek, Ürün Örme Triko Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 128104302,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494272/248704161/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494272/248704161/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494272/248704161/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494272/248704161/2/2_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248715386,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 148596950,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494272/248704161/0/0_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148596890,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494203/248704101/0/0_org_zoom.jpg",
          name: "BORDO",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148597054,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494373/248704272/0/0_org_zoom.jpg",
          name: "LACİ",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148596971,
          imageUrl: "https://cdn.dsmcdn.com/ty308/product/media/images/20220123/21/34072157/248715404/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148596890,
      productCode: "TS-SR14",
      name: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      slug: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kol ve Yaka Simli Örme Büyük Beden Yelek, Ürün Örme Triko Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 128104302,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494203/248704101/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494203/248704101/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494203/248704101/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494203/248704101/2/2_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248715298,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 148596950,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494272/248704161/0/0_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148596890,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494203/248704101/0/0_org_zoom.jpg",
          name: "BORDO",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148597054,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494373/248704272/0/0_org_zoom.jpg",
          name: "LACİ",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        },
        {
          id: 148596971,
          imageUrl: "https://cdn.dsmcdn.com/ty308/product/media/images/20220123/21/34072157/248715404/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kol Ve Yaka Simli Örme Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140471273,
      productCode: "TS-7164",
      name: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek ., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121207830,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/19/127261728/238640675/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/19/127261728/238640675/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/19/127261728/238640675/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/19/127261728/238640675/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 238640675,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 140011437,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/19/126836016/238104874/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek"
        },
        {
          id: 140011459,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126838119/238104896/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek"
        },
        {
          id: 140471273,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/19/127261728/238640675/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 140440462,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/18/127249586/238604285/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 141179620,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/18/128000500/239527969/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140441937,
      productCode: "TS-7173",
      name: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121472402,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/18/127255503/238605849/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/18/127255503/238605849/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/18/127255503/238605849/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210907/18/127255503/238605849/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "TURUNCU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 238813281,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140440462,
      productCode: "TS-7164",
      name: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek ., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121207830,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/18/127249586/238604285/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/18/127249586/238604285/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/18/127249586/238604285/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/18/127249586/238604285/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "TURKUAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 238740987,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 140011437,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/19/126836016/238104874/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek"
        },
        {
          id: 140011459,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126838119/238104896/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek"
        },
        {
          id: 140471273,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/19/127261728/238640675/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 140440462,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/18/127249586/238604285/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 141179620,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/18/128000500/239527969/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turkuaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140428262,
      productCode: "TS-7162",
      name: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121161489,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/16/127238643/238589794/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/16/127238643/238589794/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/16/127238643/238589794/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/16/127238643/238589794/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "44",
      color: "YEŞİL DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 238683715,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 306970605,
          comment: "Annem için başka bir rengini de almıştım. o kadar çok beğendi ki dokusunu bir tane daha sipariş verdik. terletme yapmıyor. eğil efil çok hoş duruyor",
          rate: 5.0,
          userFullName: "G** A**",
          trusted: true,
          commentDateISOtype: "2023-05-22",
          lastModifiedDate: "22 Mayıs 2023"
        },
        {
          id: 282341049,
          comment: "Muhteşem gömlek, çok kaliteli❤️Notunuz için teşekkür ederiz🥰",
          rate: 5.0,
          userFullName: "Aytan K.",
          trusted: true,
          commentDateISOtype: "2023-03-23",
          lastModifiedDate: "23 Mart 2023"
        }
      ],
      colors: [
        {
          id: 140026468,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/18/126808343/238125123/1/1_org_zoom.jpg",
          name: "PUDRA DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Gömlek"
        },
        {
          id: 140428262,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/16/127238643/238589794/1/1_org_zoom.jpg",
          name: "YEŞİL DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 139941375,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747494/238019538/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 139941490,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747918/238019677/1/1_org_zoom.jpg",
          name: "KAHVE DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140026468,
      productCode: "TS-7162",
      name: "Kadın Zebra Leopar Desenli Viskon Gömlek",
      slug: "Kadın Zebra Leopar Desenli Viskon Gömlek",
      description: "Kadın Zebra Leopar Desenli Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon gömlek., %100 viskon kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121161489,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/18/126808343/238125123/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/18/126808343/238125123/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/18/126808343/238125123/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/18/126808343/238125123/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "PUDRA DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 238221391,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 140026468,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/18/126808343/238125123/1/1_org_zoom.jpg",
          name: "PUDRA DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Gömlek"
        },
        {
          id: 140428262,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/16/127238643/238589794/1/1_org_zoom.jpg",
          name: "YEŞİL DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 139941375,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747494/238019538/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 139941490,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747918/238019677/1/1_org_zoom.jpg",
          name: "KAHVE DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140011459,
      productCode: "TS-7164",
      name: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek",
      slug: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek",
      description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse deniz kabuğu desenli kolları bağcıklı pamuklu büyük beden gömlek ., %100 cotton kumaş., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121207830,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126838119/238104896/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126838119/238104896/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126838119/238104896/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126838119/238104896/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "XL",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 238104896,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 192346587,
          comment: "Ürün gayet güzeldi fakat beden olarak biraz küçüktü",
          rate: 5.0,
          userFullName: "M** d**",
          trusted: true,
          commentDateISOtype: "2022-07-11",
          lastModifiedDate: "11 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 140011437,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/19/126836016/238104874/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek"
        },
        {
          id: 140011459,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/19/126838119/238104896/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Gömlek"
        },
        {
          id: 140471273,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210907/19/127261728/238640675/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 140440462,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/18/127249586/238604285/1/1_org_zoom.jpg",
          name: "TURKUAZ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 141179620,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/18/128000500/239527969/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 139941375,
      productCode: "TS-7162",
      name: "Kadın Bordo Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Bordo Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Bordo Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121161489,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747494/238019538/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747494/238019538/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747494/238019538/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/14/126747494/238019538/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 238019538,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 140026468,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/18/126808343/238125123/1/1_org_zoom.jpg",
          name: "PUDRA DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Gömlek"
        },
        {
          id: 140428262,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210907/16/127238643/238589794/1/1_org_zoom.jpg",
          name: "YEŞİL DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 139941375,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747494/238019538/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 139941490,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126747918/238019677/1/1_org_zoom.jpg",
          name: "KAHVE DESENLİ",
          description: "Kadın Zebra Leopar Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 139940869,
      productCode: "TS-7171",
      name: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121161113,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/14/126745245/238018974/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/14/126745245/238018974/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126745245/238018974/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126745245/238018974/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "40",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 238018974,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 194028958,
          comment: "Gerçekten çok güzel annem çok beğendi",
          rate: 5.0,
          userFullName: "B** S** B**",
          trusted: true,
          commentDateISOtype: "2022-07-21",
          lastModifiedDate: "21 Temmuz 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 139940212,
      productCode: "TS-7170",
      name: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121160650,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126750680/238018200/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126750680/238018200/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/14/126750680/238018200/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/14/126750680/238018200/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "46",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 238132637,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 224218950,
          comment: "uzulerek iade ediyorum gömleğin hutbeyi çok güzel  bedenlerin kalıbı biraz büyük galiba 46 giyerken 44 aldım 42 olsa çok iyi olurmuş giyeceklere güle güle giysin",
          rate: 5.0,
          userFullName: "g** y**",
          trusted: true,
          commentDateISOtype: "2022-11-03",
          lastModifiedDate: "3 Kasım 2022"
        }
      ],
      colors: [
        {
          id: 290597257,
          imageUrl: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785040/464247199/1/1_org_zoom.jpg",
          name: "ŞAL DESEN",
          description: "Kadın Patchwork Renkli Dijital Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 139940212,
          imageUrl: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126750680/238018200/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 139939843,
      productCode: "TS-7175",
      name: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Renkli Dijital Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121160375,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/15/126766390/238017742/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/15/126766390/238017742/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210906/15/126766390/238017742/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/15/126766390/238017742/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "HAKİ DESENLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 238017742,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124088698,
      productCode: "TS-7159",
      name: "Piti Kareli Ince Yazlık Pamuklu Büyük Beden Gömlek",
      slug: "Piti Kareli Ince Yazlık Pamuklu Büyük Beden Gömlek",
      description: "Piti Kareli Ince Yazlık Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Piti Kareli İnce Yazlık Pamuklu Büyük Beden Gömlek, %100 Cotton kumaş., Kumaşın ince bir dokusu vardır., İç gösterebilir, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109224525,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/15/108973010/200944733/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/15/108973010/200944733/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty142/product/media/images/20210710/15/108973010/200944733/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/15/108973010/200944733/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 200965914,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 124088698,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/15/108973010/200944733/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Piti Kareli Ince Yazlık Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 124088688,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/15/108973089/200944715/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Piti Kareli Ince Yazlık Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Ekose / Kareli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124013108,
      productCode: "TS-7124",
      name: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      slug: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558041,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183227/200722385/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183227/200722385/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183227/200722385/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183227/200722385/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.6,
        totalCount: 5.0
      },
      winnerVariant: "XL",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 200722385,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 134444499,
          comment: "Bilerek 5 verdim . Yorumlara bakarak bir küçük aldım 44 bedenim xl aldım son düğme kapanmadı kumaşı terletecek bi kumaş . Asla bu fiyat a değmez . İade",
          rate: 1.0,
          userFullName: "H** T** Ç**",
          trusted: true,
          commentDateISOtype: "2021-10-20",
          lastModifiedDate: "20 Ekim 2021"
        },
        {
          id: 304090955,
          comment: "hediye aldım ama kalıp dar geldi urun kalitesi güzeldi",
          rate: 4.0,
          userFullName: "n** d**",
          trusted: true,
          commentDateISOtype: "2023-05-14",
          lastModifiedDate: "14 Mayıs 2023"
        },
        {
          id: 177823283,
          comment: "Omuz kısmı biraz dar geldi ama onun dışında gayet güzel.",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-04-26",
          lastModifiedDate: "26 Nisan 2022"
        }
      ],
      colors: [
        {
          id: 95363931,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183255/158956794/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 95363843,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183281/158956704/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 124013108,
          imageUrl: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183227/200722385/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 95363890,
          imageUrl: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183046/158963902/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Krep"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124013103,
      productCode: "TS-6968",
      name: "Kadın Sarı Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Sarı Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Sarı Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün L bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41427435,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888805/200701609/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888805/200701609/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/9/108888805/200701609/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888805/200701609/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 200701609,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 151425287,
          comment: "gömlek güzel fakat biraz ince malesef ki bu fiyatı haketmiyor",
          rate: 3.0,
          userFullName: "s** t**",
          trusted: true,
          commentDateISOtype: "2022-01-07",
          lastModifiedDate: "7 Ocak 2022"
        }
      ],
      colors: [
        {
          id: 124013103,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888805/200701609/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Kadın Sarı Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 49280155,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200918/15/11117482/87325762/1/1_org_zoom.jpg",
          name: "LEOPAR",
          description: "Kadın Kahverengi Desenli Viskon Gömlek"
        },
        {
          id: 49376664,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200919/11/11195510/87469413/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Desenli Viskon Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 103198449,
      productCode: "TS-YY7001",
      name: "Büyük Beden Yelek",
      slug: "Büyük Beden Yelek",
      description: "Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Ince sandy yazlık simli büyük beden fashion anne yelek, ürün çok şık bir kumaşa ve görünüme sahiptir, kırışma zor olmaz, çamaşır makinesinde, düşük derece ayarında yıkanabilir, sağlam bir kumaş çeşididir, renkleri çok canlı göstermesinden ötürü, oldukça şık görünmektedir, ürün, dökümlü ve kullanış açısından çok rahat olan bir kumaşa sahiptir, kumaş oldukça parlak bir görünüme sahiptir, mankenin üzerindeki ürün 44-46 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 89922332,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty108/product/media/images/20210504/16/85515557/170621810/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210504/16/85515557/170621810/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210504/16/85515557/170621810/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty109/product/media/images/20210504/16/85515557/170621810/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 6.0
      },
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 170749172,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 123980656,
          comment: "Çok şık ve kullanışlı bir yelek. Kilolular için kurtarıcı",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-08-23",
          lastModifiedDate: "23 Ağustos 2021"
        },
        {
          id: 156769295,
          comment: "cok güzel begendim teşekkür ederim",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-01-28",
          lastModifiedDate: "28 Ocak 2022"
        },
        {
          id: 179479695,
          comment: "Ürün gayet güzel kaliteli her mevsim giyilebilir emeğinize sağlık",
          rate: 5.0,
          userFullName: "B** C**",
          trusted: true,
          commentDateISOtype: "2022-05-06",
          lastModifiedDate: "6 Mayıs 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95363946,
      productCode: "TS-7909",
      name: "Kısa Kol Düz Renk Uzun Boy Pamuklu Büyük Beden Gömlek",
      slug: "Kısa Kol Düz Renk Uzun Boy Pamuklu Büyük Beden Gömlek",
      description: "Kısa Kol Düz Renk Uzun Boy Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Kısa Kol Düz Renk Tunik Boy Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558132,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty53/product/media/images/20210331/3/76484016/158956809/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty53/product/media/images/20210331/3/76484016/158956809/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty43/product/media/images/20210331/3/76484016/158956809/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty49/product/media/images/20210331/3/76484016/158956809/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty40/product/media/images/20210331/3/76484016/158956809/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "M",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "M",
          itemNumber: 167058104,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 241500152,
          comment: "anneme hediye aldim çok guzeldi",
          rate: 5.0,
          userFullName: "E** G**",
          trusted: true,
          commentDateISOtype: "2022-12-15",
          lastModifiedDate: "15 Aralık 2022"
        }
      ],
      colors: [
        {
          id: 95363946,
          imageUrl: "https://cdn.dsmcdn.com/ty53/product/media/images/20210331/3/76484016/158956809/0/0_org_zoom.jpg",
          name: "MAVİ",
          description: "Kısa Kol Düz Renk Uzun Boy Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 290597321,
          imageUrl: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/19/100785103/464247275/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Kısa Kol Düz Renk Uzun Boy Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95363891,
      productCode: "TS-7128",
      name: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558085,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty317/product/media/images/20220131/23/39959091/158963905/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty317/product/media/images/20220131/23/39959091/158963905/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty317/product/media/images/20220131/23/39959091/158963905/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty315/product/media/images/20220131/23/39959091/158963905/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 4.0
      },
      winnerVariant: "XL",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 158963918,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 206764889,
          comment: "ürün çok güzel ve kumaşı çok kaliteli",
          rate: 5.0,
          userFullName: "B** s**",
          trusted: true,
          commentDateISOtype: "2022-09-11",
          lastModifiedDate: "11 Eylül 2022"
        },
        {
          id: 186102428,
          comment: "Tam bedeninize göre alabilirsiniz. Güzel geldi ama kumaş olarak ince",
          rate: 5.0,
          userFullName: "Ö** B**",
          trusted: true,
          commentDateISOtype: "2022-06-07",
          lastModifiedDate: "7 Haziran 2022"
        }
      ],
      colors: [
        {
          id: 283366510,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949058/450750768/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        },
        {
          id: 283366572,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949123/450750848/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek"
        },
        {
          id: 95363891,
          imageUrl: "https://cdn.dsmcdn.com/ty317/product/media/images/20220131/23/39959091/158963905/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Çiçek Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95363890,
      productCode: "TS-7124",
      name: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      slug: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558041,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183046/158963902/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183046/158963902/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183046/158963902/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183046/158963902/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.33333333333333,
        totalCount: 48.0
      },
      winnerVariant: "XL",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 158963902,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 170037095,
          comment: "Ürünü annem için aldım tam beden alınabilir kumaşı çok güzel 🥰",
          rate: 5.0,
          userFullName: "A** A**",
          trusted: true,
          commentDateISOtype: "2022-03-24",
          lastModifiedDate: "24 Mart 2022"
        },
        {
          id: 174799758,
          comment: "Annem  için aldım 48-50  beden 4 xl  güzel oldu  modelide şık başka renklerinide  alacağız.",
          rate: 5.0,
          userFullName: "S** G**",
          trusted: true,
          commentDateISOtype: "2022-04-15",
          lastModifiedDate: "15 Nisan 2022"
        },
        {
          id: 220785643,
          comment: "anneme aldim, begendi",
          rate: 4.0,
          userFullName: "l** s**",
          trusted: true,
          commentDateISOtype: "2022-10-23",
          lastModifiedDate: "23 Ekim 2022"
        }
      ],
      colors: [
        {
          id: 95363931,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183255/158956794/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 95363843,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183281/158956704/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 124013108,
          imageUrl: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183227/200722385/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 95363890,
          imageUrl: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183046/158963902/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Krep"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 87737017,
      productCode: "TS-SR06",
      name: "Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka",
      slug: "Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka",
      description: "Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka, Ürün Örme Triko Hırkadır, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün L bedendir. Modelin Ölçüleri: Boy: 1.72 Göğüs: 91, Bel: 70, Kalça: 102 T-Shirt Bedeni:M bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 75684055,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty77/product/media/images/20210227/19/67414856/146773303/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty77/product/media/images/20210227/19/67414856/146773303/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty78/product/media/images/20210227/19/67414856/146773303/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty78/product/media/images/20210227/19/67414856/146773303/2/2_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 3.0
      },
      winnerVariant: "42-44",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 146931780,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 161401760,
          comment: "Annem için aldım kalitesini ve dokusunu çok beğendi hoş bir hırka",
          rate: 5.0,
          userFullName: "E** D** K**",
          trusted: true,
          commentDateISOtype: "2022-02-16",
          lastModifiedDate: "16 Şubat 2022"
        },
        {
          id: 244982754,
          comment: "Kalite olarak Pek beğenmedik renk olarak güzel",
          rate: 2.0,
          userFullName: "S** A**",
          trusted: true,
          commentDateISOtype: "2022-12-23",
          lastModifiedDate: "23 Aralık 2022"
        }
      ],
      colors: [
        {
          id: 87737017,
          imageUrl: "https://cdn.dsmcdn.com/ty77/product/media/images/20210227/19/67414856/146773303/0/0_org_zoom.jpg",
          name: "BORDO",
          description: "Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 87733194,
          imageUrl: "https://cdn.dsmcdn.com/ty79/product/media/images/20210227/18/67413131/146769296/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 87733194,
      productCode: "TS-SR06",
      name: "Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka",
      slug: "Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka",
      description: "Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka, Ürün Örme Triko Hırkadır, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün L bedendir. Modelin Ölçüleri: Boy: 1.72 Göğüs: 91, Bel: 70, Kalça: 102 T-Shirt Bedeni:M bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 75684055,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty79/product/media/images/20210227/18/67413131/146769296/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty79/product/media/images/20210227/18/67413131/146769296/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty77/product/media/images/20210227/18/67413131/146769296/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty77/product/media/images/20210227/18/67413131/146769296/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty79/product/media/images/20210227/18/67413131/146769296/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 379.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 146931790,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 87737017,
          imageUrl: "https://cdn.dsmcdn.com/ty77/product/media/images/20210227/19/67414856/146773303/0/0_org_zoom.jpg",
          name: "BORDO",
          description: "Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka"
        },
        {
          id: 87733194,
          imageUrl: "https://cdn.dsmcdn.com/ty79/product/media/images/20210227/18/67413131/146769296/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Mürekkep Desenli Örme Düğmeli Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49608622,
      productCode: "TYC00041758595",
      name: "Kadın Beyaz Desenli Viskon Gömlek",
      slug: "Kadın Beyaz Desenli Viskon Gömlek",
      description: "Kadın Beyaz Desenli Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan desenli viskon gömlek., %100 viskon kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün l bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41745245,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/15/11407259/87883896/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/15/11407259/87883896/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11407259/87883896/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11407259/87883896/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11407259/87883896/4/4_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 2.0,
      ratingScore: {
        averageRating: 2.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "BEYAZ PUANTİYELİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87899788,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 263289248,
          comment: "kumaşı kalitesiz. iade etmedigime cok pismanim",
          rate: 2.0,
          userFullName: "H** A**",
          trusted: true,
          commentDateISOtype: "2023-01-31",
          lastModifiedDate: "31 Ocak 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49376664,
      productCode: "TS-6968",
      name: "Kadın Beyaz Desenli Viskon Gömlek",
      slug: "Kadın Beyaz Desenli Viskon Gömlek",
      description: "Kadın Beyaz Desenli Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan desenli viskon gömlek., viskon kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün l bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41427435,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200919/11/11195510/87469413/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200919/11/11195510/87469413/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200919/11/11195510/87469413/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200919/11/11195510/87469413/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200919/11/11195510/87469413/4/4_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87469413,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 124013103,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888805/200701609/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Kadın Sarı Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 49280155,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200918/15/11117482/87325762/1/1_org_zoom.jpg",
          name: "LEOPAR",
          description: "Kadın Kahverengi Desenli Viskon Gömlek"
        },
        {
          id: 49376664,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200919/11/11195510/87469413/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Desenli Viskon Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49280155,
      productCode: "TS-6968",
      name: "Kadın Kahverengi Desenli Viskon Gömlek",
      slug: "Kadın Kahverengi Desenli Viskon Gömlek",
      description: "Kadın Kahverengi Desenli Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan desenli viskon gömlek. %100 viskon kumaş. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41427435,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200918/15/11117482/87325762/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200918/15/11117482/87325762/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200918/15/11117482/87325762/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200918/15/11117482/87325762/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87339318,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 124013103,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888805/200701609/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Kadın Sarı Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 49280155,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200918/15/11117482/87325762/1/1_org_zoom.jpg",
          name: "LEOPAR",
          description: "Kadın Kahverengi Desenli Viskon Gömlek"
        },
        {
          id: 49376664,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200919/11/11195510/87469413/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kadın Beyaz Desenli Viskon Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 309342932,
      productCode: "TS-1697005",
      name: "Kadın Etnik Desenli Viskon Büyük Beden Salaş Pantolon",
      slug: "Kadın Etnik Desenli Viskon Büyük Beden Salaş Pantolon",
      description: "Kadın Etnik Desenli Viskon Büyük Beden Salaş Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli viskon büyük beden salaş pantolon, ürünün ön kısmı büzgülüdür, bel kısmı lastiklidir, ürünün kumaşı pamuklu viskondur ve kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 38 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 257444853,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty445/product/media/images/20220603/20/120793902/493835450/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty445/product/media/images/20220603/20/120793902/493835450/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty444/product/media/images/20220603/20/120793902/493835450/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty446/product/media/images/20220603/20/120793902/493835450/3/3_org_zoom.jpg"
      ],
      price: 249.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 249.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 249.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "38",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 493850025,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 1,
          key: "Bel",
          value: "Yüksek Bel"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Geniş Paça"
        },
        {
          id: 33,
          key: "Desen",
          value: "Etnik"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "İnce"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 247948159,
      productCode: "TS-E0021",
      name: "Etnik Desenli Penye Beli Lastikli Kalem Büyük Beden Etek",
      slug: "Etnik Desenli Penye Beli Lastikli Kalem Büyük Beden Etek",
      description: "Etnik Desenli Penye Beli Lastikli Kalem Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçü"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 215314770,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty341/product/media/images/20220223/20/57711761/396709037/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty341/product/media/images/20220223/20/57711761/396709037/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/20/57711761/396709037/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/20/57711761/396709037/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "BORDO-ETNİK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 396748567,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 247948159,
          imageUrl: "https://cdn.dsmcdn.com/ty341/product/media/images/20220223/20/57711761/396709037/1/1_org_zoom.jpg",
          name: "BORDO-ETNİK",
          description: "Etnik Desenli Penye Beli Lastikli Kalem Büyük Beden Etek"
        },
        {
          id: 247954559,
          imageUrl: "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/21/57718406/396730693/1/1_org_zoom.jpg",
          name: "BORDO-ÇİZGİLİ",
          description: "Etnik Desenli Penye Beli Lastikli Kalem Büyük Beden Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 1,
          key: "Bel",
          value: "Lastikli"
        },
        {
          id: 33,
          key: "Desen",
          value: "Etnik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 141070345,
      productCode: "TS-E0013",
      name: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 121857394,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty171/product/media/images/20210909/10/127894021/239367828/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210909/10/127894021/239367828/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/10/127894021/239367828/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210909/10/127894021/239367828/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 1.0
      },
      winnerVariant: "44-46",
      color: "MÜRDÜM",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 239377878,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 309812842,
          comment: "Anneme aldım çok beğendi",
          rate: 3.0,
          userFullName: "S** T**",
          trusted: true,
          commentDateISOtype: "2023-05-31",
          lastModifiedDate: "31 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 141070397,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/10/127894062/239367898/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        },
        {
          id: 141070345,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210909/10/127894021/239367828/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        },
        {
          id: 141070414,
          imageUrl: "https://cdn.dsmcdn.com/ty484/product/media/images/20220719/23/145981717/239378076/1/1_org_zoom.jpg",
          name: "TURUNCU",
          description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124675993,
      productCode: "TS-E003",
      name: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek, Ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 Beden ölçüleri: en:56 cm – boy: 100 cm’dir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 109793172,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty253/product/media/images/20211125/11/111685187/202909656/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty253/product/media/images/20211125/11/111685187/202909656/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty253/product/media/images/20211125/11/111685187/202909656/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty253/product/media/images/20211125/11/111685187/202909656/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 1.0,
      ratingScore: {
        averageRating: 1.0,
        totalCount: 1.0
      },
      winnerVariant: "48-50",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48-50",
          itemNumber: 202932808,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 320753917,
          comment: "kafasına göre gönderiyor desene bakıp almayın 2 tane farklı model aldım satıcıdan ikiside değişik göndermiş",
          rate: 1.0,
          userFullName: "O** K**",
          trusted: true,
          commentDateISOtype: "2023-07-11",
          lastModifiedDate: "11 Temmuz 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124642287,
      productCode: "TS-E006",
      name: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 109765096,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989274/202853288/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989274/202853288/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty146/product/media/images/20210713/11/109989274/202853288/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989274/202853288/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "44-46",
      color: "MOR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 202873139,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 262232553,
          comment: "urun geyet güzel  çok hızlı geldi güzel paketlemisler",
          rate: 5.0,
          userFullName: "f** s**",
          trusted: true,
          commentDateISOtype: "2023-01-28",
          lastModifiedDate: "28 Ocak 2023"
        }
      ],
      colors: [
        {
          id: 124642287,
          imageUrl: "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989274/202853288/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        },
        {
          id: 124642350,
          imageUrl: "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989329/202853405/1/1_org_zoom.jpg",
          name: "KOYU MOR",
          description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 102742605,
      productCode: "TS-E3150",
      name: "Kadın Siyah Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Kadın Siyah Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Kadın Siyah Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek, Ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 Beden ölçüleri: en:56 cm – boy: 100 cm’dir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 89545851,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty108/product/media/images/20210503/10/84980891/169953666/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210503/10/84980891/169953666/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210503/10/84980891/169953666/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210503/10/84980891/169953666/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 4.0
      },
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 169953666,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 320754082,
          comment: "kafasına göre gönderiyor desene bakıp almayın 2 tane farklı model aldım satıcıdan ikiside değişik göndermiş",
          rate: 1.0,
          userFullName: "O** K**",
          trusted: true,
          commentDateISOtype: "2023-07-11",
          lastModifiedDate: "11 Temmuz 2023"
        }
      ],
      colors: [
        {
          id: 102825809,
          imageUrl: "https://cdn.dsmcdn.com/ty110/product/media/images/20210503/13/85068199/170086876/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        },
        {
          id: 102742605,
          imageUrl: "https://cdn.dsmcdn.com/ty108/product/media/images/20210503/10/84980891/169953666/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95157329,
      productCode: "TS-E6075",
      name: "Kadın Turuncu Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      slug: "Kadın Turuncu Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      description: "Kadın Turuncu Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44-46 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 82379647,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty70/product/media/images/20210330/10/76173129/158621678/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty70/product/media/images/20210330/10/76173129/158621678/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty83/product/media/images/20210330/10/76173129/158621678/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty67/product/media/images/20210330/10/76173129/158621678/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty76/product/media/images/20210330/10/76173129/158621678/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 4.0
      },
      winnerVariant: "44-46",
      color: "TURUNCU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 158642448,
          stock: 6.0
        }
      ],
      productReviews: [
        {
          id: 175235642,
          comment: "Thank you",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-04-17",
          lastModifiedDate: "17 Nisan 2022"
        },
        {
          id: 102599808,
          comment: "Rengi ve kumaşı çok güzel",
          rate: 5.0,
          userFullName: "fatma a.",
          trusted: true,
          commentDateISOtype: "2021-05-07",
          lastModifiedDate: "7 Mayıs 2021"
        },
        {
          id: 213713696,
          comment: "anneme aldım çok beğendi sadece kalıbı biraz küçük",
          rate: 5.0,
          userFullName: "F** K**",
          trusted: true,
          commentDateISOtype: "2022-10-01",
          lastModifiedDate: "1 Ekim 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 83092535,
      productCode: "TS-E002",
      name: "Leopar Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      slug: "Leopar Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      description: "Leopar Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek, Ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 Beden ölçüleri: en:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.72 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 69676488,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty336/product/media/images/20220220/20/54191358/140597349/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty336/product/media/images/20220220/20/54191358/140597349/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty336/product/media/images/20220220/20/54191358/140597349/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty338/product/media/images/20220220/20/54191358/140597349/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty338/product/media/images/20220220/20/54191358/140597349/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty338/product/media/images/20220220/20/54191358/140597349/5/5_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.60714285714286,
        totalCount: 28.0
      },
      winnerVariant: "44",
      color: "DESENLİ2",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 140597349,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 309812974,
          comment: "Anneme aldım çok beğendi",
          rate: 3.0,
          userFullName: "S** T**",
          trusted: true,
          commentDateISOtype: "2023-05-31",
          lastModifiedDate: "31 Mayıs 2023"
        },
        {
          id: 152449208,
          comment: "anneme aldim cok cok begendi tesekkurler",
          rate: 5.0,
          userFullName: "t** c**",
          trusted: true,
          commentDateISOtype: "2022-01-12",
          lastModifiedDate: "12 Ocak 2022"
        },
        {
          id: 147555622,
          comment: "Annem için aldım 52.54 beden tam oldu renk ve kalitesi gayet güzel cok zor beğenen annem de çok beğendi",
          rate: 5.0,
          userFullName: "Y** Y**",
          trusted: true,
          commentDateISOtype: "2021-12-16",
          lastModifiedDate: "16 Aralık 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 102280359,
      productCode: "TS-1703-47",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli pamuklu dokuma kolları ve eteği ponponlu büyük beden elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten,"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 89170841,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty109/product/media/images/20210501/19/84466930/169407684/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty109/product/media/images/20210501/19/84466930/169407684/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210501/19/84466930/169407684/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty109/product/media/images/20210501/19/84466930/169407684/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 0.0,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.33333333333333,
        totalCount: 3.0
      },
      winnerVariant: "48",
      color: "ANTRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48",
          itemNumber: 169411581,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 184019694,
          comment: "anneme aldım beyendi",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-05-27",
          lastModifiedDate: "27 Mayıs 2022"
        },
        {
          id: 137974671,
          comment: "kayınvalidem için hediye aldım,beğenildi😊",
          rate: 5.0,
          userFullName: "S** A**",
          trusted: true,
          commentDateISOtype: "2021-11-07",
          lastModifiedDate: "7 Kasım 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 56510868,
      productCode: "TS-8022",
      name: "Klasik Pamuklu Gömlek",
      slug: "Klasik Pamuklu Gömlek",
      description: "Klasik Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Klasik Pamuklu gömlek., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün L bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 47804734,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty20/product/media/images/20201103/15/21804147/100206490/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty20/product/media/images/20201103/15/21804147/100206490/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty20/product/media/images/20201103/15/21804147/100206490/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty19/product/media/images/20201103/15/21804147/100206490/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty20/product/media/images/20201103/15/21804147/100206490/4/4_org_zoom.jpg"
      ],
      price: 369.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 369.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 369.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 4.0
      },
      winnerVariant: "XL",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 100217244,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 162121769,
          comment: "Güzel, tam beden alınmalı",
          rate: 5.0,
          userFullName: "P** A**",
          trusted: true,
          commentDateISOtype: "2022-02-18",
          lastModifiedDate: "18 Şubat 2022"
        },
        {
          id: 318312959,
          comment: "Kurtarıcı bir ürün çok rahat ve şık alınabilir",
          rate: 5.0,
          userFullName: "e** i**",
          trusted: true,
          commentDateISOtype: "2023-07-02",
          lastModifiedDate: "2 Temmuz 2023"
        }
      ],
      colors: [
        {
          id: 56510868,
          imageUrl: "https://cdn.dsmcdn.com/ty20/product/media/images/20201103/15/21804147/100206490/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Klasik Pamuklu Gömlek"
        },
        {
          id: 56510428,
          imageUrl: "https://cdn.dsmcdn.com/ty19/product/media/images/20201103/15/21803456/100203916/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Klasik Pamuklu Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 725794958,
      productCode: "TS-P1228",
      name: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      slug: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse krep kumaş düz kesim büyük beden yazlık lastikli pantolon, ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., ürün hafif ve rahattır., terleme yaratmaz., dökümlü ve salaş bir görünüm sağlar., kolay kolay buruşmaz., gösterişli ve şık bir görünüm sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün numune bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Pantolon",
      productGroupId: 552638955,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Pantolon",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364993691/954703682/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364993691/954703682/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty914/product/media/images/20230527/15/364993691/954703682/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364993691/954703682/3/3_org_zoom.jpg"
      ],
      price: 289.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 289.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 289.99,
        sellingPrice: 289.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 2.0
      },
      winnerVariant: "42",
      color: "INDIGO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 954703682,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 313978789,
          comment: "Ürünün kalitesi güzel ama sanırım kalıbı dar 44 beden aldım ve bana çok küçük geldi değişim yapacağım",
          rate: 3.0,
          userFullName: "R** E**",
          trusted: true,
          commentDateISOtype: "2023-06-14",
          lastModifiedDate: "14 Haziran 2023"
        }
      ],
      colors: [
        {
          id: 725948661,
          imageUrl: "https://cdn.dsmcdn.com/ty914/product/media/images/20230527/16/365147033/954858888/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725631449,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364826277/954539071/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725793366,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/15/364992010/954702030/1/1_org_zoom.jpg",
          name: "BEJ",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 726203337,
          imageUrl: "https://cdn.dsmcdn.com/ty913/product/media/images/20230527/18/365413366/955118936/1/1_org_zoom.jpg",
          name: "KİREMİT",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        },
        {
          id: 725794958,
          imageUrl: "https://cdn.dsmcdn.com/ty912/product/media/images/20230527/15/364993691/954703682/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Kadın Klasik Şık Krep Kumaş Düz Kesim Yazlık Lastikli Büyük Beden Pantolon"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 17,
          key: "Paça Tipi",
          value: "Bol Paça"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuk Karışımlı"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 880,
          key: "Siluet",
          value: "Regular"
        },
        {
          id: 881,
          key: "Ortam",
          value: "Casual/Günlük"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Beli lastikli"
        },
        {
          id: 870,
          key: "Persona",
          value: "Smart"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "İnce"
        },
        {
          id: 710,
          key: "Koleksiyon",
          value: "Büyük Beden"
        },
        {
          id: 893,
          key: "Kemer/Kuşak Durumu",
          value: "Bağcıklı"
        },
        {
          id: 279,
          key: "Yaş",
          value: "Tüm Yaş Grupları"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290605380,
      productCode: "TS-1703-85",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 245144006,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/20/100794283/464263090/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220430/20/100794283/464263090/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220430/20/100794283/464263090/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 464263090,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "2 Cep"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124013010,
      productCode: "TS-1748",
      name: "Krep Dokuma Geniş Kesim Büyük Beden Gömlek",
      slug: "Krep Dokuma Geniş Kesim Büyük Beden Gömlek",
      description: "Krep Dokuma Geniş Kesim Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109169703,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724966/200701187/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724966/200701187/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724966/200701187/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724966/200701187/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 3.0
      },
      winnerVariant: "50",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 202375024,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 189703421,
          comment: "Çok güzel,  46 numara, tam oldu 63 kiloya, rahat,  kumaş ve renk harika. Tafsiye ediyorum!!!",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-06-24",
          lastModifiedDate: "24 Haziran 2022"
        },
        {
          id: 192036289,
          comment: "bende olmadı iade",
          rate: 4.0,
          userFullName: "NİLGÜN H.",
          trusted: true,
          commentDateISOtype: "2022-07-08",
          lastModifiedDate: "8 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 283366498,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949042/450750754/1/1_org_zoom.jpg",
          name: "YEŞİL ÇITIR ÇİÇEKLİ",
          description: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124013010,
          imageUrl: "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724966/200701187/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Krep Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 171619517,
          imageUrl: "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794332/280055461/1/1_org_zoom.jpg",
          name: "BEJ PUANTİYELİ",
          description: "Kadın Puantiyeli Krep Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124012968,
          imageUrl: "https://cdn.dsmcdn.com/ty234/product/media/images/20211110/0/172574731/200721599/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124012991,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888690/200701009/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124012991,
      productCode: "TS-1748",
      name: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek",
      slug: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek",
      description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, %100 Cotton kumaş., Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109169703,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888690/200701009/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888690/200701009/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/9/108888690/200701009/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/9/108888690/200701009/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 200721657,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 196470472,
          comment: "Çok güzel kaliteli tam beden alınız  likralı bir ürün değil  erken ve özenli  teslimat",
          rate: 5.0,
          userFullName: "N** T**",
          trusted: true,
          commentDateISOtype: "2022-08-04",
          lastModifiedDate: "4 Ağustos 2022"
        }
      ],
      colors: [
        {
          id: 283366498,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949042/450750754/1/1_org_zoom.jpg",
          name: "YEŞİL ÇITIR ÇİÇEKLİ",
          description: "Pamuklu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124013010,
          imageUrl: "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724966/200701187/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Krep Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 171619517,
          imageUrl: "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/22/159794332/280055461/1/1_org_zoom.jpg",
          name: "BEJ PUANTİYELİ",
          description: "Kadın Puantiyeli Krep Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124012968,
          imageUrl: "https://cdn.dsmcdn.com/ty234/product/media/images/20211110/0/172574731/200721599/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek"
        },
        {
          id: 124012991,
          imageUrl: "https://cdn.dsmcdn.com/ty143/product/media/images/20210710/9/108888690/200701009/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 112369892,
      productCode: "C-0210",
      name: "Desenli Devore Omuz Açık Büyük Beden Bluz",
      slug: "Desenli Devore Omuz Açık Büyük Beden Bluz",
      description: "Desenli Devore Omuz Açık Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Devore Büyük Beden Bluz., Kumaşın asimetrik bir yapısı ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 98512524,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty123/product/media/images/20210604/1/95698734/182784986/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty123/product/media/images/20210604/1/95698734/182784986/0/0_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 182881964,
          stock: 9.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 112369892,
          imageUrl: "https://cdn.dsmcdn.com/ty123/product/media/images/20210604/1/95698734/182784986/0/0_org_zoom.jpg",
          name: "VİZON",
          description: "Desenli Devore Omuz Açık Büyük Beden Bluz"
        },
        {
          id: 112369084,
          imageUrl: "https://cdn.dsmcdn.com/ty125/product/media/images/20210604/0/95689612/182784177/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli Devore Omuz Açık Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 103499288,
      productCode: "C-0163",
      name: "Baskılı Viskon Büyük Beden Bluz",
      slug: "Baskılı Viskon Büyük Beden Bluz",
      description: "Baskılı Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 42-44 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 90259276,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty112/product/media/images/20210505/4/85831368/170996954/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty112/product/media/images/20210505/4/85831368/170996954/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty111/product/media/images/20210505/4/85831368/170996954/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty111/product/media/images/20210505/4/85831368/170996954/2/2_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 179.99,
        sellingPrice: 299.99
      },
      rating: 1.0,
      ratingScore: {
        averageRating: 1.0,
        totalCount: 1.0
      },
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 170996954,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49732630,
      productCode: "C-0205-1",
      name: "Kadın Vizon V Yaka Dantelli Büyük Beden Bluz",
      slug: "Kadın Vizon V Yaka Dantelli Büyük Beden Bluz",
      description: "Kadın Vizon V Yaka Dantelli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bluz., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün l bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40722716,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200923/11/11523218/88150073/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200923/11/11523218/88150073/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200923/11/11523218/88150073/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200923/11/11523218/88150073/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200923/11/11523218/88150073/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.8,
        totalCount: 5.0
      },
      winnerVariant: "L",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 88175747,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 49732630,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200923/11/11523218/88150073/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Kadın Vizon V Yaka Dantelli Büyük Beden Bluz"
        },
        {
          id: 48459185,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10353704/85846503/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah V Yaka, Yaka Dantelli Devore Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48405001,
      productCode: "C-0110-1",
      name: "Kadın Ekru Şifon Dantelli Viskon Bluz",
      slug: "Kadın Ekru Şifon Dantelli Viskon Bluz",
      description: "Kadın Ekru Şifon Dantelli Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40675141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10300876/85773975/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10300876/85773975/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10300876/85773975/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10300876/85773975/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10300876/85773975/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85773975,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 306284708,
      productCode: "TS-7093",
      name: "Plus Mercan Desenli Pamuklu Büyük Beden Pamuklu Gömlek",
      slug: "Plus Mercan Desenli Pamuklu Büyük Beden Pamuklu Gömlek",
      description: "Plus Mercan Desenli Pamuklu Büyük Beden Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Kadın desenli hakim yaka gömlek., %100 cotton kumaş., kumaşın tok bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40940024,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty441/product/media/images/20220528/21/117447264/489532541/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty441/product/media/images/20220528/21/117447264/489532541/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty441/product/media/images/20220528/21/117447264/489532541/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty443/product/media/images/20220528/21/117447264/489532541/3/3_org_zoom.jpg"
      ],
      price: 399.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 399.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 399.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "LACİ-ORANJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 489551545,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 186910890,
          comment: "Renkleri çok güzel, biraz geniş kalıp normalde 46 giyiyorum 44 tam oldu. 1.58 boy 93 kilo.",
          rate: 5.0,
          userFullName: "Ö** T**",
          trusted: true,
          commentDateISOtype: "2022-06-11",
          lastModifiedDate: "11 Haziran 2022"
        }
      ],
      colors: [
        {
          id: 306284708,
          imageUrl: "https://cdn.dsmcdn.com/ty441/product/media/images/20220528/21/117447264/489532541/1/1_org_zoom.jpg",
          name: "LACİ-ORANJ",
          description: "Plus Mercan Desenli Pamuklu Büyük Beden Pamuklu Gömlek"
        },
        {
          id: 48713059,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10596534/86359196/1/1_org_zoom.jpg",
          name: "TURUNCU",
          description: "Kadın Turuncu Battal Desenli Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 704536586,
      productCode: "TS-M255",
      name: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      slug: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      description: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Elegant Pamuklu Desenli Büyük Beden Geniş Kalıp Kalçayı Kapatan Rahat Kesim Gömlek, Ürün Dökümlüdür. Kalçayı Kapatır. Taytlar ve pantolanlarla rahatlıkla kullanılabilir. Kumaş pamukludur. Dijital Baskıdır. Tam kalıptır."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 541830830,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty871/product/media/images/20230508/21/342995065/931161728/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty871/product/media/images/20230508/21/342995065/931161728/1/1_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 931166737,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Leopar"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Katlamalı"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "İç Göstermeyen"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 704536579,
      productCode: "TS-M261",
      name: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      slug: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      description: "Elegant Pamuklu Leopar Desenli Büyük Beden Kalçayı Kapatan Rahat Kesim Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Elegant Pamuklu Desenli Büyük Beden Geniş Kalıp Kalçayı Kapatan Rahat Kesim Gömlek, Ürün Dökümlüdür. Kalçayı Kapatır. Taytlar ve pantolanlarla rahatlıkla kullanılabilir. Kumaş pamukludur. Dijital Baskıdır. Tam kalıptır."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 541830824,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty870/product/media/images/20230508/21/342995057/931161720/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty870/product/media/images/20230508/21/342995057/931161720/1/1_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 931166760,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Katlamalı"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "İç Göstermeyen"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 376616339,
      productCode: "TS-L641",
      name: "Çiçek Nakışlı Etnik Büyük Beden Gömlek",
      slug: "Çiçek Nakışlı Etnik Büyük Beden Gömlek",
      description: "Çiçek Nakışlı Etnik Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458638,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/10/203307571/608586045/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/10/203307571/608586045/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/10/203307571/608586045/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/10/203307571/608586045/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "ETNİK-SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 608613106,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 376616339,
          imageUrl: "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/10/203307571/608586045/1/1_org_zoom.jpg",
          name: "ETNİK-SARI",
          description: "Çiçek Nakışlı Etnik Büyük Beden Gömlek"
        },
        {
          id: 322470072,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896353/515255093/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Çiçek Nakışlı %100 Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 376534345,
          imageUrl: "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/0/203216739/608470903/1/1_org_zoom.jpg",
          name: "ETNİK-KIRMIZI",
          description: "Çiçek Nakışlı Etnik Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322782106,
      productCode: "TS-YY722",
      name: "Yazlık Önü Düğmeli Penye Büyük Beden Anne Yelek",
      slug: "Yazlık Önü Düğmeli Penye Büyük Beden Anne Yelek",
      description: "Yazlık Önü Düğmeli Penye Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Yazlık Penye Büyük Beden Anne Yelek, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 42-44 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 266692332,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/22/136323708/515717561/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/22/136323708/515717561/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/22/136323708/515717561/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/22/136323708/515717561/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 315.98,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "40-42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 515717561,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 304584850,
          comment: "Urun cok guzel kargolamasi cok hizliydi. Yelegin kumas kalitesi modeli cok guzel tavsiye ederim tam anne hediyesi 3 tane aldim",
          rate: 5.0,
          userFullName: "E** T**",
          trusted: true,
          commentDateISOtype: "2023-05-16",
          lastModifiedDate: "16 Mayıs 2023"
        },
        {
          id: 303929222,
          comment: "Çok güzel ve kaliteli bir yelek tavsiye ederim. Hızlı kargo ve güzel notunuz içinde teşekkür ederim. 🌸",
          rate: 5.0,
          userFullName: "E** K** U**",
          trusted: true,
          commentDateISOtype: "2023-05-13",
          lastModifiedDate: "13 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 322782106,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/22/136323708/515717561/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yazlık Önü Düğmeli Penye Büyük Beden Anne Yelek"
        },
        {
          id: 322779715,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/22/136305583/515714635/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Yazlık Önü Düğmeli Penye Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322663825,
      productCode: "TS-A108",
      name: "Omuz Detaylı Kısa Kol Büyük Beden Şık Bluz",
      slug: "Omuz Detaylı Kısa Kol Büyük Beden Şık Bluz",
      description: "Omuz Detaylı Kısa Kol Büyük Beden Şık Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265945150,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/18/136164232/515554721/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/18/136164232/515554721/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/18/136164232/515554721/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/18/136164232/515554721/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 515554721,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322663825,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/18/136164232/515554721/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Omuz Detaylı Kısa Kol Büyük Beden Şık Bluz"
        },
        {
          id: 321802390,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135126745/513625560/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Omuz Detaylı Kısa Kol Armani Büyük Beden Şık Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322554364,
      productCode: "TS-7187",
      name: "Puantiye Desenli Viskon Büyük Beden Gömlek",
      slug: "Puantiye Desenli Viskon Büyük Beden Gömlek",
      description: "Puantiye Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117257,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/10/135994784/515361811/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/10/135994784/515361811/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/10/135994784/515361811/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "INDIGO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515371626,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366718,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949281/450751079/1/1_org_zoom.jpg",
          name: "SİYAH-PUANTİYE",
          description: "Puantiye Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 283364941,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947133/450748181/1/1_org_zoom.jpg",
          name: "BEYAZ-PUANTİYE",
          description: "Puantiye Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 322554364,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/10/135994784/515361811/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Puantiye Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Slim/Fitted"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470004,
      productCode: "TS-L244",
      name: "Çizgi Desenli Pamuklu Büyük Beden Gömlek",
      slug: "Çizgi Desenli Pamuklu Büyük Beden Gömlek",
      description: "Çizgi Desenli Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458572,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896289/515255025/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896289/515255025/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896289/515255025/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896289/515255025/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "INDIGO-ÇİZGİLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515259899,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322469994,
      productCode: "TS-L301",
      name: "Pitikare Desenli Şık Büyük Beden Gömlek",
      slug: "Pitikare Desenli Şık Büyük Beden Gömlek",
      description: "Pitikare Desenli Şık Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458562,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty589/product/media/images/20221105/19/208318119/515259887/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty589/product/media/images/20221105/19/208318119/515259887/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty589/product/media/images/20221105/19/208318119/515259887/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty590/product/media/images/20221105/19/208318119/515259887/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "36",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515255015,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 249953819,
          comment: "Kumaşı yumuşak ve ince yapısı var gerçekten çok beğendim ama beden olmadı üzülerek iade ediyorum",
          rate: 5.0,
          userFullName: "E** E**",
          trusted: true,
          commentDateISOtype: "2023-01-06",
          lastModifiedDate: "6 Ocak 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321986479,
      productCode: "TS-A256",
      name: "Yuvarlak Tül Nakış Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Tül Nakış Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Tül Nakış Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265945014,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220705/0/135323588/513843237/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220705/0/135323588/513843237/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220705/0/135323588/513843237/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220705/0/135323588/513843237/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 513903780,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321986479,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220705/0/135323588/513843237/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Yuvarlak Tül Nakış Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 321802026,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135126381/513625055/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Yuvarlak Tül Nakış Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321802026,
      productCode: "TS-A256",
      name: "Yuvarlak Tül Nakış Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Tül Nakış Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Tül Nakış Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265945014,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135126381/513625055/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135126381/513625055/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135126381/513625055/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135126381/513625055/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 513646636,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321986479,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220705/0/135323588/513843237/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Yuvarlak Tül Nakış Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 321802026,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135126381/513625055/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Yuvarlak Tül Nakış Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321800956,
      productCode: "TYC00497271537",
      name: "Omuz Detaylı Kısa Kol Armani Büyük Beden Şık Bluz",
      slug: "Omuz Detaylı Kısa Kol Armani Büyük Beden Şık Bluz",
      description: "Omuz Detaylı Kısa Kol Armani Büyük Beden Şık Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265944612,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135125307/513623591/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135125307/513623591/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135125307/513623591/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135125307/513623591/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 513645427,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 320955237,
      productCode: "TS-EL00488",
      name: "Ip Askılı Renkli Şifon Iç Göstermeyen Büyük Beden Elbise",
      slug: "Ip Askılı Renkli Şifon Iç Göstermeyen Büyük Beden Elbise",
      description: "Ip Askılı Renkli Şifon Iç Göstermeyen Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "İp Askılı Elbise, Ürün Şifon kumaştır, Hamile Bayanların kilo alıp verme durumları içinde oldukça kullanışlı bir elbisedir. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 265367141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134212392/512223194/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134212392/512223194/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220702/22/134212392/512223194/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220702/22/134212392/512223194/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "PEMBE-ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512240857,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Şifon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Askılı"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Şifon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Bağlamalı"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292146123,
      productCode: "TS-A188",
      name: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      slug: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "V yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246429344,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102420199/466888872/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102420199/466888872/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102420199/466888872/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102420199/466888872/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466901136,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292144755,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418817/466885153/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292142414,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102416600/466879154/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144052,
          imageUrl: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125036470/466894134/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292146123,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102420199/466888872/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292145211,
      productCode: "TS-A190",
      name: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Büyük Beden Bluz",
      slug: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Büyük Beden Bluz",
      description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246429992,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102419274/466885937/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102419274/466885937/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102419274/466885937/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102419274/466885937/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466885937,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292145211,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102419274/466885937/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Büyük Beden Bluz"
        },
        {
          id: 292143122,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417287/466880401/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292143524,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417651/466882032/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144289,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418454/466883513/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292144755,
      productCode: "TS-A188",
      name: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      slug: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "V yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246429344,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418817/466885153/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418817/466885153/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102418817/466885153/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102418817/466885153/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466896881,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292144755,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418817/466885153/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292142414,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102416600/466879154/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144052,
          imageUrl: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125036470/466894134/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292146123,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102420199/466888872/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292144052,
      productCode: "TS-A188",
      name: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      slug: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246429344,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125036470/466894134/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125036470/466894134/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125036470/466894134/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty453/product/media/images/20220613/10/125036470/466894134/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 4.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466882919,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 198796665,
          comment: "Anneme aldık çok beğendik",
          rate: 5.0,
          userFullName: "A** S**",
          trusted: true,
          commentDateISOtype: "2022-08-14",
          lastModifiedDate: "14 Ağustos 2022"
        },
        {
          id: 184494934,
          comment: "Çök hosuma gitti severek giyecegim",
          rate: 5.0,
          userFullName: "İ** E**",
          trusted: true,
          commentDateISOtype: "2022-05-30",
          lastModifiedDate: "30 Mayıs 2022"
        },
        {
          id: 187843777,
          comment: "Ürün tamamen yanlış gönderildiği için yorum yapamıyorum . Birde “sizin için özenle hazırladık” diye dalga geçer gibi not yazılması traji komik .",
          rate: 1.0,
          userFullName: "Rahşan Ö. K.",
          trusted: true,
          commentDateISOtype: "2022-06-16",
          lastModifiedDate: "16 Haziran 2022"
        }
      ],
      colors: [
        {
          id: 292144755,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418817/466885153/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292142414,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102416600/466879154/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144052,
          imageUrl: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125036470/466894134/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292146123,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102420199/466888872/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292143616,
      productCode: "TS-A186",
      name: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246427488,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102417742/466882180/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102417742/466882180/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102417742/466882180/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417742/466882180/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466892849,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292143616,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102417742/466882180/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292140502,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102414417/466872867/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292142295,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102416304/466878901/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292143524,
      productCode: "TS-A190",
      name: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246429992,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417651/466882032/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417651/466882032/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417651/466882032/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102417651/466882032/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "FUŞYA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466882032,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292145211,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102419274/466885937/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Büyük Beden Bluz"
        },
        {
          id: 292143122,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417287/466880401/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292143524,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417651/466882032/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144289,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418454/466883513/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292143363,
      productCode: "TS-A193",
      name: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz",
      slug: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz",
      description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "V yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246428621,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417522/466880801/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417522/466880801/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417522/466880801/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417522/466880801/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466880801,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292141676,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102415607/466876384/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292143363,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417522/466880801/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144760,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102418822/466885163/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "V Yaka Taş Detaylı Çiçek Desenli Büyük Beden Bluz"
        },
        {
          id: 292143296,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417451/466880655/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292143296,
      productCode: "TS-A193",
      name: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz",
      slug: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz",
      description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "V yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246428621,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417451/466880655/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417451/466880655/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417451/466880655/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102417451/466880655/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466880655,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292141676,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102415607/466876384/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292143363,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417522/466880801/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144760,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102418822/466885163/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "V Yaka Taş Detaylı Çiçek Desenli Büyük Beden Bluz"
        },
        {
          id: 292143296,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417451/466880655/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292143122,
      productCode: "TS-A190",
      name: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246429992,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417287/466880401/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417287/466880401/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102417287/466880401/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417287/466880401/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SAFRAN",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466890607,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292145211,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102419274/466885937/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Büyük Beden Bluz"
        },
        {
          id: 292143122,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417287/466880401/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292143524,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417651/466882032/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144289,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418454/466883513/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yuvarlak Yaka Çiçek Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292142414,
      productCode: "TS-A188",
      name: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      slug: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "V yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246429344,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102416600/466879154/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102416600/466879154/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102416600/466879154/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102416600/466879154/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SAFRAN",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466889501,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292144755,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102418817/466885153/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292142414,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102416600/466879154/1/1_org_zoom.jpg",
          name: "SAFRAN",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144052,
          imageUrl: "https://cdn.dsmcdn.com/ty455/product/media/images/20220613/10/125036470/466894134/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292146123,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102420199/466888872/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "V Yaka Güpür Detaylı Kol Puantiye Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292142295,
      productCode: "TS-A186",
      name: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246427488,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102416304/466878901/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102416304/466878901/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102416304/466878901/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466889140,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292143616,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102417742/466882180/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292140502,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102414417/466872867/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292142295,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102416304/466878901/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292141676,
      productCode: "TS-A193",
      name: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz",
      slug: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz",
      description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "V yaka viskon bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246428621,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102415607/466876384/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102415607/466876384/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102415607/466876384/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102415607/466876384/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466886774,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 292141676,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102415607/466876384/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292143363,
          imageUrl: "https://cdn.dsmcdn.com/ty418/product/media/images/20220505/22/102417522/466880801/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292144760,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102418822/466885163/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "V Yaka Taş Detaylı Çiçek Desenli Büyük Beden Bluz"
        },
        {
          id: 292143296,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417451/466880655/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "V Yaka Taş Detaylı Çiçek Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292140570,
      productCode: "TS-A115",
      name: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246427556,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102414485/466872971/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102414485/466872971/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102414485/466872971/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102414485/466872971/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466872971,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 200804503,
          comment: "Çok beyendim",
          rate: 5.0,
          userFullName: "K** s**",
          trusted: true,
          commentDateISOtype: "2022-08-21",
          lastModifiedDate: "21 Ağustos 2022"
        }
      ],
      colors: [
        {
          id: 292140570,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102414485/466872971/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292143078,
          imageUrl: "https://cdn.dsmcdn.com/ty417/product/media/images/20220505/22/102417249/466880332/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yuvarlak Yaka Çiçek Balon Kol Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366718,
      productCode: "TS-7187",
      name: "Puantiye Desenli Viskon Büyük Beden Gömlek",
      slug: "Puantiye Desenli Viskon Büyük Beden Gömlek",
      description: "Puantiye Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117257,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949281/450751079/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949281/450751079/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92949281/450751079/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "SİYAH-PUANTİYE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 450751079,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 283366718,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92949281/450751079/1/1_org_zoom.jpg",
          name: "SİYAH-PUANTİYE",
          description: "Puantiye Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 283364941,
          imageUrl: "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947133/450748181/1/1_org_zoom.jpg",
          name: "BEYAZ-PUANTİYE",
          description: "Puantiye Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 322554364,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/10/135994784/515361811/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Puantiye Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Slim/Fitted"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 280715118,
      productCode: "TS-1746",
      name: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Empirme Desenli Sandy Kumaş Elbise, Kırışma zor olmaz, Çamaşır makinesinde, düşük derece ayarında yıkanabilir, Sağlam bir kumaş çeşididir, Renkleri çok canlı göstermesinden ötürü, oldukça şık görünmektedir ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., Mankenin üzerindeki 44 bedendir"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 236618738,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90151360/446991381/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90151360/446991381/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty397/product/media/images/20220413/12/90151360/446991381/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty396/product/media/images/20220413/12/90151360/446991381/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "HAKİ SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 447065708,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 215590567,
          comment: "çok güzel,yumuşacık",
          rate: 5.0,
          userFullName: "H** E**",
          trusted: true,
          commentDateISOtype: "2022-11-21",
          lastModifiedDate: "21 Kasım 2022"
        }
      ],
      colors: [
        {
          id: 280713296,
          imageUrl: "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90149636/446989253/1/1_org_zoom.jpg",
          name: "MİNT",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280707228,
          imageUrl: "https://cdn.dsmcdn.com/ty397/product/media/images/20220413/12/90143589/446981850/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280715118,
          imageUrl: "https://cdn.dsmcdn.com/ty398/product/media/images/20220413/12/90151360/446991381/1/1_org_zoom.jpg",
          name: "HAKİ SİYAH",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        },
        {
          id: 280703511,
          imageUrl: "https://cdn.dsmcdn.com/ty484/product/media/images/20220720/0/146021726/447043321/1/1_org_zoom.jpg",
          name: "HELEGRAN EKRU",
          description: "Kadın Desenli Sandy Kısa Kol Büyük Beden Elbise"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Sandy"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 48,
          key: "Boy",
          value: "Midi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Sandy"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148596980,
      productCode: "TS-SR16",
      name: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      slug: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Düz Renk Düğmesiz Büyük Beden Yelek, Ürün Örme Triko Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 128104332,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494298/248704191/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494298/248704191/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494298/248704191/1/1_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "GRİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248715416,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 148596934,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494234/248704145/0/0_org_zoom.jpg",
          name: "HAKİ",
          description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        },
        {
          id: 148596921,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494249/248704132/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        },
        {
          id: 148596980,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494298/248704191/0/0_org_zoom.jpg",
          name: "GRİ",
          description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148596934,
      productCode: "TS-SR16",
      name: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      slug: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Düz Renk Düğmesiz Büyük Beden Yelek, Ürün Örme Triko Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 128104332,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494234/248704145/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494234/248704145/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/1/135494234/248704145/1/1_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248704145,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 148596934,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494234/248704145/0/0_org_zoom.jpg",
          name: "HAKİ",
          description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        },
        {
          id: 148596921,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494249/248704132/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        },
        {
          id: 148596980,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494298/248704191/0/0_org_zoom.jpg",
          name: "GRİ",
          description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148596921,
      productCode: "TS-SR16",
      name: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      slug: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Düz Renk Düğmesiz Büyük Beden Yelek, Ürün Örme Triko Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 128104332,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494249/248704132/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494249/248704132/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/1/135494249/248704132/1/1_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248704132,
          stock: 4.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 148596934,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494234/248704145/0/0_org_zoom.jpg",
          name: "HAKİ",
          description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        },
        {
          id: 148596921,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494249/248704132/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        },
        {
          id: 148596980,
          imageUrl: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494298/248704191/0/0_org_zoom.jpg",
          name: "GRİ",
          description: "Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148596797,
      productCode: "TS-SR17",
      name: "Kazayağı Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      slug: "Kazayağı Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      description: "Kazayağı Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kazayağı Desenli Düğmesiz Büyük Beden Yelek, Ürün Örme Triko Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 128104154,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/1/135494123/248704008/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/1/135494123/248704008/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/1/135494123/248704008/1/1_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SAKS MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248704008,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 148596734,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494048/248703936/0/0_org_zoom.jpg",
          name: "BEJ",
          description: "Kazayağı Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        },
        {
          id: 148596797,
          imageUrl: "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/1/135494123/248704008/0/0_org_zoom.jpg",
          name: "SAKS MAVİ",
          description: "Kazayağı Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148596734,
      productCode: "TS-SR17",
      name: "Kazayağı Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      slug: "Kazayağı Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      description: "Kazayağı Desenli Düğmesiz Cepli Örme Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kazayağı Desenli Düğmesiz Büyük Beden Yelek, Ürün Örme Triko Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün 42-44 bedendir. Modelin Ölçüleri: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 128104154,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494048/248703936/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494048/248703936/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/1/135494048/248703936/1/1_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42-44",
      color: "BEJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248703936,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 283772996,
          comment: "Annem icin aldim memnun",
          rate: 5.0,
          userFullName: "G** A**",
          trusted: true,
          commentDateISOtype: "2023-04-06",
          lastModifiedDate: "6 Nisan 2023"
        }
      ],
      colors: [
        {
          id: 148596734,
          imageUrl: "https://cdn.dsmcdn.com/ty180/product/media/images/20210923/1/135494048/248703936/0/0_org_zoom.jpg",
          name: "BEJ",
          description: "Kazayağı Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        },
        {
          id: 148596797,
          imageUrl: "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/1/135494123/248704008/0/0_org_zoom.jpg",
          name: "SAKS MAVİ",
          description: "Kazayağı Desenli Düğmesiz Cepli Örme Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bej"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 148568672,
      productCode: "TS-0013",
      name: "Kadın Ekru Transparan Simli Zebra Desenli Büyük Beden Hırka",
      slug: "Kadın Ekru Transparan Simli Zebra Desenli Büyük Beden Hırka",
      description: "Kadın Ekru Transparan Simli Zebra Desenli Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Kumaşın tok bir yapısı vardır ve yumuşak bir dokusu vardır, %100 cotton ve triko karışımı kumaştır, Şık ve zarif bir görüntü sağlar, Ürünün kumaşı dökümlüdür. Vücut hatlarına yapışma yapmaz, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün 42-44 bedendir, Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 128077972,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/0/135465608/248670168/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/0/135465608/248670168/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty182/product/media/images/20210923/0/135465608/248670168/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty181/product/media/images/20210923/0/135465608/248670168/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 248670168,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140403480,
      productCode: "TS-7174",
      name: "Kadın Tilki Dijital Desenli Viskon Büyük Beden Gömlek",
      slug: "Kadın Tilki Dijital Desenli Viskon Büyük Beden Gömlek",
      description: "Kadın Tilki Dijital Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon gömlek. %100 viskon kumaş. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 40 bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121436638,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty415/product/media/images/20220505/12/102025395/238679314/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty415/product/media/images/20220505/12/102025395/238679314/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220505/12/102025395/238679314/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty416/product/media/images/20220505/12/102025395/238679314/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 238562338,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 140007667,
      productCode: "TS-7176",
      name: "Kadın Renkli Dijital Desenli Viskon Gömlek",
      slug: "Kadın Renkli Dijital Desenli Viskon Gömlek",
      description: "Kadın Renkli Dijital Desenli Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon gömlek., %100 viskon kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 40 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121205534,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/17/126792956/238099848/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/17/126792956/238099848/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/17/126792956/238099848/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/17/126792956/238099848/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "40",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 238159448,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 127193742,
          comment: "Cok tatli ve guzel bir rengi var. Normalda 42 beden giyiyorum. 44 beden alsim. Çok guzel durdu.",
          rate: 5.0,
          userFullName: "Ö** A**",
          trusted: true,
          commentDateISOtype: "2021-09-16",
          lastModifiedDate: "16 Eylül 2021"
        },
        {
          id: 150063128,
          comment: "görselle birebir aynı kaliteli bir dokusu var hizli kargo özenli paketleme renkleri çok güzel ben çok begendim genis kalip bir gömlek tatliş notunuz icinde ayrica teşekkür ederim🤗🎀",
          rate: 5.0,
          userFullName: "N** M**",
          trusted: true,
          commentDateISOtype: "2021-12-29",
          lastModifiedDate: "29 Aralık 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 139939141,
      productCode: "TS-7164-7",
      name: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Deniz Kabuğu Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 121159904,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/14/126749649/238016956/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/14/126749649/238016956/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty171/product/media/images/20210906/14/126749649/238016956/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty172/product/media/images/20210906/14/126749649/238016956/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 238016956,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138327482,
      productCode: "TS-1703-58",
      name: "Kadın Çiçek Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Çiçek Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Çiçek Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Pamuklu Dokuma Kolları ve Eteği Güpür Detaylı Büyük Beden Elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., ter"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120192763,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057104/236145088/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057104/236145088/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty169/product/media/images/20210902/16/125057104/236145088/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125057104/236145088/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.83333333333333,
        totalCount: 6.0
      },
      winnerVariant: "42",
      color: "GRİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 236145088,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 195035026,
          comment: "Çok rahat ve kullanışlı 👍",
          rate: 5.0,
          userFullName: "Ö** D** K**",
          trusted: true,
          commentDateISOtype: "2022-07-27",
          lastModifiedDate: "27 Temmuz 2022"
        },
        {
          id: 194916270,
          comment: "çok rahat bir elbise",
          rate: 5.0,
          userFullName: "F** M** B**",
          trusted: true,
          commentDateISOtype: "2022-07-26",
          lastModifiedDate: "26 Temmuz 2022"
        },
        {
          id: 131113803,
          comment: "cok.guzeldi , tewekkurler",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-10-06",
          lastModifiedDate: "6 Ekim 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138325022,
      productCode: "TS-1703-73",
      name: "Kadın Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., Hem rahat, hem de şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 Viskon, %5 Elastan., Ürünün deseni dijitaldir., Ürünü 30 derecede tersten yıkayınız., Mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120190912,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054594/236139836/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054594/236139836/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/16/125054594/236139836/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty168/product/media/images/20210902/16/125054594/236139836/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "46",
      color: "TURUNCU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 236150158,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 138320014,
      productCode: "TS-1703-59",
      name: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kısa Kol Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Kadın Etnik Desenli Pamuk Dokuma Truvakar Kol Büyük Beden Elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, ha"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 120186943,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/15/125049576/236127158/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/15/125049576/236127158/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/15/125049576/236127158/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty170/product/media/images/20210902/15/125049576/236127158/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 236142275,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124023843,
      productCode: "TS-7132",
      name: "Yıldız Desenli Pamuklu Püsküllü Kadın Büyük Beden Gömlek",
      slug: "Yıldız Desenli Pamuklu Püsküllü Kadın Büyük Beden Gömlek",
      description: "Yıldız Desenli Pamuklu Püsküllü Kadın Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Yıldız desenli pamuklu gömlek., ürünün kumaşı %100 cottondur., kumaşın tok bir dokusu vardır., şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün l bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109177233,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724967/200745628/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724967/200745628/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty960/product/media/images/20230703/22/390724967/200745628/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty962/product/media/images/20230703/22/390724967/200745628/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "38",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 200767878,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124022882,
      productCode: "TS-7147",
      name: "Kuş Desenli Pamuklu Kadın Büyük Beden Gömlek",
      slug: "Kuş Desenli Pamuklu Kadın Büyük Beden Gömlek",
      description: "Kuş Desenli Pamuklu Kadın Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Desenli Pamuklu gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 109176762,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty142/product/media/images/20210710/10/108900101/200742204/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty142/product/media/images/20210710/10/108900101/200742204/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/10/108900101/200742204/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty141/product/media/images/20210710/10/108900101/200742204/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42",
      color: "BEYAZ-KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 200764605,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 257773299,
          comment: "Bu markanın gömleklerin  modelleri çok rahat,duruşu güzel,bir çok modelini aldım.",
          rate: 5.0,
          userFullName: "Ö** T**",
          trusted: true,
          commentDateISOtype: "2023-01-19",
          lastModifiedDate: "19 Ocak 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 122456935,
      productCode: "TS-1703-49",
      name: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Battal Elbise",
      slug: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Battal Elbise",
      description: "Kadın Büyük Beden Dijital Desenli Viskon Kısa Kol Battal Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu viskon elbise. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 4"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 107835273,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928420/197673659/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928420/197673659/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty139/product/media/images/20210703/16/106928420/197673659/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty140/product/media/images/20210703/16/106928420/197673659/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "46",
      color: "KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 197685227,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 198264590,
          comment: "Tesekkür ederim",
          rate: 5.0,
          userFullName: "H** I** k**",
          trusted: true,
          commentDateISOtype: "2022-08-11",
          lastModifiedDate: "11 Ağustos 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 104535145,
      productCode: "TS-1702-8",
      name: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 91235194,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty111/product/media/images/20210509/23/87080333/172568283/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty111/product/media/images/20210509/23/87080333/172568283/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty112/product/media/images/20210509/23/87080333/172568283/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty113/product/media/images/20210509/23/87080333/172568283/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 0.0,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "50",
      color: "MİNT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 172569788,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 112511106,
          comment: "3 tane aldım bu butikten.  gayet kaliteli. tam beden. yıkadık. çekmedi.",
          rate: 5.0,
          userFullName: "F** H**",
          trusted: true,
          commentDateISOtype: "2021-06-16",
          lastModifiedDate: "16 Haziran 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96942225,
      productCode: "TS-1702-3",
      name: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu, midi boy penye elbise., hem rahat, hem de şık bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., % 95 viskon, %5 elastan., ürünün deseni dijitaldir., ürünü 30 derecede tersten yıkayınız., mankenin"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 83968065,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338407/161428949/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338407/161428949/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338407/161428949/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty101/product/media/images/20210406/22/78338407/161428949/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 0.0,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 2.0
      },
      winnerVariant: "42",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 161432421,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 124409299,
          comment: "Orta degerde kuması kargo hızlıydı",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-08-26",
          lastModifiedDate: "26 Ağustos 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96942093,
      productCode: "TS-1702-1",
      name: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      slug: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      description: "Kadın Desenli Viskon Kolsuz Büyük Beden Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Düz Renk Klasik Renkli Örme Büyük Beden Elbise, Ürün Örme Triko Anne Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün 2XL bedendir. Mank"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 83967946,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty99/product/media/images/20210406/22/78338274/161428817/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty99/product/media/images/20210406/22/78338274/161428817/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty99/product/media/images/20210406/22/78338274/161428817/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty100/product/media/images/20210406/22/78338274/161428817/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 2.0
      },
      winnerVariant: "42",
      color: "PEMBE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 161428817,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 116417493,
          comment: "viskon ve elasten karışımı kumaşı çok aradım,bu elbisede buldum,yumuşacık harika,renkler canlı resimde görüldüğü gibi,rahat elbise istiyorsanız alın ,tam bedeninizi alabilirsiniz",
          rate: 5.0,
          userFullName: "Aysan B.",
          trusted: true,
          commentDateISOtype: "2021-07-01",
          lastModifiedDate: "1 Temmuz 2021"
        },
        {
          id: 125437033,
          comment: "kalitesine gore fiyati biraz yuksek. ama iade etmiyorum kullanicam",
          rate: 3.0,
          userFullName: "G** K**",
          trusted: true,
          commentDateISOtype: "2021-09-02",
          lastModifiedDate: "2 Eylül 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Midi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95364007,
      productCode: "TS-7130",
      name: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558087,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty32/product/media/images/20210331/3/76484068/158956870/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty32/product/media/images/20210331/3/76484068/158956870/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty53/product/media/images/20210331/3/76484068/158956870/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty46/product/media/images/20210331/3/76484068/158956870/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "PEMBE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 158956870,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 95363894,
          imageUrl: "https://cdn.dsmcdn.com/ty48/product/media/images/20210331/3/76483971/158956755/0/0_org_zoom.jpg",
          name: "SARI",
          description: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 95364007,
          imageUrl: "https://cdn.dsmcdn.com/ty32/product/media/images/20210331/3/76484068/158956870/0/0_org_zoom.jpg",
          name: "PEMBE",
          description: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95363894,
      productCode: "TS-7130",
      name: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      slug: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      description: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558087,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty48/product/media/images/20210331/3/76483971/158956755/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty48/product/media/images/20210331/3/76483971/158956755/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty72/product/media/images/20210331/3/76483971/158956755/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty79/product/media/images/20210331/3/76483971/158956755/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "XL",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 158963930,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 175402392,
          comment: "Gorseldeki ile bire bir. cok şık bir gomlek.",
          rate: 5.0,
          userFullName: "m** b**",
          trusted: true,
          commentDateISOtype: "2022-04-18",
          lastModifiedDate: "18 Nisan 2022"
        },
        {
          id: 322296412,
          comment: "Kumas cok guzel, kibar bir gomlek. 170 cm boy, 120 kg, 3xl dar geldigi icin iade etmek zorunda kaldim.",
          rate: 5.0,
          userFullName: "D** K**",
          trusted: true,
          commentDateISOtype: "2023-07-16",
          lastModifiedDate: "16 Temmuz 2023"
        }
      ],
      colors: [
        {
          id: 95363894,
          imageUrl: "https://cdn.dsmcdn.com/ty48/product/media/images/20210331/3/76483971/158956755/0/0_org_zoom.jpg",
          name: "SARI",
          description: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        },
        {
          id: 95364007,
          imageUrl: "https://cdn.dsmcdn.com/ty32/product/media/images/20210331/3/76484068/158956870/0/0_org_zoom.jpg",
          name: "PEMBE",
          description: "Puantiye Desenli Kolları Bağcıklı Pamuklu Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Hakim Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 88436393,
      productCode: "TS-SR10",
      name: "Düz Renk Düğmeli Klasik Örme Büyük Beden Yelek",
      slug: "Düz Renk Düğmeli Klasik Örme Büyük Beden Yelek",
      description: "Düz Renk Düğmeli Klasik Örme Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Düz Renk Düğmeli Klasik Örme Büyük Beden Yelek, Ürün Örme Triko Yelek, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün bedendir. Mankenin üzerindeki ürün L bedendir. Modelin Ölçüleri: Boy: 1.72 Göğüs: 91, Bel: 70, Kalça: 102 T-Shirt Bedeni:M bedendir."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 76313248,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty78/product/media/images/20210303/14/68268881/147802638/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty78/product/media/images/20210303/14/68268881/147802638/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty77/product/media/images/20210303/14/68268881/147802638/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty79/product/media/images/20210303/14/68268881/147802638/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty79/product/media/images/20210303/14/68268881/147802638/3/3_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 147802638,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49383921,
      productCode: "TS-7998",
      name: "Pamuklu Püsküllü Kadın Gömlek",
      slug: "Pamuklu Püsküllü Kadın Gömlek",
      description: "Pamuklu Püsküllü Kadın Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Düz Renk Püsküllü Pamuklu gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün L bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40940217,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200919/12/11201872/87487163/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200919/12/11201872/87487163/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200919/12/11201872/87487163/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200919/12/11201872/87487163/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200919/12/11201872/87487163/4/4_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 289.99,
        sellingPrice: 349.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 2.0
      },
      winnerVariant: "L",
      color: "Indigo",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87498333,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 112089547,
          comment: "Çok güzel dokusu duruşu ama maalesef küçük beden büyüğünü sepete ekledim bile herkese tavsiye ediyorum 👍",
          rate: 5.0,
          userFullName: "Yüksel G.",
          trusted: true,
          commentDateISOtype: "2021-06-15",
          lastModifiedDate: "15 Haziran 2021"
        }
      ],
      colors: [
        {
          id: 48713280,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10596850/86359552/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Pamuklu Püsküllü Gömlek"
        },
        {
          id: 49383921,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200919/12/11201872/87487163/1/1_org_zoom.jpg",
          name: "Indigo",
          description: "Pamuklu Püsküllü Kadın Gömlek"
        },
        {
          id: 48949631,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200916/13/10827103/86783506/1/1_org_zoom.jpg",
          name: "GÜL",
          description: "Kadın Pembe Pamuklu Püsküllü Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49091518,
      productCode: "TS-9896",
      name: "KadınGri Ekose Pamuklu Gömlek",
      slug: "KadınGri Ekose Pamuklu Gömlek",
      description: "KadınGri Ekose Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41261510,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200917/12/10958516/87046951/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200917/12/10958516/87046951/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200917/12/10958516/87046951/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200917/12/10958516/87046951/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200917/12/10958516/87046951/4/4_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "L",
      color: "EKOSE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87058644,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 148830448,
          comment: "ürün çok güzel kumaşının dokusu yumsacik benim kolum kalın olduğu için iyade ettim üzülerek",
          rate: 5.0,
          userFullName: "S** Ö**",
          trusted: true,
          commentDateISOtype: "2021-12-23",
          lastModifiedDate: "23 Aralık 2021"
        },
        {
          id: 305613162,
          comment: "Kaliteli ve tam ölçülere göre geldi.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-05-18",
          lastModifiedDate: "18 Mayıs 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Ekose / Kareli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48949631,
      productCode: "TS-7998",
      name: "Kadın Pembe Pamuklu Püsküllü Gömlek",
      slug: "Kadın Pembe Pamuklu Püsküllü Gömlek",
      description: "Kadın Pembe Pamuklu Püsküllü Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Düz Renk Püsküllü Pamuklu gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün L bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40940217,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200916/13/10827103/86783506/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200916/13/10827103/86783506/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200916/13/10827103/86783506/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200916/13/10827103/86783506/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200916/13/10827103/86783506/4/4_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 289.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "GÜL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 86793213,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 48713280,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10596850/86359552/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Pamuklu Püsküllü Gömlek"
        },
        {
          id: 49383921,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200919/12/11201872/87487163/1/1_org_zoom.jpg",
          name: "Indigo",
          description: "Pamuklu Püsküllü Kadın Gömlek"
        },
        {
          id: 48949631,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200916/13/10827103/86783506/1/1_org_zoom.jpg",
          name: "GÜL",
          description: "Kadın Pembe Pamuklu Püsküllü Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48713280,
      productCode: "TS-7998",
      name: "Kadın Yeşil Pamuklu Püsküllü Gömlek",
      slug: "Kadın Yeşil Pamuklu Püsküllü Gömlek",
      description: "Kadın Yeşil Pamuklu Püsküllü Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan düz renk püsküllü pamuklu gömlek., %100 cotton kumaş., kumaşın tok bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40940217,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10596850/86359552/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10596850/86359552/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10596850/86359552/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10596850/86359552/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10596850/86359552/4/4_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 289.99,
        sellingPrice: 349.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 86371263,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 48713280,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10596850/86359552/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Pamuklu Püsküllü Gömlek"
        },
        {
          id: 49383921,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200919/12/11201872/87487163/1/1_org_zoom.jpg",
          name: "Indigo",
          description: "Pamuklu Püsküllü Kadın Gömlek"
        },
        {
          id: 48949631,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200916/13/10827103/86783506/1/1_org_zoom.jpg",
          name: "GÜL",
          description: "Kadın Pembe Pamuklu Püsküllü Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48269295,
      productCode: "TS-1703-3",
      name: "Kadın Siyah Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      slug: "Kadın Siyah Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      description: "Kadın Siyah Büyük Beden Dijital Desenli Viskon Kısa Kol Elbise",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Çiçek Desenli Önden Düğmeli Pamuklu Dokuma Büyük Beden Elbise, %100 pamuk kumaş., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassa"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Elbise",
      productGroupId: 40565945,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Elbise",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200909/13/10162759/85431548/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200909/13/10162759/85431548/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200909/13/10162759/85431548/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200909/13/10162759/85431548/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200909/13/10162759/85431548/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200909/13/10162759/85431548/5/5_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200909/13/10162759/85431548/6/6_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 0.0,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 85431548,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 107095389,
          comment: "Kumaşı tiril tiril",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-05-24",
          lastModifiedDate: "24 Mayıs 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95157512,
      productCode: "TS-E3015",
      name: "Düz Renk Örme Triko Astarlı Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      slug: "Düz Renk Örme Triko Astarlı Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      description: "Düz Renk Örme Triko Astarlı Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse düz renk astarlı beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44-46 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 82379824,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/20/166427506/158642119/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/20/166427506/158642119/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/20/166427506/158642119/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/20/166427506/158642119/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/20/166427506/158642119/4/4_org_zoom.jpg"
      ],
      price: 332.32,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 332.32,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 332.32,
        sellingPrice: 332.32
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 3.0
      },
      winnerVariant: "42",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 176626435,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 110566953,
          comment: "Asla fiyatını edecek bir etek değil. Beklediğim gibi dış kıyafet olabilecek bir etek değildi. Resimdekinden çok daha ucuz görünüyor. Resmen evde giymek için para vermiş oldum.",
          rate: 1.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-06-07",
          lastModifiedDate: "7 Haziran 2021"
        },
        {
          id: 170003183,
          comment: "kayinvalidem  için almıştım severek giyiyor",
          rate: 5.0,
          userFullName: "d** a**",
          trusted: true,
          commentDateISOtype: "2022-03-24",
          lastModifiedDate: "24 Mart 2022"
        },
        {
          id: 198979933,
          comment: "Görselle uyumlu güzel bi etek",
          rate: 3.0,
          userFullName: "E** K**",
          trusted: true,
          commentDateISOtype: "2022-08-15",
          lastModifiedDate: "15 Ağustos 2022"
        }
      ],
      colors: [
        {
          id: 95157674,
          imageUrl: "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/21/166438552/158639550/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Düz Renk Örme Triko Astarlı Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        },
        {
          id: 95157512,
          imageUrl: "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/20/166427506/158642119/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Düz Renk Örme Triko Astarlı Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 80296892,
      productCode: "TS-E001",
      name: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      slug: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.72 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 68847996,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty224/product/media/images/20211104/23/165839194/176626210/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211104/23/165839194/176626210/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211104/23/165839194/176626210/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211104/23/165839194/176626210/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.85714285714286,
        totalCount: 7.0
      },
      winnerVariant: "44",
      color: "DESENLİ3",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 136062898,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 102923111,
          comment: "Etek resimde görüldüğü gibi çok güzel ve kaliteli.     Satıcı sorularıma hemen cevap verdi. Anneme hediye almıştım yanında anneler günü ile ilgili çok güzel bir not göndermişler. Çok düşüncelisiniz teşekkürler 😍",
          rate: 5.0,
          userFullName: "n** d**",
          trusted: true,
          commentDateISOtype: "2021-05-08",
          lastModifiedDate: "8 Mayıs 2021"
        },
        {
          id: 86607668,
          comment: "modeli harika giyildiğinde kumaş kalitesi mükemmel. teşekkürler Anneme aldım çok beğendi",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-02-20",
          lastModifiedDate: "20 Şubat 2021"
        },
        {
          id: 102548466,
          comment: "uzun olduğu için bir yıldız kırdım",
          rate: 4.0,
          userFullName: "B** A**",
          trusted: true,
          commentDateISOtype: "2021-05-07",
          lastModifiedDate: "7 Mayıs 2021"
        }
      ],
      colors: [
        {
          id: 80296944,
          imageUrl: "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/21/166437576/136043629/1/1_org_zoom.jpg",
          name: "DESENLİ1",
          description: "Kadın Örme Triko Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        },
        {
          id: 80296892,
          imageUrl: "https://cdn.dsmcdn.com/ty224/product/media/images/20211104/23/165839194/176626210/1/1_org_zoom.jpg",
          name: "DESENLİ3",
          description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 290596087,
      productCode: "TS-7211",
      name: "Renkli Puantiye Desenli Viskon Büyük Beden Gömlek",
      slug: "Renkli Puantiye Desenli Viskon Büyük Beden Gömlek",
      description: "Renkli Puantiye Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 245135745,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/18/100783784/464245287/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/18/100783784/464245287/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/18/100783784/464245287/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty414/product/media/images/20220430/18/100783784/464245287/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "44",
      color: "LACİ PUANTİYE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 464263302,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Klasik"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Truvakar Kol"
        },
        {
          id: 101,
          key: "Cep",
          value: "Tek"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322528137,
      productCode: "TS-7182",
      name: "Çizgili %100 Pamuklu Keten Büyük Beden Gömlek Ts-7182",
      slug: "Çizgili %100 Pamuklu Keten Büyük Beden Gömlek Ts-7182",
      description: "Çizgili %100 Pamuklu Keten Büyük Beden Gömlek Ts-7182",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117264,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/9/135967269/515321624/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/9/135967269/515321624/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/9/135967269/515321624/2/2_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 343.24,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "44",
      color: "GRİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 515321624,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 314693726,
          comment: "cok şık duruyor.yqz aksamlari için ideal",
          rate: 5.0,
          userFullName: "T** K**",
          trusted: true,
          commentDateISOtype: "2023-06-16",
          lastModifiedDate: "16 Haziran 2023"
        },
        {
          id: 198222862,
          comment: "güzel tavsiye ederim.",
          rate: 5.0,
          userFullName: "S** Ö**",
          trusted: true,
          commentDateISOtype: "2022-08-11",
          lastModifiedDate: "11 Ağustos 2022"
        },
        {
          id: 302447018,
          comment: "Kumaşı duruşu çok güzel ve kaliteli. 178 boy, 85 kg 44 beden tam oldu. Arkayi kapatiyor taytla rahat giyilir. ben biraz bol durmasi icin iade edip bir beden büyuk siparis edecegim. Saticinin ozenli paketlenmesi ve ince notu icin teşekkür ederim ♥️",
          rate: 5.0,
          userFullName: "E** S**",
          trusted: true,
          commentDateISOtype: "2023-05-10",
          lastModifiedDate: "10 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 283366602,
          imageUrl: "https://cdn.dsmcdn.com/ty454/product/media/images/20220613/10/125036541/450750883/1/1_org_zoom.jpg",
          name: "INDIGO",
          description: "Çizgili Pamuklu Keten Büyük Beden Gömlek"
        },
        {
          id: 322528137,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/9/135967269/515321624/1/1_org_zoom.jpg",
          name: "GRİ",
          description: "Çizgili %100 Pamuklu Keten Büyük Beden Gömlek Ts-7182"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471099,
      productCode: "TS-L240",
      name: "Nervür Detaylı Şifon Büyük Beden Klasik Gömlek",
      slug: "Nervür Detaylı Şifon Büyük Beden Klasik Gömlek",
      description: "Nervür Detaylı Şifon Büyük Beden Klasik Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458614,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897612/515256656/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897612/515256656/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897612/515256656/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897612/515256656/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515261093,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471099,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897612/515256656/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Nervür Detaylı Şifon Büyük Beden Klasik Gömlek"
        },
        {
          id: 322470047,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896334/515255068/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Nervür Detaylı Şifon Büyük Beden Klasik Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283366424,
      productCode: "TS-7196",
      name: "Rengarenk Suluboya Çiçek Desenli Viskon Büyük Beden Gömlek",
      slug: "Rengarenk Suluboya Çiçek Desenli Viskon Büyük Beden Gömlek",
      description: "Rengarenk Suluboya Çiçek Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239118571,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92948955/450750642/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92948955/450750642/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92948955/450750642/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92948955/450750642/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 269.99,
        sellingPrice: 379.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "RENGARENK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450757653,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95363843,
      productCode: "TS-7124",
      name: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      slug: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 82558041,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183281/158956704/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183281/158956704/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183281/158956704/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183281/158956704/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/20/2183281/158956704/4/4_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 349.99,
        sellingPrice: 379.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.5,
        totalCount: 6.0
      },
      winnerVariant: "XL",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 158956704,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 210519010,
          comment: "anneme aldim swverel giyor",
          rate: 3.0,
          userFullName: "Azer Z.",
          trusted: true,
          commentDateISOtype: "2022-09-21",
          lastModifiedDate: "21 Eylül 2022"
        },
        {
          id: 111497575,
          comment: "ÜRÜN ELİME ULASMADI ÜRÜNÜM NERDE NASIL BİR MAĞAZASINIZ NERDE ÜRÜN NERDE PARAM ???",
          rate: 1.0,
          userFullName: "Ayşegül K.",
          trusted: true,
          commentDateISOtype: "2021-06-11",
          lastModifiedDate: "11 Haziran 2021"
        }
      ],
      colors: [
        {
          id: 95363931,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183255/158956794/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 95363843,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/20/2183281/158956704/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 124013108,
          imageUrl: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183227/200722385/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        },
        {
          id: 95363890,
          imageUrl: "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/20/2183046/158963902/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Düz Renk Truvakar Kol Büyük Beden Krep Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Krep"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 377068575,
      productCode: "TS-YY672",
      name: "Elegant Örgü Triko Anne Büyük Beden Yelek",
      slug: "Elegant Örgü Triko Anne Büyük Beden Yelek",
      description: "Elegant Örgü Triko Anne Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Triko Büyük Beden Anne Yelek, Ürün Örme Triko Anne Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 304457104,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811702/609488377/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811702/609488377/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221030/7/203811702/609488377/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811702/609488377/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "MÜRDÜM",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 609489602,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377068575,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811702/609488377/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Elegant Örgü Triko Anne Büyük Beden Yelek"
        },
        {
          id: 376979591,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221029/19/203718428/609176432/1/1_org_zoom.jpg",
          name: "TAŞ",
          description: "Elegant Örgü Triko Anne Büyük Beden Yelek"
        },
        {
          id: 377068537,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811671/609488339/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Elegant Örgü Triko Anne Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 376979591,
      productCode: "TS-YY672",
      name: "Elegant Örgü Triko Anne Büyük Beden Yelek",
      slug: "Elegant Örgü Triko Anne Büyük Beden Yelek",
      description: "Elegant Örgü Triko Anne Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Triko Büyük Beden Anne Yelek, Ürün Örme Triko Anne Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 304457104,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty580/product/media/images/20221029/19/203718428/609176432/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221029/19/203718428/609176432/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221029/19/203718428/609176432/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221029/19/203718428/609176432/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "TAŞ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 609180625,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 377068575,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811702/609488377/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Elegant Örgü Triko Anne Büyük Beden Yelek"
        },
        {
          id: 376979591,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221029/19/203718428/609176432/1/1_org_zoom.jpg",
          name: "TAŞ",
          description: "Elegant Örgü Triko Anne Büyük Beden Yelek"
        },
        {
          id: 377068537,
          imageUrl: "https://cdn.dsmcdn.com/ty580/product/media/images/20221030/7/203811671/609488339/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Elegant Örgü Triko Anne Büyük Beden Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 376710732,
      productCode: "TS-L554",
      name: "Kruvaze Renkli Çizgili Klasik Krep Büyük Beden Gömlek",
      slug: "Kruvaze Renkli Çizgili Klasik Krep Büyük Beden Gömlek",
      description: "Kruvaze Renkli Çizgili Klasik Krep Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Renkli Büyük Beden Krep Gömlek., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 304192057,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/16/203413234/608748391/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty580/product/media/images/20221028/16/203413234/608748391/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/16/203413234/608748391/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/16/203413234/608748391/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "KİREMİT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 608748391,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 376710677,
      productCode: "TS-L672",
      name: "Kruvaze Renkli Çizgili Klasik Krep Büyük Beden Gömlek",
      slug: "Kruvaze Renkli Çizgili Klasik Krep Büyük Beden Gömlek",
      description: "Kruvaze Renkli Çizgili Klasik Krep Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Renkli Büyük Beden Krep Gömlek., Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 304192002,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/16/203413177/608748334/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/16/203413177/608748334/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty581/product/media/images/20221028/16/203413177/608748334/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty579/product/media/images/20221028/16/203413177/608748334/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "38",
      color: "MERCAN",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 608760813,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321349817,
      productCode: "TS-S121",
      name: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      slug: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684099,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134627000/512820430/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134627000/512820430/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134627000/512820430/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134627000/512820430/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "40-42",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512913528,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 194272641,
          comment: "iyi cok guzel",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-23",
          lastModifiedDate: "23 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 321346556,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623638/512815749/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        },
        {
          id: 321349817,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134627000/512820430/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Kısa Kol Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321346807,
      productCode: "TS-S204",
      name: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz",
      slug: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz",
      description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 40-42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265684290,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623888/512816012/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623888/512816012/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty466/product/media/images/20220703/20/134623888/512816012/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty465/product/media/images/20220703/20/134623888/512816012/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40-42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40-42",
          itemNumber: 512827776,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 321349786,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134626967/512820398/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz"
        },
        {
          id: 321346807,
          imageUrl: "https://cdn.dsmcdn.com/ty467/product/media/images/20220703/20/134623888/512816012/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez %100 Pamuk Kumaş Yaka Işlemeli Büyük Beden Uzun Kol Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Nakışlı / İşlemeli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283365026,
      productCode: "TS-6820",
      name: "Renkli Çiçek Desenli Pamuklu Büyük Beden Gömlek",
      slug: "Renkli Çiçek Desenli Pamuklu Büyük Beden Gömlek",
      description: "Renkli Çiçek Desenli Pamuklu Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117333,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947218/450748276/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947218/450748276/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92947218/450748276/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947218/450748276/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947218/450748276/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450755625,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283364935,
      productCode: "TS-7189",
      name: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      slug: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      description: "Renkli Papatya Desenli Pamuklu Truvakar Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117252,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92947124/450748174/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92947124/450748174/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947124/450748174/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty401/product/media/images/20220416/14/92947124/450748174/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 450755481,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Pamuk"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283364878,
      productCode: "TS-1749",
      name: "Leopar Desenli Viskon Büyük Beden Gömlek",
      slug: "Leopar Desenli Viskon Büyük Beden Gömlek",
      description: "Leopar Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Viskon gömlek., %100 Viskon kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117214,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947068/450748113/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947068/450748113/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92947068/450748113/2/2_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 329.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.33333333333333,
        totalCount: 3.0
      },
      winnerVariant: "38",
      color: "SİYAH-LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "38",
          itemNumber: 450755326,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 314976402,
          comment: "anneme aldım çok beğendi",
          rate: 4.0,
          userFullName: "E** t**",
          trusted: true,
          commentDateISOtype: "2023-06-17",
          lastModifiedDate: "17 Haziran 2023"
        }
      ],
      colors: [
        {
          id: 377340980,
          imageUrl: "https://cdn.dsmcdn.com/ty584/product/media/images/20221031/11/204113931/609978107/1/1_org_zoom.jpg",
          name: "LEOPAR",
          description: "Leopar Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 283366547,
          imageUrl: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92949095/450750817/1/1_org_zoom.jpg",
          name: "KAHVE-LEOPAR",
          description: "Leopar Desenli Viskon Büyük Beden Gömlek"
        },
        {
          id: 283364878,
          imageUrl: "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947068/450748113/1/1_org_zoom.jpg",
          name: "SİYAH-LEOPAR",
          description: "Leopar Desenli Viskon Büyük Beden Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Leopar"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 180722696,
      productCode: "TS-0021",
      name: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      slug: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Triko Büyük Beden Anne Yelek, Ürün Örme Triko Anne Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün 2XL bedendir. Mankenin Ölçüleri"
        },
        {
          bold: false,
          description: "Göğüs: 90 Bel: 60 Basen: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 155139287,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty321/product/media/images/20220205/11/44804427/295444210/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty321/product/media/images/20220205/11/44804427/295444210/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty323/product/media/images/20220205/11/44804427/295444210/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty322/product/media/images/20220205/11/44804427/295444210/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty322/product/media/images/20220205/11/44804427/295444210/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 7.0
      },
      winnerVariant: "XXL",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XXL",
          itemNumber: 295444210,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 157967239,
          comment: "Çok güzel kulanişli şik görünümlü",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-02-02",
          lastModifiedDate: "2 Şubat 2022"
        },
        {
          id: 257951687,
          comment: "görseldeki  gibi  begendim  ayrıca notunuz için teşekkür ederim  efendim🤗",
          rate: 5.0,
          userFullName: "Z** K**",
          trusted: true,
          commentDateISOtype: "2023-01-20",
          lastModifiedDate: "20 Ocak 2023"
        },
        {
          id: 151951393,
          comment: "Çok güzel beğendim",
          rate: 5.0,
          userFullName: "f** ü**",
          trusted: true,
          commentDateISOtype: "2022-01-10",
          lastModifiedDate: "10 Ocak 2022"
        }
      ],
      colors: [
        {
          id: 180722696,
          imageUrl: "https://cdn.dsmcdn.com/ty321/product/media/images/20220205/11/44804427/295444210/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek"
        },
        {
          id: 180719881,
          imageUrl: "https://cdn.dsmcdn.com/ty313/product/media/images/20220130/22/39365905/295440322/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek"
        },
        {
          id: 180721594,
          imageUrl: "https://cdn.dsmcdn.com/ty323/product/media/images/20220205/10/44763217/295291113/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 180721594,
      productCode: "TS-0021",
      name: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      slug: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Triko Büyük Beden Anne Yelek, Ürün Örme Triko Anne Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün 2XL bedendir. Mankenin Ölçüleri"
        },
        {
          bold: false,
          description: "Göğüs: 90 Bel: 60 Basen: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 155139287,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty323/product/media/images/20220205/10/44763217/295291113/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty323/product/media/images/20220205/10/44763217/295291113/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty322/product/media/images/20220205/10/44763217/295291113/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty321/product/media/images/20220205/10/44763217/295291113/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty322/product/media/images/20220205/10/44763217/295291113/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 5.0
      },
      winnerVariant: "XXL",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XXL",
          itemNumber: 295291113,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 189286152,
          comment: "Annem bayıldı, evde ve dışarda hergün giydi. Hem şık hem kalça altından az uzun süper kapatıyor. 4xl çoj şık oldu",
          rate: 5.0,
          userFullName: "H** D**",
          trusted: true,
          commentDateISOtype: "2022-06-22",
          lastModifiedDate: "22 Haziran 2022"
        },
        {
          id: 289438223,
          comment: "gayet iyi modeli",
          rate: 5.0,
          userFullName: "N** g** A**",
          trusted: true,
          commentDateISOtype: "2023-04-07",
          lastModifiedDate: "7 Nisan 2023"
        },
        {
          id: 149985693,
          comment: "🥰 yıllardır alış veriş yapıyorum hiç yorum yapma gereğinden bulunmamıştım TAKI SIZINLE  😉karsilasana kadar  size karşı kelimeler kiyafetsiz diğer takipçilerine gönül rahatlığıyla almalarını öneriyorum cok memnun kalacaklar 🥰",
          rate: 5.0,
          userFullName: "e** d**",
          trusted: true,
          commentDateISOtype: "2021-12-29",
          lastModifiedDate: "29 Aralık 2021"
        }
      ],
      colors: [
        {
          id: 180722696,
          imageUrl: "https://cdn.dsmcdn.com/ty321/product/media/images/20220205/11/44804427/295444210/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek"
        },
        {
          id: 180719881,
          imageUrl: "https://cdn.dsmcdn.com/ty313/product/media/images/20220130/22/39365905/295440322/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek"
        },
        {
          id: 180721594,
          imageUrl: "https://cdn.dsmcdn.com/ty323/product/media/images/20220205/10/44763217/295291113/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126155043,
      productCode: "TS-S104001",
      name: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz",
      slug: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz",
      description: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 111000065,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111677713/206666319/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111677713/206666319/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677713/206666319/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111677713/206666319/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 206683711,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126154982,
      productCode: "TS-S206018",
      name: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz",
      slug: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz",
      description: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Şile bez kumaş yaka işlemeli büyük beden bluz, ürünün kumaşı, vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, giyen kişiyi serin tutar, kumaş nefes alır, oldukça sağlıklı bir kumaştır, tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 44-46 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 111000010,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111677653/206666014/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111677653/206666014/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677653/206666014/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677653/206666014/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 206666014,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126029371,
      productCode: "TS-S104018",
      name: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz",
      slug: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz",
      description: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110879797,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534403/205961591/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534403/205961591/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/21/111534403/205961591/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/21/111534403/205961591/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 205961591,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126029365,
      productCode: "TS-S206001",
      name: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      slug: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110879792,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534397/205961555/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534397/205961555/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534397/205961555/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/21/111534397/205961555/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 137.99,
        sellingPrice: 329.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 4.0
      },
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 205975220,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 181365506,
          comment: "siyah istedim,beyaz gönderdiler,ama güzel ürün,incecik",
          rate: 3.0,
          userFullName: "K** E**",
          trusted: true,
          commentDateISOtype: "2022-05-14",
          lastModifiedDate: "14 Mayıs 2022"
        },
        {
          id: 152272485,
          comment: "Cok begendim hoşuma gitti ürün kaliteli herkese tavsiye ederim ❤️",
          rate: 5.0,
          userFullName: "h** z**",
          trusted: true,
          commentDateISOtype: "2022-01-11",
          lastModifiedDate: "11 Ocak 2022"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126024092,
      productCode: "TS-5312",
      name: "Etnik Desenli Viskon Büyük Beden Bluz",
      slug: "Etnik Desenli Viskon Büyük Beden Bluz",
      description: "Etnik Desenli Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon kısa kol büyük beden bluz., tayt ve pantolonlarla kullanımı rahattır, günlük kullanıma uygundur, ürünün ana kumaşı viskon kumaştır.Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., kol kısmı kısa koldur.., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110875046,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527009/205932779/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527009/205932779/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527009/205932779/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111527009/205932779/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 205948641,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 179959375,
          comment: "cok guzel.kalitesi cok iyi",
          rate: 5.0,
          userFullName: "f** s**",
          trusted: true,
          commentDateISOtype: "2022-05-08",
          lastModifiedDate: "8 Mayıs 2022"
        }
      ],
      colors: [
        {
          id: 126024092,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527009/205932779/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Etnik Desenli Viskon Büyük Beden Bluz"
        },
        {
          id: 126024079,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/19/111526998/205932528/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Etnik Desenli Viskon Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126024079,
      productCode: "TS-5312",
      name: "Etnik Desenli Viskon Büyük Beden Bluz",
      slug: "Etnik Desenli Viskon Büyük Beden Bluz",
      description: "Etnik Desenli Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon kısa kol büyük beden bluz., tayt ve pantolonlarla kullanımı rahattır, günlük kullanıma uygundur, ürünün ana kumaşı viskon kumaştır.Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., kol kısmı kısa koldur.., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110875046,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/19/111526998/205932528/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/19/111526998/205932528/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526998/205932528/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/19/111526998/205932528/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 205948423,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126024092,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527009/205932779/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Etnik Desenli Viskon Büyük Beden Bluz"
        },
        {
          id: 126024079,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/19/111526998/205932528/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Etnik Desenli Viskon Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96367853,
      productCode: "TS-S205",
      name: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      slug: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz, Ürünün kumaşı"
        },
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44-46 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 83483004,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty96/product/media/images/20210405/1/77687004/160503561/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty96/product/media/images/20210405/1/77687004/160503561/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty94/product/media/images/20210405/1/77687004/160503561/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty96/product/media/images/20210405/1/77687004/160503561/2/2_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 189.99,
        sellingPrice: 329.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.16666666666667,
        totalCount: 6.0
      },
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 160507404,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 141127627,
          comment: "büyük beden olanlar için ideal tam beden alınmasını tavsiye ederim salaş olsun isterseniz 1 beden büyük iyi olur",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-11-18",
          lastModifiedDate: "18 Kasım 2021"
        },
        {
          id: 156413667,
          comment: "Ürün uzun kollu olarak gösteriliyor ve ben bunun için sipariş etmiştim. Kısa kollu bir ürün göndermişler. Bu konuda çok memnuniyetsizim",
          rate: 1.0,
          userFullName: "Sirin Y.",
          trusted: true,
          commentDateISOtype: "2022-01-27",
          lastModifiedDate: "27 Ocak 2022"
        },
        {
          id: 155905364,
          comment: "kumaş kalitesi,dikişi ,kalıbı çok güzel ,rahat rahat alın giyin",
          rate: 5.0,
          userFullName: "ö** k**",
          trusted: true,
          commentDateISOtype: "2022-01-25",
          lastModifiedDate: "25 Ocak 2022"
        }
      ],
      colors: [
        {
          id: 96367788,
          imageUrl: "https://cdn.dsmcdn.com/ty93/product/media/images/20210405/0/77686802/160503496/0/0_org_zoom.jpg",
          name: "EKRU",
          description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz"
        },
        {
          id: 96367853,
          imageUrl: "https://cdn.dsmcdn.com/ty96/product/media/images/20210405/1/77687004/160503561/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "U Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96367788,
      productCode: "TS-S205",
      name: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      slug: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz, Ürünün kumaşı"
        },
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44-46 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 83483004,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty93/product/media/images/20210405/0/77686802/160503496/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty93/product/media/images/20210405/0/77686802/160503496/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty95/product/media/images/20210405/0/77686802/160503496/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty94/product/media/images/20210405/0/77686802/160503496/2/2_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.75,
        totalCount: 4.0
      },
      winnerVariant: "44-46",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 160507347,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 188536069,
          comment: "Anneme aldım üstüne tam oldu çok beğendi teşekkürler",
          rate: 5.0,
          userFullName: "K** E**",
          trusted: true,
          commentDateISOtype: "2022-06-19",
          lastModifiedDate: "19 Haziran 2022"
        },
        {
          id: 169780601,
          comment: "Babannem için aldık çok beğendi. Kısa sürede ulaştı elimize güzel ve kaliteli.",
          rate: 5.0,
          userFullName: "Ş** B**",
          trusted: true,
          commentDateISOtype: "2022-03-23",
          lastModifiedDate: "23 Mart 2022"
        },
        {
          id: 268901062,
          comment: "Güzel begendim",
          rate: 5.0,
          userFullName: "C** E**",
          trusted: true,
          commentDateISOtype: "2023-02-14",
          lastModifiedDate: "14 Şubat 2023"
        }
      ],
      colors: [
        {
          id: 96367788,
          imageUrl: "https://cdn.dsmcdn.com/ty93/product/media/images/20210405/0/77686802/160503496/0/0_org_zoom.jpg",
          name: "EKRU",
          description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz"
        },
        {
          id: 96367853,
          imageUrl: "https://cdn.dsmcdn.com/ty96/product/media/images/20210405/1/77687004/160503561/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "U Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96367765,
      productCode: "TS-S109",
      name: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      slug: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz, Ürünün kumaşı"
        },
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44-46 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 83482893,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty98/product/media/images/20210405/0/77686817/160503475/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty98/product/media/images/20210405/0/77686817/160503475/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty97/product/media/images/20210405/0/77686817/160503475/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty95/product/media/images/20210405/0/77686817/160503475/2/2_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 329.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.5,
        totalCount: 4.0
      },
      winnerVariant: "44-46",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 160507313,
          stock: 6.0
        }
      ],
      productReviews: [
        {
          id: 191701725,
          comment: "anneme aldım, kalitesini çok beyendi, bayıldı resmen. tam yazlık. yıkadıkdan sonra bi deyişiklik olursa gelip yazacağım. kendi bedenini aldık 44-46.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-06",
          lastModifiedDate: "6 Temmuz 2022"
        },
        {
          id: 113084381,
          comment: "Çok güzel 👍👍👍",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-06-17",
          lastModifiedDate: "17 Haziran 2021"
        },
        {
          id: 186370237,
          comment: "yalnış ürün göndermişsiniz. kısa kol yerine uzun kollu bi ürün geldi",
          rate: 3.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-06-08",
          lastModifiedDate: "8 Haziran 2022"
        }
      ],
      colors: [
        {
          id: 96367765,
          imageUrl: "https://cdn.dsmcdn.com/ty98/product/media/images/20210405/0/77686817/160503475/0/0_org_zoom.jpg",
          name: "EKRU",
          description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz"
        },
        {
          id: 96367665,
          imageUrl: "https://cdn.dsmcdn.com/ty96/product/media/images/20210405/0/77686778/160503374/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "U Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96367665,
      productCode: "TS-S109",
      name: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      slug: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Şile Bez Kumaş Yaka İşlemeli Büyük Beden Bluz, Ürünün kumaşı"
        },
        {
          bold: false,
          description: "Vücutta ter tutmaz ve ütü gerektirmez, %100 pamuk lifli bir kumaştır, Giyen kişiyi serin tutar, Kumaş nefes alır, Oldukça sağlıklı bir kumaştır, Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 44-46 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 83482893,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty96/product/media/images/20210405/0/77686778/160503374/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty96/product/media/images/20210405/0/77686778/160503374/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty93/product/media/images/20210405/0/77686778/160503374/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty98/product/media/images/20210405/0/77686778/160503374/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty95/product/media/images/20210405/0/77686778/160503374/3/3_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 329.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.4,
        totalCount: 5.0
      },
      winnerVariant: "44-46",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 160503374,
          stock: 10.0
        }
      ],
      productReviews: [
        {
          id: 167414477,
          comment: "kalıbı fazlasıyla rahat, beden seçiminde dikkatli olun.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-03-15",
          lastModifiedDate: "15 Mart 2022"
        },
        {
          id: 176230040,
          comment: "Malesef büyük geldi beyazını alcaktım dur bi deneyeyim diye önden bunu sipariş ettim ancak olmadı ama kalitesi güzel",
          rate: 3.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-04-20",
          lastModifiedDate: "20 Nisan 2022"
        },
        {
          id: 118682879,
          comment: "kaliteli ve güzel bedeninde tam iyi oldu satıcıya ve trendyola teşekkürler",
          rate: 5.0,
          userFullName: "N** E**",
          trusted: true,
          commentDateISOtype: "2021-07-14",
          lastModifiedDate: "14 Temmuz 2021"
        }
      ],
      colors: [
        {
          id: 96367765,
          imageUrl: "https://cdn.dsmcdn.com/ty98/product/media/images/20210405/0/77686817/160503475/0/0_org_zoom.jpg",
          name: "EKRU",
          description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz"
        },
        {
          id: 96367665,
          imageUrl: "https://cdn.dsmcdn.com/ty96/product/media/images/20210405/0/77686778/160503374/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Şile Bez Kumaş Yaka Işlemeli Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "U Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 95157674,
      productCode: "TS-E3015",
      name: "Düz Renk Örme Triko Astarlı Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      slug: "Düz Renk Örme Triko Astarlı Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      description: "Düz Renk Örme Triko Astarlı Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse düz renk astarlı beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44-46 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 82379824,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/21/166438552/158639550/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/21/166438552/158639550/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/21/166438552/158639550/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/21/166438552/158639550/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/21/166438552/158639550/4/4_org_zoom.jpg"
      ],
      price: 316.3,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 316.3,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 316.3,
        sellingPrice: 316.3
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "MÜRDÜM",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 176626327,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 95157674,
          imageUrl: "https://cdn.dsmcdn.com/ty225/product/media/images/20211105/21/166438552/158639550/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Düz Renk Örme Triko Astarlı Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        },
        {
          id: 95157512,
          imageUrl: "https://cdn.dsmcdn.com/ty227/product/media/images/20211105/20/166427506/158642119/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Düz Renk Örme Triko Astarlı Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 74224589,
      productCode: "TS-1739",
      name: "Polar Fermuarlı Hırka",
      slug: "Polar Fermuarlı Hırka",
      description: "Polar Fermuarlı Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Mont, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yapmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün XXL bedendir., Modelin Ölçüleri: Boy: 1.74 Göğüs: 92, Bel: 65, Kalça: 90"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 47804667,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20827182/127585285/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20827182/127585285/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20827182/127585285/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/14/20827182/127585285/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/14/20827182/127585285/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.61538461538462,
        totalCount: 13.0
      },
      winnerVariant: "40",
      color: "ANTRASİT",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 130689002,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 95821404,
          comment: "İlk izlenim tüy topluyor 40 bedenim geniş dursun diye 44 beden aldim tam oturdu kalıbı dar  2 beden büyük alınmalı kalitesi güzel.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-04-09",
          lastModifiedDate: "9 Nisan 2021"
        },
        {
          id: 146655929,
          comment: "Çok güzel bir ürün. Rengini çok beğendim. Tüylenme de yapmadı. Herkes kendi bedenini alabilir. Salaş isteyenler bir beden büyük alabilir.",
          rate: 5.0,
          userFullName: "Y** Ş**",
          trusted: true,
          commentDateISOtype: "2021-12-12",
          lastModifiedDate: "12 Aralık 2021"
        },
        {
          id: 237128481,
          comment: "Kalitesi mükemmel tam beden",
          rate: 5.0,
          userFullName: "G** S**",
          trusted: true,
          commentDateISOtype: "2022-12-04",
          lastModifiedDate: "4 Aralık 2022"
        }
      ],
      colors: [
        {
          id: 74221978,
          imageUrl: "https://cdn.dsmcdn.com/ty312/product/media/images/20220131/17/39741781/306762676/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Hırka"
        },
        {
          id: 56510351,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20850820/335689356/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Hırka."
        },
        {
          id: 57209073,
          imageUrl: "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69133127/101297412/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Büyük Beden Hırka"
        },
        {
          id: 74224589,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20827182/127585285/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        },
        {
          id: 634,
          key: "Kumaş/İplik Özellik",
          value: "Polar"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 74221978,
      productCode: "TS-1739",
      name: "Polar Fermuarlı Hırka",
      slug: "Polar Fermuarlı Hırka",
      description: "Polar Fermuarlı Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Mont, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yapmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün XXL bedendir., Modelin Ölçüleri: Boy: 1.74 Göğüs: 92, Bel: 65, Kalça: 90"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 47804667,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty312/product/media/images/20220131/17/39741781/306762676/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty312/product/media/images/20220131/17/39741781/306762676/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty312/product/media/images/20220131/17/39741781/306762676/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty314/product/media/images/20220131/17/39741781/306762676/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty314/product/media/images/20220131/17/39741781/306762676/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.52631578947368,
        totalCount: 19.0
      },
      winnerVariant: "42",
      color: "MOR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 306762676,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 274399200,
          comment: "fazlasıyla sıcak tutuyor. tam bedeninizi alın",
          rate: 5.0,
          userFullName: "M** U**",
          trusted: true,
          commentDateISOtype: "2023-03-02",
          lastModifiedDate: "2 Mart 2023"
        },
        {
          id: 165511108,
          comment: "Rengi göründüğü gibi çıkmadı,biraz renkten hayal kırıklığı yaşadım o kadar!kalınlığı,dikişi mükemmel ,inşallah tiftiklenmezde içime sinerek giyerim",
          rate: 4.0,
          userFullName: "Z** S** S**",
          trusted: true,
          commentDateISOtype: "2022-03-05",
          lastModifiedDate: "5 Mart 2022"
        },
        {
          id: 80970936,
          comment: "Annem çok kilolu ama çok güzel oldu , rengi görseldekinden daha güzel , kolları fazla uzun geldi. Teşekkürler",
          rate: 5.0,
          userFullName: "Neslihan A.",
          trusted: true,
          commentDateISOtype: "2021-01-21",
          lastModifiedDate: "21 Ocak 2021"
        }
      ],
      colors: [
        {
          id: 74221978,
          imageUrl: "https://cdn.dsmcdn.com/ty312/product/media/images/20220131/17/39741781/306762676/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Hırka"
        },
        {
          id: 56510351,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20850820/335689356/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Hırka."
        },
        {
          id: 57209073,
          imageUrl: "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69133127/101297412/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Büyük Beden Hırka"
        },
        {
          id: 74224589,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20827182/127585285/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        },
        {
          id: 634,
          key: "Kumaş/İplik Özellik",
          value: "Polar"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 57209073,
      productCode: "TS-1739",
      name: "Kadın Siyah Polar Fermuarlı Büyük Beden Hırka",
      slug: "Kadın Siyah Polar Fermuarlı Büyük Beden Hırka",
      description: "Kadın Siyah Polar Fermuarlı Büyük Beden Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Mont, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yapmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün XXL bedendir., Modelin Ölçüleri: Boy: 1.74 Göğüs: 92, Bel: 65, Kalça: 90"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 47804667,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69133127/101297412/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69133127/101297412/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty360/product/media/images/20220313/15/69133127/101297412/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty361/product/media/images/20220313/15/69133127/101297412/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty360/product/media/images/20220313/15/69133127/101297412/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.5625,
        totalCount: 144.0
      },
      winnerVariant: "42",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 130689325,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 295544784,
          comment: "salaş durmasını istiyorsanız bir beden büyük alin",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-04-23",
          lastModifiedDate: "23 Nisan 2023"
        },
        {
          id: 70410546,
          comment: "firmaya teşekkür ederim,ürün kısa sürede elime ulaştı.kalitesi güzel. Ben yorumlara bakarak bir beden büyük istedim,çok bol oldu.Geniş kesimli bir model.Kendi bedeninizi alın.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2020-12-02",
          lastModifiedDate: "2 Aralık 2020"
        },
        {
          id: 100412402,
          comment: "Çok beğendim. Kendi bedenimde aldım tam oldu",
          rate: 5.0,
          userFullName: "sevgi k.",
          trusted: true,
          commentDateISOtype: "2021-04-30",
          lastModifiedDate: "30 Nisan 2021"
        }
      ],
      colors: [
        {
          id: 74221978,
          imageUrl: "https://cdn.dsmcdn.com/ty312/product/media/images/20220131/17/39741781/306762676/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Hırka"
        },
        {
          id: 56510351,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20850820/335689356/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Hırka."
        },
        {
          id: 57209073,
          imageUrl: "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69133127/101297412/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Büyük Beden Hırka"
        },
        {
          id: 74224589,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20827182/127585285/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 634,
          key: "Kumaş/İplik Özellik",
          value: "Polar"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 56510351,
      productCode: "TS-1739",
      name: "Kadın Bordo Polar Fermuarlı Hırka.",
      slug: "Kadın Bordo Polar Fermuarlı Hırka.",
      description: "Kadın Bordo Polar Fermuarlı Hırka.",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Mont, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yapmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün XXL bedendir., Modelin Ölçüleri: Boy: 1.74 Göğüs: 92, Bel: 65, Kalça: 90"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 47804667,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20850820/335689356/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20850820/335689356/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20850820/335689356/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/15/20850820/335689356/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20850820/335689356/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.73333333333333,
        totalCount: 45.0
      },
      winnerVariant: "40",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 130689340,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 150841967,
          comment: "Anneme aldım ve cok begendik. Hafif yumuşacık ve cok şık.  Anamur olmasına rağmen cok hızlı teslimat için ayrica teşekkürler",
          rate: 5.0,
          userFullName: "D** Ç**",
          trusted: true,
          commentDateISOtype: "2022-01-03",
          lastModifiedDate: "3 Ocak 2022"
        },
        {
          id: 75783378,
          comment: "ürün göründüğü gibi ve kumaşı sıcacık tutuyor, anneme aldım çok beğendi. siyahını da aldım, tavsiye ederim",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2020-12-25",
          lastModifiedDate: "25 Aralık 2020"
        },
        {
          id: 66054632,
          comment: "Çok kaliteli, yumuşacık ve çok sıcak tutuyor kendi bedeninizi alabilirsiniz",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2020-11-15",
          lastModifiedDate: "15 Kasım 2020"
        }
      ],
      colors: [
        {
          id: 74221978,
          imageUrl: "https://cdn.dsmcdn.com/ty312/product/media/images/20220131/17/39741781/306762676/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Hırka"
        },
        {
          id: 56510351,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20850820/335689356/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Hırka."
        },
        {
          id: 57209073,
          imageUrl: "https://cdn.dsmcdn.com/ty357/product/media/images/20220313/15/69133127/101297412/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Büyük Beden Hırka"
        },
        {
          id: 74224589,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/14/20827182/127585285/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        },
        {
          id: 634,
          key: "Kumaş/İplik Özellik",
          value: "Polar"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Fermuarlı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48714723,
      productCode: "TS-6901",
      name: "Kadın Lacivert Desenli Viskon Gömlek",
      slug: "Kadın Lacivert Desenli Viskon Gömlek",
      description: "Kadın Lacivert Desenli Viskon Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan desenli viskon gömlek."
        },
        {
          bold: false,
          description: "%100 viskon kumaş."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 40939041,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty24/product/media/images/20201119/10/28956999/86364053/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty24/product/media/images/20201119/10/28956999/86364053/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956999/86364053/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty24/product/media/images/20201119/10/28956999/86364053/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956999/86364053/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 86364053,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Tekstil"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48466020,
      productCode: "C-0427-1",
      name: "Kadın Bordo Önü Yoğun Taş Işli Uzun Kol Viskon Bluz",
      slug: "Kadın Bordo Önü Yoğun Taş Işli Uzun Kol Viskon Bluz",
      description: "Kadın Bordo Önü Yoğun Taş Işli Uzun Kol Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40728554,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/13/10360378/85856360/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/13/10360378/85856360/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/13/10360378/85856360/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/13/10360378/85856360/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/13/10360378/85856360/4/4_org_zoom.jpg"
      ],
      price: 329.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 329.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 329.99,
        sellingPrice: 329.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85973483,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126155052,
          imageUrl: "https://cdn.dsmcdn.com/ty273/product/media/images/20211219/0/13090683/206683892/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Viskon Büyük Beden Bluz"
        },
        {
          id: 48466020,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/13/10360378/85856360/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Önü Yoğun Taş Işli Uzun Kol Viskon Bluz"
        },
        {
          id: 126155007,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677677/206666069/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Viskon Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 303804176,
      productCode: "TS-2045",
      name: "Kalp Desenli Çizgi Varaklı Detay %100 Viskon Büyük Beden Atlet Bluz",
      slug: "Kalp Desenli Çizgi Varaklı Detay %100 Viskon Büyük Beden Atlet Bluz",
      description: "Kalp Desenli Çizgi Varaklı Detay %100 Viskon Büyük Beden Atlet Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon kısa kol büyük beden bluz., günlük kullanıma uygundur, ürünün ana kumaşı viskon kumaştır.Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., kol kısmı sıfır koldur.., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 50 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 253459332,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty435/product/media/images/20220521/17/114576202/485383385/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty435/product/media/images/20220521/17/114576202/485383385/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty437/product/media/images/20220521/17/114576202/485383385/2/2_org_zoom.jpg"
      ],
      price: 249.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 249.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 249.99,
        sellingPrice: 249.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "50",
      color: "ÇİZGİLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 485383385,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Kısa Kol"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Viskon"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Kapamasız"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 247954559,
      productCode: "TS-E0021",
      name: "Etnik Desenli Penye Beli Lastikli Kalem Büyük Beden Etek",
      slug: "Etnik Desenli Penye Beli Lastikli Kalem Büyük Beden Etek",
      description: "Etnik Desenli Penye Beli Lastikli Kalem Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçü"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 215314770,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/21/57718406/396730693/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/21/57718406/396730693/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/21/57718406/396730693/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty340/product/media/images/20220223/21/57718406/396730693/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "BORDO-ÇİZGİLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 396753672,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 247948159,
          imageUrl: "https://cdn.dsmcdn.com/ty341/product/media/images/20220223/20/57711761/396709037/1/1_org_zoom.jpg",
          name: "BORDO-ETNİK",
          description: "Etnik Desenli Penye Beli Lastikli Kalem Büyük Beden Etek"
        },
        {
          id: 247954559,
          imageUrl: "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/21/57718406/396730693/1/1_org_zoom.jpg",
          name: "BORDO-ÇİZGİLİ",
          description: "Etnik Desenli Penye Beli Lastikli Kalem Büyük Beden Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 1,
          key: "Bel",
          value: "Lastikli"
        },
        {
          id: 33,
          key: "Desen",
          value: "Etnik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 141070414,
      productCode: "TS-E0013",
      name: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşı polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 121857394,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty484/product/media/images/20220719/23/145981717/239378076/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty484/product/media/images/20220719/23/145981717/239378076/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty485/product/media/images/20220719/23/145981717/239378076/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty484/product/media/images/20220719/23/145981717/239378076/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.75,
        totalCount: 4.0
      },
      winnerVariant: "44-46",
      color: "TURUNCU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 239378087,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 193724460,
          comment: "Ürün defolu geldi",
          rate: 1.0,
          userFullName: "E** K**",
          trusted: true,
          commentDateISOtype: "2022-07-20",
          lastModifiedDate: "20 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 141070397,
          imageUrl: "https://cdn.dsmcdn.com/ty173/product/media/images/20210909/10/127894062/239367898/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        },
        {
          id: 141070345,
          imageUrl: "https://cdn.dsmcdn.com/ty171/product/media/images/20210909/10/127894021/239367828/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        },
        {
          id: 141070414,
          imageUrl: "https://cdn.dsmcdn.com/ty484/product/media/images/20220719/23/145981717/239378076/1/1_org_zoom.jpg",
          name: "TURUNCU",
          description: "Zebra Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124642400,
      productCode: "TS-E0011",
      name: "Kadın Leopar Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Kadın Leopar Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Kadın Leopar Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 109765186,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989360/202853485/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989360/202853485/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty146/product/media/images/20210713/11/109989360/202853485/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989360/202853485/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 2.0
      },
      winnerVariant: "48-50",
      color: "KAHVE-LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "48-50",
          itemNumber: 202853485,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 245070653,
          comment: "Etek Cok guzel fakat anneme kucuk geldi o yuzden iade ediyoruz",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-12-24",
          lastModifiedDate: "24 Aralık 2022"
        },
        {
          id: 312211523,
          comment: "Beğenmedim kumaşı naylon, iade etmek zorunda kaldım.",
          rate: 2.0,
          userFullName: "Zeynep D.",
          trusted: true,
          commentDateISOtype: "2023-06-08",
          lastModifiedDate: "8 Haziran 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124642350,
      productCode: "TS-E006",
      name: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 109765096,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989329/202853405/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989329/202853405/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty144/product/media/images/20210713/11/109989329/202853405/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989329/202853405/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "46-48",
      color: "KOYU MOR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46-48",
          itemNumber: 202873303,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 307490979,
          comment: "çok kaliteli,anneme aldım. çok beğendi 🙏",
          rate: 5.0,
          userFullName: "E** K**",
          trusted: true,
          commentDateISOtype: "2023-05-24",
          lastModifiedDate: "24 Mayıs 2023"
        },
        {
          id: 302805509,
          comment: "Annecim bunu da beğendi teşekkürler",
          rate: 5.0,
          userFullName: "A** A**",
          trusted: true,
          commentDateISOtype: "2023-05-11",
          lastModifiedDate: "11 Mayıs 2023"
        },
        {
          id: 238765625,
          comment: "Güzel bir ürün tavsiye ederim",
          rate: 5.0,
          userFullName: "D** S**",
          trusted: true,
          commentDateISOtype: "2022-12-08",
          lastModifiedDate: "8 Aralık 2022"
        }
      ],
      colors: [
        {
          id: 124642287,
          imageUrl: "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989274/202853288/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        },
        {
          id: 124642350,
          imageUrl: "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989329/202853405/1/1_org_zoom.jpg",
          name: "KOYU MOR",
          description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124642349,
      productCode: "TS-E007",
      name: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek, Ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 Beden ölçüleri: en:56 cm – boy: 100 cm’dir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 109765147,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty146/product/media/images/20210713/11/109992073/202853403/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty146/product/media/images/20210713/11/109992073/202853403/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty146/product/media/images/20210713/11/109992073/202853403/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109992073/202853403/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44-46",
      color: "TURUNCU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 202877890,
          stock: 5.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 124642156,
      productCode: "TS-E008",
      name: "Kadın Leopar Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      slug: "Kadın Leopar Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      description: "Kadın Leopar Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Tusse desenli beli lastikli parçalı dokuma büyük beden etek, ürünün kumaşo polyviskondur. Kırışıklığa karşı dayanıklıdır. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün 44 bedendir. 44 beden ölçüleri: En:56 cm – boy: 100 cm’dir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 109764982,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty144/product/media/images/20210713/11/109989166/202852729/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty144/product/media/images/20210713/11/109989166/202852729/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty145/product/media/images/20210713/11/109989166/202852729/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty144/product/media/images/20210713/11/109989166/202852729/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 2.0,
      ratingScore: {
        averageRating: 2.0,
        totalCount: 1.0
      },
      winnerVariant: "44-46",
      color: "KAHVE-LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 202872963,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 312210686,
          comment: "Bu fiyata güzel değil, kumaşı naylon bir kumaş iade etmek zorunda kaldım.",
          rate: 2.0,
          userFullName: "Zeynep D.",
          trusted: true,
          commentDateISOtype: "2023-06-08",
          lastModifiedDate: "8 Haziran 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 102825809,
      productCode: "TS-E3150",
      name: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      slug: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Etek",
      productGroupId: 89545851,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Etek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty110/product/media/images/20210503/13/85068199/170086876/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty110/product/media/images/20210503/13/85068199/170086876/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210503/13/85068199/170086876/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210503/13/85068199/170086876/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty108/product/media/images/20210503/13/85068199/170086876/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.25,
        totalCount: 4.0
      },
      winnerVariant: "44-46",
      color: "MÜRDÜM",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44-46",
          itemNumber: 170086876,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 177042762,
          comment: "Anneme aldım cok guzel oldu boyu uzun oldu onun dışında cok kalıtelıydı",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-04-23",
          lastModifiedDate: "23 Nisan 2022"
        },
        {
          id: 312210884,
          comment: "Beğenmedik kumaşı naylon bir kumaş iade etmek zorunda kaldım.",
          rate: 2.0,
          userFullName: "Zeynep D.",
          trusted: true,
          commentDateISOtype: "2023-06-08",
          lastModifiedDate: "8 Haziran 2023"
        },
        {
          id: 145405024,
          comment: "tek kelime ile kaliteli",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-12-06",
          lastModifiedDate: "6 Aralık 2021"
        }
      ],
      colors: [
        {
          id: 102825809,
          imageUrl: "https://cdn.dsmcdn.com/ty110/product/media/images/20210503/13/85068199/170086876/1/1_org_zoom.jpg",
          name: "MÜRDÜM",
          description: "Kadın Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Etek"
        },
        {
          id: 102742605,
          imageUrl: "https://cdn.dsmcdn.com/ty108/product/media/images/20210503/10/84980891/169953666/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Çiçek Desenli Beli Lastikli Parçalı Dokuma Büyük Beden Anne Etek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Maxi"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Beli Lastikli"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 292140502,
      productCode: "TS-A186",
      name: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      slug: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 246427488,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102414417/466872867/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102414417/466872867/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102414417/466872867/2/2_org_zoom.jpg"
      ],
      price: 349.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 349.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 328.24,
        sellingPrice: 349.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 466883682,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 315054746,
          comment: "Güzel şık bluz rahat kalıp",
          rate: 5.0,
          userFullName: "S** E**",
          trusted: true,
          commentDateISOtype: "2023-06-17",
          lastModifiedDate: "17 Haziran 2023"
        }
      ],
      colors: [
        {
          id: 292143616,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102417742/466882180/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292140502,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102414417/466872867/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        },
        {
          id: 292142295,
          imageUrl: "https://cdn.dsmcdn.com/ty419/product/media/images/20220505/22/102416304/466878901/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Yuvarlak Taş Puantiye Detaylı Desenli Armani Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471143,
      productCode: "TS-L671",
      name: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      slug: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458609,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897655/515256702/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897655/515256702/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897655/515256702/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897655/515256702/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "YEŞİL-BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515261127,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471125,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897636/515256683/1/1_org_zoom.jpg",
          name: "SİYAH-BEYAZ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322471111,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897620/515256670/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322471143,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897655/515256702/1/1_org_zoom.jpg",
          name: "YEŞİL-BEYAZ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322470042,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896329/515255064/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471125,
      productCode: "TS-L671",
      name: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      slug: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458609,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897636/515256683/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897636/515256683/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897636/515256683/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897636/515256683/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "SİYAH-BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515261114,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471125,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897636/515256683/1/1_org_zoom.jpg",
          name: "SİYAH-BEYAZ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322471111,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897620/515256670/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322471143,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897655/515256702/1/1_org_zoom.jpg",
          name: "YEŞİL-BEYAZ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322470042,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896329/515255064/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471111,
      productCode: "TS-L671",
      name: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      slug: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458609,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897620/515256670/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897620/515256670/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897620/515256670/2/2_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515261106,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471125,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897636/515256683/1/1_org_zoom.jpg",
          name: "SİYAH-BEYAZ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322471111,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897620/515256670/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322471143,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897655/515256702/1/1_org_zoom.jpg",
          name: "YEŞİL-BEYAZ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322470042,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896329/515255064/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471063,
      productCode: "TS-L679",
      name: "Fil Desenli Pamuklu Büyük Beden Bluz Gömlek",
      slug: "Fil Desenli Pamuklu Büyük Beden Bluz Gömlek",
      description: "Fil Desenli Pamuklu Büyük Beden Bluz Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458565,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897558/515256616/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897558/515256616/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897558/515256616/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897558/515256616/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515261048,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322469997,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896281/515255017/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Fil Desenli Pamuklu Büyük Beden Bluz Gömlek"
        },
        {
          id: 322471063,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897558/515256616/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Fil Desenli Pamuklu Büyük Beden Bluz Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471004,
      productCode: "TS-L681",
      name: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek",
      slug: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek",
      description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Şifon Büyük Beden Elegant Şık Gömlek, Ürün klasik pantolonlarla çok şık kombinlenebilrmketedir. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458508,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897501/515256556/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897501/515256556/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897501/515256556/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897501/515256556/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515256556,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471004,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897501/515256556/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek"
        },
        {
          id: 322469937,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896221/515254957/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek"
        },
        {
          id: 322470997,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897489/515256549/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470997,
      productCode: "TS-L681",
      name: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek",
      slug: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek",
      description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Şifon Büyük Beden Elegant Şık Gömlek, Ürün klasik pantolonlarla çok şık kombinlenebilrmketedir. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458508,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897489/515256549/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897489/515256549/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897489/515256549/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897489/515256549/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "FUŞYA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515261008,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471004,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897501/515256556/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek"
        },
        {
          id: 322469937,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896221/515254957/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek"
        },
        {
          id: 322470997,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897489/515256549/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470939,
      productCode: "TYC00500236851",
      name: "%100 pamuklu Kuğu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      slug: "%100 pamuklu Kuğu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      description: "%100 pamuklu Kuğu Çıtır Çiçekli Dokuma Geniş Kesim Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Pamuklu Dokuma Geniş Kesim Büyük Beden Gömlek, Kumaşın ince bir dokusu vardır, Yazlık bir üründür, Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266459475,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897418/515256470/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897418/515256470/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897418/515256470/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897418/515256470/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42",
      color: "TOZ PEMBE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 515260971,
          stock: 10.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322470042,
      productCode: "TS-L671",
      name: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      slug: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458609,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896329/515255064/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896329/515255064/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896329/515255064/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896329/515255064/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "FUŞYA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515255064,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471125,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897636/515256683/1/1_org_zoom.jpg",
          name: "SİYAH-BEYAZ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322471111,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897620/515256670/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322471143,
          imageUrl: "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135897655/515256702/1/1_org_zoom.jpg",
          name: "YEŞİL-BEYAZ",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        },
        {
          id: 322470042,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896329/515255064/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Palmiye Nakışlı Pamuklu Büyük Beden Gömlek Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322469997,
      productCode: "TS-L679",
      name: "Fil Desenli Pamuklu Büyük Beden Bluz Gömlek",
      slug: "Fil Desenli Pamuklu Büyük Beden Bluz Gömlek",
      description: "Fil Desenli Pamuklu Büyük Beden Bluz Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458565,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896281/515255017/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896281/515255017/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896281/515255017/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896281/515255017/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515259892,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322469997,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896281/515255017/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Fil Desenli Pamuklu Büyük Beden Bluz Gömlek"
        },
        {
          id: 322471063,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897558/515256616/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Fil Desenli Pamuklu Büyük Beden Bluz Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322469937,
      productCode: "TS-L681",
      name: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek",
      slug: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek",
      description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Şifon Büyük Beden Elegant Şık Gömlek, Ürün klasik pantolonlarla çok şık kombinlenebilrmketedir. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 38 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 266458508,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896221/515254957/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896221/515254957/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135896221/515254957/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220706/0/135896221/515254957/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "36",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "36",
          itemNumber: 515259807,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 322471004,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897501/515256556/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek"
        },
        {
          id: 322469937,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896221/515254957/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek"
        },
        {
          id: 322470997,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897489/515256549/1/1_org_zoom.jpg",
          name: "FUŞYA",
          description: "Plus Renkli Desenli Şifon Büyük Beden Elegant Şık Bluz Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 321792667,
      productCode: "TS-2100",
      name: "Desenli %100 Triko Pamuk Triko Truvakar Kol Büyük Beden Bluz",
      slug: "Desenli %100 Triko Pamuk Triko Truvakar Kol Büyük Beden Bluz",
      description: "Desenli %100 Triko Pamuk Triko Truvakar Kol Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Baskılı Bluz., Pamuklu Triko kumaştır., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün L bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 265940815,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135117164/513612032/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty469/product/media/images/20220704/17/135117164/513612032/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220704/17/135117164/513612032/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty468/product/media/images/20220704/17/135117164/513612032/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SAX MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 513612032,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 283364968,
      productCode: "TS-7194",
      name: "Büyük Puantiye Desenli Viskon Büyük Beden Gömlek",
      slug: "Büyük Puantiye Desenli Viskon Büyük Beden Gömlek",
      description: "Büyük Puantiye Desenli Viskon Büyük Beden Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Pamuk gömlek., %100 Pamuk kumaş., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 239117281,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92947153/450748215/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92947153/450748215/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty399/product/media/images/20220416/14/92947153/450748215/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty400/product/media/images/20220416/14/92947153/450748215/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "44",
      color: "SİYAH-PUANTİYE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "44",
          itemNumber: 450748215,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "%100 Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Puantiyeli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "%100 Pamuk"
        },
        {
          id: 183,
          key: "Kapama Şekli",
          value: "Düğmeli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 273926948,
      productCode: "TS-2023",
      name: "Şal Desen Büzgülü Viskon Büyük Beden Tunik Bluz",
      slug: "Şal Desen Büzgülü Viskon Büyük Beden Tunik Bluz",
      description: "Şal Desen Büzgülü Viskon Büyük Beden Tunik Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon kısa kol büyük beden bluz., günlük kullanıma uygundur, ürünün ana kumaşı viskon kumaştır.Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., kol kısmı sıfır koldur.., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 40 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 232834167,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty383/product/media/images/20220404/18/83001636/436635284/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty383/product/media/images/20220404/18/83001636/436635284/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/18/83001636/436635284/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/18/83001636/436635284/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "40",
      color: "LACİ ŞAL DESEN",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 436691691,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 179732400,
          comment: "Çok güzel gerçekten bir beden bence küçük alınması daha iyi olur",
          rate: 5.0,
          userFullName: "U** S**",
          trusted: true,
          commentDateISOtype: "2022-05-07",
          lastModifiedDate: "7 Mayıs 2022"
        }
      ],
      colors: [
        {
          id: 273926948,
          imageUrl: "https://cdn.dsmcdn.com/ty383/product/media/images/20220404/18/83001636/436635284/1/1_org_zoom.jpg",
          name: "LACİ ŞAL DESEN",
          description: "Şal Desen Büzgülü Viskon Büyük Beden Tunik Bluz"
        },
        {
          id: 273920619,
          imageUrl: "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/18/82993901/436623343/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Empirme Büzgülü Viskon Büyük Beden Tunik Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 273920619,
      productCode: "TS-2023",
      name: "Empirme Büzgülü Viskon Büyük Beden Tunik Bluz",
      slug: "Empirme Büzgülü Viskon Büyük Beden Tunik Bluz",
      description: "Empirme Büzgülü Viskon Büyük Beden Tunik Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon kısa kol büyük beden bluz., günlük kullanıma uygundur, ürünün ana kumaşı viskon kumaştır.Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., kol kısmı sıfır koldur.., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 40 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 232834167,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/18/82993901/436623343/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/18/82993901/436623343/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/18/82993901/436623343/2/2_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "40",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 436682887,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 199309296,
          comment: "Çok memnun kaldım. Yandaki bağcıklar sayesinde ön ve arkadan hoş büzgüler oluşuyor ve üzerinizde dökümlü durmasını sağlıyor. Ayrıca büzgüler göbek bölgesindeki fazlalıkları güzel gizliyor.😉.. tavsiye ederim.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-08-16",
          lastModifiedDate: "16 Ağustos 2022"
        },
        {
          id: 205556580,
          comment: "siyah kumas pantolonla harika durdu bekledigimden daha guzelmis",
          rate: 5.0,
          userFullName: "s** a**",
          trusted: true,
          commentDateISOtype: "2022-09-08",
          lastModifiedDate: "8 Eylül 2022"
        }
      ],
      colors: [
        {
          id: 273926948,
          imageUrl: "https://cdn.dsmcdn.com/ty383/product/media/images/20220404/18/83001636/436635284/1/1_org_zoom.jpg",
          name: "LACİ ŞAL DESEN",
          description: "Şal Desen Büzgülü Viskon Büyük Beden Tunik Bluz"
        },
        {
          id: 273920619,
          imageUrl: "https://cdn.dsmcdn.com/ty382/product/media/images/20220404/18/82993901/436623343/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Empirme Büzgülü Viskon Büyük Beden Tunik Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa Kol"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 48,
          key: "Boy",
          value: "Uzun"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 247990243,
      productCode: "BL-K043",
      name: "Yaka Detaylı Viskon Bluz",
      slug: "Yaka Detaylı Viskon Bluz",
      description: "Yaka Detaylı Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Aksesuar Yaka Bluz., Viskon kumaştır., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün L bedendir., Modelin Ölçüleri"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41692851,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/22/57756145/396794240/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/22/57756145/396794240/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/22/57756145/396794240/2/2_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 396805864,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 166711076,
          comment: "Cok hoşuma gitti rengi herseyiyle güzel 👍",
          rate: 5.0,
          userFullName: "h** z**",
          trusted: true,
          commentDateISOtype: "2022-03-11",
          lastModifiedDate: "11 Mart 2022"
        }
      ],
      colors: [
        {
          id: 247990243,
          imageUrl: "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/22/57756145/396794240/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Yaka Detaylı Viskon Bluz"
        },
        {
          id: 49555176,
          imageUrl: "https://cdn.dsmcdn.com/ty339/product/media/images/20220222/21/56615437/87798267/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Kadın Kahverengi Aksesuar Yakalı Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 180719881,
      productCode: "TS-0021",
      name: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      slug: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Triko Büyük Beden Anne Yelek, Ürün Örme Triko Anne Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün 2XL bedendir. Mankenin Ölçüleri"
        },
        {
          bold: false,
          description: "Göğüs: 90 Bel: 60 Basen: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 155139287,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty313/product/media/images/20220130/22/39365905/295440322/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty313/product/media/images/20220130/22/39365905/295440322/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty313/product/media/images/20220130/22/39365905/295440322/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty312/product/media/images/20220130/22/39365905/295440322/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty312/product/media/images/20220130/22/39365905/295440322/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.71428571428571,
        totalCount: 7.0
      },
      winnerVariant: "XXL",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XXL",
          itemNumber: 295440280,
          stock: 7.0
        }
      ],
      productReviews: [
        {
          id: 176912135,
          comment: "anneme aldim cok begendi",
          rate: 5.0,
          userFullName: "m** a**",
          trusted: true,
          commentDateISOtype: "2022-04-23",
          lastModifiedDate: "23 Nisan 2022"
        },
        {
          id: 158774640,
          comment: "ürün fotoğrafta göründüğü gibi gönül rahatlığıyla ile alabilirsiniz. Beden ölçüleri de tam",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-02-06",
          lastModifiedDate: "6 Şubat 2022"
        },
        {
          id: 158853788,
          comment: "anneme hediye almıştım..çok beğendi..kalitesi ve yeri çok güzel bir ürün.annelerimiz değerlidir..kaliteli ve değerli olan herşeyi hak ederler..",
          rate: 5.0,
          userFullName: "e** y**",
          trusted: true,
          commentDateISOtype: "2022-02-06",
          lastModifiedDate: "6 Şubat 2022"
        }
      ],
      colors: [
        {
          id: 180722696,
          imageUrl: "https://cdn.dsmcdn.com/ty321/product/media/images/20220205/11/44804427/295444210/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek"
        },
        {
          id: 180719881,
          imageUrl: "https://cdn.dsmcdn.com/ty313/product/media/images/20220130/22/39365905/295440322/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek"
        },
        {
          id: 180721594,
          imageUrl: "https://cdn.dsmcdn.com/ty323/product/media/images/20220205/10/44763217/295291113/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Çiçek Desenli Örme Triko Fermuar Detaylı Cepli Büyük Beden Anne Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 176996269,
      productCode: "TS-1730",
      name: "Dijital Çiçek Desenli Cep Ve Fermuarlı Büyük Beden Hırka Ceket",
      slug: "Dijital Çiçek Desenli Cep Ve Fermuarlı Büyük Beden Hırka Ceket",
      description: "Dijital Çiçek Desenli Cep Ve Fermuarlı Büyük Beden Hırka Ceket",
      contentDescriptions: [
        {
          bold: false,
          description: "Dijital desenli hırka, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, örme yüzeylerde ipliğin ilmek formu çok esnektir., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün 46-48 bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 151594797,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty224/product/media/images/20211104/22/165817257/288487933/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty224/product/media/images/20211104/22/165817257/288487933/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty223/product/media/images/20211104/22/165817257/288487933/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty222/product/media/images/20211104/22/165817257/288487933/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "46-48",
      color: "SİYAH-ÇİÇEKLİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46-48",
          itemNumber: 288505377,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 284854576,
          comment: "ceket cok yakisdi,ama kumasi poliyestr,naylon kimi",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-03-28",
          lastModifiedDate: "28 Mart 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polyester"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Kısa"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 171408762,
      productCode: "TS-8029",
      name: "Klasik Ekose Desenli Battal Pamuklu Oversize Oduncu Gömlek",
      slug: "Klasik Ekose Desenli Battal Pamuklu Oversize Oduncu Gömlek",
      description: "Klasik Ekose Desenli Battal Pamuklu Oversize Oduncu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Ekose Desenli Battal Pamuklu Oduncu ,gömlek., %100 Cotton kumaş., Kumaşın tok bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 146523926,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/18/159567815/279761682/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/18/159567815/279761682/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/18/159567815/279761682/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty215/product/media/images/20211029/18/159567815/279761682/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.56410256410256,
        totalCount: 39.0
      },
      winnerVariant: "XL",
      color: "SİYAH-BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 279816801,
          stock: 8.0
        }
      ],
      productReviews: [
        {
          id: 156043846,
          comment: "Bedeni tam ve kalitesi güzel",
          rate: 5.0,
          userFullName: "N** K**",
          trusted: true,
          commentDateISOtype: "2022-01-25",
          lastModifiedDate: "25 Ocak 2022"
        },
        {
          id: 246912502,
          comment: "çok güzeldir ama bedeni olmadigi için iyade etim  teşekkür",
          rate: 5.0,
          userFullName: "s** b**",
          trusted: true,
          commentDateISOtype: "2022-12-28",
          lastModifiedDate: "28 Aralık 2022"
        },
        {
          id: 152541041,
          comment: "Kalitesi harika tam bedenenizi tercih edebilirsiniz ❤️",
          rate: 5.0,
          userFullName: "N** Ş**",
          trusted: true,
          commentDateISOtype: "2022-01-12",
          lastModifiedDate: "12 Ocak 2022"
        }
      ],
      colors: [
        {
          id: 171408762,
          imageUrl: "https://cdn.dsmcdn.com/ty213/product/media/images/20211029/18/159567815/279761682/1/1_org_zoom.jpg",
          name: "SİYAH-BEYAZ",
          description: "Klasik Ekose Desenli Battal Pamuklu Oversize Oduncu Gömlek"
        },
        {
          id: 171408678,
          imageUrl: "https://cdn.dsmcdn.com/ty214/product/media/images/20211029/18/159567413/279761582/1/1_org_zoom.jpg",
          name: "KIRMIZI-KAHVE",
          description: "Klasik Ekose Desenli Battal Pamuklu Oversize Oduncu Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Oversize"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Kumaş"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126157140,
      productCode: "C-0426-1",
      name: "Kadın Yıldız Işli U.kol Viskon Büyük Beden Bluz",
      slug: "Kadın Yıldız Işli U.kol Viskon Büyük Beden Bluz",
      description: "Kadın Yıldız Işli U.kol Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve uzun kollu viskon bluz. Kumaştaki minik taş detayı şık bir görüntü sağlar. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır.Şık ve zarif bir görüntü sağlar.Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir.Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40753011,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680711/206673738/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680711/206673738/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111680711/206673738/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680711/206673738/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "M",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "M",
          itemNumber: 206694654,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126157140,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680711/206673738/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Kadın Yıldız Işli U.kol Viskon Büyük Beden Bluz"
        },
        {
          id: 48495723,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/13/10389851/85955330/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Yıldız Işli Uzun Kol Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126157121,
      productCode: "TS-2042",
      name: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz",
      slug: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz",
      description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon yırtmaçlı kısa kol büyük beden bluz., ürün kalçayı yapatır, tayt ve pantolonlarla kullanımı rahattır, günlük kullanıma uygundur, ürünün ana kumaşı viskon kumaştır.Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., kol kısmı sıfır koldur., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110875062,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680694/206673672/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680694/206673672/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111680694/206673672/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111680694/206673672/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "ORANJ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 206694638,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126028785,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/20/111533792/205956261/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz"
        },
        {
          id: 126024098,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111527015/205932831/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz"
        },
        {
          id: 126157121,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680694/206673672/1/1_org_zoom.jpg",
          name: "ORANJ",
          description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Turuncu"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126157070,
      productCode: "C-0107",
      name: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz",
      slug: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz",
      description: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve sıfır kolluviskon bluz. Kol detayı şık bir görüntü sağlar. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır.Şık ve zarif bir görüntü sağlar.Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir.Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110879803,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111680637/206673430/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111680637/206673430/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680637/206673430/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111680637/206673430/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 299.99
      },
      rating: 3.0,
      ratingScore: {
        averageRating: 3.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 206694231,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 121449460,
          comment: "hızlı kargo ancak yıkamada arkası çizgili beyaz siyah olduğu için ön tarafını siyahlığı arka tarafa renk verdi umarım geçer",
          rate: 3.0,
          userFullName: "p** k**",
          trusted: true,
          commentDateISOtype: "2021-08-06",
          lastModifiedDate: "6 Ağustos 2021"
        }
      ],
      colors: [
        {
          id: 126029381,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534412/205961629/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz"
        },
        {
          id: 126157070,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111680637/206673430/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126155052,
      productCode: "C-0427-1",
      name: "Kadın Viskon Büyük Beden Bluz",
      slug: "Kadın Viskon Büyük Beden Bluz",
      description: "Kadın Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40728554,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty273/product/media/images/20211219/0/13090683/206683892/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty273/product/media/images/20211219/0/13090683/206683892/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty274/product/media/images/20211219/0/13090683/206683892/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty275/product/media/images/20211219/0/13090683/206683892/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "M",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "M",
          itemNumber: 206683892,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 48466020,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/13/10360378/85856360/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Önü Yoğun Taş Işli Uzun Kol Viskon Bluz"
        },
        {
          id: 126155052,
          imageUrl: "https://cdn.dsmcdn.com/ty273/product/media/images/20211219/0/13090683/206683892/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Viskon Büyük Beden Bluz"
        },
        {
          id: 126155007,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677677/206666069/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Viskon Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126155027,
      productCode: "C-0106",
      name: "Kadın Laci Kol Detaylı V Yaka Viskon Bluz",
      slug: "Kadın Laci Kol Detaylı V Yaka Viskon Bluz",
      description: "Kadın Laci Kol Detaylı V Yaka Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705693,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677701/206666152/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677701/206666152/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677701/206666152/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677701/206666152/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 206683570,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 120791747,
          comment: "Ürün çok şık  ve kaliteli. Kendi bedeninizi tercih edebilirsiniz. çok güzel",
          rate: 5.0,
          userFullName: "Tünay A.",
          trusted: true,
          commentDateISOtype: "2021-08-01",
          lastModifiedDate: "1 Ağustos 2021"
        },
        {
          id: 185626426,
          comment: "Normal. Annem için aldım, beğendik.",
          rate: 5.0,
          userFullName: "G** M**",
          trusted: true,
          commentDateISOtype: "2022-06-04",
          lastModifiedDate: "4 Haziran 2022"
        }
      ],
      colors: [
        {
          id: 49568076,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367735/87816666/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Kol Detaylı V Yaka Viskon Bluz"
        },
        {
          id: 126155027,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677701/206666152/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Laci Kol Detaylı V Yaka Viskon Bluz"
        },
        {
          id: 126024086,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527003/205932601/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Kol Detaylı V Yaka Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126155007,
      productCode: "C-0427-1",
      name: "Kadın Viskon Büyük Beden Bluz",
      slug: "Kadın Viskon Büyük Beden Bluz",
      description: "Kadın Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40728554,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677677/206666069/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677677/206666069/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111677677/206666069/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677677/206666069/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "M",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "M",
          itemNumber: 206666069,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126155052,
          imageUrl: "https://cdn.dsmcdn.com/ty273/product/media/images/20211219/0/13090683/206683892/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Viskon Büyük Beden Bluz"
        },
        {
          id: 48466020,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/13/10360378/85856360/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Önü Yoğun Taş Işli Uzun Kol Viskon Bluz"
        },
        {
          id: 126155007,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677677/206666069/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Viskon Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126154966,
      productCode: "C-0111-1",
      name: "Kadın Volanlı Viskon Kısa Kol Büyük Beden Bluz",
      slug: "Kadın Volanlı Viskon Kısa Kol Büyük Beden Bluz",
      description: "Kadın Volanlı Viskon Kısa Kol Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40728355,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111677647/206665986/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111677647/206665986/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111677647/206665986/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111677647/206665986/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 206683342,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126154966,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111677647/206665986/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Volanlı Viskon Kısa Kol Büyük Beden Bluz"
        },
        {
          id: 48465726,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10360077/85855905/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Volanlı Viskon Kısa Kol Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Volanlı"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126029403,
      productCode: "C-0184-1",
      name: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz",
      slug: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz",
      description: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40678344,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534463/205961674/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534463/205961674/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/21/111534463/205961674/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534463/205961674/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205975489,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 120791798,
          comment: "Ürün çok güzel yeri de yumuşacık. Ayrıca çok şık duruyor.",
          rate: 5.0,
          userFullName: "Tünay A.",
          trusted: true,
          commentDateISOtype: "2021-08-01",
          lastModifiedDate: "1 Ağustos 2021"
        }
      ],
      colors: [
        {
          id: 126029395,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534423/205961652/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz"
        },
        {
          id: 48410495,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10306433/85781479/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Simli Omuz Açık Viskon Bluz"
        },
        {
          id: 126029403,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534463/205961674/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Omzu açık"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126029395,
      productCode: "C-0184-1",
      name: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz",
      slug: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz",
      description: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40678344,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534423/205961652/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534423/205961652/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534423/205961652/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534423/205961652/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205975457,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126029395,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534423/205961652/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz"
        },
        {
          id: 48410495,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10306433/85781479/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Simli Omuz Açık Viskon Bluz"
        },
        {
          id: 126029403,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534463/205961674/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Omzu açık"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126029381,
      productCode: "C-0107",
      name: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz",
      slug: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz",
      description: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110879803,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534412/205961629/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534412/205961629/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/21/111534412/205961629/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534412/205961629/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 205975422,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 189289164,
          comment: "ço kaliteli ve serin tutur",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-07-04",
          lastModifiedDate: "4 Temmuz 2022"
        }
      ],
      colors: [
        {
          id: 126029381,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534412/205961629/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz"
        },
        {
          id: 126157070,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111680637/206673430/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126029378,
      productCode: "C-0114-1",
      name: "Kadın Laci Şifon Kol Bağcıklı Viskon Bluz",
      slug: "Kadın Laci Şifon Kol Bağcıklı Viskon Bluz",
      description: "Kadın Laci Şifon Kol Bağcıklı Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40674805,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534408/205961623/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534408/205961623/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/21/111534408/205961623/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/21/111534408/205961623/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205961623,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126029376,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534407/205961614/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Şifon Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 126029378,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534408/205961623/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Laci Şifon Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 48404615,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10300398/85773359/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Şifon Kol Bağcıklı Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126029376,
      productCode: "C-0114-1",
      name: "Kadın Ekru Şifon Kol Bağcıklı Viskon Bluz",
      slug: "Kadın Ekru Şifon Kol Bağcıklı Viskon Bluz",
      description: "Kadın Ekru Şifon Kol Bağcıklı Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40674805,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534407/205961614/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534407/205961614/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534407/205961614/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534407/205961614/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205961614,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126029376,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534407/205961614/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Şifon Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 126029378,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534408/205961623/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Laci Şifon Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 48404615,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10300398/85773359/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Şifon Kol Bağcıklı Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126029364,
      productCode: "C-0108",
      name: "Kadın Şifon Patlı Büyük Beden Viskon Bluz",
      slug: "Kadın Şifon Patlı Büyük Beden Viskon Bluz",
      description: "Kadın Şifon Patlı Büyük Beden Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110879791,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534396/205961532/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534396/205961532/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/21/111534396/205961532/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534396/205961532/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205975192,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126029364,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534396/205961532/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Şifon Patlı Büyük Beden Viskon Bluz"
        },
        {
          id: 126157000,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111680568/206673103/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Şifon Patlı Büyük Beden Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126024098,
      productCode: "TS-2042",
      name: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz",
      slug: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz",
      description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli viskon yırtmaçlı kısa kol büyük beden bluz., ürün kalçayı yapatır, tayt ve pantolonlarla kullanımı rahattır, günlük kullanıma uygundur, ürünün ana kumaşı viskon kumaştır.Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., kol kısmı sıfır koldur., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 110875062,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111527015/205932831/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111527015/205932831/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111527015/205932831/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/19/111527015/205932831/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 1.0,
      ratingScore: {
        averageRating: 1.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "BEYAZ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 205932831,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 180527627,
          comment: "Beyaz yerine krem geldi.Neden insanlarla dalga geçiyor sunuz",
          rate: 1.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-05-10",
          lastModifiedDate: "10 Mayıs 2022"
        }
      ],
      colors: [
        {
          id: 126028785,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/20/111533792/205956261/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz"
        },
        {
          id: 126024098,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111527015/205932831/1/1_org_zoom.jpg",
          name: "BEYAZ",
          description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz"
        },
        {
          id: 126157121,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680694/206673672/1/1_org_zoom.jpg",
          name: "ORANJ",
          description: "Kedi Desenli Taşlı Büyük Beden Atlet Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Beyaz"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126024086,
      productCode: "C-0106",
      name: "Kadın Siyah Kol Detaylı V Yaka Viskon Bluz",
      slug: "Kadın Siyah Kol Detaylı V Yaka Viskon Bluz",
      description: "Kadın Siyah Kol Detaylı V Yaka Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kolluviskon bluz. Yaka kısmındaki güpür detayı şık bir görüntü sağlar. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır.Şık ve zarif bir görüntü sağlar.Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir.Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705693,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527003/205932601/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527003/205932601/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/19/111527003/205932601/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/19/111527003/205932601/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205932601,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 49568076,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367735/87816666/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Kol Detaylı V Yaka Viskon Bluz"
        },
        {
          id: 126155027,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677701/206666152/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Laci Kol Detaylı V Yaka Viskon Bluz"
        },
        {
          id: 126024086,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527003/205932601/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Kol Detaylı V Yaka Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126024083,
      productCode: "C-0155-1",
      name: "Kadın Siyah Pentürlü Kısa Kol Bağcıklı Viskon Büyük Beden Bluz",
      slug: "Kadın Siyah Pentürlü Kısa Kol Bağcıklı Viskon Büyük Beden Bluz",
      description: "Kadın Siyah Pentürlü Kısa Kol Bağcıklı Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır, şık ve zarif bir görüntü sağlar, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz, tersten, hassas yıkamada yıkayınız, mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40677662,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527000/205932570/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527000/205932570/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111527000/205932570/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111527000/205932570/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205948425,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126024083,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527000/205932570/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Pentürlü Kısa Kol Bağcıklı Viskon Büyük Beden Bluz"
        },
        {
          id: 48409723,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305637/85780069/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Kadın Pembe Pentürlü Kısa Kol Bağcıklı Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126024056,
      productCode: "C-0417-1",
      name: "Kadın Ekru V Yaka Omuz Güpürlü Viskon Büyük Beden Bluz",
      slug: "Kadın Ekru V Yaka Omuz Güpürlü Viskon Büyük Beden Bluz",
      description: "Kadın Ekru V Yaka Omuz Güpürlü Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kolluviskon bluz. Yaka kısmındaki güpür detayı şık bir görüntü sağlar. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır.Şık ve zarif bir görüntü sağlar.Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir.Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40747462,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526972/205932379/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526972/205932379/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526972/205932379/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526972/205932379/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205948340,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 48489560,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/12/10382736/85939297/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru V Yaka Omuz Güpürlü Viskon Bluz"
        },
        {
          id: 126024056,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526972/205932379/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Ekru V Yaka Omuz Güpürlü Viskon Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126024053,
      productCode: "C-0159-1",
      name: "Kadın Mavi Gelincik Baskılı Kol Bağcıklı Viskon Bluz",
      slug: "Kadın Mavi Gelincik Baskılı Kol Bağcıklı Viskon Bluz",
      description: "Kadın Mavi Gelincik Baskılı Kol Bağcıklı Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır, şık ve zarif bir görüntü sağlar, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz, tersten, hassas yıkamada yıkayınız, mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40677886,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526971/205932367/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526971/205932367/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526971/205932367/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526971/205932367/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205948339,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126024053,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526971/205932367/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Gelincik Baskılı Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 48409979,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305912/85780632/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Gelincik Baskılı Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 126023918,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526796/205930912/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Gelincik Baskılı Kol Bağcıklı Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126023918,
      productCode: "C-0159-1",
      name: "Kadın Siyah Gelincik Baskılı Kol Bağcıklı Viskon Bluz",
      slug: "Kadın Siyah Gelincik Baskılı Kol Bağcıklı Viskon Bluz",
      description: "Kadın Siyah Gelincik Baskılı Kol Bağcıklı Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz, Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır, Şık ve zarif bir görüntü sağlar, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün L bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40677886,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526796/205930912/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526796/205930912/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526796/205930912/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526796/205930912/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205946701,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126024053,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526971/205932367/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Gelincik Baskılı Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 48409979,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305912/85780632/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Gelincik Baskılı Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 126023918,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526796/205930912/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Gelincik Baskılı Kol Bağcıklı Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 126023753,
      productCode: "C-0192",
      name: "Kadın Siyah Yaka Püsküllü Viskon Büyük Beden Bluz",
      slug: "Kadın Siyah Yaka Püsküllü Viskon Büyük Beden Bluz",
      description: "Kadın Siyah Yaka Püsküllü Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz, Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır, Şık ve zarif bir görüntü sağlar, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün L bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705616,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526634/205930235/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526634/205930235/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/19/111526634/205930235/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526634/205930235/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 199.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 205930235,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 122621412,
          comment: "Anneme aldım bu ürünü. Çok beğendi. Penyesi falan da enfess. Herkese tavsiye ediyorum ☺️",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-08-14",
          lastModifiedDate: "14 Ağustos 2021"
        }
      ],
      colors: [
        {
          id: 49567999,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367679/87816562/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Yaka Püsküllü Viskon Bluz"
        },
        {
          id: 126023753,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526634/205930235/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Yaka Püsküllü Viskon Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 112369498,
      productCode: "C-0421",
      name: "Önü Düz Arkası Güpür Detaylı Viskon Büyük Beden Bluz",
      slug: "Önü Düz Arkası Güpür Detaylı Viskon Büyük Beden Bluz",
      description: "Önü Düz Arkası Güpür Detaylı Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Önü Düz Arkası Güpürlü Büyük Beden Bluz., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 98512889,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty124/product/media/images/20210604/0/95687811/182784589/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty124/product/media/images/20210604/0/95687811/182784589/0/0_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 182784589,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 112369084,
      productCode: "C-0210",
      name: "Desenli Devore Omuz Açık Büyük Beden Bluz",
      slug: "Desenli Devore Omuz Açık Büyük Beden Bluz",
      description: "Desenli Devore Omuz Açık Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Devore Büyük Beden Bluz., Kumaşın asimetrik bir yapısı ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 98512524,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty125/product/media/images/20210604/0/95689612/182784177/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty125/product/media/images/20210604/0/95689612/182784177/0/0_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 4.0
      },
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 182881965,
          stock: 9.0
        }
      ],
      productReviews: [
        {
          id: 182747716,
          comment: "muhteşem çok şık... bedenim 48  2xl göndermişler .Tam oldu",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-05-21",
          lastModifiedDate: "21 Mayıs 2022"
        },
        {
          id: 212844924,
          comment: "Arkadaşıma hediye aldım çok beğendi.Geniş olduğu için salaş giymek isteyenler de geniş bedenlere de olabilecek bir ürün .Kol  detayı eteğindeki fırfır ve boyundaki püskülü bluza hava katmış.Alanlar beğenecektir.",
          rate: 5.0,
          userFullName: "D** S**",
          trusted: true,
          commentDateISOtype: "2022-09-28",
          lastModifiedDate: "28 Eylül 2022"
        },
        {
          id: 137292918,
          comment: "gerçekten bayıldımmm",
          rate: 5.0,
          userFullName: "A** N** Ö**",
          trusted: true,
          commentDateISOtype: "2021-11-03",
          lastModifiedDate: "3 Kasım 2021"
        }
      ],
      colors: [
        {
          id: 112369892,
          imageUrl: "https://cdn.dsmcdn.com/ty123/product/media/images/20210604/1/95698734/182784986/0/0_org_zoom.jpg",
          name: "VİZON",
          description: "Desenli Devore Omuz Açık Büyük Beden Bluz"
        },
        {
          id: 112369084,
          imageUrl: "https://cdn.dsmcdn.com/ty125/product/media/images/20210604/0/95689612/182784177/0/0_org_zoom.jpg",
          name: "SİYAH",
          description: "Desenli Devore Omuz Açık Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 112368138,
      productCode: "C-0422",
      name: "Omuz Güpür Detaylı Viskon Büyük Beden Bluz",
      slug: "Omuz Güpür Detaylı Viskon Büyük Beden Bluz",
      description: "Omuz Güpür Detaylı Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Omuz Güpür Detaylı Viskon Büyük Beden Bluz., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 98511682,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty123/product/media/images/20210604/0/95690148/182783201/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty123/product/media/images/20210604/0/95690148/182783201/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty124/product/media/images/20210604/0/95690148/182783201/1/1_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.2,
        totalCount: 5.0
      },
      winnerVariant: "42-44",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 182881971,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 140022530,
          comment: "Şık duruyor. Sorunsuz ve zamanında geldi",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-11-14",
          lastModifiedDate: "14 Kasım 2021"
        },
        {
          id: 125902257,
          comment: "Kolları çok sıkı bedeni çok bol",
          rate: 2.0,
          userFullName: "N** S**",
          trusted: true,
          commentDateISOtype: "2021-09-06",
          lastModifiedDate: "6 Eylül 2021"
        },
        {
          id: 121579017,
          comment: "kumaş,model fena diil sadece bir kolun bilek kismi diğerine göre daha dar.giyinilebilir olduğu için iade etmedim.",
          rate: 4.0,
          userFullName: "G** B**",
          trusted: true,
          commentDateISOtype: "2021-08-07",
          lastModifiedDate: "7 Ağustos 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 111527204,
      productCode: "C-0402",
      name: "Çiçek Desenli Devore Büyük Beden Bluz",
      slug: "Çiçek Desenli Devore Büyük Beden Bluz",
      description: "Çiçek Desenli Devore Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Taşlı Devore Büyük Beden Bluz., Kumaşın asimetrik bir yapısı ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 97923363,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty388/product/media/images/20220408/1/85853577/181659939/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty388/product/media/images/20220408/1/85853577/181659939/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty387/product/media/images/20220408/1/85853577/181659939/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty388/product/media/images/20220408/1/85853577/181659939/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 182675060,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 111525650,
          imageUrl: "https://cdn.dsmcdn.com/ty123/product/media/images/20210602/3/94774699/181658341/0/0_org_zoom.jpg",
          name: "SARI",
          description: "Çiçek Desenli Devore Büyük Beden Bluz"
        },
        {
          id: 111527204,
          imageUrl: "https://cdn.dsmcdn.com/ty388/product/media/images/20220408/1/85853577/181659939/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Çiçek Desenli Devore Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 111525650,
      productCode: "C-0402",
      name: "Çiçek Desenli Devore Büyük Beden Bluz",
      slug: "Çiçek Desenli Devore Büyük Beden Bluz",
      description: "Çiçek Desenli Devore Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Taşlı Devore Büyük Beden Bluz., Kumaşın asimetrik bir yapısı ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 97923363,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty123/product/media/images/20210602/3/94774699/181658341/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty123/product/media/images/20210602/3/94774699/181658341/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty124/product/media/images/20210602/3/94774699/181658341/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty121/product/media/images/20210602/3/94774699/181658341/2/2_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "42-44",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 181658341,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 111525650,
          imageUrl: "https://cdn.dsmcdn.com/ty123/product/media/images/20210602/3/94774699/181658341/0/0_org_zoom.jpg",
          name: "SARI",
          description: "Çiçek Desenli Devore Büyük Beden Bluz"
        },
        {
          id: 111527204,
          imageUrl: "https://cdn.dsmcdn.com/ty388/product/media/images/20220408/1/85853577/181659939/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Çiçek Desenli Devore Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 111511833,
      productCode: "C-0404",
      name: "Desenli Devore Büyük Beden Bluz",
      slug: "Desenli Devore Büyük Beden Bluz",
      description: "Desenli Devore Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Desenli Devore Büyük Beden Bluz., Kumaşın asimetrik bir yapısı ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 42-44 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 97922727,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty387/product/media/images/20220408/1/85853567/182675055/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty387/product/media/images/20220408/1/85853567/182675055/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty388/product/media/images/20220408/1/85853567/182675055/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty388/product/media/images/20220408/1/85853567/182675055/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "42-44",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42-44",
          itemNumber: 181644206,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 112286342,
          comment: "Çok beğendim. Teşekkürler.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-06-16",
          lastModifiedDate: "16 Haziran 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 96367723,
      productCode: "C-0403",
      name: "Çiçek Desenli Taşlı Devore Büyük Beden Bluz",
      slug: "Çiçek Desenli Taşlı Devore Büyük Beden Bluz",
      description: "Çiçek Desenli Taşlı Devore Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Çiçek Desenli Taşlı Devore Büyük Beden Bluz., Kumaşın asimetrik bir yapısı ve yumuşak bir dokusu vardır., Şık ve zarif bir görüntü sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 83482943,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty95/product/media/images/20210405/0/77686759/160503430/0/0_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty95/product/media/images/20210405/0/77686759/160503430/0/0_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty93/product/media/images/20210405/0/77686759/160503430/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty97/product/media/images/20210405/0/77686759/160503430/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty94/product/media/images/20210405/0/77686759/160503430/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "PEMBE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 160507305,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çiçekli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 80209026,
      productCode: "TS-1738",
      name: "Polar Fermuarlı Yelek",
      slug: "Polar Fermuarlı Yelek",
      description: "Polar Fermuarlı Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Yelek, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yazmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.75 Göğüs: 92, Bel: 65, Kalça: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 47117113,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242285354/135927520/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242285354/135927520/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242285354/135927520/2/2_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.44444444444444,
        totalCount: 18.0
      },
      winnerVariant: "46",
      color: "YEŞİL",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 135927520,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 137166188,
          comment: "Ablama hediye aldım çok güzel yumuşacık bir yelekmis kalıp olarak m beden giyen ablama satıcı 42 beden önerdi bende tereddüt etmeden onu aldım üzerine göre olmuş yelek harika satıcı ise muhteşem çok ilgili irtibata gecince hediye paketi ve not yazmalarını istedim sıcak tutan yelek yanın da notunda sıcacıktı dedi ablam güvenle alışveriş yapabilirsiniz tavsiye ediyorum ve çok teşekkür ederim.",
          rate: 5.0,
          userFullName: "fecrihan h.",
          trusted: true,
          commentDateISOtype: "2021-11-02",
          lastModifiedDate: "2 Kasım 2021"
        },
        {
          id: 85908711,
          comment: "cok kaliteli bir ürün alan pisman olmaz ilk aldigimda 2 tane almistim tekrar 2tane kizima ve esime aldim asla tereddut etmeyin   cok tesekkurler cok cabuk elime ulasti❤",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-02-17",
          lastModifiedDate: "17 Şubat 2021"
        },
        {
          id: 113093941,
          comment: "Çok kaliteli ve kullanışlı bir ürün, aldığınıza asla pişman olmayacağınız bir parça. Üstelik çok sıcak tutuyor. Renkleri ve dokusu çok güzel. Ayrıca hediyeniz için çok çok teşekkür ederim.",
          rate: 5.0,
          userFullName: "D** S**",
          trusted: true,
          commentDateISOtype: "2021-06-17",
          lastModifiedDate: "17 Haziran 2021"
        }
      ],
      colors: [
        {
          id: 74226167,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852162/127578942/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 80209026,
          imageUrl: "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242285354/135927520/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 55819993,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852834/106292279/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 59783642,
          imageUrl: "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242285591/105892405/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Yelek"
        },
        {
          id: 55695632,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20851881/98941395/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Laci Polar Fermuarlı Yelek"
        },
        {
          id: 59783623,
          imageUrl: "https://cdn.dsmcdn.com/ty316/product/media/images/20220131/23/39959203/139698782/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Yelek"
        },
        {
          id: 74245548,
          imageUrl: "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polar"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 74245548,
      productCode: "TS-1738",
      name: "Polar Fermuarlı Yelek",
      slug: "Polar Fermuarlı Yelek",
      description: "Polar Fermuarlı Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Yelek, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yazmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.75 Göğüs: 92, Bel: 65, Kalça: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 47117113,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.8,
        totalCount: 5.0
      },
      winnerVariant: "46",
      color: "ANTRASİT",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 127655498,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 135834132,
          comment: "Gerçekten ultra kalite bir yelek ve paketlenmesi içindeki notuna ve kargo hızına varana kadar her şey fazlasıyla mükemmel. Çok teşekkür ederim. Kalıplar biraz dar bir büyük beden tavsiye ederim. Çok teşekkür ederim.",
          rate: 5.0,
          userFullName: "N** D**",
          trusted: true,
          commentDateISOtype: "2021-10-26",
          lastModifiedDate: "26 Ekim 2021"
        },
        {
          id: 155536278,
          comment: "bu yelekten daha once alip cok begendigim icin bir tane daha yakinima aldim.",
          rate: 5.0,
          userFullName: "B** İ**",
          trusted: true,
          commentDateISOtype: "2022-01-23",
          lastModifiedDate: "23 Ocak 2022"
        },
        {
          id: 89497129,
          comment: "Anneme hediye olarak almıştım fakat beğenmediği için iade ediyorum.",
          rate: 4.0,
          userFullName: "Songül K.",
          trusted: true,
          commentDateISOtype: "2021-03-09",
          lastModifiedDate: "9 Mart 2021"
        }
      ],
      colors: [
        {
          id: 74226167,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852162/127578942/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 80209026,
          imageUrl: "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242285354/135927520/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 55819993,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852834/106292279/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 59783642,
          imageUrl: "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242285591/105892405/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Yelek"
        },
        {
          id: 55695632,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20851881/98941395/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Laci Polar Fermuarlı Yelek"
        },
        {
          id: 59783623,
          imageUrl: "https://cdn.dsmcdn.com/ty316/product/media/images/20220131/23/39959203/139698782/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Yelek"
        },
        {
          id: 74245548,
          imageUrl: "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polar"
        },
        {
          id: 348,
          key: "Renk",
          value: "Gri"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 74226167,
      productCode: "TS-1738",
      name: "Polar Fermuarlı Yelek",
      slug: "Polar Fermuarlı Yelek",
      description: "Polar Fermuarlı Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Yelek, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yazmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.75 Göğüs: 92, Bel: 65, Kalça: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 47117113,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852162/127578942/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852162/127578942/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852162/127578942/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20852162/127578942/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20852162/127578942/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.91666666666667,
        totalCount: 12.0
      },
      winnerVariant: "42",
      color: "MOR",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "42",
          itemNumber: 127564902,
          stock: 4.0
        }
      ],
      productReviews: [
        {
          id: 153145600,
          comment: "kötü paket ve buruşuk bir ürün yakışmadı Tusse'ye",
          rate: 5.0,
          userFullName: "Elen F. Y.",
          trusted: true,
          commentDateISOtype: "2022-01-17",
          lastModifiedDate: "17 Ocak 2022"
        },
        {
          id: 127708694,
          comment: "kalıbı dar bir beden büyük alın ama güzel ben beğendim kaliteli bir ürün, teşekkürler 🙏",
          rate: 5.0,
          userFullName: "Z** Ç**",
          trusted: true,
          commentDateISOtype: "2021-09-20",
          lastModifiedDate: "20 Eylül 2021"
        },
        {
          id: 88734180,
          comment: "Çok kaliteli güzel",
          rate: 5.0,
          userFullName: "Sıla A.",
          trusted: true,
          commentDateISOtype: "2021-03-04",
          lastModifiedDate: "4 Mart 2021"
        }
      ],
      colors: [
        {
          id: 74226167,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852162/127578942/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 80209026,
          imageUrl: "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242285354/135927520/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 55819993,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852834/106292279/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 59783642,
          imageUrl: "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242285591/105892405/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Yelek"
        },
        {
          id: 55695632,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20851881/98941395/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Laci Polar Fermuarlı Yelek"
        },
        {
          id: 59783623,
          imageUrl: "https://cdn.dsmcdn.com/ty316/product/media/images/20220131/23/39959203/139698782/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Yelek"
        },
        {
          id: 74245548,
          imageUrl: "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polar"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mor"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 64930016,
      productCode: "TS-0006",
      name: "Kadın Kahverengi Triko Büyük Beden Yelek",
      slug: "Kadın Kahverengi Triko Büyük Beden Yelek",
      description: "Kadın Kahverengi Triko Büyük Beden Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Triko Büyük Beden Anne Yelek, Ürün Örme Triko Anne Yelektir, Kumaşın sıkı özel örme bir yapısı vardır, Rahat kesimdir, Şık ve zarif bir görüntü sağlar, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün 2XL bedendir. Mankenin Ölçüleri"
        },
        {
          bold: false,
          description: "Boy: 1,74 Göğüs: 90 Bel: 60 Basen: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 55633873,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty25/product/media/images/20201125/16/32460551/112736107/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty25/product/media/images/20201125/16/32460551/112736107/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty25/product/media/images/20201125/16/32460551/112736107/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty26/product/media/images/20201125/16/32460551/112736107/3/3_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.72727272727273,
        totalCount: 11.0
      },
      winnerVariant: "2XL",
      color: "KAHVE",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "2XL",
          itemNumber: 112847827,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 185222292,
          comment: "ürün çok güzeldi anneme almıştım maalesef büyük geldi iade alacaklar kesinlikle kaçırmasınlar kalitesi mükemmel kışın sıcak tutar.",
          rate: 5.0,
          userFullName: "Nevin G.",
          trusted: true,
          commentDateISOtype: "2022-06-02",
          lastModifiedDate: "2 Haziran 2022"
        },
        {
          id: 154331549,
          comment: "Süper kaliteli bir ürün ,bazen yelek sadece evde giyersin ,bu öyle değil çok şık bir gömlekle rahatlıkla gezmeye gidebilirsin Çok teşekkürler ,keşke siyah kaçırmasaydım ,tükenmiş",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-01-18",
          lastModifiedDate: "18 Ocak 2022"
        },
        {
          id: 256844945,
          comment: "hedıye aldım kayınvalıdeme harıka urun tuylenmede yapmadı",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-01-18",
          lastModifiedDate: "18 Ocak 2023"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 59783623,
      productCode: "TS-1738",
      name: "Kadın Siyah Polar Fermuarlı Yelek",
      slug: "Kadın Siyah Polar Fermuarlı Yelek",
      description: "Kadın Siyah Polar Fermuarlı Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Yelek, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yazmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.75 Göğüs: 92, Bel: 65, Kalça: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 47117113,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty316/product/media/images/20220131/23/39959203/139698782/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty316/product/media/images/20220131/23/39959203/139698782/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty316/product/media/images/20220131/23/39959203/139698782/2/2_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.36585365853659,
        totalCount: 41.0
      },
      winnerVariant: "40",
      color: "SİYAH",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 106292267,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 69245543,
          comment: "Tam bedenim geldi çokk güzel aldığı hak ediyoo",
          rate: 5.0,
          userFullName: "hatice t.",
          trusted: true,
          commentDateISOtype: "2020-11-28",
          lastModifiedDate: "28 Kasım 2020"
        },
        {
          id: 75456860,
          comment: "Kendi bedeninizi alın bir beden büyük almayın beğenmedim teşekkürler.",
          rate: 1.0,
          userFullName: "G** H**",
          trusted: true,
          commentDateISOtype: "2020-12-24",
          lastModifiedDate: "24 Aralık 2020"
        },
        {
          id: 69213740,
          comment: "Ürün çok güzel ve kaliteli. M beden aldım ama dar oldu, L beden için yeniden sipariş verdim. Kargo hizli. Satıcı oldukça ilgili. Kesinlikle tavsiye ederim.",
          rate: 5.0,
          userFullName: "seyda y.",
          trusted: true,
          commentDateISOtype: "2020-11-28",
          lastModifiedDate: "28 Kasım 2020"
        }
      ],
      colors: [
        {
          id: 74226167,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852162/127578942/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 80209026,
          imageUrl: "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242285354/135927520/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 55819993,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852834/106292279/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 59783642,
          imageUrl: "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242285591/105892405/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Yelek"
        },
        {
          id: 55695632,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20851881/98941395/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Laci Polar Fermuarlı Yelek"
        },
        {
          id: 59783623,
          imageUrl: "https://cdn.dsmcdn.com/ty316/product/media/images/20220131/23/39959203/139698782/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Yelek"
        },
        {
          id: 74245548,
          imageUrl: "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polar"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55819993,
      productCode: "TS-1738",
      name: "Polar Fermuarlı Yelek",
      slug: "Polar Fermuarlı Yelek",
      description: "Polar Fermuarlı Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Yelek, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yazmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.75 Göğüs: 92, Bel: 65, Kalça: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 47117113,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852834/106292279/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852834/106292279/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20852834/106292279/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20852834/106292279/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20852834/106292279/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.70833333333333,
        totalCount: 24.0
      },
      winnerVariant: "40",
      color: "HAKİ",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 106292279,
          stock: 6.0
        }
      ],
      productReviews: [
        {
          id: 71254264,
          comment: "çok güzel bir ürün  2 beden küçük geldi yinede iyi oldu beğendim tafsiye ederim",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2020-12-06",
          lastModifiedDate: "6 Aralık 2020"
        },
        {
          id: 70265599,
          comment: "Yeleğin kumaşı dikişleri çok kaliteli. Mankenin ölçülerine dikkat ederek L beden aldım. Bana biraz bol geldi ama yine de kullanıcam. L beden 44 bedene denk geliyor. Tavsiye ederim.",
          rate: 5.0,
          userFullName: "A** y**",
          trusted: true,
          commentDateISOtype: "2020-12-02",
          lastModifiedDate: "2 Aralık 2020"
        },
        {
          id: 144795770,
          comment: "Ürün iyi geldi sorun yok sadece bir beden büyük alın",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2021-12-03",
          lastModifiedDate: "3 Aralık 2021"
        }
      ],
      colors: [
        {
          id: 74226167,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852162/127578942/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 80209026,
          imageUrl: "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242285354/135927520/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 55819993,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852834/106292279/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 59783642,
          imageUrl: "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242285591/105892405/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Yelek"
        },
        {
          id: 55695632,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20851881/98941395/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Laci Polar Fermuarlı Yelek"
        },
        {
          id: 59783623,
          imageUrl: "https://cdn.dsmcdn.com/ty316/product/media/images/20220131/23/39959203/139698782/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Yelek"
        },
        {
          id: 74245548,
          imageUrl: "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polar"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55695632,
      productCode: "TS-1738",
      name: "Laci Polar Fermuarlı Yelek",
      slug: "Laci Polar Fermuarlı Yelek",
      description: "Laci Polar Fermuarlı Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Yelek, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yazmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.75 Göğüs: 92, Bel: 65, Kalça: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 47117113,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20851881/98941395/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20851881/98941395/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/15/20851881/98941395/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/15/20851881/98941395/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty287/product/media/images/20220103/15/20851881/98941395/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.8,
        totalCount: 25.0
      },
      winnerVariant: "40",
      color: "LACİ",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 106292269,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 74812796,
          comment: "Ürün çok kaliteli. Anneme aldım çok beğendi. Rengi tam göründüğü gibi.",
          rate: 5.0,
          userFullName: "G** K**",
          trusted: true,
          commentDateISOtype: "2020-12-22",
          lastModifiedDate: "22 Aralık 2020"
        },
        {
          id: 70057123,
          comment: "eşime almıştım çok beğendim",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2020-12-01",
          lastModifiedDate: "1 Aralık 2020"
        },
        {
          id: 66787670,
          comment: "Ürünü kendi giydiğiniz bedenden bir beden büyük alırsanız daha iyi olur.",
          rate: 4.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2020-11-18",
          lastModifiedDate: "18 Kasım 2020"
        }
      ],
      colors: [
        {
          id: 74226167,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852162/127578942/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 80209026,
          imageUrl: "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242285354/135927520/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 55819993,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852834/106292279/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 59783642,
          imageUrl: "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242285591/105892405/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Yelek"
        },
        {
          id: 55695632,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20851881/98941395/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Laci Polar Fermuarlı Yelek"
        },
        {
          id: 59783623,
          imageUrl: "https://cdn.dsmcdn.com/ty316/product/media/images/20220131/23/39959203/139698782/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Yelek"
        },
        {
          id: 74245548,
          imageUrl: "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polar"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55298543,
      productCode: "TS-2101",
      name: "Kadın Yeşil Yaprak Baskılı Bluz",
      slug: "Kadın Yeşil Yaprak Baskılı Bluz",
      description: "Kadın Yeşil Yaprak Baskılı Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan baskılı bluz pamuklu triko kumaştır. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır.Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız.Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705379,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty17/product/media/images/20201027/15/19936678/98078307/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201027/15/19936678/98078307/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/15/19936678/98078307/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201027/15/19936678/98078307/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201027/15/19936678/98078307/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "XL",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 98078307,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 55298543,
          imageUrl: "https://cdn.dsmcdn.com/ty17/product/media/images/20201027/15/19936678/98078307/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Yaprak Baskılı Bluz"
        },
        {
          id: 49567762,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367578/87816043/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Kadın Kırmızı Yaprak Baskılı Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49733294,
      productCode: "C-0425-1",
      name: "Kadın Vizon Uzun Kol Devore Bluz",
      slug: "Kadın Vizon Uzun Kol Devore Bluz",
      description: "Kadın Vizon Uzun Kol Devore Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve uzun kollu bluz., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün l bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40725932,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163310/88152301/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163310/88152301/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2163310/88152301/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty260/product/media/images/20211130/19/2163310/88152301/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163310/88152301/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 88193157,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 146537735,
          comment: "Yazlık bir ürün. Kolları biraz uzun terzi ile halledecek bir ürün. Beğendim",
          rate: 5.0,
          userFullName: "E** K** Y**",
          trusted: true,
          commentDateISOtype: "2021-12-11",
          lastModifiedDate: "11 Aralık 2021"
        }
      ],
      colors: [
        {
          id: 49733294,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163310/88152301/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Kadın Vizon Uzun Kol Devore Bluz"
        },
        {
          id: 48462861,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10357145/85851743/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Uzun Kol Devore Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49707418,
      productCode: "TYC00041680590",
      name: "Kadın Vizon Önü Yoğun Taş İşli Uzun Kol Viskon Bluz",
      slug: "Kadın Vizon Önü Yoğun Taş İşli Uzun Kol Viskon Bluz",
      description: "Kadın Vizon Önü Yoğun Taş İşli Uzun Kol Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve uzun kollu bluz., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün l bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41838905,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200923/10/11500092/88098365/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200923/10/11500092/88098365/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200923/10/11500092/88098365/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200923/10/11500092/88098365/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200923/10/11500092/88098365/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 88128906,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 70402471,
          comment: "annem begendi guzel resimdekinin aynisi",
          rate: 5.0,
          userFullName: "m** e**",
          trusted: true,
          commentDateISOtype: "2020-12-02",
          lastModifiedDate: "2 Aralık 2020"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49629411,
      productCode: "C-0450",
      name: "Kadın Haki Kol Bağcıklı Devore Bluz",
      slug: "Kadın Haki Kol Bağcıklı Devore Bluz",
      description: "Kadın Haki Kol Bağcıklı Devore Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bluz. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür Vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün l bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40939345,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/17/11427269/87934161/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/17/11427269/87934161/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/17/11427269/87934161/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/17/11427269/87934161/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/17/11427269/87934161/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87961499,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 48712222,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10595670/86357066/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Kadın Kahverengi Büyük beden Kol Bağcıklı Devore Bluz"
        },
        {
          id: 49629411,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/17/11427269/87934161/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Kol Bağcıklı Devore Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49605139,
      productCode: "TYC00041680631",
      name: "Kadın Pudra Yıldız İşli Uzun Kol Viskon Büyük Beden Bluz",
      slug: "Kadın Pudra Yıldız İşli Uzun Kol Viskon Büyük Beden Bluz",
      description: "Kadın Pudra Yıldız İşli Uzun Kol Viskon Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Şık ve zarif bir görüntü sağlar. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41742167,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/15/11403305/87877644/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/15/11403305/87877644/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/15/11403305/87877644/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/15/11403305/87877644/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11403305/87877644/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87889907,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49568076,
      productCode: "C-0106",
      name: "Kadın Ekru Kol Detaylı V Yaka Viskon Bluz",
      slug: "Kadın Ekru Kol Detaylı V Yaka Viskon Bluz",
      description: "Kadın Ekru Kol Detaylı V Yaka Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705693,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367735/87816666/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367735/87816666/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367735/87816666/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367735/87816666/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367735/87816666/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87816666,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 49568076,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367735/87816666/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Kol Detaylı V Yaka Viskon Bluz"
        },
        {
          id: 126155027,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210719/9/111677701/206666152/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Laci Kol Detaylı V Yaka Viskon Bluz"
        },
        {
          id: 126024086,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527003/205932601/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Kol Detaylı V Yaka Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49567999,
      productCode: "C-0192",
      name: "Kadın Ekru Yaka Püsküllü Viskon Bluz",
      slug: "Kadın Ekru Yaka Püsküllü Viskon Bluz",
      description: "Kadın Ekru Yaka Püsküllü Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705616,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367679/87816562/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367679/87816562/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367679/87816562/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367679/87816562/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367679/87816562/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 239.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87827133,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 49567999,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367679/87816562/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Yaka Püsküllü Viskon Bluz"
        },
        {
          id: 126023753,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526634/205930235/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Yaka Püsküllü Viskon Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49567762,
      productCode: "TS-2101",
      name: "Kadın Kırmızı Yaprak Baskılı Bluz",
      slug: "Kadın Kırmızı Yaprak Baskılı Bluz",
      description: "Kadın Kırmızı Yaprak Baskılı Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705379,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367578/87816043/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367578/87816043/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367578/87816043/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367578/87816043/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367578/87816043/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "KIRMIZI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87826956,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 55298543,
          imageUrl: "https://cdn.dsmcdn.com/ty17/product/media/images/20201027/15/19936678/98078307/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Yaprak Baskılı Bluz"
        },
        {
          id: 49567762,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367578/87816043/1/1_org_zoom.jpg",
          name: "KIRMIZI",
          description: "Kadın Kırmızı Yaprak Baskılı Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49555176,
      productCode: "BL-K043",
      name: "Kadın Kahverengi Aksesuar Yakalı Bluz",
      slug: "Kadın Kahverengi Aksesuar Yakalı Bluz",
      description: "Kadın Kahverengi Aksesuar Yakalı Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Aksesuar Yaka Bluz., Viskon kumaştır., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün L bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41692851,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty339/product/media/images/20220222/21/56615437/87798267/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty339/product/media/images/20220222/21/56615437/87798267/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty339/product/media/images/20220222/21/56615437/87798267/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty340/product/media/images/20220222/21/56615437/87798267/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty339/product/media/images/20220222/21/56615437/87798267/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87802968,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 247990243,
          imageUrl: "https://cdn.dsmcdn.com/ty339/product/media/images/20220223/22/57756145/396794240/1/1_org_zoom.jpg",
          name: "KAHVE",
          description: "Yaka Detaylı Viskon Bluz"
        },
        {
          id: 49555176,
          imageUrl: "https://cdn.dsmcdn.com/ty339/product/media/images/20220222/21/56615437/87798267/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Kadın Kahverengi Aksesuar Yakalı Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49554068,
      productCode: "C-0406",
      name: "Kadın Haki Asimetrik Devore Hırka",
      slug: "Kadın Haki Asimetrik Devore Hırka",
      description: "Kadın Haki Asimetrik Devore Hırka",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve uzun kollu bayan devore hırka., kumaşın asimetrik bir yapısı ve yumuşak bir dokusu vardır., şık ve zarif bir görüntü sağlar., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Hırka",
      productGroupId: 41691748,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Hırka",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/9/11354752/87797174/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/9/11354752/87797174/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/9/11354752/87797174/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/9/11354752/87797174/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/9/11354752/87797174/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 0.0,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87797174,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 49554068,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/9/11354752/87797174/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Asimetrik Devore Hırka"
        },
        {
          id: 100205659,
          imageUrl: "https://cdn.dsmcdn.com/ty105/product/media/images/20210422/17/82147251/166456624/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Asimetrik Devore Büyük Beden Hırka"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 2,
          key: "Boy / Ölçü",
          value: "Uzun"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48794399,
      productCode: "TS-9899",
      name: "Kadın Pembe Gabardin Pamuklu Gömlek",
      slug: "Kadın Pembe Gabardin Pamuklu Gömlek",
      description: "Kadın Pembe Gabardin Pamuklu Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 41007141,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200915/9/10674878/86514753/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200915/9/10674878/86514753/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200915/9/10674878/86514753/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200915/9/10674878/86514753/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200915/9/10674878/86514753/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "NAR ÇİÇEĞİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 86514753,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Gabardin"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48712222,
      productCode: "C-0450",
      name: "Kadın Kahverengi Büyük beden Kol Bağcıklı Devore Bluz",
      slug: "Kadın Kahverengi Büyük beden Kol Bağcıklı Devore Bluz",
      description: "Kadın Kahverengi Büyük beden Kol Bağcıklı Devore Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40939345,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10595670/86357066/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10595670/86357066/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10595670/86357066/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10595670/86357066/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/12/10595670/86357066/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 179.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 86368723,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 48712222,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200914/12/10595670/86357066/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Kadın Kahverengi Büyük beden Kol Bağcıklı Devore Bluz"
        },
        {
          id: 49629411,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/17/11427269/87934161/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Kol Bağcıklı Devore Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48514119,
      productCode: "C-0419",
      name: "Kadın Siyah Önü Güpürlü Viskon Atlet Bluz",
      slug: "Kadın Siyah Önü Güpürlü Viskon Atlet Bluz",
      description: "Kadın Siyah Önü Güpürlü Viskon Atlet Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40769364,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/14/10408299/85997734/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/14/10408299/85997734/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/14/10408299/85997734/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/14/10408299/85997734/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/14/10408299/85997734/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 86005793,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kolsuz"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48495723,
      productCode: "C-0426-1",
      name: "Kadın Lacivert Yıldız Işli Uzun Kol Viskon Bluz",
      slug: "Kadın Lacivert Yıldız Işli Uzun Kol Viskon Bluz",
      description: "Kadın Lacivert Yıldız Işli Uzun Kol Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve uzun kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40753011,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/13/10389851/85955330/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/13/10389851/85955330/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/13/10389851/85955330/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/13/10389851/85955330/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/13/10389851/85955330/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "2XL",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "2XL",
          itemNumber: 85974809,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126157140,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210719/9/111680711/206673738/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Kadın Yıldız Işli U.kol Viskon Büyük Beden Bluz"
        },
        {
          id: 48495723,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/13/10389851/85955330/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Yıldız Işli Uzun Kol Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48489560,
      productCode: "C-0417-1",
      name: "Kadın Ekru V Yaka Omuz Güpürlü Viskon Bluz",
      slug: "Kadın Ekru V Yaka Omuz Güpürlü Viskon Bluz",
      description: "Kadın Ekru V Yaka Omuz Güpürlü Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kolluviskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40747462,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/12/10382736/85939297/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/12/10382736/85939297/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/12/10382736/85939297/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/12/10382736/85939297/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/12/10382736/85939297/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85939297,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 48489560,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/12/10382736/85939297/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru V Yaka Omuz Güpürlü Viskon Bluz"
        },
        {
          id: 126024056,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526972/205932379/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Ekru V Yaka Omuz Güpürlü Viskon Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48471604,
      productCode: "C-0299-1",
      name: "Kadın Lacivert Taşlı Viskon Bluz",
      slug: "Kadın Lacivert Taşlı Viskon Bluz",
      description: "Kadın Lacivert Taşlı Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40732893,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10365064/85892029/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10365064/85892029/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10365064/85892029/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/10/10365064/85892029/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10365064/85892029/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85924919,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48465726,
      productCode: "C-0111-1",
      name: "Kadın Lacivert Volanlı Viskon Kısa Kol Bluz",
      slug: "Kadın Lacivert Volanlı Viskon Kısa Kol Bluz",
      description: "Kadın Lacivert Volanlı Viskon Kısa Kol Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40728355,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10360077/85855905/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10360077/85855905/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/10/10360077/85855905/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10360077/85855905/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10360077/85855905/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 179.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85855905,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126154966,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210719/9/111677647/206665986/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Volanlı Viskon Kısa Kol Büyük Beden Bluz"
        },
        {
          id: 48465726,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10360077/85855905/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Volanlı Viskon Kısa Kol Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Volanlı"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48465044,
      productCode: "C-0450-1",
      name: "Kadın Vizon Kol Bağcıklı Devore Bluz",
      slug: "Kadın Vizon Kol Bağcıklı Devore Bluz",
      description: "Kadın Vizon Kol Bağcıklı Devore Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Bluz",
      productGroupId: 40727838,
      categoryHierarchy: "Bluz/Giyim/Üst Giyim/Bluz & Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10359403/85854978/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10359403/85854978/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10359403/85854978/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10359403/85854978/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/10/10359403/85854978/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "2XL",
      color: "VİZON",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "2XL",
          itemNumber: 85854978,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48462861,
      productCode: "C-0425-1",
      name: "Kadın Haki Uzun Kol Devore Bluz",
      slug: "Kadın Haki Uzun Kol Devore Bluz",
      description: "Kadın Haki Uzun Kol Devore Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve uzun kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40725932,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10357145/85851743/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10357145/85851743/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/10/10357145/85851743/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10357145/85851743/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/10/10357145/85851743/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "HAKİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85851743,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 104572232,
          comment: "çok beğendim kumaşı kesimi harika L beden küçük geldi iade etmek zorunda kaldım XL istedim tereddüt etmeden alabilirsiniz Bir beden büyüğünü almanızı tavsiye ederim tek kusuru fiyatı daha uygun olursa daha iyi olacak😊",
          rate: 5.0,
          userFullName: "sevim y.",
          trusted: true,
          commentDateISOtype: "2021-05-15",
          lastModifiedDate: "15 Mayıs 2021"
        }
      ],
      colors: [
        {
          id: 49733294,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163310/88152301/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Kadın Vizon Uzun Kol Devore Bluz"
        },
        {
          id: 48462861,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/10/10357145/85851743/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Kadın Haki Uzun Kol Devore Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Haki"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48460692,
      productCode: "C-0417-2",
      name: "Kadın Siyah V Yaka Omuz Güpürlü Viskon Bluz",
      slug: "Kadın Siyah V Yaka Omuz Güpürlü Viskon Bluz",
      description: "Kadın Siyah V Yaka Omuz Güpürlü Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu kadın viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40723986,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10355034/85848601/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10355034/85848601/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/9/10355034/85848601/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10355034/85848601/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10355034/85848601/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 3.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85848601,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 168285239,
          comment: "Ürün fena değil  beden olmadığı için iade ettik",
          rate: 3.0,
          userFullName: "m** a**",
          trusted: true,
          commentDateISOtype: "2022-03-18",
          lastModifiedDate: "18 Mart 2022"
        }
      ],
      colors: [
        {
          id: 126029373,
          imageUrl: "https://cdn.dsmcdn.com/ty389/product/media/images/20220408/1/85852877/205975346/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Siyah V Yaka Omuz Güpürlü Viskon Bluz"
        },
        {
          id: 48460692,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10355034/85848601/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah V Yaka Omuz Güpürlü Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48459185,
      productCode: "C-0205-1",
      name: "Kadın Siyah V Yaka, Yaka Dantelli Devore Bluz",
      slug: "Kadın Siyah V Yaka, Yaka Dantelli Devore Bluz",
      description: "Kadın Siyah V Yaka, Yaka Dantelli Devore Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40722716,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10353704/85846503/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10353704/85846503/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10353704/85846503/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/9/10353704/85846503/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200911/9/10353704/85846503/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 1.0,
      ratingScore: {
        averageRating: 1.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85846503,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 173349528,
          comment: "berbat asla tavsiye etmem",
          rate: 1.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-04-09",
          lastModifiedDate: "9 Nisan 2022"
        }
      ],
      colors: [
        {
          id: 49732630,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200923/11/11523218/88150073/1/1_org_zoom.jpg",
          name: "VİZON",
          description: "Kadın Vizon V Yaka Dantelli Büyük Beden Bluz"
        },
        {
          id: 48459185,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200911/9/10353704/85846503/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah V Yaka, Yaka Dantelli Devore Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "V Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48456351,
      productCode: "C-0407",
      name: "Kadın Mercan Dijital Omuz Açık Devore Bluz",
      slug: "Kadın Mercan Dijital Omuz Açık Devore Bluz",
      description: "Kadın Mercan Dijital Omuz Açık Devore Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon dijital bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40720226,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty24/product/media/images/20201119/10/28956963/85846211/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty24/product/media/images/20201119/10/28956963/85846211/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956963/85846211/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty23/product/media/images/20201119/10/28956963/85846211/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty24/product/media/images/20201119/10/28956963/85846211/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "MERCAN",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85846210,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kırmızı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48414419,
      productCode: "C-0189-1",
      name: "Kadın Siyah Simli Uzun Kollu Viskon Bluz",
      slug: "Kadın Siyah Simli Uzun Kollu Viskon Bluz",
      description: "Kadın Siyah Simli Uzun Kollu Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40681896,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/18/10310171/85788528/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/18/10310171/85788528/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/18/10310171/85788528/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/18/10310171/85788528/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/18/10310171/85788528/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85788528,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48412445,
      productCode: "C-0184-2",
      name: "Kadın Ekru Simli Omuz Açık Viskon Bluz",
      slug: "Kadın Ekru Simli Omuz Açık Viskon Bluz",
      description: "Kadın Ekru Simli Omuz Açık Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün l bedendir."
        },
        {
          bold: false,
          description: "Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40680091,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10308313/85785076/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10308313/85785076/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10308313/85785076/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10308313/85785076/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10308313/85785076/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "EKRU",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85785076,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Omzu açık"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Ekru"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48410495,
      productCode: "C-0184-1",
      name: "Kadın Lacivert Simli Omuz Açık Viskon Bluz",
      slug: "Kadın Lacivert Simli Omuz Açık Viskon Bluz",
      description: "Kadın Lacivert Simli Omuz Açık Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40678344,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10306433/85781479/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10306433/85781479/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10306433/85781479/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10306433/85781479/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10306433/85781479/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85781479,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126029395,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534423/205961652/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Kol Detaylı Yaka Viskon Bluz"
        },
        {
          id: 48410495,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10306433/85781479/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Simli Omuz Açık Viskon Bluz"
        },
        {
          id: 126029403,
          imageUrl: "https://cdn.dsmcdn.com/ty149/product/media/images/20210717/21/111534463/205961674/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Kol Detaylı Yaka Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Omzu açık"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48409979,
      productCode: "C-0159-1",
      name: "Kadın Lacivert Gelincik Baskılı Kol Bağcıklı Viskon Bluz",
      slug: "Kadın Lacivert Gelincik Baskılı Kol Bağcıklı Viskon Bluz",
      description: "Kadın Lacivert Gelincik Baskılı Kol Bağcıklı Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40677886,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305912/85780632/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305912/85780632/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10305912/85780632/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305912/85780632/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305912/85780632/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "LACİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85789838,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126024053,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111526971/205932367/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Gelincik Baskılı Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 48409979,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305912/85780632/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Lacivert Gelincik Baskılı Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 126023918,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/19/111526796/205930912/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Gelincik Baskılı Kol Bağcıklı Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Lacivert"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48409723,
      productCode: "C-0155-1",
      name: "Kadın Pembe Pentürlü Kısa Kol Bağcıklı Viskon Bluz",
      slug: "Kadın Pembe Pentürlü Kısa Kol Bağcıklı Viskon Bluz",
      description: "Kadın Pembe Pentürlü Kısa Kol Bağcıklı Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40677662,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305637/85780069/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305637/85780069/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10305637/85780069/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10305637/85780069/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305637/85780069/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 1.0
      },
      winnerVariant: "L",
      color: "PUDRA",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85788806,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126024083,
          imageUrl: "https://cdn.dsmcdn.com/ty147/product/media/images/20210717/19/111527000/205932570/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Pentürlü Kısa Kol Bağcıklı Viskon Büyük Beden Bluz"
        },
        {
          id: 48409723,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10305637/85780069/1/1_org_zoom.jpg",
          name: "PUDRA",
          description: "Kadın Pembe Pentürlü Kısa Kol Bağcıklı Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Pembe"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48404615,
      productCode: "C-0114-1",
      name: "Kadın Siyah Şifon Kol Bağcıklı Viskon Bluz",
      slug: "Kadın Siyah Şifon Kol Bağcıklı Viskon Bluz",
      description: "Kadın Siyah Şifon Kol Bağcıklı Viskon Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Yuvarlak yaka ve kısa kollu bayan viskon bluz."
        },
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır."
        },
        {
          bold: false,
          description: "Şık ve zarif bir görüntü sağlar."
        },
        {
          bold: false,
          description: "Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Mankenin üzerindeki ürün L bedendir."
        },
        {
          bold: false,
          description: "Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40674805,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10300398/85773359/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10300398/85773359/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10300398/85773359/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10300398/85773359/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200910/17/10300398/85773359/4/4_org_zoom.jpg"
      ],
      price: 299.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 299.99,
        sellingPrice: 299.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 85773359,
          stock: 3.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 126029376,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534407/205961614/1/1_org_zoom.jpg",
          name: "EKRU",
          description: "Kadın Ekru Şifon Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 126029378,
          imageUrl: "https://cdn.dsmcdn.com/ty148/product/media/images/20210717/21/111534408/205961623/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Kadın Laci Şifon Kol Bağcıklı Viskon Bluz"
        },
        {
          id: 48404615,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200910/17/10300398/85773359/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Şifon Kol Bağcıklı Viskon Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Kısa"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 59783642,
      productCode: "TS-1738",
      name: "Kadın Bordo Polar Fermuarlı Yelek",
      slug: "Kadın Bordo Polar Fermuarlı Yelek",
      description: "Kadın Bordo Polar Fermuarlı Yelek",
      contentDescriptions: [
        {
          bold: false,
          description: "Beden tablosunu incelemenizi rica ederiz, Polar Fermuarlı Yelek, Kumaşın yumuşak bir dokusu vardır., Ürünün kumaşı nefes alır,sıcak tutar ve vücut hatlarına yapışma yapmaz., Tüylenme yazmaz., Tersten,düşük sıcaklıkta yıkayınız., Mankenin üzerindeki ürün M bedendir., Modelin Ölçüleri: Boy: 1.75 Göğüs: 92, Bel: 65, Kalça: 84"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Yelek",
      productGroupId: 47117113,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Yelek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242285591/105892405/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242285591/105892405/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty656/product/media/images/20221220/16/242285591/105892405/2/2_org_zoom.jpg"
      ],
      price: 299.9,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 299.9,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 241.54,
        sellingPrice: 299.9
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.76923076923077,
        totalCount: 39.0
      },
      winnerVariant: "50",
      color: "BORDO",
      gender: "Unisex",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "50",
          itemNumber: 105892422,
          stock: 5.0
        }
      ],
      productReviews: [
        {
          id: 73626646,
          comment: "Merhaba L alıp olmayınca iade etmiştim. Sahibi Orhan bey çok ilgiliydi. Grisini, iki beden de büyüğünü aldım. Cok iyi rahat ve güzel oldu. Ayrıca Eşofman takim ve bir de alt eşofman aldım. Duruşu kalitesi dikişi gayet iyi. Yıllarca gireyim gibi. Çok teşekkürler.",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2020-12-16",
          lastModifiedDate: "16 Aralık 2020"
        },
        {
          id: 88793442,
          comment: "çok güzel rengi harika bedenimi aldim ama galiba biraz kilo almışım kalin kazakla giyemeyeceğim tavsiye ederim alın fiyat biraz fazla ama keşke indirime girse diğet renklerinide alsaydım",
          rate: 5.0,
          userFullName: "s** s**",
          trusted: true,
          commentDateISOtype: "2021-03-05",
          lastModifiedDate: "5 Mart 2021"
        },
        {
          id: 72180321,
          comment: "Anneme aldık M beden seçmiştim 42 geldi ama neyse ki bedeni oldu. Rengi fotoğraftakinden farklı biraz daha açık bi bordo geliyor, fena değil, giyilir",
          rate: 3.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2020-12-10",
          lastModifiedDate: "10 Aralık 2020"
        }
      ],
      colors: [
        {
          id: 74226167,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852162/127578942/1/1_org_zoom.jpg",
          name: "MOR",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 80209026,
          imageUrl: "https://cdn.dsmcdn.com/ty654/product/media/images/20221220/16/242285354/135927520/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 55819993,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20852834/106292279/1/1_org_zoom.jpg",
          name: "HAKİ",
          description: "Polar Fermuarlı Yelek"
        },
        {
          id: 59783642,
          imageUrl: "https://cdn.dsmcdn.com/ty655/product/media/images/20221220/16/242285591/105892405/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Polar Fermuarlı Yelek"
        },
        {
          id: 55695632,
          imageUrl: "https://cdn.dsmcdn.com/ty285/product/media/images/20220103/15/20851881/98941395/1/1_org_zoom.jpg",
          name: "LACİ",
          description: "Laci Polar Fermuarlı Yelek"
        },
        {
          id: 59783623,
          imageUrl: "https://cdn.dsmcdn.com/ty316/product/media/images/20220131/23/39959203/139698782/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Polar Fermuarlı Yelek"
        },
        {
          id: 74245548,
          imageUrl: "https://cdn.dsmcdn.com/ty286/product/media/images/20220103/15/20851119/139701900/1/1_org_zoom.jpg",
          name: "ANTRASİT",
          description: "Polar Fermuarlı Yelek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Polar"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55298385,
      productCode: "TS-5464",
      name: "Kadın Yeşil Çelik Dijital Düğmeli Bluz",
      slug: "Kadın Yeşil Çelik Dijital Düğmeli Bluz",
      description: "Kadın Yeşil Çelik Dijital Düğmeli Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Çelik düğmeli dijital bluz viskon kumaştır.Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız.Mankenin üzerindeki ürün l bedendir.Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 99."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705412,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/15/19936515/98077970/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/15/19936515/98077970/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/15/19936515/98077970/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201027/15/19936515/98077970/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/15/19936515/98077970/4/4_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 98093902,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 55298385,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/15/19936515/98077970/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Çelik Dijital Düğmeli Bluz"
        },
        {
          id: 49567795,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367441/87816159/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Çelik Dijital Düğmeli Bluz"
        },
        {
          id: 49605882,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11404104/87878850/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Çelik Dijital Düğmeli Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55273565,
      productCode: "TS-5471",
      name: "Kadın Yeşil Dijital Baskılı Taş Işli Tunik",
      slug: "Kadın Yeşil Dijital Baskılı Taş Işli Tunik",
      description: "Kadın Yeşil Dijital Baskılı Taş Işli Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Dijital Baskılı Taş İşli Tunik, Triko Lyocell kumaştır, Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır, Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz, Tersten, hassas yıkamada yıkayınız, Mankenin üzerindeki ürün XL bedendir, Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 46704803,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19907626/98017072/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19907626/98017072/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19907626/98017072/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19907626/98017072/3/3_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 98035532,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 93638991,
          comment: "Ürün yazlık ve ince bir kumaşa sahip, aynı ürünün farklı desen ve renklerinden de aldım ürünler resimde göründüğü gibi, çok güzel bir şal hediyesi ve zarif bir not koyulmuş satıcı tarafından çok teşekkür ederim 🙂🙂❤️",
          rate: 5.0,
          userFullName: "B** B**",
          trusted: true,
          commentDateISOtype: "2021-03-29",
          lastModifiedDate: "29 Mart 2021"
        }
      ],
      colors: [
        {
          id: 55273565,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19907626/98017072/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Dijital Baskılı Taş Işli Tunik"
        },
        {
          id: 55123891,
          imageUrl: "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19730254/97717116/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Dijital Baskılı Taş İşli Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55273506,
      productCode: "TS-5465",
      name: "Kadın Mavi Dijital Londra Baskılı Tunik",
      slug: "Kadın Mavi Dijital Londra Baskılı Tunik",
      description: "Kadın Mavi Dijital Londra Baskılı Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Londra Baskılı Tunik., Triko Lyocell kumaştır., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 41009346,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19903731/98017016/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19903731/98017016/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19903731/98017016/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19903731/98017016/3/3_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "2XL",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "2XL",
          itemNumber: 98030370,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 307326330,
          comment: "Kumaşı dokusu çok güzel",
          rate: 5.0,
          userFullName: "n** Ç**",
          trusted: true,
          commentDateISOtype: "2023-05-23",
          lastModifiedDate: "23 Mayıs 2023"
        }
      ],
      colors: [
        {
          id: 55273506,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19903731/98017016/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Dijital Londra Baskılı Tunik"
        },
        {
          id: 55125048,
          imageUrl: "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19731249/97719781/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Dijital Londra Baskılı Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55125257,
      productCode: "TS-5460",
      name: "Kadın Yeşil Etnik Dijital Tunik",
      slug: "Kadın Yeşil Etnik Dijital Tunik",
      description: "Kadın Yeşil Etnik Dijital Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Etnik Dijital Tunik., Triko Lyocell kumaştır., Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün XL bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 41029488,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731554/97720687/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731554/97720687/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19731554/97720687/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731554/97720687/3/3_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 4.0,
      ratingScore: {
        averageRating: 4.0,
        totalCount: 2.0
      },
      winnerVariant: "XL",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 97737994,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 93639005,
          comment: "Ürün yazlık ve ince bir kumaşa sahip, aynı ürünün farklı desen ve renklerinden de aldım ürünler resimde göründüğü gibi, çok güzel bir şal hediyesi ve zarif bir not koyulmuş satıcı tarafından çok teşekkür ederim 🙂🙂❤️",
          rate: 5.0,
          userFullName: "B** B**",
          trusted: true,
          commentDateISOtype: "2021-03-29",
          lastModifiedDate: "29 Mart 2021"
        },
        {
          id: 278114408,
          comment: "çok beğenmedim ve iade ettim",
          rate: 3.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2023-03-14",
          lastModifiedDate: "14 Mart 2023"
        }
      ],
      colors: [
        {
          id: 55125257,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731554/97720687/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Etnik Dijital Tunik"
        },
        {
          id: 55124323,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19730613/97717557/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Etnik Dijital Tunik"
        },
        {
          id: 48821629,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200915/13/10702189/86572187/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Etnik Dijital Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55125048,
      productCode: "TS-5465",
      name: "Kadın Siyah Dijital Londra Baskılı Tunik",
      slug: "Kadın Siyah Dijital Londra Baskılı Tunik",
      description: "Kadın Siyah Dijital Londra Baskılı Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan londra baskılı tunik., triko lyocell kumaştır., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 41009346,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19731249/97719781/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19731249/97719781/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19731249/97719781/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731249/97719781/3/3_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 97719781,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 55273506,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19903731/98017016/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Kadın Mavi Dijital Londra Baskılı Tunik"
        },
        {
          id: 55125048,
          imageUrl: "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19731249/97719781/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Dijital Londra Baskılı Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55125016,
      productCode: "TS-5463",
      name: "Kadın Yeşil Dijital Yırtmaçlı Tunik",
      slug: "Kadın Yeşil Dijital Yırtmaçlı Tunik",
      description: "Kadın Yeşil Dijital Yırtmaçlı Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan dijital yırtmaçlı tunik., triko lyocell kumaştır., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 41718143,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731197/97718988/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731197/97718988/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731197/97718988/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19731197/97718988/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731197/97718988/4/4_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 2.0
      },
      winnerVariant: "XL",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 97736567,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 93638971,
          comment: "Ürün yazlık ve ince bir kumaşa sahip, aynı ürünün farklı desen ve renklerinden de aldım ürünler resimde göründüğü gibi, çok güzel bir şal hediyesi ve zarif bir not koyulmuş satıcı tarafından çok teşekkür ederim 🙂🙂❤️",
          rate: 5.0,
          userFullName: "B** B**",
          trusted: true,
          commentDateISOtype: "2021-03-29",
          lastModifiedDate: "29 Mart 2021"
        },
        {
          id: 104944765,
          comment: "Cok güzel ve kaliteli",
          rate: 5.0,
          userFullName: "Z** T**",
          trusted: true,
          commentDateISOtype: "2021-05-17",
          lastModifiedDate: "17 Mayıs 2021"
        }
      ],
      colors: [
        {
          id: 55125016,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731197/97718988/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Dijital Yırtmaçlı Tunik"
        },
        {
          id: 49580488,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/12/11379797/87837392/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Yırtmaçlı Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55124323,
      productCode: "TS-5460",
      name: "Kadın Bordo Etnik Dijital Tunik",
      slug: "Kadın Bordo Etnik Dijital Tunik",
      description: "Kadın Bordo Etnik Dijital Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan etnik dijital tunik., triko lyocell kumaştır., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 41029488,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19730613/97717557/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19730613/97717557/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19730613/97717557/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19730613/97717557/3/3_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 97717557,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 55125257,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731554/97720687/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Etnik Dijital Tunik"
        },
        {
          id: 55124323,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19730613/97717557/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Etnik Dijital Tunik"
        },
        {
          id: 48821629,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200915/13/10702189/86572187/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Etnik Dijital Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55123891,
      productCode: "TS-5471",
      name: "Kadın Bordo Dijital Baskılı Taş İşli Tunik",
      slug: "Kadın Bordo Dijital Baskılı Taş İşli Tunik",
      description: "Kadın Bordo Dijital Baskılı Taş İşli Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan dijital baskılı taş işli tunik, triko lyocell kumaştır, kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır, ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz, tersten, hassas yıkamada yıkayınız, mankenin üzerindeki ürün xl bedendir, modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 46704803,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19730254/97717116/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19730254/97717116/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19730254/97717116/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19730254/97717116/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19730254/97717116/4/4_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 97735444,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 55273565,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/13/19907626/98017072/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Dijital Baskılı Taş Işli Tunik"
        },
        {
          id: 55123891,
          imageUrl: "https://cdn.dsmcdn.com/ty17/product/media/images/20201026/15/19730254/97717116/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Dijital Baskılı Taş İşli Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 55121559,
      productCode: "TS-5438",
      name: "Kadın Yeşil Eteği Taş Işli Tunik",
      slug: "Kadın Yeşil Eteği Taş Işli Tunik",
      description: "Kadın Yeşil Eteği Taş Işli Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan eteği taş işli tunik., triko lyocell kumaştır., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 41009013,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2163724/97734225/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2163724/97734225/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163724/97734225/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2163724/97734225/3/3_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "XL",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 97734225,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 55121559,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2163724/97734225/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Eteği Taş Işli Tunik"
        },
        {
          id: 48796554,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163714/86536284/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Eteği Taş İşli Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49605882,
      productCode: "TS-5464",
      name: "Kadın Siyah Çelik Dijital Düğmeli Büyük Beden Bluz",
      slug: "Kadın Siyah Çelik Dijital Düğmeli Büyük Beden Bluz",
      description: "Kadın Siyah Çelik Dijital Düğmeli Büyük Beden Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Çelik düğmeli dijital bluz. Viskon kumaştır. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün L bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 99"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705412,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11404104/87878850/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11404104/87878850/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11404104/87878850/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11404104/87878850/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11404104/87878850/4/4_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.66666666666667,
        totalCount: 3.0
      },
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87878850,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 243490857,
          comment: "10 yıldız olsa verirdim. Çok şık ve kaliteli bir ürün ve üstelik düğmeleri açık da giyilebir . Teşekkürler",
          rate: 5.0,
          userFullName: "f** d**",
          trusted: true,
          commentDateISOtype: "2022-12-20",
          lastModifiedDate: "20 Aralık 2022"
        }
      ],
      colors: [
        {
          id: 55298385,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/15/19936515/98077970/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Çelik Dijital Düğmeli Bluz"
        },
        {
          id: 49567795,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367441/87816159/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Çelik Dijital Düğmeli Bluz"
        },
        {
          id: 49605882,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11404104/87878850/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Çelik Dijital Düğmeli Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49580488,
      productCode: "TS-5463",
      name: "Kadın Siyah Yırtmaçlı Tunik",
      slug: "Kadın Siyah Yırtmaçlı Tunik",
      description: "Kadın Siyah Yırtmaçlı Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Triko lyocell kumaştır. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün xl bedendir. Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 41718143,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/12/11379797/87837392/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/12/11379797/87837392/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/12/11379797/87837392/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/12/11379797/87837392/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/12/11379797/87837392/4/4_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "XL",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 87847949,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 93638955,
          comment: "Ürün yazlık ve ince bir kumaşa sahip, aynı ürünün farklı desen ve renklerinden de aldım ürünler resimde göründüğü gibi, çok güzel bir şal hediyesi ve zarif bir not koyulmuş satıcı tarafından çok teşekkür ederim 🙂🙂❤️",
          rate: 5.0,
          userFullName: "B** B**",
          trusted: true,
          commentDateISOtype: "2021-03-29",
          lastModifiedDate: "29 Mart 2021"
        },
        {
          id: 157912755,
          comment: "Çok güzel zarif notunuz içinde çok teşekkür ederiz",
          rate: 5.0,
          userFullName: "**** ****",
          trusted: true,
          commentDateISOtype: "2022-02-02",
          lastModifiedDate: "2 Şubat 2022"
        },
        {
          id: 80607658,
          comment: "Çok güzel ve kaliteli",
          rate: 5.0,
          userFullName: "Z** T**",
          trusted: true,
          commentDateISOtype: "2021-01-20",
          lastModifiedDate: "20 Ocak 2021"
        }
      ],
      colors: [
        {
          id: 55125016,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731197/97718988/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Dijital Yırtmaçlı Tunik"
        },
        {
          id: 49580488,
          imageUrl: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/12/11379797/87837392/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Yırtmaçlı Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49567795,
      productCode: "TS-5464",
      name: "Kadın Bordo Çelik Dijital Düğmeli Bluz",
      slug: "Kadın Bordo Çelik Dijital Düğmeli Bluz",
      description: "Kadın Bordo Çelik Dijital Düğmeli Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705412,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367441/87816159/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367441/87816159/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367441/87816159/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367441/87816159/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367441/87816159/4/4_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87816159,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 55298385,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201027/15/19936515/98077970/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Çelik Dijital Düğmeli Bluz"
        },
        {
          id: 49567795,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367441/87816159/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Çelik Dijital Düğmeli Bluz"
        },
        {
          id: 49605882,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/15/11404104/87878850/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Çelik Dijital Düğmeli Büyük Beden Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48821629,
      productCode: "TS-5460",
      name: "Kadın Siyah Etnik Dijital Tunik",
      slug: "Kadın Siyah Etnik Dijital Tunik",
      description: "Kadın Siyah Etnik Dijital Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Ürünün kumaşı dökümlüdür,vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 41029488,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200915/13/10702189/86572187/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200915/13/10702189/86572187/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200915/13/10702189/86572187/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200915/13/10702189/86572187/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200915/13/10702189/86572187/4/4_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 86572187,
          stock: 2.0
        }
      ],
      productReviews: [
        {
          id: 93638805,
          comment: "Ürün yazlık ve ince bir kumaşa sahip, aynı ürünün farklı desen ve renklerinden de aldım ürünler resimde göründüğü gibi, çok güzel bir şal hediyesi ve zarif bir not koyulmuş satıcı tarafından çok teşekkür ederim 🙂🙂❤️",
          rate: 5.0,
          userFullName: "B** B**",
          trusted: true,
          commentDateISOtype: "2021-03-29",
          lastModifiedDate: "29 Mart 2021"
        }
      ],
      colors: [
        {
          id: 55125257,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19731554/97720687/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Etnik Dijital Tunik"
        },
        {
          id: 55124323,
          imageUrl: "https://cdn.dsmcdn.com/ty18/product/media/images/20201026/15/19730613/97717557/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Etnik Dijital Tunik"
        },
        {
          id: 48821629,
          imageUrl: "https://cdn.dsmcdn.com/ty12/product/media/images/20200915/13/10702189/86572187/1/1_org_zoom.jpg",
          name: "SİYAH",
          description: "Kadın Siyah Etnik Dijital Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Çizgili"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48796554,
      productCode: "TS-5438",
      name: "Kadın Bordo Eteği Taş İşli Tunik",
      slug: "Kadın Bordo Eteği Taş İşli Tunik",
      description: "Kadın Bordo Eteği Taş İşli Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan eteği taş işli tunik., triko lyocell kumaştır., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 41009013,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163714/86536284/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163714/86536284/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2163714/86536284/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2163714/86536284/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163714/86536284/4/4_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "XL",
      color: "BORDO",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 86536284,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 55121559,
          imageUrl: "https://cdn.dsmcdn.com/ty258/product/media/images/20211130/19/2163724/97734225/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Kadın Yeşil Eteği Taş Işli Tunik"
        },
        {
          id: 48796554,
          imageUrl: "https://cdn.dsmcdn.com/ty259/product/media/images/20211130/19/2163714/86536284/1/1_org_zoom.jpg",
          name: "BORDO",
          description: "Kadın Bordo Eteği Taş İşli Tunik"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Baskılı"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Trend"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Bordo"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 48764112,
      productCode: "TS-5470",
      name: "Kadın Mavi Dijital Londra Baskılı Bluz",
      slug: "Kadın Mavi Dijital Londra Baskılı Bluz",
      description: "Kadın Mavi Dijital Londra Baskılı Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan Dijital Bluz Lyocell kumaştır. Kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz. Tersten, hassas yıkamada yıkayınız. Mankenin üzerindeki ürün XL bedendir. Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 40984041,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/18/10644925/86461884/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/18/10644925/86461884/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/18/10644925/86461884/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/18/10644925/86461884/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200914/18/10644925/86461884/4/4_org_zoom.jpg"
      ],
      price: 279.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 279.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 279.99,
        sellingPrice: 279.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "XL",
      color: "MAVİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "XL",
          itemNumber: 86461884,
          stock: 2.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Dokuma"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Mavi"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 691152533,
      productCode: "C-W52735",
      name: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      slug: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 533916112,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327604303/914455453/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327604303/914455453/1/1_org_zoom.jpg"
      ],
      price: 229.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 229.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 229.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 3.0
      },
      winnerVariant: "40",
      color: "SEPET",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 914455453,
          stock: 0.0
        }
      ],
      productReviews: [
        {
          id: 310153141,
          comment: "Tam oldu teşekkürler anneme aldım",
          rate: 5.0,
          userFullName: "E** K**",
          trusted: true,
          commentDateISOtype: "2023-06-01",
          lastModifiedDate: "1 Haziran 2023"
        },
        {
          id: 322167211,
          comment: "gömlek çok geniş, iade malesef",
          rate: 5.0,
          userFullName: "B** B**",
          trusted: true,
          commentDateISOtype: "2023-07-15",
          lastModifiedDate: "15 Temmuz 2023"
        }
      ],
      colors: [
        {
          id: 691155578,
          imageUrl: "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327608021/914459153/1/1_org_zoom.jpg",
          name: "KARIŞIK",
          description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz"
        },
        {
          id: 692319365,
          imageUrl: "https://cdn.dsmcdn.com/ty835/product/media/images/20230419/21/328810287/915705647/1/1_org_zoom.jpg",
          name: "LEOPAR",
          description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz"
        },
        {
          id: 691152533,
          imageUrl: "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327604303/914455453/1/1_org_zoom.jpg",
          name: "SEPET",
          description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 250,
          key: "Stil",
          value: "Günlük"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        },
        {
          id: 48,
          key: "Boy",
          value: "Kısa"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Extra Esnek"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Örgü"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "Orta"
        },
        {
          id: 66,
          key: "Paket İçeriği",
          value: "Tekli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 322471082,
      productCode: "TS-L675",
      name: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      slug: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek",
      contentDescriptions: [
        {
          bold: false,
          description: "TUSSE Desenli Saten Büyük Beden Elegant Şık Gömlek, Ürün klasik pantolonlarla çok şık kombinlenebilrmketedir. Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., Tersten, hassas yıkamada yıkayınız., Mankenin üzerindeki ürün 46 bedendir., Modelin Ölçüleri: Boy: 1.76 Göğüs: 92, Bel: 67, Kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Gömlek",
      productGroupId: 266458546,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Gömlek",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897591/515256635/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897591/515256635/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897591/515256635/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897591/515256635/3/3_org_zoom.jpg"
      ],
      price: 379.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 379.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 303.12,
        sellingPrice: 379.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 4.75,
        totalCount: 4.0
      },
      winnerVariant: "46",
      color: "SARI",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "46",
          itemNumber: 515261068,
          stock: 3.0
        }
      ],
      productReviews: [
        {
          id: 222925828,
          comment: "Fotoğrafdan daha güzel renkleri.anneme hediyye aldım. Bedeni tam cok güzel",
          rate: 5.0,
          userFullName: "N** M**",
          trusted: true,
          commentDateISOtype: "2022-10-30",
          lastModifiedDate: "30 Ekim 2022"
        },
        {
          id: 293739997,
          comment: "anneme aldım saten çok güzel bir gömlek begendi 165boy 100 kg oldu",
          rate: 4.0,
          userFullName: "H** A**",
          trusted: true,
          commentDateISOtype: "2023-04-17",
          lastModifiedDate: "17 Nisan 2023"
        }
      ],
      colors: [
        {
          id: 322471082,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135897591/515256635/1/1_org_zoom.jpg",
          name: "SARI",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322471019,
          imageUrl: "https://cdn.dsmcdn.com/ty469/product/media/images/20220706/0/135897514/515256571/1/1_org_zoom.jpg",
          name: "YEŞİL",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        },
        {
          id: 322469976,
          imageUrl: "https://cdn.dsmcdn.com/ty470/product/media/images/20220706/0/135896261/515254996/1/1_org_zoom.jpg",
          name: "MAVİ",
          description: "Plus Renkli Desenli Saten Büyük Beden Elegant Şık Gömlek"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 348,
          key: "Renk",
          value: "Sarı"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 692319365,
      productCode: "C-W52735",
      name: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      slug: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 533916112,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty835/product/media/images/20230419/21/328810287/915705647/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty835/product/media/images/20230419/21/328810287/915705647/1/1_org_zoom.jpg"
      ],
      price: 229.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 229.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 229.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "LEOPAR",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 916163321,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 691155578,
          imageUrl: "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327608021/914459153/1/1_org_zoom.jpg",
          name: "KARIŞIK",
          description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz"
        },
        {
          id: 692319365,
          imageUrl: "https://cdn.dsmcdn.com/ty835/product/media/images/20230419/21/328810287/915705647/1/1_org_zoom.jpg",
          name: "LEOPAR",
          description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz"
        },
        {
          id: 691152533,
          imageUrl: "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327604303/914455453/1/1_org_zoom.jpg",
          name: "SEPET",
          description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Günlük"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 48,
          key: "Boy",
          value: "Kısa"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Extra Esnek"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Örgü"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "Orta"
        },
        {
          id: 66,
          key: "Paket İçeriği",
          value: "Tekli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 691155578,
      productCode: "C-W52735",
      name: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      slug: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 533916112,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327608021/914459153/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327608021/914459153/1/1_org_zoom.jpg"
      ],
      price: 229.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 229.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 229.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "KARIŞIK",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 914483645,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [
        {
          id: 691155578,
          imageUrl: "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327608021/914459153/1/1_org_zoom.jpg",
          name: "KARIŞIK",
          description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz"
        },
        {
          id: 692319365,
          imageUrl: "https://cdn.dsmcdn.com/ty835/product/media/images/20230419/21/328810287/915705647/1/1_org_zoom.jpg",
          name: "LEOPAR",
          description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz"
        },
        {
          id: 691152533,
          imageUrl: "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327604303/914455453/1/1_org_zoom.jpg",
          name: "SEPET",
          description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz"
        }
      ],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Günlük"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Çok Renkli"
        },
        {
          id: 48,
          key: "Boy",
          value: "Kısa"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Extra Esnek"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Örgü"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "Orta"
        },
        {
          id: 66,
          key: "Paket İçeriği",
          value: "Tekli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 691153025,
      productCode: "TYC00795535490",
      name: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      slug: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 533916572,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty832/product/media/images/20230418/18/327605078/914456056/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty832/product/media/images/20230418/18/327605078/914456056/1/1_org_zoom.jpg"
      ],
      price: 229.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 229.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 229.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "KARELİ",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 914481739,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Günlük"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 348,
          key: "Renk",
          value: "Kahverengi"
        },
        {
          id: 48,
          key: "Boy",
          value: "Kısa"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Extra Esnek"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Örgü"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "Orta"
        },
        {
          id: 66,
          key: "Paket İçeriği",
          value: "Tekli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 691152658,
      productCode: "TYC00795535280",
      name: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      slug: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      description: "Desenli Empirme Dökümlü Viskon Kadın Büyük Beden Penye Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Ürün krep kumaştır. Kumaşın içeriğinde ipek, viskoz ve pamuk ile vardır., Ürün hafif ve rahattır., Terleme yaratmaz., Dökümlü ve salaş bir görünüm sağlar., Kolay kolay buruşmaz., Gösterişli ve şık bir görünüm sağlar., Ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz."
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 10 adetten fazla stok sunulmuştur."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 533916231,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327604444/914455602/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty833/product/media/images/20230418/18/327604444/914455602/1/1_org_zoom.jpg"
      ],
      price: 229.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 229.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 229.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "40",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "40",
          itemNumber: 914455602,
          stock: 0.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Viskon"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Günlük"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun Kol"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Klasik"
        },
        {
          id: 33,
          key: "Desen",
          value: "Desenli"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        },
        {
          id: 48,
          key: "Boy",
          value: "Kısa"
        },
        {
          id: 191,
          key: "Kol Boyu",
          value: "Uzun Kol"
        },
        {
          id: 124,
          key: "Ek Özellik",
          value: "Extra Esnek"
        },
        {
          id: 473,
          key: "Astar Durumu",
          value: "Astarsız"
        },
        {
          id: 101,
          key: "Cep",
          value: "Cepsiz"
        },
        {
          id: 117,
          key: "Dokuma Tipi",
          value: "Örgü"
        },
        {
          id: 178,
          key: "Kalınlık",
          value: "Orta"
        },
        {
          id: 66,
          key: "Paket İçeriği",
          value: "Tekli"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49579646,
      productCode: "TS-5439",
      name: "Kadın Yeşil Kapüşonlu Fashion Tunik",
      slug: "Kadın Yeşil Kapüşonlu Fashion Tunik",
      description: "Kadın Yeşil Kapüşonlu Fashion Tunik",
      contentDescriptions: [
        {
          bold: false,
          description: "Bayan kapüşonlu fashion tunik., triko lyocell kumaştır., kumaşın parlak bir görüntüsü ve yumuşak bir dokusu vardır., ürünün kumaşı dökümlüdür, vücut hatlarına yapışma yapmaz., tersten, hassas yıkamada yıkayınız., mankenin üzerindeki ürün xl bedendir., Modelin ölçüleri: Boy: 1.76 göğüs: 92, bel: 67, kalça: 96"
        },
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Tunik",
      productGroupId: 41717301,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Tunik",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/12/11378990/87836106/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/12/11378990/87836106/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/12/11378990/87836106/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/12/11378990/87836106/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/12/11378990/87836106/4/4_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/12/11378990/87836106/5/5_org_zoom.jpg"
      ],
      price: 229.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 229.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 229.99
      },
      rating: 5.0,
      ratingScore: {
        averageRating: 5.0,
        totalCount: 1.0
      },
      winnerVariant: "2XL",
      color: "YEŞİL",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "2XL",
          itemNumber: 87854956,
          stock: 1.0
        }
      ],
      productReviews: [
        {
          id: 150018703,
          comment: "cok guzel, kaliteli, rengi de guzel, bir yakasi cok aciq, terziye ehtiyac var. 105 kq 3xl aldim. guzel oldu",
          rate: 5.0,
          userFullName: "L** İ** V** İ** L** ş**",
          trusted: true,
          commentDateISOtype: "2021-12-29",
          lastModifiedDate: "29 Aralık 2021"
        }
      ],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Triko"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Uzun"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Bisiklet Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Yeşil"
        }
      ]
    },
    {
      merchantId: 215534,
      id: 49568059,
      productCode: "TS-2038",
      name: "Kadın Siyah Paris Baskılı Simli Bluz",
      slug: "Kadın Siyah Paris Baskılı Simli Bluz",
      description: "Kadın Siyah Paris Baskılı Simli Bluz",
      contentDescriptions: [
        {
          bold: false,
          description: "Bu üründen en fazla 10 adet sipariş verilebilir. 10 adetin üzerindeki siparişleri Tusse iptal etme hakkını saklı tutar."
        },
        {
          bold: false,
          description: "Kampanya fiyatından satılmak üzere 5 adetten az stok bulunmaktadır."
        },
        {
          bold: false,
          description: "İncelemiş olduğunuz ürünün satış fiyatını satıcı belirlemektedir."
        },
        {
          bold: false,
          description: "Bu ürün indirim kampanyasına dahil değildir."
        },
        {
          bold: false,
          description: "Bir ürün, birden fazla satıcı tarafından satılabilir. Birden fazla satıcı tarafından satışa sunulan ürünlerin satıcıları ürün için belirledikleri fiyata, satıcı puanlarına, teslimat statülerine, ürünlerdeki promosyonlara, kargonun bedava olup olmamasına ve ürünlerin hızlı teslimat ile teslim edilip edilememesine, ürünlerin stok ve kategorileri bilgilerine göre sıralanmaktadır."
        }
      ],
      tax: 10.0,
      categoryName: "Büyük Beden Bluz",
      productGroupId: 41705676,
      categoryHierarchy: "Giyim/Büyük Beden/Büyük Beden Bluz",
      brand: "TUSSE",
      hasFreeShipping: true,
      image: "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367723/87816646/1/1_org_zoom.jpg",
      images: [
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367723/87816646/1/1_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367723/87816646/2/2_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty12/product/media/images/20200922/11/11367723/87816646/3/3_org_zoom.jpg",
        "https://cdn.dsmcdn.com/ty11/product/media/images/20200922/11/11367723/87816646/4/4_org_zoom.jpg"
      ],
      price: 229.99,
      prices: {
        buyingPrice: 0.0,
        discountedPrice: 229.99,
        manipulatedOriginalPrice: 0.0,
        originalPrice: 229.99,
        sellingPrice: 229.99
      },
      rating: 0.0,
      ratingScore: null,
      winnerVariant: "L",
      color: "SİYAH",
      gender: "Kadın",
      variants: [
        {
          attributeId: 338,
          attributeName: "Beden",
          attributeType: "Size",
          attributeValue: "L",
          itemNumber: 87827370,
          stock: 1.0
        }
      ],
      productReviews: [],
      colors: [],
      hasStock: true,
      attributes: [
        {
          id: 179,
          key: "Kalıp",
          value: "Büyük Beden"
        },
        {
          id: 14,
          key: "Materyal",
          value: "Pamuklu"
        },
        {
          id: 33,
          key: "Desen",
          value: "Düz Renk"
        },
        {
          id: 12,
          key: "Kol Tipi",
          value: "Truvakar Kol"
        },
        {
          id: 200,
          key: "Kumaş Tipi",
          value: "Örme"
        },
        {
          id: 250,
          key: "Stil",
          value: "Klasik"
        },
        {
          id: 22,
          key: "Yaka Tipi",
          value: "Yuvarlak Yaka"
        },
        {
          id: 348,
          key: "Renk",
          value: "Siyah"
        }
      ]
    }
  ],
  userWishlist: [],
  userCart: [],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/apps/ecommerce/products').reply(config => {
  // eslint-disable-next-line object-curly-newline
  //console.log(config.params)
  const { q = '', sortBy = 'featured', perPage = 9, page = 1, gender='', categories = '', brand ='', rating = 0, priceRange= [0,10000] } = config.params
  

  const queryLowered = q.toLowerCase()

  let filteredData = data.products.filter(product => (product.name.toLowerCase().includes(queryLowered) || product.productCode.toLowerCase().includes(queryLowered)) && product.categoryName.toLowerCase().includes(categories.toLowerCase()) && product.brand.toLowerCase().includes(brand.toLowerCase()) && product.gender.toLowerCase().includes(gender.toLowerCase()) && (product.price <= priceRange[1] && product.price >= priceRange[0]) && product.rating == rating)
  if (rating==0){
    filteredData = data.products.filter(product => (product.name.toLowerCase().includes(queryLowered) || product.productCode.toLowerCase().includes(queryLowered)) && product.categoryName.toLowerCase().includes(categories.toLowerCase()) && product.brand.toLowerCase().includes(brand.toLowerCase()) && product.gender.toLowerCase().includes(gender.toLowerCase()) && (product.price <= priceRange[1] && product.price >= priceRange[0]))
  }

  let sortDesc = false
  const sortByKey = (() => {
    if (sortBy === 'price-desc') {
      sortDesc = true
      return 'price'
    }
    if (sortBy === 'price-asc') {
      return 'price'
    }
    sortDesc = true
    return 'id'
  })()

  const sortedData = filteredData.sort(sortCompare(sortByKey))
  if (sortDesc) sortedData.reverse()

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

  paginatedData.forEach(product => {
    /* eslint-disable no-param-reassign */
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
    product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1
    /* eslint-enable */
  })

  return [
    200,
    {
      products: paginatedData,
      total: filteredData.length,
      userWishlist: data.userWishlist,
      userCart: data.userCart,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Product
// ------------------------------------------------
mock.onGet(/\/apps\/ecommerce\/products\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.products.findIndex(p => p.id === productId)
  const product = data.products[productIndex]

  if (product) {
    // Add data of wishlist and cart
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
    product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1

    // * Add Dummy data for details page
    product.colorOptions = ['primary', 'success', 'warning', 'danger', 'info']
    product.sizeOptions = ['S   ','M   ','L   ','XL   ','XXL ','XXXL']

    return [200, { product }]
  }
  return [404]
})

// ------------------------------------------------
// GET: Return Wishlist Products
// ------------------------------------------------
mock.onGet('/apps/ecommerce/wishlist').reply(() => {
  const products = data.userWishlist.map(wishlistProduct => {
    const product = data.products.find(p => p.id === wishlistProduct.productId)
    product.isInCart = data.userCart.findIndex(p => p.productId === wishlistProduct.productId) > -1
    return product
  })

  return [200, { products }]
})

// ------------------------------------------------
// GET: Return Cart Products
// ------------------------------------------------
mock.onGet('/apps/ecommerce/cart').reply(() => {
  const products = data.userCart.map(cartProduct => {
    const product = data.products.find(p => p.id === cartProduct.productId)

    // Other data
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === cartProduct.productId) > -1
    product.qty = cartProduct.qty
    product.shippingDate = randomDate(nextDay, nextWeek)
    product.offers = getRandomInt(1, 4)
    product.discountPercentage = getRandomInt(3, 20)

    return product
  })

  return [200, { products }]
})

// ------------------------------------------------
// POST: Add Item in user Cart
// ------------------------------------------------
mock.onPost('/apps/ecommerce/cart').reply(config => {
  // Get product from post data
  const { productId } = JSON.parse(config.data)

  const { length } = data.userCart
  let lastId = 0
  if (length) lastId = data.userCart[length - 1].i

  data.userCart.push({
    id: lastId + 1,
    productId,
    qty: 1,
  })

  return [201]
})

// ------------------------------------------------
// DELETE: Remove Item from user Cart
// ------------------------------------------------
mock.onDelete(/\/apps\/ecommerce\/cart\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.userCart.findIndex(i => i.productId === productId)
  if (productIndex > -1) data.userCart.splice(productIndex, 1)

  return [200]
})

mock.onPost(/\/apps\/ecommerce\/item\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)
  const datalar = JSON.parse(config.data)
  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.userCart.findIndex(i => i.productId === productId)
  data.userCart[productIndex].qty = datalar.qty
  return [200]
})

// ------------------------------------------------
// POST: Add Item in user Wishlist
// ------------------------------------------------
mock.onPost('/apps/ecommerce/wishlist').reply(config => {
  // Get product from post data
  const { productId } = JSON.parse(config.data)

  const { length } = data.userWishlist
  let lastId = 0
  if (length) lastId = data.userWishlist[length - 1].i

  data.userWishlist.push({
    id: lastId + 1,
    productId,
  })

  return [201]
})

// ------------------------------------------------
// DELETE: Remove Item from user Wishlist
// ------------------------------------------------
mock.onDelete(/\/apps\/ecommerce\/wishlist\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.userWishlist.findIndex(i => i.productId === productId)
  if (productIndex > -1) data.userWishlist.splice(productIndex, 1)

  return [200]
})
