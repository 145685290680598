import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  faqData: {
    // payment
    payment: {
      icon: 'CreditCardIcon',
      title: 'Ödeme',
      subtitle: 'Güvenli Alışveriş',
      qandA: [
        {
          question: 'Nasıl Ödeme Yapabilirim?',
          ans:
            'Alışverişinizi tamamladığınızda ödemelerinizi Kredi Kartları, Banka Kartları veya Kapıda Ödeme seçeneği ile gerçekleştirebilirsiniz.',
        },
        {
          question: 'Kapıda Ödeme Seçeneğiniz Var mı?',
          ans:
            'Evet, UPS Kargo güvencesiyle Kapıda Ödemenizi Kredi Kartı veya Nakit olarak yapabilirsiniz. Kapıda Ödeme hizmet bedeli 5.50 TL`dir.',
        },
        {
          question: 'İnternet Sitenizden Kartla Ödeme Yapmak Güvenli midir?',
          ans:
            'Sitemizden yapacağınız alışverişlerde Kredi Kartı ve ATM kartı bilgileriniz yalnızca sipariş işlemi gerçekleştirilirken kullanılır ve kesinlikle kayıtlı tutulmaz. Sitemizden güvenli olarak alışveriş yapabilmeniz için sitemiz üzerinden alınan bilgiler SSL olarak adlandırılan ve güvenli bilgi aktarımı sağlayan teknoloji ile taşınır.',
        },
        {
          question: 'Hangi Bankanın Kredi Kartları ile Ödeme Yapabilirim?',
          ans:
            'Sitemizden yapacağınız alışverişleri tüm bankaların kredi kartlarından tek çekim, Akbank, Garanti Bankası, QNB Finansbank, İş Bankası Kredi Kartlarından ise 6 aya kadar taksit imkânları ile gerçekleştirebilirsiniz.',
        },
        {
          question: 'Ödeme Ekranında Hata Alıyorum, Ne Yapmalıyım?',
          ans:
            '* Kartınızın internet alışverişlerine açık olduğunu kontrol etmelisiniz, * Kart ile ilgili istenen bilgileri doğru girdiğinizden emin olmalısınız, * Kartınızın limitinin yeterli olduğundan emin olmalısınız, * Kartınızın kullanıma açık olduğundan emin olmalısınız, Bu durumlar dışında hata almaya devam ediyorsanız; +90 (212) 598 51 23 numaralı telefondan müşteri hizmetlerimizi arayarak veya aldığınız hatayla alakalı bankanızla görüşme yaparak konu ile ilgili destek alabilirsiniz.',
        },
        {
          question: 'Sitede Bulunan Ürünlerin Fiyatlarına KDV Dahil midir?',
          ans:
            'Sitede bulunan tüm ürünlerin fiyatlarına KDV dahildir.',
        },
        
      ],
    },
    // delivery
    delivery: {
      icon: 'ShoppingBagIcon',
      title: 'Teslimat',
      subtitle: 'Kargo ve Teslimat Süreçleri',
      qandA: [
        {
          question: 'Hangi kargo firması ile çalışıyorsunuz?',
          ans:
            'İnternet sitemizden yapacağınız alışverişler sizlere UPS Kargo güvencesiyle teslim edilmektedir.',
        },
        {
          question:
            'Kargo ücreti ne kadardır?',
          ans:
            'İnternet sitemizden yapacağınız tüm alışverişlerde kargo ücreti sadece 8,99 TL`dir. 100 TL ve üzerinde yapacağınız alışverişlerinizden ise Kargo Ücreti alınmamaktadır.',
        },
        {
          question: 'Kapıda ödeme için ekstra bir ücret ödemem gerekiyor mu?',
          ans:
            'Sitemizden kapıda ödeme seçeneği ile yapacağınız alışverişlerde kargo ücretine ek olarak 3,99 TL kapıda ödeme hizmet bedeli yansıtılacaktır. Kapıda ödeme seçeneği ile sipariş verebilmeniz için üyelik hesabı oluşturmanız gerekmektedir.',
        },
        {
          question: 'Kargom ne zaman ulaşır?',
          ans:
            'Kargonuz, biz kargoya verdikten sonra UPS kargo aracılığı ile 5 iş günü içerisinde adresinize teslim edilecektir..Mobil bölgelere (Merkeze uzak ilçelere) teslimatlar UPS kargonun belirlemiş olduğu günlerde tarafınıza ulaşmaktadır. Bu sebepten dolayı kargonuzun teslim sürecinin biraz daha uzun olabileceği dikkate alınmalıdır.Resmi tatil ve bayram dönemlerinde kargo firması çalışmadığı için teslimatlar tatilden sonraki ilk 2 iş gününde yapılacaktır.',
        },
        {
          question: 'Kargomu Nasıl Takip Edebilirim?',
          ans:
            'Siparişiniz kargoya teslim edildikten sonra kargonuzun durumunu profilinizde bulunan `Siparişlerim` butonunda bulunan veya mailinize gelen kargo takip numaranız ile aşağıda vermiş olduğumuz linke tıklayarak takip edebilirsiniz.',
        },
        {
          question: 'Kargomu benden başkası teslim alabilir mi?',
          ans:
            'Kargonuzu sizin dışınızda; alıcı olarak belirttiğiniz kişi veya kimlik imrazı ile bir başkası teslim alabilir.Paketinizin teslim edilmesi sırasında teslim alan kişiden imzası istenecektir.',
        },
        {
          question: 'Kargom ben adresimde yokken gelmiş ne yapmalıyım?',
          ans:'Kargonuz sizi veya yakınınızı adresinizde bulamazsa başka bir gün tekrar dağıtıma çıkacaktır; fakat ikinci seferde de size ulaşılamazsa paketiniz kargo şirketinde beklemeye alınacaktır. Bu gibi durumlarda 0212 598 51 23 numaralı Müşteri Temsilcilerimize hafta içi 09:00 - 18:00, Cumartesi 09:00 - 15:00 saatleri arasında ulaşarak veya UPS Kargo firmasını ayarak kargonuz ile alakalı ayrıntılı bilgi alabilirsiniz.'
        },
        {
          question:'Kargom elime ulaşmadı, ne yapmalıyım?',
          ans:'Kargonuzun teslim edilmemesi durumunda `Sipariş Takibi` ekranında bulunan `Kargo Takip` linkine tıklayarak kargonuzun durumunu görebilir veya 0212 598 51 23 numaralı Müşteri Hizmetlerimizi arayarak bilgi alabilirsiniz.'
        },
        {
          question:'Siparişim kargoya verildikten sonra adres değişikliği yapabilir miyim?',
          ans:'Siparişinizin durumu `Kargoya Verildi` ise adres değişikliği tarafımızca yapılamamaktadır. Adres değişiklik bilgisini 0212 598 51 23 UPS Kargo müşteri hizmetlerini arayarak güncelleyebilirsiniz.'
        },
        {
          question:'Resmi tatil günlerinde siparişim teslim edilir mi?',
          ans:'Resmi tatil ve bayram dönemlerinde kargo firması çalışmadığı için teslimatlar tatilden sonraki ilk 2 iş gününde yapılacaktır.'
        },
        {
          question:'Yurtdışına teslimat yapıyor musunuz?',
          ans:'Satın almış olduğunuz ürünler şuan için sadece Türkiye sınırları içerisinde UPS Kargo güvencesiyle teslim edilmektedir.'
        }
      ],
    },
    // cancellation and return
    cancellationReturn: {
      icon: 'RefreshCwIcon',
      title: 'iade & Değişim',
      subtitle: 'İade Değişim Koşulları',
      qandA: [
        {
          question: 'Online alışverişimdeki ürünleri iade edebilir miyim?',
          ans:
            'Almış olduğunuz ürünleri; orijinal ambalaj veya kutusunda, etiketli ve kullanılmamış olarak sitemiz üzerinden iade edebilirsiniz. İade edilecek ürünlerle birlikte e-Arşiv Faturanızın da gönderilmesi gerekmektedir. Faturası gönderilmeyen ürünlerin iade işlemleri kabul edilmemektedir.',
        },
        {
          question: 'Online alışverişimdeki ürünleri kaç gün içerisinde iade edebilirim?',
          ans:
            'Ürünleri, size ulaştığı günü takiben yasal süre olan 14 Gün gün içerisinde iade edebilirsiniz.',
        },
        {
          question: 'Ürünleri mağazalarınızdan iade edebilir miyim?',
          ans:
            'Online alışverişinizde almış olduğunuz ürünleri sadece sitemiz üzerinden talep oluşturarak veya 0212 598 51 23 numaralı Müşteri Hizmetlerimizi arayıp destek alarak iade edebilirsiniz. Mağazalarımızda ürün iadesi yapılamamaktadır.',
        },
        {
          question: 'İade işlemleri için ürünleri hangi kargo şirketi ile göndereceğim?',
          ans:
            'İade işlemlerinde, ürün/ürünlerinizi anlaşmalı olduğumuz kargo firması UPS Kargo ile UPS zarfı içerisinde veya orijinal kutusunda ücretsiz gönderebilirsiniz. UPS Kargo dışındaki kargo firmaları ile yapılan gönderilerin kargo ücreti müşterilerimize aittir.',
        },
        {
          question: 'İade edilecek ürünleri hangi adrese göndermeliyim?',
          ans:
            'Ürünlerinizi Ups kargo 945F7E iade kodu ile Fatih Mahallesi Prof.Dr. Aziz Sancar Cad No:34 Küçükçekmece/İstanbul adresine gönderebilirsiniz.',
        },
        {
          question: 'e-Arşiv faturamı kaybettim, ne yapmalıyım?',
          ans:
            'Faturanızı kaybetmeniz durumunda 0212 598 51 23 numaralı Müşteri Hizmetlerimizi arayarak ya da info@tusse.com.tr adresine e-mail göndererek faturanızı tekrar talep edebilirsiniz. Talebi alınan fatura istekleri belirtmiş olduğunuz mail adresinize en kısa sürede ulaştırılacaktır',
        },
        {
          question: 'İade edilen ürünlerin geri ödemesi ne zaman yapılır?',
          ans:
            'Kargoya teslim ettiğiniz ürünler depomuza ulaştığında ürünlerin iade şartlarına uygunluğu incelenir. Ürünlerin bu şartlara uygun olması durumunda, iade tutarı 3 iş günü içerisinde hesabınıza yatırılacak ve bilgisi tarafınıza mail olarak ulaştırılacaktır. İade tutarının hesabınızda gözükme süresi banka prosedürleri gereği 7 – 15 iş gününü bulabilmektedir. İade ettiğiniz ürün/ürünlerin geri ödemesi siparişte kullandığınız ödeme metodu kullanılarak iade edilir',
        },
        {
          question:'Online alışverişimdeki ürünleri iptal edebilir miyim?',
          ans:'Siparişiniz `Hazırlık Aşamasında` ya da `Kargoya Verildi`durumunda değilse iptal işlemlerinizi 0212 598 51 23 numaralı Müşteri Hizmetlerimizi arayarak veya info@tusse.com.tr adresine e-mail göndererek gerçekleştirebilirsiniz. Siparişinizin durumunu `Hesabım`sekmesindeki `Siparişlerim` penceresinden kontrol edebilirsiniz'
        },
        {
          question:'İptal edilen siparişlerimi tekrar aktif edebilir miyim?',
          ans:'İptal edilen siparişlerinizin aktif edilmesi mümkün olmadığından beğendiğiniz ürünler için yeni bir sipariş oluşturmanız gerekmektedir.'
        },
        {
          question:'Online alışverişimdeki ürünleri değiştirebilir miyim?',
          ans:'Satın almış olduğunuz ürünlerde değişim işlemlerinde ürününüz firmamıza ulaştıktan sonra ücreti hesabınıza bakiye olarak yüklenir, mevcut bakiyeniz ile sayfamızdan yeniden alışveriş yapabilirsiniz.'
        }
      ],
    },
    // my orders
    myOrders: {
      icon: 'PackageIcon',
      title: 'Siparişlerim',
      subtitle: 'Alışveriş Listesi ve Durumları',
      qandA: [
        {
          question: 'Nasıl Sipariş Verebilirim?',
          ans:
            'Satın almak istediğiniz ürün/ürünleri sepetinize ekleyiniz. Sepetinizde bulunan ürünleri satın almak için sağ üst köşede yer alan `Sepetim ` bağlantısına tıklayabilir ve seçmiş olduğunuz ürünleri görebilirsiniz. Alt kısımda yer alan `Alışverişi tamamla`butonuna tıklayarak adres ve ödeme bilgilerinizin isteneceği sayfaya yönlendirilirsiniz. Bilgilerinizi doğru bir şekilde girdikten sonra işleme devam edip alışverişinizi tamamlayabilirsiniz. Daha önceden sitemize üye olduysanız bilgileriniz otomatik olarak sisteme eklenecektir.',
        },
        {
          question: 'Siparişimi Nasıl Kontrol Edebilirim?',
          ans:
            'Siparişlerinizi görmek için profilinizde bulunan ` Siparişlerim` sekmesine tıklayınız. Açılan ekranda tüm geçmiş ve güncel siparişlerinizin durumunu görebilirsiniz. Detaylı incelemek için ilgili siparişe tıklayabilirsiniz.',
        },
        {
          question: 'Siparişimi Nasıl Değiştirebilirim?',
          ans:
            'Eğer alışverişi tamamlamadıysanız profilinizde bulunan `Sepetim ` sekmesine tıklayarak seçmiş olduğunuz ürünleri değiştirebilir sepetinize yeni ürün ekleyip çıkarabilirsiniz. Ödeme yaptığınız takdirde siparişinizde değişim yapamazsınız.',
        },
        {
          question: 'Siparişimi İptal Edebilir miyim?',
          ans:
            'Siparişiniz onaylanıp kargoya verilmediyse siparişinizi profilinizden iptal edebilirsiniz. Siparişiniz kargoya verildiği takdirde ise iade işlemi yapabilirsiniz. Siparişinizdeki ürünler için iade süresi 14 gündür. Bu sürede, sipariş içeriğindeki ürünleri iade edebilirsiniz. İade işlemleriniz için `Siparişlerim`bölümünden talep oluşturmanız gerekmektedir. Bu aşamada, iade için kargo firmasına teslim ettiğiniz ürünlerin kargo takip numarasını sisteme eklemeniz gerekmektedir.',
        },
        {
          question: 'Sipariş Verdikten Sonra Yeni Ürün Ekleyebilir miyim?',
          ans:
            'Sipariş verip ödemeyi gerçekleştirdiğinizde siparişinize yeni ürün ekleyemezsiniz.',
        },
        {
          question:'Sipariş Verdikten Sonra Teslimat Adresimi Değiştirebilir miyim?',
          ans:'Sipariş verdikten sonra teslimat adresinizin değiştirilmesi için, üyeyseniz profilinizde bulunan ` Siparişlerim ` menusunden, üye değilseniz de size gelen mailden siparişinizin durumunu kontrol edebilirsiniz. Eğer siparişiniz kargoya verilmediyse "Hesabım" menusunden yeni bir teslimat adresi oluşturabilir ve bilgilerinizi güncelleyebilirsiniz. Siparişiniz kargoya verildiği takdirde UPS Kargo Müşteri hizmetlerine 444 00 66 numaralı telefondan ulaşarak gerekli yardımı alabilirsiniz'
        }
      ],
    },
    // product and services
    productServices: {
      icon: 'UserIcon',
      title: 'Üyelik',
      subtitle: 'Üyelik İşlemleri',
      qandA: [
        {
          question: 'tusse.com`a nasıl üye olabilirim?',
          ans:
            'tusse.com`a ücretsiz olarak kolayca üye olabilirsiniz.Üye Ol butonlarını kullanarak ve ilgili alanları eksiksiz bir şekilde doldurarak üyelik işleminizi gerçekleştirebilirsiniz.',
        },
        {
          question: 'Üye olmadan sitenizden alışveriş yapabilir miyim?',
          ans:
            'Evet, üye olmadan sitemizde ziyetçi olarak alışveriş yapabilirsiniz; fakat üyelerimize sunulan avantajlar, özel fırsatlar ve kapıda ödeme seçeneğinden yararlanabilmeniz için üye olmanız önerilir.',
        },
        {
          question: 'Şifre mi unuttum, ne yapmalıyım?',
          ans:
            'Şifrenizi unuttuğunuzda, sağ üst köşede giriş yaptığınız alana tıklayıp ekran üzerindeki `Şifremi Unuttum` bağlantısına tıklayınız. Açılan ekranda üye olurken kullanmış olduğunuz e-posta adresinizi yazıp `Gönder` butonuna basarak mevcut e-posta hesabınıza gelen mail ile şifrenizi değiştiriniz.',
        },
        {
          question: 'Şifremi değiştirmek istiyorum, ne yapmalıyım?',
          ans:
            'Şifre değişikliği yapabilmek için sisteme kayıtlı e-postanız ve şifrenizle üye girişi yapınız. Giriş yaptıktan sonra kullanıcı isminizin yanında bulunan ok işaretinden `Hesabım` butonuna tıklayınız. Açılan ekranın sol tarafında bulunan `Üyelik İşlemleri` kısmında şifre değişikliğine tıklayıp, açılan pencerede mevcut şifrenizi ve yeni şifrenizi yazıp ‘Güncelle’ butonuna tıklayarak şifre değiştirme işlemini tamamlayınız.',
        },
        {
          question:'Adresimi değiştirmek istiyorum, nasıl yapmalıyım?',
          ans:'Adres değişikliği yapabilmek için sisteme kayıtlı e-postanız ve şifrenizle üye girişi yapınız. Giriş yaptıktan sonra kullanıcı isminizin yanında bulunan ok işaretinden ‘Hesabım’ butonuna tıklayınız. Açılan ekranın sol tarafında bulunan ‘Üyelik İşlemleri’ kısmında ‘Adres Listem’ kısmına tıklayıp, açılan pencerede mevcut adresinizi değiştirebilir, yeni adres eklemek için de pencerenin sağ üst köşesinde bulunan ‘Adres Ekle’ butonuna tıklayarak farklı bir adres tanımlayabilirsiniz.'
        },
        {
          question:'Üyelik bilgilerimi güncellemek istiyorum, ne yapmalıyım?',
          ans:'Bilgi güncellemesi yapmak için sisteme kayıtlı e-postanız ve şifrenizle üye girişi yapınız. Giriş yaptıktan sonra kullanıcı isminizin yanında bulunan ok işaretinden ‘Hesabım’ butonuna tıklayınız. Açılan ekranın sol tarafında bulunan ‘Üyelik İşlemleri’ kısmında ‘Üyelik Bilgilerim’ kısmına tıklayıp, açılan pencerede bilgilerinizi değiştirip güncelle butonuna basınız.'
        }
      ],
    },
  },
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
