import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('slugable', function(value) {
	value = value.toLowerCase();
    // faz as substituições dos acentos
    value = value.replace(/[á|ã|â|à]/gi, "a");
    value = value.replace(/[é|ê|è]/gi, "e");
    value = value.replace(/[í|ì|î|I|ı]/gi, "i");
    value = value.replace(/[õ|ò|ó|ô]/gi, "o");
    value = value.replace(/[ú|ù|û]/gi, "u");
    value = value.replace(/[ç]/gi, "c");
    value = value.replace(/[ñ]/gi, "n");
    value = value.replace(/[á|ã|â]/gi, "a");
    value = value.replace(/[Ş|ş|]/gi, "s");
    // faz a substituição dos espaços e outros caracteres por - (hífen)
    value = value.replace(/\W/gi, "-");
    // remove - (hífen) duplicados
    value = value.replace(/(\-)\1+/gi, "-");
    return value;
});

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 0
  });
  return formatter.format(value);
});

import Tawk from 'vue-tawk'
Vue.use(Tawk, {
  tawkSrc: 'https://embed.tawk.to/60461ede385de407571dc2c6/1f08uh67p'
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
